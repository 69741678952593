import styled from "styled-components";
const BgLogo = process.env.REACT_APP_ASSETS_URL + "/imgs/homeForest.webp";
const LeafBackground =
  process.env.REACT_APP_ASSETS_URL + "/imgs/leafBackgorund.webp";

import { FontStyles } from "../../../components";
import { fonts, SVG } from "../../../config";

const Container = styled.div(({ theme, $menuHeight }) => {
  return {
    zIndex: 1,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    backgroundColor: "#ededed",

    borderRadius: "5px",
    flex: 1,
    width: "100%",

    height: "auto",

    [`@media (max-width:${745 - 1}px)`]: {},
    [`@media (max-width:${470 - 1}px)`]: {},
    [`@media (min-width:${1440}px)`]: {},
  };
});

const BgVideoContainer = styled.div(({ theme, $menuHeight }) => {
  return {
    position: "relative",
    width: "100%",
    zIndex: 0,
    display: "flex",
    justifyContent: "center",

    [`@media (max-width:${745 - 1}px)`]: {},
    [`@media (max-width:${470 - 1}px)`]: {
      width: "100%",
    },
    [`@media (min-width:${1440}px)`]: {},
  };
});

const BgVideo = styled.video(({ theme, $menuHeight }) => {
  return {
    objectFit: "cover",
    width: "100%",
    maxHeight: `${697 + $menuHeight}px`,
    position: "relative",
    marginTop: `-${$menuHeight}px`,
    pointerEvents: "none",
    [`@media (max-width:${745 - 1}px)`]: {},
    [`@media (max-width:${470 - 1}px)`]: {
      width: "100%",
      marginTop: 0,
    },
    [`@media (min-width:${1440}px)`]: {},
  };
});

const CoinContainer = styled.div(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    position: "relative",
    flexDirection: "row",
    width: "100%",
    maxHeight: "486px",

    paddingInline: spacing(15.875),
    paddingBlock: spacing(4.25),
    [`@media (max-width:${1024 - 1}px)`]: {
      flexDirection: "column",
      maxHeight: "none",
      height: "fit-content",
      paddingInline: spacing(6),
      paddingBottom: spacing(4.25),
      paddingTop: 0,
    },
    [`@media (max-width:${581 - 1}px)`]: {
      paddingInline: spacing(3.25),
      paddingBottom: spacing(2.25),
    },
  };
});

const HalfContainer = styled.div(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    maxWidth: "50%",
    [`@media (max-width:${1024 - 1}px)`]: {
      maxWidth: "100%",
    },
    [`@media (max-width:${581 - 1}px)`]: { maxWidth: "100%" },
  };
});

const CoinText = styled.p(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    maxHeight: "300px",
    ...FontStyles.medium22,
    fontFamily: fonts.regularCf,
    overflowY: "scroll",
    overflowX: "hidden",
    fontSize: spacing(2.5),
    [`@media (max-width:${1024 - 1}px)`]: {
      maxHeight: "355px",
      height: "fit-content",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: spacing(1.5),
      maxHeight: "180px",
    },
  };
});

const CoinImg = styled.img(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    position: "absolute",
    maxWidth: "60%",
    top: "50%",
    right: 0,
    transform: "translate(0, -52%)",
    zIndex: 99,
    [`@media (max-width:${1024 - 1}px)`]: {
      display: "none",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      display: "none",
    },
  };
});

const CoinImg2 = styled.img(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    display: "none",
    position: "relative",
    maxWidth: "50%",
    width: "408.525px",
    marginTop: "-20%",
    zIndex: 99,
    [`@media (max-width:${1024 - 1}px)`]: {
      display: "flex",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      display: "flex",
    },
  };
});

const Logo = styled(SVG.BioLogo)(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    color: "black",
    maxWidth: "369px",
    width: "100%",
    height: "fit-content",
    [`@media (max-width:${1024 - 1}px)`]: {
      width: "460px",
      maxWidth: "51vw",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      display: "flex",
    },
  };
});

const PlantContainer = styled.div(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    background: "#1A2024",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    [`@media (max-width:${1024 - 1}px)`]: {
      flexDirection: "column",
    },
    [`@media (max-width:${581 - 1}px)`]: { flexDirection: "column" },
  };
});

const PlantImage = styled.div(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    height: "100%",
    width: "100%",
    maxWidth: "41vw",
    minHeight: "317.694px",
    backgroundImage: `url(${imgSrc})`,
    backgroundSize: "cover",
    backgroundPositionX: "center",
    backgroundPositionY: "bottom",
    [`@media (max-width:${1024 - 1}px)`]: {
      flexDirection: "column",
      maxWidth: "100%",
      maxHeight: "313.897px",
      minHeight: 0,
      height: "100vh",
    },
    [`@media (max-width:${581 - 1}px)`]: { maxHeight: "165.809px" },
  };
});

const TextContainer = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: spacing(2),
    paddingInline: spacing(3.75),
    paddingBlock: spacing(5.5),
    maxWidth: `calc(59vw - ${spacing(7.5)}px)`,
    [`@media (max-width:${1024 - 1}px)`]: {
      gap: spacing(2),
      paddingInline: spacing(4.25),
      paddingBlock: spacing(5.5),
      maxWidth: "100%",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      gap: spacing(1),
      paddingInline: spacing(3),
      paddingBlock: spacing(2.875),
      paddingTop: spacing(1),
    },
  };
});

const PlantTitle = styled.p(({ theme }) => {
  const { spacing } = theme;
  return {
    padding: 0,
    margin: 0,
    color: "#E7FF00",
    ...FontStyles.bold32,
    fontFamily: fonts.boldCf,
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(5),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: spacing(2.5),
    },
  };
});

const PlantDescription = styled.p(({ theme }) => {
  const { spacing } = theme;
  return {
    padding: 0,
    margin: 0,
    color: "white",
    ...FontStyles.regular16,
    fontFamily: fonts.regularCf,
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(2.25),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: spacing(1.25),
    },
  };
});

const TechContainer = styled.div(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    backgroundImage: `url(${imgSrc})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "1847px 545px",
    backgroundPositionY: "center",
    backgroundPositionX: "right",

    paddingBlock: spacing(7.5),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    [`@media (max-width:${1024 - 1}px)`]: {
      flexDirection: "column",
      backgroundSize: "cover",
      paddingInline: spacing(6.75),
      paddingBlock: spacing(10),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      paddingInline: spacing(3.75),
      paddingBlock: spacing(5),
      flexDirection: "column",
    },
    [`@media (min-width:${1441}px)`]: { backgroundSize: "cover" },
  };
});

const TechItem = styled.div(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    maxWidth: "423px",
    height: "100%",
    [`@media (max-width:${1024 - 1}px)`]: { maxWidth: "none" },
    [`@media (max-width:${581 - 1}px)`]: {},
  };
});

const TechLine = styled.div(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    backgroundColor: "white",
    width: "1px",
    height: "100%",
    marginInline: spacing(5.25),
    [`@media (max-width:${1024 - 1}px)`]: { display: "none" },
    [`@media (max-width:${581 - 1}px)`]: {},
  };
});

const TechIconBox = styled.div(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    position: "relative",
    width: "fit-content",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "fit-content",
    maxWidth: "240px",
    maxHeight: "240px",
    [`@media (max-width:${1024 - 1}px)`]: {
      maxWidth: "281px",
      maxHeight: "281px",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      maxWidth: "148.536px",
      maxHeight: "148.536px",
    },
  };
});

const TokenIcon = styled(SVG.TokenIcon)(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    transform: "translate(50%, 50%)",
    position: "absolute",
    bottom: "50%",
    right: "50%",
    [`@media (max-width:${1024 - 1}px)`]: {},
    [`@media (max-width:${581 - 1}px)`]: { maxHeight: "34px" },
  };
});

const WhitepaperIcon = styled(SVG.WhitepaperIcon)(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    transform: "translate(50%, 50%)",
    position: "absolute",
    bottom: "50%",
    right: "50%",
    [`@media (max-width:${1024 - 1}px)`]: {},
    [`@media (max-width:${581 - 1}px)`]: { maxHeight: "34px" },
  };
});

const TechItemTitle = styled.p(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    padding: 0,
    margin: 0,
    textAlign: "center",
    color: "white",
    ...FontStyles.bold32,
    fontFamily: fonts.boldCf,
    marginBottom: spacing(1.25),
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(5.75),
    },
    [`@media (max-width:${581 - 1}px)`]: { fontSize: spacing(3) },
  };
});

const TechItemDescription = styled.p(({ theme, other }) => {
  const { spacing } = theme;
  return {
    padding: 0,
    margin: 0,
    textAlign: "center",
    color: "white",
    ...FontStyles.regular18,
    fontFamily: fonts.regularCf,
    maxWidth: other ? "356px" : "378px",
    fontSize: spacing(20 / 8),
    [`@media (max-width:${1024 - 1}px)`]: {
      maxWidth: "none",
      fontSize: spacing(2.75),
    },
    [`@media (max-width:${581 - 1}px)`]: { fontSize: spacing(1.5) },
  };
});

const Styles = {
  Container,
  BgVideoContainer,
  BgVideo,
  CoinText,
  CoinContainer,
  HalfContainer,
  CoinImg,
  CoinImg2,
  Logo,
  PlantContainer,
  PlantImage,
  TextContainer,
  PlantTitle,
  PlantDescription,
  TechItemTitle,
  TechContainer,
  TechItem,
  TechIconBox,
  TokenIcon,
  TechItemDescription,
  TechLine,
  WhitepaperIcon,
};

export default Styles;
