import { useEffect, useState } from "react";
import Styles from "../EsqueceuSenhaStyles";
import { LocalStorage } from "../../../utils";
import Texts from "../../../config/texts";

const TIME_IN_MILISECONDS_TO_COUNTDOWN = 90 * 1000;
const INTERVAL_IN_MILISECONDS = 1000;

function Countdown(props) {
  const texts =
    Texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].forgetPassword.countdown;
  const [timer, setTimer] = useState<number>(TIME_IN_MILISECONDS_TO_COUNTDOWN);
  const [referenceTime, setReferenceTime] = useState(Date.now());

  useEffect(() => {
    const countDownUntilZero = () => {
      setTimer((prevTime) => {
        if (prevTime <= 0) return 0;

        const now = Date.now();
        const interval = now - referenceTime;

        setReferenceTime(now);
        return prevTime - interval;
      });
    };

    if (timer > 0) {
      setTimeout(countDownUntilZero, INTERVAL_IN_MILISECONDS);
    }
  }, [timer, referenceTime]);

  const ReSendCode = async () => {
    props.setReSend(() => {
      setTimer(90 * 1000);
      setReferenceTime(Date.now());
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <Styles.Countdown>
        <Styles.InfoIcon /> {texts.limitTime}
        <u>
          {`${
            Math.floor(timer / 60000) < 0
              ? "00"
              : String("0" + parseInt(String(Math.floor(timer / 60000))))
          }` +
            ":" +
            `${
              (timer % 60000) / 1000 < 0
                ? "00"
                : String(
                    `${(timer % 60000) / 1000 < 10 ? "0" : ""}` +
                      parseInt(String((timer % 60000) / 1000))
                  )
            }`}
        </u>
      </Styles.Countdown>
      <Styles.ReSend
        disabled={timer <= 0 ? false : true}
        onClick={() => timer <= 0 && ReSendCode()}
      >
        {texts.resendCode}
      </Styles.ReSend>
    </div>
  );
}

export default Countdown;
