import styled from "styled-components";
import fonts from "../../config/fonts";
import { ButtonContained } from "../../components";
const grainImage = process.env.REACT_APP_ASSETS_URL + "/imgs/grainBackground.webp";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { SVG } from "../../config";

const Container = styled.div(({ $menuHeight }) => {
  return {
    backgroundColor: "rgba(5, 5, 0, 1)",

    width: "100%",
    position: "relative",
    height: "auto",
    // minHeight: `calc(100vh - ${$menuHeight + 16}px)`,

    overflow: "hidden",
    backgroundImage: `url(${grainImage})`,
    backgroundSize: "cover",

    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };
});

const Content = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    flexDirection: "column",
    padding: spacing(6.25),
    overflowX: "auto",
    background: "white",
    width: "100%",
    maxWidth: "1358px",
    height: "auto",
    marginTop: spacing(6.88),
    borderRadius: spacing(7.5),
    overflow: "hidden",
    [`@media (max-width:${1024 - 1}px)`]: {
      maxWidth: "645px",
      background: "#F2F2F2",
      borderRadius: spacing(2.8375),
      padding: spacing(4),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      maxWidth: "340px",
      borderRadius: spacing(1.5),
      padding: spacing(1.875),
      marginTop: spacing(3),
    },
    [`@media (max-width:${426 - 1}px)`]: {
      maxWidth: "300px",
    },
  };
});

const ContentBlack = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    backdropFilter: "blur(16.52573013305664px)",
    display: "flex",
    position: "relative",
    justifyContent: "center",
    flexDirection: "column",
    padding: spacing(6.25),
    overflowX: "auto",
    width: "100%",
    maxWidth: "1358px",
    height: "auto",
    marginTop: spacing(6.88),
    borderRadius: spacing(7.5),
    overflow: "hidden",
    border: "2.509px solid rgba(231, 255, 0, 0.51)",
    background:
      "linear-gradient(232deg, rgba(231, 255, 0, 0.13) 3.16%, rgba(231, 255, 0, 0.06) 20.25%, rgba(231, 255, 0, 0.00) 40.87%)",
    boxShadow: "20.07223px 30.10834px 107.88821px 0px rgba(0, 0, 0, 0.15)",
    [`@media (max-width:${1024 - 1}px)`]: {
      maxWidth: "645px",
      borderRadius: spacing(2.8375),
      padding: spacing(4),
      border: "1.314px solid rgba(231, 255, 0, 0.51)",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      maxWidth: "340px",
      borderRadius: spacing(1.5),
      padding: spacing(1.875, 1.875, 0, 1.875),
      border: "0.694px solid rgba(231, 255, 0, 0.51)",
      marginTop: spacing(3),
    },
    [`@media (max-width:${426 - 1}px)`]: {
      maxWidth: "300px",
    },
  };
});

const Title = styled.p(({ theme, $bigger }) => {
  const { spacing } = theme;
  return {
    color: "#1A1A1A",
    fontFamily: fonts.boldCf,
    fontSize: spacing(5),
    margin: 0,
    lineHeight: "115.432%",
    textAlign: "flex-start",
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: $bigger ? spacing(3) : spacing(2.3675),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: $bigger ? spacing(1.5) : spacing(1.25),
    },
  };
});

const Subtitle = styled(Title)(({ theme, $green, $bigger }) => {
  const { spacing } = theme;
  return {
    color: $green && "#E7FF00",
    fontFamily: $green && fonts.light,
    fontSize: spacing(4),
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: $bigger ? spacing(2.84) : spacing(1.625),
      fontFamily: $bigger && fonts.medium,
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: $bigger ? spacing(1) : spacing(0.86),
      lineHeight: "21.5%",
    },
  };
});

const YellowBall = styled.div(() => {
  return {
    position: "absolute",

    top: 0,
    right: 0,
    transform: "translate(90%, -50%)",
    borderRadius: "996px",
    width: "798.961px",
    height: "996px",
    filter: "blur(225px)",
    background: "#E7FF00E6",

    zIndex: 0,

    [`@media (max-width:${1024 - 1}px)`]: {
      borderRadius: "514.6px",
      width: "268.364px",
      height: "268.364px",
      filter: "blur(116.25px)",
      top: -250,
      right: 200,
    },
    [`@media (max-width:${581 - 1}px)`]: {
      borderRadius: "514.6px",
      width: "292.1024px",
      height: "247.19px",
      filter: "blur(116.25px)",
      top: -250,
      right: 200,
    },
  };
});

const YellowBallTwo = styled.div(() => {
  return {
    position: "absolute",
    transform: "translate(-40%, 50%)",
    bottom: 0,
    left: 0,
    borderRadius: "631px",
    width: "631px",
    height: "601px",
    background: "#E7FF00E6",
    filter: "blur(300px)",

    [`@media (max-width:${1024 - 1}px)`]: {
      width: "554.205px",
      height: "467.963px",
      filter: "blur(155px)",
      transform: "translate(-75%, 75%)",
      borderRadius: "554.205px",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      width: "292.1024px",
      height: "247.19px",
      filter: "blur(155px)",
      transform: "translate(-75%, 75%)",
      borderRadius: "554.205px",
    },
  };
});

const StyledButton = styled(ButtonContained)(({ theme }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      width: "240px",
      fontFamily: fonts.heavy,
      fontSize: spacing(3),
      padding: spacing(2),
      margin: spacing(4),
      lineHeight: "150%",
      [`@media (max-width:${1024 - 1}px)`]: {
        width: "300px",
        fontSize: spacing(3.75),
        padding: spacing(2),
        margin: spacing(1),
      },
      [`@media (max-width:${581 - 1}px)`]: {
        width: "190px",
        fontSize: spacing(1.5),
        padding: spacing(1),
        margin: spacing(3),
      },
    },
  };
});

const PriceDot = styled.div(({ theme, $square }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    borderRadius: spacing(6.25),
    background: "#1A2024",
    width: spacing(8.75),
    height: spacing(8.75),
    justifyContent: "center",
    alignItems: "center",
    marginBlock: spacing(3.5),
    [`@media (max-width:${1024 - 1}px)`]: {
      background: "#E7FF00",
      width: spacing(5.86),
      height: spacing(5.86),
      marginBlock: spacing(0),
      borderRadius: $square ? spacing(1) : spacing(6.25),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      width: spacing(3),
      height: spacing(3),
    },
  };
});

const RSBlackIcon = styled(SVG.BlackRS)(({ theme }) => {
  const { spacing } = theme;
  return {
    width: "22.795px",
    height: "auto",
    [`@media (max-width:${581 - 1}px)`]: {
      width: "12px",
    },
  };
});

const TreeBlackIcon = styled(SVG.BlackTree)(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "none",
    [`@media (max-width:${1024 - 1}px)`]: {
      display: "flex",
      width: "28.395px",
      height: "auto",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      width: "15px",
    },
  };
});

const TreeGreenIcon = styled(SVG.GreenTree)(({ theme, $square }) => {
  const { spacing } = theme;
  return {
    [`@media (max-width:${1024 - 1}px)`]: {
      display: $square ? "none" : "flex",
      width: spacing(3.5),
      height: "auto",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      width: spacing(1.84),
    },
  };
});

const ValueText = styled.p(({ theme }) => {
  const { spacing } = theme;
  return {
    margin: 0,
    padding: 0,
    fontFamily: fonts.heavy,
    fontSize: spacing(4.93),
    color: "black",
    marginInline: spacing(2),
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(2.5),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: spacing(1.64),
      marginInline: spacing(1),
    },
  };
});

const PriceContainer = styled.div(({ theme, $mobile }) => {
  const { spacing } = theme;
  return {
    display: $mobile ? "none" : "flex",
    flexDirection: "column",
    [`@media (max-width:${1024 - 1}px)`]: {
      display: !$mobile ? "none" : "flex",
      alignItems: "flex-start",
      flexDirection: "row",
    },
    [`@media (max-width:${581 - 1}px)`]: {},
  };
});

const PriceContent = styled.div(({ theme }) => {
  return {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",

    [`@media (max-width:${1024 - 1}px)`]: {
      display: "none",
    },
    [`@media (max-width:${581 - 1}px)`]: {},
  };
});

const PriceContentMobile = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "none",
    [`@media (max-width:${1024 - 1}px)`]: {
      display: "flex",
      alignItems: "center",
      marginLeft: spacing(5),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      marginLeft: spacing(2.5),
    },
  };
});

const ProgressBar = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    background: "#979797",
    width: "100%",
    height: 12,
    borderRadius: 20,
    filter: "drop-shadow(0px 0px 17px rgba(0, 0, 0, 0.10))",

    [`@media (max-width:${1024 - 1}px)`]: {
      background: "#FFF",
      marginTop: spacing(3),
      filter: "drop-shadow(0px 0px 8.903409004211426px rgba(0, 0, 0, 0.10))",
      height: 8,
    },
    [`@media (max-width:${581 - 1}px)`]: { height: 6 },
  };
});

const ProgressGreenBar = styled.div(() => {
  return {
    transition: "width 1s linear",
    height: 12,
    background: "#E7FF00",
    borderRadius: 10,

    [`@media (max-width:${1024 - 1}px)`]: { height: 8 },
    [`@media (max-width:${581 - 1}px)`]: { height: 6 },
  };
});

const InputTitle = styled.p(({ theme, $green }) => {
  const { spacing } = theme;
  return {
    margin: 0,
    padding: 0,
    fontFamily: fonts.regular,
    fontSize: spacing(2.625),
    color: $green ? $green : "#616161",

    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(1.6),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: spacing(1),
    },
  };
});

const BarContainer = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: spacing(1.25),
    margin: spacing(4, 1.25),

    [`@media (max-width:${581 - 1}px)`]: {},
  };
});

const BarBackgroundIcon = styled.div(({ theme, actived }) => {
  const { spacing } = theme;
  return {
    width: 62,
    height: 62,
    background: actived ? "black" : "#E7E7E7",
    borderRadius: spacing(26.85),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: spacing(3.5),

    [`@media (max-width:${581 - 1}px)`]: {},
  };
});

const BarLine = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    height: 2,
    width: spacing(13.5),
    background: "#E7E7E7",
    marginInline: spacing(4),

    [`@media (max-width:${581 - 1}px)`]: {},
  };
});

const BarTextTitle = styled.p(({ theme }) => {
  const { spacing } = theme;
  return {
    color: "gray",
    margin: 0,
    padding: 0,
    fontSize: spacing(2.125),
    fontFamily: fonts.heavy,

    [`@media (max-width:${581 - 1}px)`]: {},
  };
});

const BarTextSubtitle = styled.p(({ theme }) => {
  const { spacing } = theme;
  return {
    color: "black",
    margin: 0,
    padding: 0,
    fontSize: spacing(3.28),
    fontFamily: fonts.boldCf,

    [`@media (max-width:${581 - 1}px)`]: {},
  };
});

const TableContainer = styled.div(() => {
  return {
    display: "flex",
    [`@media (max-width:${1024 - 1}px)`]: {
      display: "none",
    },
  };
});

const TableContainerMobile = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "none",
    [`@media (max-width:${1024 - 1}px)`]: {
      display: "flex",
      flexDirection: "column",
      background: "#F3F3F3",
      borderRadius: spacing(2.25),
      border: "1.284px solid #D9D9D9",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      borderRadius: spacing(1.25),
      border: "0.678px solid #D9D9D9",
    },
  };
});

const Table = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "#F3F3F3",
    borderRadius: spacing(3.125),
    border: "1px solid #D1D1D1",
  };
});

const TableRow = styled.div(() => {
  return {
    display: "flex",
    width: "100%",
  };
});

const TableItem = styled.div(({ theme, $last, $bottom, $withButton }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: $withButton ? "340px" : "230px",
    borderBottom: !$bottom ? "1px solid #D1D1D1" : "none",
    borderRight: !$last ? "1px solid #D1D1D1" : "none",
    padding: spacing(1.25),

    [`@media (max-width:${1024 - 1}px)`]: {
      display: "flex",
      justifyContent: "space-between",
      borderBottom: !$bottom && "1px solid #D1D1D1",
      borderRight: "none",
      padding: spacing(2),
      width: "100%",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      padding: spacing(1),
    },
  };
});

const TableHeaderText = styled.p(({ theme }) => {
  const { spacing } = theme;
  return {
    fontFamily: fonts.boldCf,
    fontSize: spacing(2.25),
    margin: 0,
    padding: 0,

    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(2.5375),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: spacing(1.3375),
    },
  };
});

const TableBodyText = styled.p(({ theme, $last }) => {
  const { spacing } = theme;
  return {
    fontFamily: $last ? fonts.boldCf : fonts.regularCf,
    fontSize: spacing(2.15),
    margin: 0,
    padding: 0,

    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(2.43),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: spacing(1.283),
    },
  };
});

const SeePurchaseButton = styled(ButtonContained)(({ theme }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      width: "100px",
      fontFamily: fonts.regular,
      fontSize: spacing(1.25),
      textTransform: "capitalize",
      lineHeight: "140%",
      background: "#D3D527",
      borderRadius: spacing(0.66),
      boxShadow: "none",
      padding: 2,
      marginTop: -5,
      marginLeft: spacing(1),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "&:hover": {
        backgroundColor: "#D3D527",
      },
      [`@media (max-width:${1024 - 1}px)`]: {
        fontSize: spacing(1.41),
      },
      [`@media (max-width:${581 - 1}px)`]: {
        width: "70px",
        fontSize: spacing(0.745),
        marginTop: -15,
      },
    },
  };
});

const DeletePurchaseButton = styled(SeePurchaseButton)(() => {
  return {
    "&&.MuiButton-root": {
      background: "#A00D0D",
      color: "white",
      "&:hover": {
        backgroundColor: "#A00D0D",
      },
    },
  };
});

const ModalContainer = styled.div(() => {
  return {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };
});

const ModalContent = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: spacing(40.625),
    background: "#F2F2F2",
    backdropFilter: "blur(18.792049407958984px)",
    borderRadius: spacing(4.55),
    margin: "-10px 35px",
    padding: spacing(2.5),

    [`@media (max-width:${1024 - 1}px)`]: {
      width: spacing(30),
      borderRadius: spacing(3),
      padding: spacing(2),
      margin: "-10px 35px",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      width: spacing(20),
      borderRadius: spacing(1),
      padding: spacing(1),
      margin: "-10px 25px",
    },
  };
});

const ModalImg = styled.img(({ theme }) => {
  const { spacing } = theme;
  return {
    width: spacing(31.25),
    height: "auto",
    marginTop: spacing(-6.25),

    [`@media (max-width:${1024 - 1}px)`]: {
      width: spacing(20),
      marginTop: spacing(-5),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      width: spacing(10),
      marginTop: spacing(-2.5),
    },
  };
});

const ModalText = styled.p(({ theme, $title, $quantity }) => {
  const { spacing } = theme;
  return {
    fontFamily: $title
      ? fonts.semibold
      : $quantity
      ? fonts.regularCf
      : fonts.boldCf,
    fontSize: $title
      ? spacing(2.73)
      : $quantity
      ? spacing(2.05)
      : spacing(3.42),
    color: "#3A3A3A",
    margin: 0,
    marginBottom: $quantity ? spacing(1) : 0,
    padding: 0,

    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: $title ? spacing(2.2) : $quantity ? spacing(1.75) : spacing(3),
      marginBottom: $quantity ? spacing(0.5) : 0,
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: $title ? spacing(1.2) : $quantity ? spacing(0.75) : spacing(2),
      marginBottom: $quantity ? spacing(0.1) : 0,
    },
  };
});

const ModalLine = styled.div(({ theme, $black }) => {
  const { spacing } = theme;
  return {
    height: 1,
    width: "75%",
    background: $black ? "black" : "#3A3A3A",
    marginBlock: $black ? spacing(1.875) : spacing(6.25),

    [`@media (max-width:${1024 - 1}px)`]: {
      marginBlock: $black ? spacing(1.875) : spacing(4),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      height: 0.7,
      marginBlock: $black ? spacing(1) : spacing(3),
    },
  };
});

const ModalButtonContainer = styled.div(() => {
  return {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
});

const ModalCloseButton = styled(ButtonContained)(({ theme }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      width: "145px",
      fontFamily: fonts.boldCf,
      fontSize: spacing(1.75),
      textTransform: "capitalize",
      marginTop: spacing(-1.5),
      marginLeft: spacing(1),

      [`@media (max-width:${1024 - 1}px)`]: {
        width: "100px",
        fontSize: spacing(1.25),
        marginTop: spacing(0),
      },
      [`@media (max-width:${581 - 1}px)`]: {
        width: "60px",
        fontSize: spacing(0.75),
      },
    },
  };
});

const ModalDeleteButton = styled(ModalCloseButton)(() => {
  return {
    "&&.MuiButton-root": {
      background: "#A00D0D",
      color: "white",
      "&:hover": {
        backgroundColor: "#A00D0D",
      },
    },
  };
});

const BlackContainer = styled.div(({ backgroundImg }) => {
  return {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "40%",
    zIndex: 9,

    backgroundImage: `url(${backgroundImg})`,
    backgroundSize: "100% 100%",
    backgroundRepeat: "repeat",
    backgroundPosition: "center",

    [`@media (max-width:${1024 - 1}px)`]: {},
    [`@media (max-width:${581 - 1}px)`]: {},
  };
});

const CoinImg = styled.img(({ theme }) => {
  const { spacing } = theme;
  return {
    width: "350px",
    height: "auto",
    margin: spacing(3),
    [`@media (max-width:${1024 - 1}px)`]: {
      width: "470px",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      maxWidth: "250px",
    },
  };
});

const VideoContainer = styled.div(() => {
  return {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "60%",
    background: "lightblue",
    zIndex: 0,
    maxHeight: "980px",
  };
});

const LoopVideo = styled.video(() => {
  return {
    width: "160%",
    height: "100%", pointerEvents: "none",
    [`@media (max-width:${1440 - 1}px)`]: { maxWidth: "40vw" },
    [`@media (max-width:${1024 - 1}px)`]: {
      maxWidth: "276px",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      maxWidth: "340px",
    },
  };
});

const Subtitle2 = styled(Subtitle)(({ theme, $smaller }) => {
  const { spacing } = theme;
  return {
    color: "white",
    fontFamily: fonts.heavy,
    fontSize: $smaller && spacing(2.38),
    marginBottom: $smaller && spacing(4),
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: $smaller ? spacing(2.98) : spacing(5),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: $smaller ? spacing(1.5875) : spacing(2.6375),
    },
  };
});

const RadioChecked = styled(FiberManualRecordIcon)(({ theme }) => {
  const { spacing } = theme;
  return {
    color: "#E7FF00",
    width: "40px",
    height: "auto",
    [`@media (max-width:${1024 - 1}px)`]: {
      width: "40px",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      width: "20px",
    },
  };
});

const IconContainer = styled.div(() => {
  return {
    display: "flex",
    alignItems: "center",
    width: "55px",
    marginLeft: "10px",

    [`@media (max-width:${581 - 1}px)`]: {
      width: "30px",
    },
  };
});

const PixIcon = styled(SVG.PixIcon)(() => {
  return {
    width: "38px",
    height: "auto",
    [`@media (max-width:${581 - 1}px)`]: {
      width: "21px",
    },
  };
});

const CreditIcon = styled(SVG.CreditCardIcon)(() => {
  return {
    width: "38px",
    height: "auto",
    [`@media (max-width:${581 - 1}px)`]: {
      width: "21px",
    },
  };
});

const BoletoIcon = styled(SVG.BoletoIcon)(({ theme }) => {
  const { spacing } = theme;
  return {
    height: "41px",
    width: "auto",
    [`@media (max-width:${581 - 1}px)`]: {
      height: "22px",
    },
  };
});

const RadioText = styled.p(({ theme }) => {
  const { spacing } = theme;
  return {
    margin: 0,
    color: "#616161",
    fontFamily: fonts.bold,
    fontSize: spacing(4.85),

    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: spacing(2.5),
    },
  };
});

const SliderLabelFormat = styled.div(() => {
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: -40,

    [`@media (max-width:${1024 - 1}px)`]: {
      marginTop: 0,
    },
    [`@media (max-width:${581 - 1}px)`]: {
      marginTop: 25,
    },
  };
});

const SliderLabelContainer = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "120px",
    height: "auto",
    background: "#E7FF00",
    borderRadius: "6px",
    zIndex: 99,
    padding: spacing(2, 1),

    [`@media (max-width:${1024 - 1}px)`]: {
      width: "70px",
      padding: spacing(1),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      width: "40px",
      borderRadius: "4px",
      padding: spacing(0.75),
    },
  };
});

const SliderLabelFont = styled.p(({ theme }) => {
  const { spacing } = theme;
  return {
    color: "#1A2024",
    fontFamily: fonts.heavy,
    fontSize: spacing(3.18),
    margin: 0,

    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(1.68),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: spacing(0.88125),
    },
  };
});

const SliderLabelFormatPin = styled.div(() => {
  return {
    width: "30px",
    height: "30px",
    background: "#E7FF00",
    borderRadius: "6px",
    transform: "rotate(45deg)",
    marginTop: -20,

    [`@media (max-width:${1024 - 1}px)`]: {
      width: "20px",
      height: "20px",
      borderRadius: "4px",
      marginTop: -12,
    },
    [`@media (max-width:${581 - 1}px)`]: {
      width: "10px",
      height: "10px",
      borderRadius: "2px",
      marginTop: -6,
    },
  };
});

const HeaderPayment = styled.div(() => {
  return {
    display: "flex",
    flexDirection: "column",

    [`@media (max-width:${1024 - 1}px)`]: {
      display: "none",
    },
  };
});

const HeaderPaymentMobile = styled.div(() => {
  return {
    display: "none",

    [`@media (max-width:${1024 - 1}px)`]: {
      display: "flex",
      flexDirection: "column",
    },
  };
});

const FinalContainer = styled.div(() => {
  return {
    display: "flex",
    width: "100%",
    height: "100%",
    zIndex: 99,

    [`@media (max-width:${1024 - 1}px)`]: {
      display: "none",
    },
  };
});

const FinalContainerMobile = styled.div(() => {
  return {
    display: "none",

    [`@media (max-width:${1024 - 1}px)`]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "21.791px",
      border: "0.497px solid #E7FF00",
      background: "rgba(217, 217, 217, 0.10)",
      backdropFilter: "blur(19.878753662109375px)",
      width: "100%",
      maxWidth: "645px",
      height: "auto",
      margin: "25px 0px",
      padding: "70px 0px",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      maxWidth: "340px",
      padding: "40px 0px",
      border: "0.263px solid #E7FF00",
    },
    [`@media (max-width:${426 - 1}px)`]: {
      maxWidth: "300px",
    },
  };
});

const Styles = {
  Container,
  Content,
  ContentBlack,
  Title,
  Subtitle,
  YellowBallTwo,
  YellowBall,
  InputTitle,
  StyledButton,

  PriceDot,
  RSBlackIcon,
  TreeBlackIcon,
  TreeGreenIcon,
  ValueText,
  PriceContainer,
  PriceContent,
  PriceContentMobile,
  ProgressBar,
  ProgressGreenBar,

  BarContainer,
  BarBackgroundIcon,
  BarLine,
  BarTextTitle,
  BarTextSubtitle,

  TableContainer,
  TableContainerMobile,
  Table,
  TableRow,
  TableItem,
  TableHeaderText,
  TableBodyText,
  SeePurchaseButton,
  DeletePurchaseButton,

  ModalContainer,
  ModalContent,
  ModalImg,
  ModalText,
  ModalLine,
  ModalButtonContainer,
  ModalCloseButton,
  ModalDeleteButton,

  BlackContainer,
  CoinImg,
  VideoContainer,
  LoopVideo,
  Subtitle2,

  RadioChecked,
  IconContainer,
  PixIcon,
  CreditIcon,
  BoletoIcon,
  RadioText,

  SliderLabelFormat,
  SliderLabelContainer,
  SliderLabelFont,
  SliderLabelFormatPin,

  HeaderPayment,
  HeaderPaymentMobile,

  FinalContainer,
  FinalContainerMobile,
};

export default Styles;
