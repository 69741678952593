import React from "react";

const CoinImg = process.env.REACT_APP_ASSETS_URL + "/imgs/GOLDEN_COIN.webp";
const TechPlantImg = process.env.REACT_APP_ASSETS_URL + "/imgs/TechPlant.webp";
const TechBGImg = process.env.REACT_APP_ASSETS_URL + "/imgs/TechBG.webp";
import { DefaultLogo, FontStyles, StrongText } from "../../components";
import { Spacing, SVG } from "../../config";

import Styles from "./styles";
import texts from "../../config/texts";
import { LocalStorage } from "../../utils";

function Technology() {
  const currentText =
    texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].tech;
  const [isMounted, setMount] = React.useState<boolean>(false);
  const [menuHeight, setHeight] = React.useState<any>(null);

  const whyIcons = [
    {
      icon: SVG.Impact,
      title: currentText.whysIcons[0].title,
      subtitle: currentText.whysIcons[0].subtitle,
    },

    {
      icon: SVG.Value,
      title: currentText.whysIcons[1].title,
      subtitle: currentText.whysIcons[1].subtitle,
    },
  ];

  React.useLayoutEffect(() => {
    if (!menuHeight) {
      const mh = document.getElementById("header-menu")?.offsetHeight;

      setHeight(mh);
    }
  }, [menuHeight]);

  const Mount = React.useCallback(() => {}, []);

  React.useEffect(() => {
    if (!isMounted) {
      Mount();
      setMount(true);
    }
  }, [Mount, isMounted]);

  return (
    <>
      <Styles.Container $menuHeight={menuHeight}>
        <Styles.BgVideoContainer $menuHeight={menuHeight}>
          <Styles.BgVideo
            $menuHeight={menuHeight}
            loop
            autoPlay
            playsInline
            controls={false}
            muted
            preload="auto"
          >
            <source
              src={process.env.REACT_APP_ASSETS_URL + "/videos/techVideo.webm"}
              type="video/webm"
            />
          </Styles.BgVideo>
        </Styles.BgVideoContainer>
      </Styles.Container>

      <Styles.CoinContainer>
        <Styles.HalfContainer>
          <div style={{ display: "flex", alignItems: "flex-end" }}>
            <Styles.Logo />
            <Styles.CoinImg2 src={CoinImg} />
          </div>

          <Styles.CoinText>{currentText.biocoin}</Styles.CoinText>
        </Styles.HalfContainer>
        <Styles.CoinImg src={CoinImg} />
      </Styles.CoinContainer>

      <Styles.PlantContainer>
        <Styles.PlantImage imgSrc={TechPlantImg}></Styles.PlantImage>
        <Styles.TextContainer>
          <Styles.PlantTitle>{currentText.plantTitle}</Styles.PlantTitle>
          <Styles.PlantDescription>
            {currentText.plantContent}
          </Styles.PlantDescription>
        </Styles.TextContainer>
      </Styles.PlantContainer>

      <Styles.TechContainer imgSrc={TechBGImg}>
        <Styles.TechItem>
          <Styles.TechIconBox>
            <SVG.CirclesBackground />
            <Styles.TokenIcon />
          </Styles.TechIconBox>
          <Styles.TechItemTitle>{currentText.token}</Styles.TechItemTitle>
          <Styles.TechItemDescription>
            {currentText.tokenContent}
          </Styles.TechItemDescription>
        </Styles.TechItem>

        <Styles.TechLine></Styles.TechLine>

        <Styles.TechItem>
          <Styles.TechIconBox>
            <SVG.CirclesBackground />
            <Styles.WhitepaperIcon />
          </Styles.TechIconBox>
          <Styles.TechItemTitle>{currentText.paper}</Styles.TechItemTitle>
          <Styles.TechItemDescription other>
            {currentText.paperContent}
          </Styles.TechItemDescription>
        </Styles.TechItem>
      </Styles.TechContainer>
    </>
  );
}

export default Technology;
