import React from "react";
import Styles from "../styles/StylesProDiferencial";
const BackgroundDifferentials = process.env.REACT_APP_ASSETS_URL + "/imgs/differentialsBackground.webp";
import Box from "./Box";
import FlorestCoin from "../../../assets/svg/florestCoin.svg";
import PlantCoin from "../../../assets/svg/plantCoin.svg";
import BeachCoin from "../../../assets/svg/beachCoin.svg";
import NFTCoin from "../../../assets/svg/NFTCoin.svg";
import CubeCoin from "../../../assets/svg/cubeCoin.svg";
import CloudCoin from "../../../assets/svg/cloudCoin.svg";
import Roadmap from "./Roadmap";
import RoadmapMobile from "./RoadmapMobile";
import texts from "../../../config/texts";
import { LocalStorage } from "../../../utils";

function Differentials() {
  const currentText =
    texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].about;
  const roadMapText =
    texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].home.roadmap;
  const [arrowDirection, setArrowDirection] = React.useState<string>("right");
  const [arrowRoadmapDirection, setArrowRoadmapDirection] =
    React.useState<string>("1");

  const boxData1 = [
    {
      icon: FlorestCoin,
      title: currentText.boxData[0].title,
      text: currentText.boxData[0].text,
    },
    {
      icon: PlantCoin,
      title: currentText.boxData[1].title,
      text: currentText.boxData[1].text,
    },
    {
      icon: BeachCoin,
      title: currentText.boxData[2].title,
      text: currentText.boxData[2].text,
    },
  ];
  const boxData2 = [
    {
      icon: NFTCoin,
      title: currentText.boxData[3].title,
      text: currentText.boxData[3].text,
    },
    {
      icon: CubeCoin,
      title: currentText.boxData[4].title,
      text: currentText.boxData[4].text,
    },
    {
      icon: CloudCoin,
      title: currentText.boxData[5].title,
      text: currentText.boxData[5].text,
    },
  ];
  const boxDataMobile = [
    {
      icon: FlorestCoin,
      title: currentText.boxData[6].title,
      text: currentText.boxData[6].text,
    },
    {
      icon: PlantCoin,
      title: currentText.boxData[7].title,
      text: currentText.boxData[7].text,
    },
    {
      icon: BeachCoin,
      title: currentText.boxData[8].title,
      text: currentText.boxData[8].text,
    },
    {
      icon: NFTCoin,
      title: currentText.boxData[9].title,
      text: currentText.boxData[9].text,
    },
    {
      icon: CubeCoin,
      title: currentText.boxData[10].title,
      text: currentText.boxData[10].text,
    },
    {
      icon: CloudCoin,
      title: currentText.boxData[11].title,
      text: currentText.boxData[11].text,
    },
  ];

  const roadmapData1 = roadMapText.maps[0];
  const roadmapData2 = roadMapText.maps[1];
  const roadmapData3 = roadMapText.maps[2];

  return (
    <>
      <Styles.GreenBorder />
      <Styles.Container backgroundImg={BackgroundDifferentials}>
        <Styles.GreenBlur right />
        <Styles.GreenBlur />

        <Styles.DifferentialTitle>
          {currentText.differentialTitle}
        </Styles.DifferentialTitle>

        <Styles.DifferentialText>
          {currentText.differential}
        </Styles.DifferentialText>

        <Styles.BoxContainerMobile>
          {boxDataMobile.map((item: any, index?: number) => {
            return (
              <Box
                icon={item.icon}
                title={item.title}
                text={item.text}
                index={index}
              />
            );
          })}
        </Styles.BoxContainerMobile>

        <Styles.BoxContainer>
          {arrowDirection === "right" ? (
            <>
              {boxData1.map((item: any) => {
                return (
                  <Box icon={item.icon} title={item.title} text={item.text} />
                );
              })}
              <Styles.ArrowIcon
                right
                onClick={() => setArrowDirection("left")}
              />
            </>
          ) : (
            <>
              {boxData2.map((item: any) => {
                return (
                  <Box icon={item.icon} title={item.title} text={item.text} />
                );
              })}
              <Styles.ArrowIcon onClick={() => setArrowDirection("right")} />
            </>
          )}
        </Styles.BoxContainer>

        <Styles.DifferentialTitle roadmap>
          {roadMapText.title}
        </Styles.DifferentialTitle>
        <Styles.AboutTexts>
          {arrowRoadmapDirection === "1"
            ? roadMapText.t1
            : arrowRoadmapDirection === "2"
            ? roadMapText.t2
            : roadMapText.t3}
        </Styles.AboutTexts>

        <Styles.RoadmapContainer mobile>
          <RoadmapMobile title={roadMapText.t1} data={roadmapData1} />
          <RoadmapMobile title={roadMapText.t2} data={roadmapData2} />
          <RoadmapMobile title={roadMapText.t3} data={roadmapData3} />
        </Styles.RoadmapContainer>

        <Styles.RoadmapContainer>
          {arrowRoadmapDirection === "1" ? (
            <>
              <Roadmap data={roadmapData1} />
              <Styles.ArrowIcon
                right
                roadmap
                onClick={() => setArrowRoadmapDirection("2")}
              />
            </>
          ) : arrowRoadmapDirection === "2" ? (
            <>
              <Roadmap data={roadmapData2} />
              <Styles.ArrowIcon
                roadmap
                onClick={() => setArrowRoadmapDirection("1")}
              />
              <Styles.ArrowIcon
                right
                roadmap
                onClick={() => setArrowRoadmapDirection("3")}
              />
            </>
          ) : (
            <>
              <Roadmap data={roadmapData3} />
              <Styles.ArrowIcon
                roadmap
                onClick={() => setArrowRoadmapDirection("2")}
              />
            </>
          )}
        </Styles.RoadmapContainer>
      </Styles.Container>
      <Styles.GreenBorder />
    </>
  );
}

export default Differentials;
