import styled from "styled-components";
import { Container as DefaultContainer, IconButton } from "@material-ui/core";
import { Colors, fonts } from "../../../config";
import MenuIcon from "@material-ui/icons/Menu";
import {
  ButtonOutlined,
  DefaultLogo,
  FontStyles,
  Input,
} from "../../../components";
import ButtonContainedComponent from "../../../components/buttons/ButtonContained";
const Container = styled.div(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",

    width: "100%",
    backgroundColor: "#1A2024",
    paddingBlock: theme.spacing(8),
    paddingInline: theme.spacing(15.625),
    [`@media (max-width:${935 - 1}px)`]: { paddingInline: theme.spacing(2) },
    [`@media (max-width:${1024 - 1}px)`]: { paddingInline: theme.spacing(2) },
    [`@media (max-width:${744 - 1}px)`]: {
      paddingInline: theme.spacing(3.25),
      flexDirection: "column",
      gap: theme.spacing(2),
      paddingBlock: theme.spacing(2.75),
    },
  };
});

const Content = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    gap: theme.spacing(7.5),
    alignItems: "center",
    [`@media (max-width:${1024 - 1}px)`]: {
      gap: theme.spacing(3.875),
    },
    [`@media (max-width:${744 - 1}px)`]: {
      gap: theme.spacing(2),
      justifyContent: "space-between",
    },
    [`@media (max-width:${250 - 1}px)`]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  };
});

const FirstContent = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.25),
    [`@media (max-width:${744 - 1}px)`]: {
      gap: theme.spacing(0.55),
    },
  };
});

const SecondContent = styled.div(({ alternative, theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: !alternative ? "100%" : "fit-content;",

    gap: alternative && "20px",
    [`@media (max-width:${744 - 1}px)`]: {
      gap: "0px",
      height: "fit-content;",
      paddingRight: theme?.spacing(0.6),
    },
  };
});

const FormContent = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    width: "fit-content",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: theme.spacing(1),
    [`@media (max-width:${744 - 1}px)`]: {
      width: "100%",
    },
  };
});

const LinksContainer = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "row",
    justifyContent: "space-evenly",
    [`@media (max-width:${744 - 1}px)`]: {
      justifyContent: "space-evenly",
    },

    [`@media (max-width:${460 - 1}px)`]: {
      flexDirection: "column",
    },
  };
});

const ButtonLogin = styled(ButtonContainedComponent)(({ theme }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      margin: 0,
      borderRadius: 0,
      maxHeight: spacing(6),
      [`@media (max-width:${1024 - 1}px)`]: {
        fontSize: spacing(1.1),
        maxHeight: spacing(5.3475),
      },
    },
  };
});

const IconsList = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1.75),
    [`@media (max-width:${1024 - 1}px)`]: {
      gap: theme.spacing(0.75),
    },
  };
});

const Version = styled.p(({ theme }) => {
  const { spacing, palette } = theme;
  return {
    ...FontStyles.light14,
    fontSize: spacing(1.5),
    textTransform: "capitalize",
    color: palette.secondary.main,
    fontFamily: fonts.regularCf,

    margin: 0,
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(0.9),
    },
    [`@media (max-width:${744 - 1}px)`]: {
      fontSize: spacing(1.25),
    },
  };
});

const Email = styled.a(({ theme }) => {
  const { spacing, palette } = theme;
  return {
    ...FontStyles.medium16,
    color: palette.secondary.main,
    fontFamily: fonts.regularCf,
    textDecoration: "none",
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(1),
    },
    [`@media (max-width:${744 - 1}px)`]: {
      fontSize: spacing(1.5),
    },
  };
});

const NavLink = styled.a(({ theme }) => {
  const { spacing, palette } = theme;
  return {
    ...FontStyles.medium16,
    color: palette.secondary.main,
    fontFamily: fonts.regularCf,
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {},

    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(1.1),
    },
    [`@media (max-width:${744 - 1}px)`]: {
      fontSize: spacing(1.35),
      marginBottom: spacing(0.6),
    },
  };
});

const EmailInput = styled(Input)(({ theme }) => {
  const { spacing, palette } = theme;
  return {
    margin: 0,
    borderRadius: 0,
    width: spacing(41.5),
    backgroundColor: "#050500",
    color: "#D9D9D9",
    boxShadow: "none",
    ...FontStyles.light14,
    "& .MuiInputBase-input": {
      backgroundColor: "#050500",
      boxShadow: "none !important",
      padding: 0,
    },
  };
});

const FormEmail = styled.div(({ theme }) => {
  const { spacing, palette } = theme;
  return {
    display: "flex",
    flexDirection: "row",
    width: "max-content",
    [`@media (max-width:${744 - 1}px)`]: {
      width: "100%",
    },
  };
});

const NavButton = styled(IconButton)(({ theme }) => {
  const { spacing, palette } = theme;
  return {
    "&&.MuiIconButton-root": {
      padding: 0,
      borderRadius: 0,
      maxWidth: spacing(2.375),
      maxHeight: spacing(2.375),
      [`@media (max-width:${1024 - 1}px)`]: {
        maxWidth: spacing(1.75),
        maxHeight: spacing(1.75),
      },
      "&&.MuiIconButton-label": {
        maxWidth: spacing(2.375),
        maxHeight: spacing(2.375),
        [`@media (max-width:${1024 - 1}px)`]: {
          maxWidth: spacing(1.75),
          maxHeight: spacing(1.75),
        },
      },
    },
  };
});

const NavIcons = styled.img(({ theme }) => {
  const { spacing, palette } = theme;
  return {
    maxWidth: spacing(1.5),
    maxHeight: spacing(1.5),
    [`@media (max-width:${1024 - 1}px)`]: {
      maxWidth: spacing(0.75),
      maxHeight: spacing(0.75),
    },
  };
});

const Logo = styled(DefaultLogo)(({ theme }) => {
  const { spacing, palette } = theme;
  return {
    width: 178,
    height: 178 * 0.22,
    flexShrink: 0,
    [`@media (max-width:${1024 - 1}px)`]: {
      width: 92,
      height: 92 * 0.22,
    },
  };
});

const InputTexts = styled.p(({ theme, title }) => {
  const { spacing, palette } = theme;
  return {
    padding: 0,
    margin: 0,
    fontSize: title ? spacing(1.75) : spacing(1.5),
    fontFamily: title ? fonts.heavy : fonts.lightCf,
    color: "white",
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: title ? spacing(1) : spacing(0.9),
    },
    [`@media (max-width:${744 - 1}px)`]: {
      fontSize: title ? spacing(1.25) : spacing(1),
    },
  };
});

const Styles = {
  Container,
  Content,
  FormContent,
  FirstContent,
  ButtonLogin,
  IconsList,
  Email,
  NavLink,
  SecondContent,
  EmailInput,
  FormEmail,
  NavButton,
  NavIcons,
  Logo,
  InputTexts,
  LinksContainer,
  Version,
};

export default Styles;
