import React from "react";
import {
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
  Input,
} from "@material-ui/core";

import styled from "styled-components";
import { FontStyles } from "../index";
import { useFormFull } from "form-full";
import { fonts, Spacing, SVG } from "../../config";

const StyledFormControl = styled(FormControl)(({ theme }) => {
  return {};
});

const StyledMenuItem = styled(MenuItem)(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    "&&.MuiMenuItem-root": {
      margin: 0,
      width: "100%",

      background: "rgba(255,255,255,0.12)",
      padding: spacing(1.625),
      fontFamily: fonts.boldCf,
      fontSize: spacing(1.625),

      lineHeight: "140%",
      fontWeight: "900",
      color: "white",
      paddingInline: spacing(1.625),
      [`@media (max-width:${1024 - 1}px)`]: { fontSize: spacing(3) },
      [`@media (max-width:${581 - 1}px)`]: { fontSize: spacing(1.625) },
      "&.MuiListItem-button:hover": {
        background: "rgba(255,255,255,0.12)",
      },
      "&.Mui-focusVisible": {
        background: "rgba(255,255,255,0.12)",
      },
      "&.Mui-selected": {
        background: "rgba(255,255,255,0.12)",
      },

      "& .MuiPaper-root": {
        background: "rgba(255,255,255,0.12)",
        padding: 0,
      },
    },
  };
});

const StyledSelect = styled(Select)(({ theme, $withError, $maxHeight }) => {
  const { palette: colors, spacing } = theme;
  return {
    "&&.MuiInput-root": {
      position: "relative",
      fontFamily: fonts.boldCf,
      fontSize: spacing(1.625),
      padding: 0,
      lineHeight: "140%",
      fontWeight: "900",
      width: "100%",
      color: "white",

      backgroundColor: "transparent",
      [`@media (max-width:${1024 - 1}px)`]: { fontSize: spacing(3) },
      [`@media (max-width:${581 - 1}px)`]: { fontSize: spacing(1.625) },
      "& .MuiSelect-select.MuiSelect-select": {
        padding: 0,
      },
      "& .MuiSelect-icon": {
        top: -30,
      },
    },
    "& .MuiMenu-paper": {
      backgroundColor: "red",
    },
  };
});

const Label = styled.p(({ theme, smallLabel, $withError, $maxHeight }) => {
  const { palette: colors, spacing } = theme;

  return {
    fontFamily: fonts.regularCf,
    fontSize: spacing(1.625),

    lineHeight: "140%",
    width: "100%",

    color: "white",

    padding: 0,
    margin: 0,
    paddingBottom: spacing(1),

    zIndex: $maxHeight ? 1000 : 10,
    cursor: "pointer",
    position: "relative",
    top: 0,
    left: 0,

    transition: ".2s",

    [`@media (max-width:${1024 - 1}px)`]: { fontSize: spacing(3) },
    [`@media (max-width:${581 - 1}px)`]: { fontSize: spacing(1.625) },
  };
});
const LineBox = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    width: "100%",
    borderRadius: spacing(1.625),
    border: "1px solid #E7FF00",
    background: "rgba(255,255,255,0.12)",
    padding: spacing(1.625),
    [`@media (max-width:${1024 - 1}px)`]: {},
    [`@media (max-width:${581 - 1}px)`]: {},
  };
});
function SelectComponent(props) {
  const { value, error, onBlur, ref, valid, onChange, ffHandler } =
    useFormFull.field(props);
  const {
    inputLabel,
    options,

    name,
  } = props;
  const onSelectValue = React.useCallback(
    (event) => {
      try {
        onChange(event, event.target.value);
      } catch (err) {}
      setTimeout(() => {
        onBlur(event);
        ffHandler?.testFieldError(name, true);
        ffHandler?.testErrorsAndReturnData();
      }, 10);
    },
    [onBlur, ffHandler, name, onChange]
  );

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const getWidth = (value) => {
    return Boolean(value) ? value + Spacing(3.25) : value;
  };
  return (
    <StyledFormControl fullWidth error={Boolean(error)}>
      <LineBox>
        <Label
          onClick={() => {
            handleOpen();
          }}
          $withValue={Boolean(value) || value === 0}
          $withError={Boolean(error)}
        >
          {inputLabel}
        </Label>
        <StyledSelect
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          IconComponent={SVG.SelectIcon}
          id={name + "-select"}
          $withError={Boolean(error)}
          disableUnderline
          ref={ref}
          value={value}
          onChange={onSelectValue}
          MenuProps={{
            PaperProps: {
              style: {
                background: "#2d3231",

                boxShadow: "none",
                width: getWidth(
                  document.getElementById(name + "-select")?.offsetWidth
                ),
                padding: 0,
                position: "relative",
                marginLeft: Spacing(-1.625),
                borderRadius: Spacing(1.625),
                "& .MuiMenu-list": { padding: 0 },
              },
            },
          }}
        >
          {options?.map((option) => (
            <StyledMenuItem value={option.value} disabled={option.disabled}>
              {option.label}
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </LineBox>
    </StyledFormControl>
  );
}

export default SelectComponent;
