import { Grid } from "@material-ui/core";
import Styles from "../styles/StylesProDiferencial";

function Roadmap({ data }) {
  return (
    <div style={{ width: "70%", marginBlock: 60 }}>
      <Grid
        container
        style={{
          position: "relative",
          display: "flex",
          textAlign: "center",
          alignItems: "center",
          flexDirection: "row",
          flexWrap: "nowrap",
        }}
      >
        {data.map((item, index) => {
          return (
            <Grid container item>
              <Grid item xs={11}>
                <Styles.RoadmapText title>
                  <strong>{item.title}</strong>
                </Styles.RoadmapText>
              </Grid>

              <Grid item xs={11}>
                <Styles.RoadmapLineContainer>
                  <Styles.GreenDot />
                  <Styles.Line
                    middle={index !== 0 && index !== data.length - 1}
                    end={index === data.length - 1}
                  />
                </Styles.RoadmapLineContainer>
              </Grid>

              <Grid item xs={11}>
                <Styles.RoadmapText>{item.text}</Styles.RoadmapText>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

export default Roadmap;
