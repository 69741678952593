import Styles from "../styles/WhitePaperStyles";
const iPad = process.env.REACT_APP_ASSETS_URL + "/imgs/iPad.webp";
const BackgroundPaper = process.env.REACT_APP_ASSETS_URL + "/imgs/paperDownloadBg.webp";
import texts from "../../../config/texts";
import { LocalStorage } from "../../../utils";

function WhitePaper({ $style }: any): JSX.Element {
  const currentText = texts[LocalStorage.getItem("language") ?LocalStorage.getItem("language"): 'pt'].about.whiteapaper;
  const actionText = texts[LocalStorage.getItem("language") ?LocalStorage.getItem("language"): 'pt'].actionButton;
  const handleDownload = () => {
    const pdfPath = process.env.REACT_APP_ASSETS_URL + "/documents/WhitePaper.pdf";
    window.open(pdfPath, "_blank");
  };
  return (
    <>
      <Styles.Container style={{ ...$style }} backgroundImg={BackgroundPaper}>
        <div style={{ display: "flex" }}>
          <Styles.TextContainer>
            <Styles.AboutTitle>{currentText.title}</Styles.AboutTitle>
            <Styles.AboutDescription>
              {currentText.content}
            </Styles.AboutDescription>
            <Styles.StyledButton onClick={handleDownload}>
              {actionText.downloadHere}
            </Styles.StyledButton>
          </Styles.TextContainer>
          <Styles.IpadContainer>
            <Styles.IpadImg src={iPad} />
          </Styles.IpadContainer>
        </div>
      </Styles.Container>
    </>
  );
}

export default WhitePaper;
