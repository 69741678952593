import { Input } from "../../../components";
import Styles from "../styles";
import React, { useContext } from "react";
import { FormFull } from "form-full";
import { fonts, Spacing } from "../../../config";
import { LocalStorage, masks } from "../../../utils";
import { StorageContext } from "../../../contexts/StorageContext";
import BoxHeader from "./BoxHeader";
import { CircularProgress } from "@material-ui/core";
import Texts from "../../../config/texts";

function Authentication({
  goBack,
  qrCode,
  twoFactorAuth,
  loading,
}: any): JSX.Element {
  const texts =
    Texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].config;
  const textsAction =
    Texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].actionButton;
  const { userData } = useContext<any>(StorageContext);
  const [ref, setRef] = React.useState<any>(null);

  return (
    <>
      <Styles.WhiteBox>
        <Styles.TitleBox>
          <Styles.TitleConfig>{texts.auth.title}</Styles.TitleConfig>
        </Styles.TitleBox>
        <Styles.BoxUserContent>
          <BoxHeader user={userData} />
          {loading ? (
            <CircularProgress
              size={64}
              style={{
                color: "black",
                alignSelf: "center",
                justifyContent: "center",
                marginBlock: Spacing(3),
              }}
            />
          ) : (
            <>
              <img
                style={{
                  maxWidth: "380px",
                  width: "100%",
                  height: "100%",
                  maxHeight: "380px",
                  marginTop: Spacing(3.5),
                  marginBottom: Spacing(7.5),
                }}
                src={qrCode.url}
              />
              <FormFull
                clearOnSubmit={false}
                onSubmit={() => {}}
                formRef={setRef}
              >
                <div style={{ width: "100%" }}>
                  <Styles.InputTitle>{texts.auth.code}</Styles.InputTitle>
                  <Input
                    readOnly
                    defaultValue={qrCode.urlCode}
                    key={21}
                    inputStyle={{
                      background: "rgba(27, 30, 34, 0.70)",
                      borderRadius: "53.201px",
                      color: "white",
                      fontFamily: fonts.regularCf,
                      fontSize: Spacing(2.75),
                      paddingInline: Spacing(3.75),
                      paddingBlock: Spacing(2),
                      marginTop: Spacing(0.75),
                      border: "1.988px solid #D3D527",
                      "& .MuiInputBase-input": {
                        padding: 0,

                        boxShadow: "none !important",
                      },

                      [`@media (max-width:${1024 - 1}px)`]: {
                        paddingInline: Spacing(3.5),
                        fontSize: Spacing(2.5),
                      },
                      [`@media (max-width:${581 - 1}px)`]: {
                        paddingInline: Spacing(1.25),
                        paddingBlock: Spacing(1),
                        fontSize: Spacing(1.5),
                      },
                    }}
                    name="authCode"
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <Styles.InputTitle>{texts.auth.token}</Styles.InputTitle>
                  <Input
                    validation={(value) => {
                      if (value.length > 5) {
                        return null;
                      } else {
                        return texts.auth.authValidation;
                      }
                    }}
                    mask={(value) => {
                      if (value?.length > 5) {
                        return masks.removeNumberMask(value.slice(0, 6));
                      } else {
                        return masks.removeNumberMask(value);
                      }
                    }}
                    required={texts.required}
                    inputStyle={{
                      background: "rgba(27, 30, 34, 0.70)",
                      borderRadius: "53.201px",
                      color: "white",
                      fontFamily: fonts.regularCf,
                      fontSize: Spacing(2.75),
                      paddingInline: Spacing(3.75),
                      paddingBlock: Spacing(2),
                      marginTop: Spacing(0.75),
                      border: "1.988px solid #D3D527",
                      "& .MuiInputBase-input": {
                        padding: 0,

                        boxShadow: "none !important",
                      },

                      [`@media (max-width:${1024 - 1}px)`]: {
                        paddingInline: Spacing(3.5),
                        fontSize: Spacing(2.5),
                      },
                      [`@media (max-width:${581 - 1}px)`]: {
                        paddingInline: Spacing(1.25),
                        paddingBlock: Spacing(1),
                        fontSize: Spacing(1.5),
                      },
                    }}
                    key="verify"
                    name="verify"
                  />
                </div>

                <div>
                  <Styles.SendButton
                    disabled={loading}
                    loading={loading}
                    name="submit"
                    onClick={() => {
                      const verifyCode = ref.getValue("verify");
                      twoFactorAuth(verifyCode);
                    }}
                  >
                    {texts.auth.send}
                  </Styles.SendButton>
                </div>
              </FormFull>
            </>
          )}
        </Styles.BoxUserContent>
      </Styles.WhiteBox>
      <Styles.Back
        disabled={loading}
        loading={loading}
        fullWidth={false}
        onClick={goBack}
      >
        {textsAction.back}
      </Styles.Back>
    </>
  );
}

export default Authentication;
