import { Input, InputEmail } from "../../../components";

import Styles from "../styles";

import { useContext } from "react";
import alerts from "../../../utils/Alerts";

import { useNavigate } from "react-router-dom";
import { FormFull } from "form-full";
import { fonts, Spacing } from "../../../config";
import { hooks, LocalStorage, masks, validations } from "../../../utils";
import ptBr from "../../../config/texts/pt-br";
import { api } from "../../../services";
import ResponseError from "../../../services/helpers/ResponseError";
import { StorageContext } from "../../../contexts/StorageContext";
import BoxHeader from "./BoxHeader";
import Texts from "../../../config/texts";

function UserData({ goBack, goNext }: any): JSX.Element {
  const texts = Texts[LocalStorage.getItem("language") ?LocalStorage.getItem("language"): 'pt'].config;
  const inputsText = Texts[LocalStorage.getItem("language") ?LocalStorage.getItem("language"): 'pt'];
  const { addData, userData, setUserData } = useContext<any>(StorageContext);
  const { loading, call } = hooks.useRequest();
  const navigate = useNavigate();

  const changeProcess = (data) => {
    call(
      null,
      api.editUser({
        name: data?.name,
        phone: data?.phone,
      }),
      (response) => {
        if (response.ok) {
          setUserData(response.data);
          alerts.alertSuccess(texts.dataSucess);
        } else {
          const errorResponse = new ResponseError(response);
          errorResponse.alertMessage();
        }
      }
    );
  };
  return (
    <>
      <Styles.WhiteBox>
        <Styles.TitleBox>
          <Styles.TitleConfig>{texts.data.title}</Styles.TitleConfig>
        </Styles.TitleBox>
        <Styles.BoxUserContent>
          <BoxHeader user={userData} />

          <FormFull clearOnSubmit={false} onSubmit={changeProcess}>
            <div style={{ width: "100%" }}>
              <Styles.InputTitle>{inputsText.register.email}</Styles.InputTitle>
              <InputEmail
                readOnly
                defaultValue={userData?.email}
                required={texts.required}
                inputStyle={{
                  background: "rgba(27, 30, 34, 0.70)",
                  opacity: 0.6,
                  borderRadius: "53.201px",
                  color: "white",
                  fontFamily: fonts.regularCf,
                  fontSize: Spacing(2.75),
                  paddingInline: Spacing(3.75),
                  paddingBlock: Spacing(2),
                  marginTop: Spacing(0.75),
                  border: "1.988px solid #D3D527",
                  "& .MuiInputBase-input": {
                    padding: 0,

                    boxShadow: "none !important",
                  },

                  [`@media (max-width:${1024 - 1}px)`]: {
                    paddingInline: Spacing(3.5),
                    fontSize: Spacing(2.5),
                  },
                  [`@media (max-width:${581 - 1}px)`]: {
                    paddingInline: Spacing(1.25),
                    paddingBlock: Spacing(1),
                    fontSize: Spacing(1.5),
                  },
                }}
                name="email"
              />
            </div>
            <div style={{ width: "100%" }}>
              <Styles.InputTitle>
                {inputsText.register.fullName}
              </Styles.InputTitle>
              <Input
                defaultValue={userData?.name}
                required={texts.required}
                key={21}
                inputStyle={{
                  background: "rgba(27, 30, 34, 0.70)",
                  borderRadius: "53.201px",
                  color: "white",
                  fontFamily: fonts.regularCf,
                  fontSize: Spacing(2.75),
                  paddingInline: Spacing(3.75),
                  paddingBlock: Spacing(2),
                  marginTop: Spacing(0.75),
                  border: "1.988px solid #D3D527",
                  "& .MuiInputBase-input": {
                    padding: 0,

                    boxShadow: "none !important",
                  },

                  [`@media (max-width:${1024 - 1}px)`]: {
                    paddingInline: Spacing(3.5),
                    fontSize: Spacing(2.5),
                  },
                  [`@media (max-width:${581 - 1}px)`]: {
                    paddingInline: Spacing(1.25),
                    paddingBlock: Spacing(1),
                    fontSize: Spacing(1.5),
                  },
                }}
                name="name"
                validation={validations.isValidFullname}
              />
            </div>
            <div style={{ width: "100%" }}>
              <Styles.InputTitle>{inputsText.register.phone}</Styles.InputTitle>
              <Input
                defaultValue={userData?.phone}
                required={texts.required}
                inputStyle={{
                  background: "rgba(27, 30, 34, 0.70)",
                  borderRadius: "53.201px",
                  color: "white",
                  fontFamily: fonts.regularCf,
                  fontSize: Spacing(2.75),
                  paddingInline: Spacing(3.75),
                  paddingBlock: Spacing(2),
                  marginTop: Spacing(0.75),
                  border: "1.988px solid #D3D527",
                  "& .MuiInputBase-input": {
                    padding: 0,

                    boxShadow: "none !important",
                  },

                  [`@media (max-width:${1024 - 1}px)`]: {
                    paddingInline: Spacing(3.5),
                    fontSize: Spacing(2.5),
                  },
                  [`@media (max-width:${581 - 1}px)`]: {
                    paddingInline: Spacing(1.25),
                    paddingBlock: Spacing(1),
                    fontSize: Spacing(1.5),
                  },
                }}
                name="phone"
                validation={validations.checkPhone}
                mask={masks.inputMaskTELWithDDD}
                maskToSubmit={masks.removeNumberMask}
              />
            </div>

            <div>
              <Styles.SendButton
                disabled={loading}
                loading={loading}
                action="submit"
                name="submit"
              >
                {texts.list[1].title}
              </Styles.SendButton>
            </div>
          </FormFull>
        </Styles.BoxUserContent>
      </Styles.WhiteBox>
      <Styles.Back
        disabled={loading}
        loading={loading}
        fullWidth={false}
        onClick={goBack}
      >
        {inputsText.actionButton.back}
      </Styles.Back>
    </>
  );
}

export default UserData;
