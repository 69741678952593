import { url } from "inspector";
import styled from "styled-components";
const BgLogo = process.env.REACT_APP_ASSETS_URL + "/imgs/homeForest.webp";
const GrainImage =
  process.env.REACT_APP_ASSETS_URL + "/imgs/grainBackground.webp";
const LeafBackground =
  process.env.REACT_APP_ASSETS_URL + "/imgs/leafBackgorund.webp";

import {
  ButtonContained,
  ButtonOutlined,
  FontStyles,
} from "../../../components";
import { fonts, SVG } from "../../../config";
import { Button } from "@material-ui/core";
const Container = styled.div(({ theme, $menuHeight }) => {
  return {
    overflow: "hidden",
    zIndex: 1,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    backgroundColor: "#ededed",

    borderRadius: "5px",
    flex: 1,
    width: "100%",

    height: theme.spacing(82.875),

    [`@media (max-width:${745 - 1}px)`]: {
      height: theme.spacing(42.75),
    },
    [`@media (max-width:${470 - 1}px)`]: {
      width: "100%",
      height: theme.spacing(34.375),
    },
    [`@media (min-width:${1440}px)`]: {
      height: theme.spacing(82.875),
    },
  };
});

const BgVideoContainer = styled.div(({ theme, $menuHeight }) => {
  return {
    overflow: "hidden",
    position: "relative",
    width: "100%",
    zIndex: 0,
    display: "flex",
    justifyContent: "center",
    height: theme.spacing(82.875),

    [`@media (max-width:${745 - 1}px)`]: {
      height: theme.spacing(72.5),
    },
    [`@media (max-width:${470 - 1}px)`]: {
      width: "100%",
      height: theme.spacing(34.375),
    },
    [`@media (min-width:${1440}px)`]: {
      height: theme.spacing(82.875),
    },
  };
});

const HomeButtons = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    borderRadius: "13.493px",
    border: "0.587px solid #FFF",
    background: "rgba(217, 217, 217, 0.05)",
    backdropFilter: "blur(6.5px)",
    ...FontStyles.bold12,
    fontFamily: fonts.heavy,
    color: "white",
    minWidth: spacing(13.125),
    minHeight: spacing(13.125),
    flex: 1,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    gap: spacing(5.25),
    textTransform: "capitalize",
    lineHeight: "normal",
    padding: "9px",
  };
});

const RegistryButton = styled(ButtonContained)(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      ...FontStyles.bold16,
      fontFamily: fonts.heavy,
      display: "flex",
      [`@media (max-width:${1024 - 1}px)`]: {
        fontSize: spacing(1.75),
      },
      [`@media (max-width:${470 - 1}px)`]: {
        fontSize: spacing(0.9),
        padding: spacing(0.45),
      },
    },
  };
});
const WhitelistButton = styled(ButtonOutlined)(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      ...FontStyles.bold16,
      fontFamily: fonts.heavy,
      color: "black",
      borderColor: "black",
      [`@media (max-width:${1024 - 1}px)`]: {
        display: "none",
      },
      [`@media (max-width:${470 - 1}px)`]: {
        fontSize: spacing(1),
      },
    },
  };
});

const WhitelistSecondButton = styled(ButtonOutlined)(
  ({ theme, $menuHeight }) => {
    const { spacing } = theme;
    return {
      "&&.MuiButton-root": {
        ...FontStyles.bold16,
        fontSize: spacing(1.75),
        color: "black",
        borderColor: "black",
        display: "none",
        fontFamily: fonts.heavy,
        [`@media (max-width:${1024 - 1}px)`]: {
          display: "flex",
        },
        [`@media (max-width:${470 - 1}px)`]: {
          fontSize: spacing(0.9),
          padding: spacing(0.45),
        },
      },
    };
  }
);

const LandingText = styled.p(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    lineHeight: "127.5%",
    ...FontStyles.medium14,
    fontSize: spacing(4.125),
    maxWidth: "402px",
    padding: 0,
    margin: 0,
    marginBottom: spacing(2),
    [`@media (max-width:${1024 - 1}px)`]: {
      marginBottom: spacing(1.5),
      fontSize: spacing(2.25),
      maxWidth: "224px",
    },

    [`@media (max-width:${470 - 1}px)`]: {
      fontSize: spacing(1.25),
      maxWidth: "97px",
    },
  };
});

const LandingFirst = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",

    paddingTop: spacing(20.75),
    paddingLeft: spacing(13.125),

    [`@media (max-width:${1024 - 1}px)`]: {
      paddingTop: spacing(28),
      paddingLeft: spacing(6.125),
    },

    [`@media (max-width:${470 - 1}px)`]: {
      paddingTop: spacing(10),
      paddingLeft: spacing(2.75),
    },
  };
});

const LandingSecond = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",

    paddingTop: spacing(24.75),
    paddingRight: spacing(13.125),
    gap: spacing(1),
    [`@media (max-width:${1024 - 1}px)`]: {
      paddingTop: spacing(31.5),
      paddingRight: spacing(6.125),
    },

    [`@media (max-width:${470 - 1}px)`]: {
      paddingTop: spacing(12.75),
      gap: 0,
      paddingRight: spacing(2.5),
    },
  };
});

const LandingButtonContainer = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    gap: spacing(2.25),
    paddingBottom: spacing(5.75),
    paddingLeft: spacing(13.125),

    [`@media (max-width:${1024 - 1}px)`]: {
      display: "none",
    },

    [`@media (max-width:${470 - 1}px)`]: {
      display: "none",
    },
  };
});

const BgVideo = styled.video(({ theme, $menuHeight }) => {
  return {
    border: "1px #ededed solid",
    objectFit: "cover",
    width: "auto",
    height: theme.spacing(82.875),
    position: "relative",
    top: -$menuHeight + "px",
    pointerEvents: "none",
    [`@media (max-width:${745 - 1}px)`]: {
      top: -$menuHeight + 14 + "px",
      height: theme.spacing(85),
    },
    [`@media (max-width:${470 - 1}px)`]: {
      top: -$menuHeight + 64 + "px",
      width: "100%",
      height: theme.spacing(34.375),
    },
    [`@media (min-width:${1440}px)`]: {
      height: theme.spacing(82.875),
    },
  };
});

const BgForest = styled.div(({ theme, bgImage }) => {
  return {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",

    overflow: "hidden",
    zIndex: 1,
    backgroundImage: `url(${BgLogo})`,
    backgroundSize: "1440px auto",
    backgroundPosition: "top",
    height: theme.spacing(60.375),
    backgroundRepeat: "no-repeat",

    [`@media (max-width:${745 - 1}px)`]: {
      backgroundSize: "744px auto",
      height: theme.spacing(31.5),
    },
    [`@media (max-width:${470 - 1}px)`]: {
      backgroundSize: "625px auto",
      height: theme.spacing(22.25),
    },
    [`@media (min-width:${1440}px)`]: {
      backgroundSize: "2160px auto",
      height: theme.spacing(75.5),
    },
    [`@media (min-width:${2160}px)`]: {
      backgroundSize: "3240px auto",
      height: theme.spacing(98.5),
    },
  };
});

const BgForestImg = styled.img(({ theme, $menuHeight }) => {
  return {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "top",
    [`@media (max-width:${745 - 1}px)`]: {
      height: theme.spacing(26.15625),
    },
  };
});

const AnimalsImg = styled.img(({ theme, $menuHeight }) => {
  return {
    height: "100%",
    width: theme.spacing(63.375),
    maxWidth: "40vw",

    [`@media (max-width:${1024 - 1}px)`]: {
      width: theme.spacing(32.74),
    },
    [`@media (max-width:${470 - 1}px)`]: {
      width: theme.spacing(24.875),
      maxWidth: "45vw",
    },
    [`@media (max-width:${296 - 1}px)`]: { maxWidth: "100%" },
  };
});

const AboutContainer = styled.div(({ theme, $menuHeight }) => {
  return {
    zIndex: 1,
    minHeight: theme.spacing(60.25),
    width: "100%",
    paddingBlock: theme.spacing(8.25),
    paddingInline: theme.spacing(13.125),
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(8.625),
    backgroundColor: "white",
    height: "auto",
    [`@media (max-width:${1440 - 1}px)`]: { minHeight: theme.spacing(60.25) },

    [`@media (max-width:${1024 - 1}px)`]: {
      minHeight: theme.spacing(31.125),
      paddingBlock: theme.spacing(4.25),
      paddingInline: theme.spacing(6.75),
      gap: theme.spacing(4.375),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      minHeight: theme.spacing(43.5),
      padding: theme.spacing(2.75),
      flexDirection: "column",
      gap: theme.spacing(2.5),
    },
  };
});

const AboutTexts = styled.div(({ theme, $menuHeight }) => {
  return {
    display: "flex",
    flexDirection: "column",

    [`@media (max-width:${1440 - 1}px)`]: {},

    [`@media (max-width:${1024 - 1}px)`]: {},

    [`@media (max-width:${470 - 1}px)`]: {},
  };
});

const AboutDesciption = styled.p(({ theme, white }) => {
  return {
    padding: 0,
    margin: 0,
    fontSize: theme.spacing(1.75),
    fontFamily: fonts.regularCf,
    lineHeight: "198%",
    color: white && "white",

    [`@media (max-width:${1024 - 1}px)`]: { fontSize: theme.spacing(0.9) },

    [`@media (max-width:${470 - 1}px)`]: { fontSize: theme.spacing(1.1) },
  };
});

const AboutTitle = styled.p(({ theme, $menuHeight }) => {
  return {
    cursor: "pointer",
    padding: 0,
    margin: 0,
    fontSize: theme.spacing(5.834125),
    fontFamily: fonts.regular,
    lineHeight: "normal",

    [`@media (max-width:${1024 - 1}px)`]: { fontSize: theme.spacing(3) },

    [`@media (max-width:${581 - 1}px)`]: { display: "none" },
  };
});

const AboutTitleMobile = styled.p(({ theme, $menuHeight }) => {
  return {
    cursor: "pointer",
    padding: 0,
    margin: 0,

    fontFamily: fonts.regular,
    lineHeight: "normal",
    fontSize: theme.spacing(2.75),
    display: "none",
    alignSelf: "flex-end",
    paddingLeft: theme.spacing(0.9),
    [`@media (max-width:${581 - 1}px)`]: { display: "block" },
    [`@media (max-width:${296 - 1}px)`]: { alignSelf: "flex-start" },
  };
});

const AboutMobileContainer = styled.div(({ theme, $menuHeight }) => {
  return {
    display: "flex",
    padding: 0,
    margin: 0,
    flexDirection: "row",
    height: "auto",
    [`@media (max-width:${296 - 1}px)`]: { flexDirection: "column" },
  };
});

const WhyContainer = styled.div(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    overflow: "hidden",
    width: "100%",
    minHeight: "1150px",
    backgroundColor: "black",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingInline: spacing(15.5),
    paddingBlock: spacing(7),
    backgroundImage: `url(${GrainImage})`,

    [`@media (max-width:${1024 - 1}px)`]: {
      minHeight: "0px",
      paddingBlock: theme.spacing(4.25),
      paddingInline: theme.spacing(9.25),
      gap: spacing(3.25),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      minHeight: "0px",
      padding: theme.spacing(2.75),
      gap: spacing(3.25),
    },
  };
});

const WhyFirst = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    zIndex: 1,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    gap: spacing(5.25),

    [`@media (max-width:${1024 - 1}px)`]: {
      gap: spacing(2.625),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      flexDirection: "column",
    },
  };
});

const WhyFirstTitle = styled.p(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    color: "white",
    ...FontStyles.bold42,
    fontSize: spacing(5),
    padding: 0,
    margin: 0,
    [`@media (max-width:${1024 - 1}px)`]: { fontSize: theme.spacing(2.5) },

    [`@media (max-width:${581 - 1}px)`]: { fontSize: theme.spacing(3.125) },
  };
});

const LoopVideo = styled.video(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    borderRadius: "36px",
    border: "1px solid #E7FF00",
    maxWidth: "529px",
    width: "100%",
    height: "auto", pointerEvents: "none",
    [`@media (max-width:${1440 - 1}px)`]: { maxWidth: "40vw" },
    [`@media (max-width:${1024 - 1}px)`]: {
      maxWidth: "276px",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      maxWidth: "340px",
    },
  };
});

const WhyLine = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    background: "#E7FF00",
    zIndex: 1,
    maxWidth: "1070px",
    width: "100%",
    height: "1px",
    marginBlock: "81px",
    [`@media (max-width:${1024 - 1}px)`]: {
      marginBlock: "20px",
    },

    [`@media (max-width:${581 - 1}px)`]: { display: "none" },
  };
});

const WhySecond = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    zIndex: 1,
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
    paddingBottom: "56px",
    [`@media (max-width:${1024 - 1}px)`]: {
      gap: "10px",
      paddingBottom: "0px",
    },

    [`@media (max-width:${581 - 1}px)`]: { gap: "12px", paddingBottom: "0px" },
  };
});

const WhySecondTitle = styled.p(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    color: "#E7FF00",
    ...FontStyles.bold42,
    fontFamily: fonts.heavy,
    fontSize: spacing(5),
    padding: 0,
    margin: 0,
    [`@media (max-width:${1024 - 1}px)`]: { fontSize: theme.spacing(2.5) },

    [`@media (max-width:${581 - 1}px)`]: { fontSize: theme.spacing(3.25) },
  };
});

const WhySecondSubTitle = styled.p(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    color: "white",
    fontFamily: fonts.regularCf,
    fontSize: spacing(2.5),
    padding: 0,
    margin: 0,
    [`@media (max-width:${1024 - 1}px)`]: { fontSize: theme.spacing(1.25) },

    [`@media (max-width:${581 - 1}px)`]: { fontSize: theme.spacing(1.5) },
  };
});

const WhyThird = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    zIndex: 1,
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",

    borderRadius: "27.723px",
    border: "0.711px solid #4C4F51",
    background:
      "linear-gradient(95deg, rgba(26, 32, 36, 0.64) -3.04%, rgba(5, 5, 0, 0.80) 100%)",
    backdropFilter: "blur(7.1085076332092285px)",
    paddingBlock: spacing(6.125),

    [`@media (max-width:${1024 - 1}px)`]: {
      paddingBlock: spacing(4.75),
      paddingInline: theme.spacing(2.5),
      gap: spacing(2),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      paddingInline: spacing(2),
      flexDirection: "column",
    },
  };
});

const WhyCard = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    maxWidth: "284px",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    alignItems: "center",
    flex: 1,
    height: "100%",
    padding: "22px",
    "& .paint1 ": {
      fill: "#0B0D0A !important",
      stroke: "transparent !important",
    },
    "&:hover": {
      borderRadius: "28.675px",
      background: "#DEEB13",
      "& .iconBox": {
        fill: "#0B0D0A",
        stroke: "#DEEB13",
        "& .paint0 ": { fill: "transparent !important", stroke: "#0B0D0A" },
        "& .paint3 ": { fill: "#0B0D0A !important" },
        "& .paint2 ": {
          fill: "transparent !important",
          stroke: "#DEEB13 !important",
        },
        "& .paint1 ": {
          fill: "#DEEB13 !important",
          stroke: "transparent !important",
        },
      },
      "& .titleBox": { color: "black", fontHeight: "100%", fontWeight: "600" },
      "& .subtitleBox": { color: "#0B0D0A" },
    },
    [`@media (max-width:${1024 - 1}px)`]: {
      padding: "17px",
    },

    [`@media (max-width:${581 - 1}px)`]: {
      padding: "10px",
    },
  };
});

const WhyCardSubTitle = styled.p(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    color: "white",
    ...FontStyles.regular14,
    fontFamily: fonts.light,
    fontSize: spacing(1.5),
    padding: 0,
    margin: 0,
    textAlign: "center",
    maxWidth: "224px",
    fontWeight: "300",
    lineHeight: "145.52%",
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: theme.spacing(1.25),
      maxWidth: "140px",
    },

    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: theme.spacing(1),
      maxWidth: "224px",
    },
  };
});

const WhyCardTitle = styled.p(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    color: "#E7FF00",
    ...FontStyles.bold42,
    fontFamily: fonts.heavy,
    fontSize: spacing(3.125),
    padding: 0,
    margin: 0,
    marginTop: spacing(-1),
    marginBottom: spacing(1.1),
    [`@media (max-width:${1024 - 1}px)`]: { fontSize: theme.spacing(1.75) },

    [`@media (max-width:${581 - 1}px)`]: { fontSize: theme.spacing(2.5) },
  };
});

const YellowBall = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    position: "absolute",

    top: 0,
    right: 0,
    transform: "translate(90%, -50%)",
    borderRadius: "996px",
    width: "798.961px",
    height: "996px",
    filter: "blur(225px)",
    background: "#E7FF00E6",

    zIndex: 0,

    [`@media (max-width:${1024 - 1}px)`]: {
      borderRadius: "514.6px",
      width: "412.797px",
      height: "514.6px",
      filter: "blur(116.25px)",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      transform: "translate(50%, -50%)",
      borderRadius: "310px",
      width: "310px",
      height: "310px",
      filter: "blur(100px)",
    },
  };
});

const YellowBallTwo = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    position: "absolute",
    transform: "translate(-40%, 50%)",
    bottom: 0,
    left: 0,
    borderRadius: "631px",
    width: "631px",
    height: "601px",
    background: "#E7FF00E6",
    filter: "blur(300px)",

    [`@media (max-width:${1024 - 1}px)`]: {
      borderRadius: "310.517px",
      width: "326.017px",
      height: "310.517px",
      filter: "blur(155px)",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      transform: "translate(-105%, 40%)",
      borderRadius: "540.567px",
      width: "640.189px",
      height: "540.567px",
      filter: "blur(300px)",
    },
  };
});

const MissionContainer = styled.div(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    backgroundColor: "white",
    zIndex: 1,
    width: "100%",

    [`@media (max-width:${1024 - 1}px)`]: {},
    [`@media (max-width:${581 - 1}px)`]: {},
  };
});

const MissionFirst = styled.div(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    paddingBottom: spacing(22),
    paddingTop: spacing(7),
    width: "100%",

    background: `linear-gradient(0deg, rgba(5, 5, 0, 0.58) 0%, rgba(5, 5, 0, 0.58) 100%), url(${LeafBackground}), lightgray 50% / cover no-repeat`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    [`@media (max-width:${1024 - 1}px)`]: {
      paddingTop: spacing(3.25),
      paddingBottom: spacing(10),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      paddingTop: spacing(4.25),
      paddingBottom: spacing(13),
    },
  };
});

const MissionFirstTitle = styled.p(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    cursor: "pointer",
    padding: 0,
    margin: 0,
    ...FontStyles.medium42,
    fontFamily: fonts.regular,
    fontSize: spacing(5),
    color: "white",

    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(2.5),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      textAlign: "center",
      fontSize: spacing(2.5),
    },
  };
});
const MissionFirstSubTitle = styled.p(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    padding: 0,
    margin: 0,
    ...FontStyles.bold42,
    fontSize: spacing(6.75),
    color: "white",

    [`@media (max-width:${1024 - 1}px)`]: { fontSize: spacing(3.5) },
    [`@media (max-width:${581 - 1}px)`]: {
      textAlign: "center",
      fontSize: spacing(3.75),
    },
  };
});
const MissionButton = styled(ButtonOutlined)(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      borderRadius: spacing(2.6, 0),
      borderWidth: "2px",
      paddingInline: spacing(2.5),
      paddingBlock: spacing(1),
      marginTop: spacing(1.4),
      borderColor: "white",
      color: "white",
      ...FontStyles.semibold22,
      fontSize: spacing(2.5),
      fontFamily: fonts.heavy,
      [`@media (max-width:${1024 - 1}px)`]: {
        fontSize: spacing(1.2),
        paddingInline: spacing(1.25),
        paddingBlock: spacing(0.5),
        borderWidth: "1.5px",
      },
      [`@media (max-width:${581 - 1}px)`]: {
        marginTop: spacing(2),
        fontSize: spacing(1.45),
        paddingInline: spacing(1.25),
        paddingBlock: spacing(0.5),
        borderWidth: "1.5px",
      },
    },
  };
});

const MissionSecond = styled.div(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "row",
    gap: spacing(3),
    width: "100%",
    justifyContent: "space-evenly",
    paddingInline: spacing(6),

    [`@media (max-width:${1024 - 1}px)`]: {},
    [`@media (max-width:${581 - 1}px)`]: { display: "none" },
  };
});

const MissionSecondMobile = styled.div(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    display: "none",
    [`@media (max-width:${1024 - 1}px)`]: { display: "none" },
    [`@media (max-width:${581 - 1}px)`]: {
      display: "flex",
      position: "relative",
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-evenly",
      gap: spacing(1),
      paddingInline: spacing(3),
      paddingBottom: spacing(6.75),
      zIndex: 2,
      minHeight: "380px",
    },
  };
});

const MissionBulletsContainer = styled.div(
  ({ theme, index, currentBulletIndex }) => {
    const { spacing } = theme;
    return {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      maxWidth: "259px",

      [`@media (max-width:${1024 - 1}px)`]: { maxWidth: "133px" },
      [`@media (max-width:${581 - 1}px)`]: {
        opacity: index === currentBulletIndex ? 1 : 0,
        display: index === currentBulletIndex ? "flex" : "none",
        transition: "opacity 0.5s ease-in-out",
        maxWidth: "259px",

        zIndex: 2,
        position: "inherit",
      },
    };
  }
);

const MissionBulletsIcon = styled.div(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    position: "absolute",
    left: "50%",
    top: 0,
    transform: "translate(-50%, -75%)",
    backgroundColor: "white",
    padding: "18px",
    borderRadius: 10000,
    maxWidth: "185px",
    height: "185px",
    width: "100%",

    display: "flex",

    justifyContent: "center",
    flex: 1,
    flexShrink: 0,

    [`@media (max-width:${1024 - 1}px)`]: {
      maxWidth: spacing(11.95),
      height: spacing(11.95),
      padding: spacing(1),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      zIndex: 2,
      maxWidth: spacing(23.125),
      height: spacing(23.125),
      transform: "translate(-50%, -50%)",
    },
  };
});

const MissionBulletTitle = styled.p(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    padding: 0,
    margin: 0,

    ...FontStyles.bold22,
    paddingTop: spacing(5.75),
    fontFamily: fonts.heavy,
    fontSize: spacing(2.5),
    textAlign: "center",

    [`@media (max-width:${1024 - 1}px)`]: {
      paddingTop: spacing(2.875),
      fontSize: spacing(1.25),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      paddingTop: spacing(11.5),
      fontSize: spacing(2.5),
    },
  };
});

const MissionBulletDescription = styled.p(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    padding: 0,
    margin: 0,

    ...FontStyles.medium12,
    fontFamily: fonts.regularCf,
    paddingBottom: spacing(7),
    lineHeight: "175%",
    textAlign: "center",

    [`@media (max-width:${1024 - 1}px)`]: {
      paddingBottom: spacing(3.75),
      fontSize: spacing(0.8125),
    },
    [`@media (max-width:${581 - 1}px)`]: { ...FontStyles.medium12 },
  };
});

const MissionConectionIcon = styled(SVG.MissionConection)(
  ({ theme, imgSrc }) => {
    const { spacing } = theme;
    return {
      maxWidth: "113px",
      width: "100%",
      height: "auto",
      alignSelf: "center",
      [`@media (max-width:${1024 - 1}px)`]: { maxWidth: "64px" },
      [`@media (max-width:${581 - 1}px)`]: { maxWidth: "101px" },
    };
  }
);

const WoodIcon = styled(SVG.WoodIcon)(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    maxWidth: "101px",
    width: "100%",
    height: "auto",
    alignSelf: "center",
    [`@media (max-width:${1024 - 1}px)`]: { maxWidth: "55px" },
    [`@media (max-width:${581 - 1}px)`]: { maxWidth: "101px" },
  };
});

const MissionThreeCoinIcon = styled(SVG.MissionThreeCoin)(
  ({ theme, imgSrc }) => {
    const { spacing } = theme;
    return {
      maxWidth: "141px",
      width: "100%",
      height: "auto",
      alignSelf: "center",
      [`@media (max-width:${1024 - 1}px)`]: { maxWidth: "77px" },
      [`@media (max-width:${581 - 1}px)`]: { maxWidth: "121px" },
    };
  }
);

const FireIcon = styled(SVG.FireIcon)(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    maxWidth: "108px",
    width: "100%",
    height: "auto",
    alignSelf: "center",
    [`@media (max-width:${1024 - 1}px)`]: { maxWidth: "58px" },
    [`@media (max-width:${581 - 1}px)`]: { maxWidth: "110px" },
  };
});

const MissionRecycleIcon = styled(SVG.MissionRecycle)(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    maxWidth: "111px",
    width: "100%",
    height: "auto",
    alignSelf: "center",

    [`@media (max-width:${1024 - 1}px)`]: { maxWidth: "58px" },
    [`@media (max-width:${581 - 1}px)`]: { maxWidth: "96px" },
  };
});

const FractureIcon = styled(SVG.FractureIcon)(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    maxWidth: "132px",
    width: "100%",
    height: "auto",
    alignSelf: "center",

    [`@media (max-width:${1024 - 1}px)`]: { maxWidth: "58px" },
    [`@media (max-width:${581 - 1}px)`]: { maxWidth: "112px" },
  };
});

const MissionThreeIcon = styled(SVG.MissionThree)(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    maxWidth: "111px",
    width: "100%",
    height: "auto",
    alignSelf: "center",
    [`@media (max-width:${1024 - 1}px)`]: {
      maxWidth: "58px",
    },
    [`@media (max-width:${581 - 1}px)`]: { maxWidth: "96px" },
  };
});

const ForestIcon = styled(SVG.ForestIcon)(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    maxWidth: "90px",
    width: "100%",
    height: "auto",
    alignSelf: "center",
    [`@media (max-width:${1024 - 1}px)`]: {
      maxWidth: "53px",
    },
    [`@media (max-width:${581 - 1}px)`]: { maxWidth: "90px" },
  };
});

const SecurityContainer = styled.div(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    background: `linear-gradient(0deg, rgba(210,212,40,0.86) 0%, rgba(210,212,40,0.86) 100%), url(${LeafBackground}), lightgray 50% / cover no-repeat`,
    backgroundPosition: "top",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBlock: spacing(1),
    paddingInline: spacing(6),
    gap: spacing(2),
    [`@media (max-width:${1024 - 1}px)`]: {},
    [`@media (max-width:${581 - 1}px)`]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: spacing(3),
      padding: spacing(5),
    },
  };
});

const SecurityLock = styled.img(({ theme }) => {
  const { spacing } = theme;
  return {
    maxWidth: "305px",
    cursor: "pointer",
    [`@media (max-width:${1024 - 1}px)`]: { maxWidth: "157px" },
    [`@media (max-width:${581 - 1}px)`]: { maxWidth: "126px", width: "100%" },
  };
});

const SecurityLogo = styled.img(({ theme }) => {
  const { spacing } = theme;
  return {
    filter: "brightness(0)",
    maxWidth: spacing(32.25),
    flex: 1,
    [`@media (max-width:${1024 - 1}px)`]: { maxWidth: spacing(16.6625) },
    [`@media (max-width:${581 - 1}px)`]: { maxWidth: "218px", width: "100%" },
  };
});

const SecurityFirst = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    flexDirection: "row",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",

    gap: spacing(1),
    paddingBottom: spacing(2),
    [`@media (max-width:${1024 - 1}px)`]: { paddingBottom: spacing(1) },
    [`@media (max-width:${581 - 1}px)`]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: spacing(3),
      paddingBottom: spacing(3),
    },
  };
});

const SecurityTitle = styled.p(({ theme }) => {
  const { spacing } = theme;
  return {
    ...FontStyles.bold42,
    fontSize: spacing(5),
    lineHeight: "126%",
    margin: 0,
    padding: 0,
    maxWidth: "337px",
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(2.5),
      maxWidth: "184px",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: spacing(4.125),
      maxWidth: "100%",
      lineBreak: "anywhere",
    },
  };
});

const SecurityDesciption = styled.p(({ theme }) => {
  const { spacing, palette } = theme;
  return {
    ...FontStyles.medium14,
    maxWidth: "674px",
    margin: 0,
    padding: 0,
    lineHeight: "176%",
    color: palette.black,
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(0.8375),
      maxWidth: "348px",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: spacing(1.5),
      maxWidth: "100%",
    },
  };
});

const PartnersContainer = styled.div(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    flexDirection: "row",
    display: "flex",
    position: "relative",
    background: "#050500",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: spacing(12.375),
    paddingBottom: spacing(11.5),
    paddingInline: spacing(15.5),
    [`@media (max-width:${1024 - 1}px)`]: {
      paddingTop: spacing(5.625),
      paddingBottom: spacing(5.875),
      paddingInline: theme.spacing(12.5),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      paddingBlock: spacing(5.25),
      paddingInline: theme.spacing(2.5),
      flexDirection: "column",
    },
  };
});

const PartnersContent = styled.div(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    flexDirection: "column",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flex: 1,
    [`@media (max-width:${1024 - 1}px)`]: {},
    [`@media (max-width:${581 - 1}px)`]: {},
  };
});

const PartnersLine = styled.div(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    height: "100%",
    width: "1px",
    backgroundColor: "white",
    marginInline: spacing(2),
    [`@media (max-width:${1024 - 1}px)`]: {},
    [`@media (max-width:${581 - 1}px)`]: {
      marginInline: 0,
      height: "1px",
      width: "100%",
      maxWidth: "259px",
      marginBlock: spacing(2.625),
    },
  };
});

const PartnersText = styled.p(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    padding: 0,
    margin: 0,

    ...FontStyles.bold22,

    fontSize: spacing(4.375),
    textAlign: "center",
    lineJeight: "126%",
    color: "white",
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(2.25),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: spacing(2.5),
    },
  };
});

const PartnersImg = styled.img(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    cursor: "pointer",
    maxWidth: "536px",
    width: "100%",
    [`@media (max-width:${1024 - 1}px)`]: {
      maxWidth: "276px",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      maxWidth: "260px",
    },
  };
});

const ExchangesImg = styled.img(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    width: "100%",
    cursor: "pointer",
    maxWidth: "422px",
    [`@media (max-width:${1024 - 1}px)`]: {
      maxWidth: "218px",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      maxWidth: "205px",
    },
  };
});

const CoinImg = styled.img(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    position: "absolute",
    right: 0,
    top: 0,

    maxHeight: "260px",
    transform: "translate(35%, -30%)",
    zIndex: 1,
    [`@media (max-width:${1024 - 1}px)`]: { maxHeight: "165px" },
    [`@media (max-width:${581 - 1}px)`]: { display: "none" },
  };
});

const CoinModalImg = styled.img(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    position: "absolute",
    left: "50%",
    top: 0,
    transform: "translate(-50%, -20%)",
    maxHeight: "200px",
    zIndex: 999999,
    [`@media (max-width:${1024 - 1}px)`]: { maxHeight: "100px" },
    [`@media (max-width:${581 - 1}px)`]: { maxHeight: "80px" },
  };
});

const ModalTextContainer = styled.div(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    height: "100%",
    overflow: "auto",
    marginTop: "128px",
    maxHeight: "calc(100% - 128px)",
    [`@media (max-width:${1024 - 1}px)`]: {
      marginTop: "64px",
      maxHeight: "calc(100% - 64px)",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      marginTop: "44px",
      maxHeight: "calc(100% - 44px)",
    },
  };
});

const ModalTextTitle = styled.p(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    padding: 0,
    margin: 0,
    marginBottom: spacing(2),
    color: "white",
    ...FontStyles.bold42,
    fontFamily: fonts.heavy,
    textAlign: "center",
    [`@media (max-width:${1024 - 1}px)`]: {
      marginBottom: spacing(1.75),
      fontSize: spacing(3.625),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      marginBottom: spacing(1.25),
      fontSize: spacing(2),
    },
  };
});

const ModalText = styled.p(({ theme, imgSrc }) => {
  const { spacing } = theme;
  return {
    lineHeight: "198%",
    padding: 0,
    margin: 0,
    color: "white",
    ...FontStyles.light16,
    fontFamily: fonts.regularCf,
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(1.9),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: spacing(1.1),
    },
  };
});
const DifferentialTitle = styled.p(({ theme, roadmap }) => {
  return {
    display: "flex",
    padding: 0,
    margin: 0,

    marginBottom: 16,
    fontSize: theme.spacing(5.6),
    color: "white",
    fontFamily: fonts.heavy,
    lineHeight: "normal",
    alignSelf: "center",
    zIndex: 1,

    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: roadmap ? theme.spacing(6.95) : theme.spacing(5.8),

      marginBottom: 6,
    },

    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: theme.spacing(3.1),

      marginBottom: 2,
    },
  };
});

const DifferentialText = styled.p(({ theme, desktop }) => {
  return {
    margin: 0,
    padding: 0,
    display: "flex",
    fontFamily: fonts.light,
    fontSize: theme.spacing(2.75),
    color: "white",
    alignSelf: "center",
    zIndex: 1,

    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: theme.spacing(2.8),
      marginBottom: theme.spacing(5),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: theme.spacing(1.5),
      marginBottom: theme.spacing(3),
    },
    [`@media (max-width:${321 - 1}px)`]: {
      fontSize: theme.spacing(1.2),
      marginBottom: theme.spacing(3),
    },
  };
});

const RoadmapContainer = styled.div(({ mobile }) => {
  return {
    position: "relative",
    display: !mobile ? "flex" : "none",
    justifyContent: !mobile ? "center" : null,

    width: "100%",
    height: mobile && "100%",
    [`@media (max-width:${1600 - 1}px)`]: {},
    [`@media (max-width:${1024 - 1}px)`]: {
      display: mobile ? "flex" : "none",
      width: mobile ? "100vw" : null,
      overflowX: mobile ? "scroll" : null,
      overflowY: mobile ? "hidden" : null,
      alignSelf: "flex-start",
    },
    [`@media (max-width:${581 - 1}px)`]: {},
  };
});

const RoadmapContentMobile = styled.div(() => {
  return {
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignContent: "flex-start",
    alignItems: "center",
    width: "100%",
    minWidth: "max-content",
    height: "520px",
    [`@media (max-width:${581 - 1}px)`]: {
      height: "auto",
    },
  };
});
const ArrowIcon = styled(SVG.ArrowIcon)(({ right, roadmap }) => {
  return {
    position: "absolute",
    top: !roadmap ? "50%" : null,
    bottom: roadmap ? "50%" : null,
    right: roadmap & right ? 115 : right ? 115 : null,
    left: !right ? 115 : null,
    width: 70,
    height: "auto",
    cursor: "pointer",
    transform: !right
      ? "scaleX(-1) translate(50%, 50%)"
      : "translate(50%, 50%)",

    [`@media (max-width:${1600 - 1}px)`]: {
      right: roadmap & right ? 115 : right ? 115 : null,
      left: !right ? 115 : null,
    },
  };
});
const AboutRoadTexts = styled.div(({ theme }) => {
  return {
    fontSize: theme.spacing(3.12),
    color: "white",
    fontFamily: fonts.regularCf,

    [`@media (max-width:${1024 - 1}px)`]: {
      display: "none",
    },
  };
});

const AreaIcon = styled(SVG.AreaIcon)(({ theme }) => {
  return {
    maxWidth: "100%",
    marginRight: "3px",
    [`@media (max-width:${581 - 1}px)`]: {
      maxWidth: "8px",
      marginRight: "2px",
    },
  };
});

const Styles = {
  Container,
  BgVideoContainer,
  BgVideo,
  BgForest,
  BgForestImg,
  WhitelistButton,
  RegistryButton,
  LandingText,
  LandingFirst,
  LandingButtonContainer,
  LandingSecond,
  WhitelistSecondButton,

  AnimalsImg,
  AboutContainer,
  AboutTexts,
  AboutDesciption,
  AboutTitle,
  AboutTitleMobile,
  AboutMobileContainer,

  WhyContainer,
  WhyFirst,
  LoopVideo,
  WhyFirstTitle,
  WhyLine,
  WhySecond,
  WhySecondTitle,
  WhySecondSubTitle,
  WhyThird,
  WhyCard,
  WhyCardTitle,
  WhyCardSubTitle,
  YellowBall,
  YellowBallTwo,

  MissionContainer,
  MissionFirst,
  MissionButton,
  MissionFirstTitle,
  MissionFirstSubTitle,
  MissionSecond,
  MissionBulletsContainer,
  MissionBulletsIcon,
  MissionBulletTitle,
  MissionBulletDescription,
  MissionConectionIcon,
  WoodIcon,
  MissionThreeCoinIcon,
  FireIcon,
  MissionRecycleIcon,
  FractureIcon,
  MissionThreeIcon,
  ForestIcon,
  MissionSecondMobile,
  SecurityContainer,
  SecurityLock,
  SecurityLogo,
  SecurityFirst,
  SecurityTitle,
  SecurityDesciption,

  PartnersContainer,
  PartnersText,
  PartnersContent,
  ExchangesImg,
  PartnersImg,
  CoinImg,
  PartnersLine,
  HomeButtons,

  ModalTextContainer,
  CoinModalImg,
  ModalTextTitle,
  ModalText,

  ArrowIcon,
  RoadmapContainer,
  RoadmapContentMobile,
  DifferentialTitle,
  AboutRoadTexts,
  AreaIcon,
};

export default Styles;
