import React from "react";
import { useNavigate } from "react-router-dom";
import ptBr from "../../config/texts/pt-br";
import {
  Input,
  InputDate,
  InputEmail,
  InputPassword,
  StrongText,
} from "../../components/index";
import Styles from "./styles";
import { FormFull } from "form-full";
import { LocalStorage, masks, validations } from "../../utils";
import { hooks } from "../../utils";
import { api } from "../../services";
import { paths } from "../../navigation/navigate";
import { fonts, Spacing } from "../../config";
import Checkbox from "../../components/inputs/Checkbox";
import { customModal } from "../../components/modals/utils";
import Texts from "../../config/texts";

function Register() {
  const texts =
    Texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].register;

  const navigate = useNavigate();
  const { loading, call } = hooks.useRequest();
  const [menuHeight, setHeight] = React.useState<any>(null);
  const [buttonDisabled, setButton] = React.useState(true);
  const [checkTerms, setCheckTerms] = React.useState<Boolean>(false);
  const [checkPrivacy, setCheckPrivacy] = React.useState<Boolean>(false);

  React.useLayoutEffect(() => {
    if (!menuHeight) {
      const mh = document.getElementById("header-menu")?.offsetHeight;

      setHeight(mh);
    }
  }, [menuHeight]);

  const Submit = async (data) => {
    function formatDateToYYYYMMDD(date: Date): string {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      return `${year}-${month}-${day}`;
    }

    const registerData = {
      language: "pt",
      password: data.password,
      email: data.email,
      name: data.name,
      phone: data.phone,
      cpf: data.cpf,
      birthDate: data.birthDate
        ? formatDateToYYYYMMDD(data.birthDate)
        : `${data.year}-${data.month}-${data.day}`,
      acceptTermsOfUse: data.termOfUse === "true" ? true : false,
      acceptTermsOfPrivacy: data.privacyPolicy === "true" ? true : false,
      acceptNewletter: data.news === "true" ? true : false,
    };

    call(null, api.putRegister(registerData), (response) => {
      if (response.ok) {
        customModal.setInfos(
          texts.modal.title,
          [texts.modal.content],
          {
            onClick: () => {
              navigate(paths.login);
              customModal.close();
            },
            label: texts.modal.button,
          },
          null,
          null,
          true
        );
      } else {
      }
    });
  };

  const getMaxDate = () => {
    const today = new Date();
    let maxDate = new Date(
      today.getFullYear() - 18,
      today.getMonth(),
      today.getDate()
    );
    return maxDate;
  };

  const getMinDate = () => {
    const today = new Date();
    let minDate = new Date(
      today.getFullYear() - 150,
      today.getMonth(),
      today.getDate()
    );
    return minDate;
  };
  const handleDownloadTerms = () => {
    const pdfPath =
      process.env.REACT_APP_ASSETS_URL + "/documents/termos_de_uso.pdf";
    window.open(pdfPath, "_blank");
  };
  return (
    <Styles.Container $menuHeight={menuHeight}>
      <Styles.ImageBG $menuHeight={menuHeight}>
        <Styles.YellowBall></Styles.YellowBall>
        <Styles.YellowBallTwo></Styles.YellowBallTwo>
        <FormFull onSubmit={Submit}>
          <Styles.Content>
            <Styles.Title>{texts.title}</Styles.Title>
            <div
              style={{
                display: "flex",
                gap: Spacing(1.25),
                flexDirection: "column",
              }}
            >
              <div>
                <Styles.InputTitle>{texts.fullName}</Styles.InputTitle>
                <Input
                  inputStyle={{
                    border: "1.588px solid #FFF",
                    background: "rgba(27, 30, 34, 0.70)",
                    borderRadius: "53.201px",
                    color: "white",
                    fontFamily: fonts.regularCf,
                    fontSize: Spacing(2.75),
                    paddingInline: Spacing(3.75),
                    paddingBlock: Spacing(2),
                    marginTop: Spacing(0.75),
                    "& .MuiInputBase-input": {
                      padding: 0,

                      boxShadow: "none !important",
                    },

                    [`@media (max-width:${1024 - 1}px)`]: {
                      paddingInline: Spacing(3.5),
                      fontSize: Spacing(2.5),
                    },
                    [`@media (max-width:${581 - 1}px)`]: {
                      paddingInline: Spacing(1.25),
                      paddingBlock: Spacing(1),
                      fontSize: Spacing(1.5),
                    },
                  }}
                  name="name"
                  required={texts.fullNameMessage}
                  mask={masks.capitalizeAll}
                  validation={validations.isValidFullname}
                />
              </div>
              <div>
                <Styles.InputTitle>{texts.password}</Styles.InputTitle>
                <InputPassword
                  inputStyle={{
                    border: "1.588px solid #FFF",
                    background: "rgba(27, 30, 34, 0.70)",
                    borderRadius: "53.201px",
                    color: "white",
                    fontFamily: fonts.regularCf,
                    fontSize: Spacing(2.75),
                    paddingInline: Spacing(3.75),
                    paddingBlock: Spacing(2),
                    marginTop: Spacing(0.75),
                    "& .MuiInputBase-input": {
                      padding: 0,

                      boxShadow: "none !important",
                    },

                    [`@media (max-width:${1024 - 1}px)`]: {
                      paddingInline: Spacing(3.5),
                      fontSize: Spacing(2.5),
                    },
                    [`@media (max-width:${581 - 1}px)`]: {
                      paddingInline: Spacing(1.25),
                      paddingBlock: Spacing(1),
                      fontSize: Spacing(1.5),
                    },
                  }}
                  name="password"
                  required={texts.passwordMessage}
                  validation={validations.isPassword}
                />
              </div>
              <div>
                <Styles.InputTitle>{texts.email}</Styles.InputTitle>
                <InputEmail
                  inputStyle={{
                    border: "1.588px solid #FFF",
                    background: "rgba(27, 30, 34, 0.70)",
                    borderRadius: "53.201px",
                    color: "white",
                    fontFamily: fonts.regularCf,
                    fontSize: Spacing(2.75),
                    paddingInline: Spacing(3.75),
                    paddingBlock: Spacing(2),
                    marginTop: Spacing(0.75),
                    "& .MuiInputBase-input": {
                      padding: 0,

                      boxShadow: "none !important",
                    },

                    [`@media (max-width:${1024 - 1}px)`]: {
                      paddingInline: Spacing(3.5),
                      fontSize: Spacing(2.5),
                    },
                    [`@media (max-width:${581 - 1}px)`]: {
                      paddingInline: Spacing(1.25),
                      paddingBlock: Spacing(1),
                      fontSize: Spacing(1.5),
                    },
                  }}
                  name="email"
                  required={texts.emailMessage}
                  validation={validations.isEmailValid}
                />
              </div>
              <div>
                <Styles.InputTitle>{texts.phone}</Styles.InputTitle>
                <Input
                  inputStyle={{
                    border: "1.588px solid #FFF",
                    background: "rgba(27, 30, 34, 0.70)",
                    borderRadius: "53.201px",
                    color: "white",
                    fontFamily: fonts.regularCf,
                    fontSize: Spacing(2.75),
                    paddingInline: Spacing(3.75),
                    paddingBlock: Spacing(2),
                    marginTop: Spacing(0.75),
                    "& .MuiInputBase-input": {
                      padding: 0,
                      boxShadow: "none !important",
                    },

                    [`@media (max-width:${1024 - 1}px)`]: {
                      paddingInline: Spacing(3.5),
                      fontSize: Spacing(2.5),
                    },
                    [`@media (max-width:${581 - 1}px)`]: {
                      paddingInline: Spacing(1.25),
                      paddingBlock: Spacing(1),
                      fontSize: Spacing(1.5),
                    },
                  }}
                  name="phone"
                  required={texts.phoneMessage}
                  validation={validations.checkPhone}
                  mask={masks.inputMaskTELWithDDD}
                  maskToSubmit={masks.removeNumberMask}
                />
              </div>
              <div>
                <Styles.InputTitle>{texts.cpf}</Styles.InputTitle>
                <Input
                  inputStyle={{
                    border: "1.588px solid #FFF",
                    background: "rgba(27, 30, 34, 0.70)",
                    borderRadius: "53.201px",
                    color: "white",
                    fontFamily: fonts.regularCf,
                    fontSize: Spacing(2.75),
                    paddingInline: Spacing(3.75),
                    paddingBlock: Spacing(2),
                    marginTop: Spacing(0.75),
                    "& .MuiInputBase-input": {
                      padding: 0,
                      boxShadow: "none !important",
                    },

                    [`@media (max-width:${1024 - 1}px)`]: {
                      paddingInline: Spacing(3.5),
                      fontSize: Spacing(2.5),
                    },
                    [`@media (max-width:${581 - 1}px)`]: {
                      paddingInline: Spacing(1.25),
                      paddingBlock: Spacing(1),
                      fontSize: Spacing(1.5),
                    },
                  }}
                  name="cpf"
                  required={texts.cpfMessage}
                  validation={validations.validateCPF}
                  mask={masks.CPF}
                  maskToSubmit={masks.cpfToRequest}
                />
              </div>
              <Styles.InputTitle>{texts.birthDate}</Styles.InputTitle>
              <InputDate
                name="birthDate"
                required={texts.birthDatemMessage}
                maxDate={getMaxDate()}
                minDate={getMinDate()}
              />

              <Styles.CheckboxContainer>
                <Checkbox
                  defaultValue={false}
                  name="termOfUse"
                  onChange={() => setCheckTerms(!checkTerms)}
                />
                <Styles.CheckboxLabel>
                  {texts.declaration[0]}{" "}
                  <span style={{ color: "white" }}>
                    {texts.declaration[1]}{" "}
                    <span
                      style={{
                        textDecoration: "underline white",
                        cursor: "pointer",
                      }}
                      onClick={() => handleDownloadTerms()}
                    >
                      {texts.declaration[2]}
                    </span>
                  </span>
                </Styles.CheckboxLabel>
              </Styles.CheckboxContainer>

              <Styles.CheckboxContainer>
                <Checkbox
                  defaultValue={false}
                  name="privacyPolicy"
                  onChange={() => setCheckPrivacy(!checkPrivacy)}
                />
                <Styles.CheckboxLabel>
                  {texts.policy[0]}{" "}
                  <span
                    style={{
                      color: "white",
                      textDecoration: "underline white",
                      cursor: "pointer",
                    }}
                    onClick={() => window.open(paths.privacyPolicy, "_blank")}
                  >
                    {texts.policy[1]}
                  </span>
                </Styles.CheckboxLabel>
              </Styles.CheckboxContainer>

              <Styles.CheckboxContainer>
                <Checkbox defaultValue={false} name="news" />
                <Styles.CheckboxLabel>{texts.news}</Styles.CheckboxLabel>
              </Styles.CheckboxContainer>
            </div>

            <Styles.SubmitButton
              name="submit"
              loading={loading}
              disabled={
                loading
                  ? buttonDisabled
                  : checkTerms && checkPrivacy
                  ? !buttonDisabled
                  : buttonDisabled
              }
              feedback={true}
              action="submit"
              type="submit"
            >
              {texts.register}
            </Styles.SubmitButton>
            <Styles.Line></Styles.Line>
            <Styles.GoToLoginButton noPointer>
              {texts.have[0]}{" "}
              <StrongText onClick={() => navigate(paths.login)}>
                {texts.have[1]}
              </StrongText>
            </Styles.GoToLoginButton>
          </Styles.Content>
        </FormFull>
      </Styles.ImageBG>
    </Styles.Container>
  );
}

export default Register;
