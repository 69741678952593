import { Button } from "@material-ui/core";
import { url } from "inspector";
import styled from "styled-components";
const leafBackgorund = process.env.REACT_APP_ASSETS_URL + "/imgs/blogBg.webp";
import { FontStyles } from "../../../components";
import { fonts, SVG } from "../../../config";
const Container = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    background: `linear-gradient(0deg, rgba(0, 0, 31, 0.30) 0%, rgba(0, 0, 31, 0.30) 100%), url(${leafBackgorund}), lightgray 50% / cover no-repeat`,
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    overflow: "hidden",
    height: "100vh",
    maxHeight: theme.spacing(47.5),

    marginTop: `-${spacing(-16.25) - $menuHeight}px`,

    alignItems: "flex-end",
    justifyContent: "space-between",

    paddingInline: spacing(15.625),
    paddingBlock: spacing(16.25),
    gap: spacing(2),

    [`@media (max-width:${1024 - 1}px)`]: {
      flexDirection: "column-reverse",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      marginTop: 0,
      paddingInline: spacing(6.5),
      paddingBlock: spacing(9.25),
      paddingTop: spacing(13.75),
      height: "fit-content",
      maxHeight: "none",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      paddingInline: spacing(2.75),
      paddingBlock: spacing(4.75),
      paddingTop: spacing(7.75),
    },
  };
});

const PagTitle = styled.p(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    fontFamily: fonts.bold,
    color: "white",
    fontSize: spacing(5),
    padding: 0,
    margin: 0,
    width: "50vw",

    [`@media (max-width:${1024 - 1}px)`]: {
      fontFamily: fonts.semibold,
      fontSize: `clamp(${spacing(4.75)}px, 100%, ${spacing(6.25)}px)`,
      width: "100%",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontFamily: fonts.medium,
      fontSize: spacing(3.25),
    },
  };
});

const FaqContainer = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    paddingBlock: spacing(6),
    display: "flex",
    flexDirection: "column",
    width: "100%",
    overflow: "hidden",
    paddingInline: spacing(15.625),
    maxWidth: "2200px",
    alignSelf: "center",
    [`@media (max-width:${1024 - 1}px)`]: {
      padding: spacing(6.5),

      flexDirection: "column",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      padding: "clamp(12px, 7vw, 26px)",
    },
  };
});

const BlogCard = styled(Button)(({ theme, $center }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      width: "100%",
      minHeight: "317px",
      margin: 0,
      borderRadius: "13px",
      display: "flex",
      flexDirection: "column",
      padding: spacing(2),
      paddingBottom: spacing(2.75),
      backgroundColor: $center ? "#E7FF00" : "#1A2024",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      textTransform: "none",
      "&.MuiButton-label": {
        display: "flex",
        flexDirection: "column",
      },
      [`@media (max-width:${1024 - 1}px)`]: {
        padding: spacing(3.25),
        paddingBottom: spacing(4.75),
      },
      [`@media (max-width:${581 - 1}px)`]: {
        padding: spacing(1.75),
        paddingBottom: spacing(3),
      },
    },
  };
});

const PostDate = styled.p(({ theme, $center }) => {
  const { spacing } = theme;
  return {
    paddingTop: spacing(1.25),
    fontFamily: fonts.boldCf,
    fontSize: spacing(2.5),
    color: $center ? "#1A2024" : "#E7FF00",

    margin: 0,
    textAlign: "left",
    lineHeight: "normal",
    paddingInline: spacing(1.125),
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(4.125),
      paddingInline: spacing(1.875),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: spacing(2.125),
      paddingInline: spacing(1),
    },
  };
});

const PostTitle = styled.p(({ theme, $center }) => {
  const { spacing } = theme;
  return {
    fontFamily: fonts.boldCf,
    fontSize: spacing(3.25),
    color: $center ? "#1A2024" : "#E7FF00",
    textAlign: "left",
    margin: 0,
    lineHeight: "normal",
    paddingInline: spacing(1.125),
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(5.375),
      paddingInline: spacing(1.875),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: spacing(2.75),
      paddingInline: spacing(1),
    },
  };
});

const PostDescription = styled.p(({ theme, $center }) => {
  const { spacing } = theme;
  return {
    fontFamily: fonts.regularCf,
    fontSize: spacing(2),
    color: $center ? "#1A2024" : "#E7FF00",
    margin: 0,
    maxWidth: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineHeight: "normal",
    textAlign: "left",
    paddingInline: spacing(1.125),
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(3.125),
      paddingInline: spacing(1.875),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: spacing(1.625),
      paddingInline: spacing(1),
    },
  };
});

const PostCard = styled.div(({ theme, $center }) => {
  const { spacing } = theme;
  return {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "6px",
    [`@media (max-width:${1024 - 1}px)`]: {},
    [`@media (max-width:${581 - 1}px)`]: { gap: "4px" },
  };
});

const PostCardImg = styled.div(({ theme, $center, $image }) => {
  const { spacing } = theme;
  return {
    width: "100%",
    height: "179px",
    backgroundImage: `url(${$image})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    border: `1px solid ${$center ? "#1A2024" : "#E7FF00"}`,
    marginBottom: "10px",
    borderRadius: " 13px 13px 0px 0px",
    [`@media (max-width:${1024 - 1}px)`]: { height: "297.45px" },
    [`@media (max-width:${581 - 1}px)`]: {
      height: "157.121px",
      marginBottom: "6px",
    },
  };
});

const FooterContainer = styled.div(({ theme, $noTablet, $image }) => {
  const { spacing } = theme;
  return {
    width: "100%",

    backgroundColor: "#E7FF00",
    paddingLeft: spacing(16.75),
    paddingBlock: spacing(3.5),
    [`@media (max-width:${1024 - 1}px)`]: {
      display: $noTablet && "none",
      paddingLeft: spacing(9.75),
      paddingBlock: spacing(3.5),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      display: $noTablet && "none",
      paddingLeft: spacing(4.75),
      paddingBlock: spacing(1.5),
    },
  };
});

const FooterTitle = styled.p(({ theme, $center, $image }) => {
  const { spacing } = theme;
  return {
    fontFamily: fonts.boldCf,
    fontSize: spacing(5),
    margin: 0,
    paddingBottom: spacing(3),
    paddingTop: spacing(2),
    fontWeight: "800",
    lineHeight: "normal",
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(5),
      paddingBottom: spacing(3),
    },
  };
});

const ScrollList = styled.div(({ theme, $center, $image }) => {
  const { spacing } = theme;
  return {
    width: "100%",
    overflowX: "scroll",
    overflowY: "hidden",
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#E7FF00",
    alignItems: "flex-start",
    gap: spacing(7.125),
    [`@media (max-width:${1024 - 1}px)`]: { gap: spacing(7.125) },
  };
});

const ScrollCard = styled(Button)(({ theme, $center, $image }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      padding: 0,
      flex: 1,
      width: "fit-content",
      minWidth: "fit-content",
      maxWidth: "fit-content",
      display: "flex",
      flexDirection: "column",
    },
  };
});
const ScrollCardContent = styled.div(({ theme, $center, $image }) => {
  const { spacing } = theme;
  return {
    padding: 0,
    flex: 1,
    width: "fit-content",

    maxWidth: "215px",
    display: "flex",
    flexDirection: "column",
    gap: spacing(1),

    [`@media (max-width:${1024 - 1}px)`]: { gap: spacing(1) },
  };
});
const DateText = styled.p(({ theme }) => {
  const { spacing } = theme;
  return {
    margin: 0,
    fontFamily: fonts.boldCf,
    fontSize: spacing(2),
    color: "#1A2024",
    lineHeight: "normal",
    width: "fit-content",
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(2),
    },
  };
});

const BlogText = styled.p(({ theme }) => {
  const { spacing } = theme;
  return {
    margin: 0,
    height: "fit-content",
    fontFamily: fonts.boldCf,
    fontSize: spacing(1.375),
    color: "#E7FF00",
    padding: spacing(0.75),
    paddingInline: spacing(1.1),
    backgroundColor: "#1A2024",
    lineHeight: "normal",
    width: "fit-content",

    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(2),
    },
  };
});
const BlogImage = styled.div(({ theme, $src }) => {
  const { spacing } = theme;
  return {
    borderRadius: "15px",
    background: "#1A2024",
    width: "215px",
    height: "119px",
    border: "1px solid black",
    backgroundImage: `url(${$src})`,
    backgroundSize: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    [`@media (max-width:${1024 - 1}px)`]: {},
  };
});
const Styles = {
  Container,
  PagTitle,
  FaqContainer,

  BlogCard,
  PostTitle,
  PostDate,
  PostDescription,
  PostCard,
  PostCardImg,
  FooterContainer,
  FooterTitle,
  ScrollList,
  ScrollCard,
  DateText,
  ScrollCardContent,
  BlogText,
  BlogImage,
};

export default Styles;
