const colors = {
  // TODO/HINT REMOVER Cores projeto anterior
  whiteBackground: "#FFFFFF",
  blue: "#1E74BB",
  lightBlue: "#DFF1FF",
  softGray: "#F7F7F7",
  softBlue: "#F3FAFF",

  whiteSmoke: "#E1E1E1",
  black: "#1A2024",
  mediumBlue: "#1E74BB",
  grayText: "#626366",
  grayDisabled: "#EFEFEF",
  orangeAlert: "#F59721",
  greenSuccess: "#6EBe44",

  softGrayHover: "#F1F1F1",
  darkGray2TextOpacity: "#4C4C4499",
  white: "#ffffff",
  redError: "#F0166D",
  red: "#FF0000",

  darkOpacity: "#00000020",
  purple2: "#36344D",
  lightBlueInput: "#396A82",
  orange: "#DEA017",

  // TODO/HINT pegar cores totais e mesclar aqui
  //
  //
  darkGray: "#050500",

  lightGray: "#F6F6F6",
  purple: "#5C42DB",
  yellow: "#E7FF00",
};

export default colors;
