import React from "react";
import styled from "styled-components";
import { SVG } from "../../config";

const LogoContainer = styled.div(({ maxWidth, $style }) => {
  return {
    width: maxWidth ? maxWidth : "100%",
    height: maxWidth ? maxWidth * 0.22 : "auto",
    flexShrink: 0,
    fill: "white",
    display: "flex",
    alignItems: "flex-end",
    ...$style,
  };
});

type DefaultLogoProps = {
  white?: boolean;
  maxWidth?: number;
  styles?: any;
  onClick?: () => void;
};

function DefaultLogo({ white, maxWidth, styles, onClick }: DefaultLogoProps) {
  return (
    <LogoContainer maxWidth={maxWidth} $style={styles} onClick={onClick}>
      {white ? (
        <SVG.BioLogo
          style={{
            width: "100%",
            height: "100%",
            alignSelf: "center",
          }}
        />
      ) : (
        <SVG.BioLogo
          style={{
            width: "100%",
            height: "100%",
            alignSelf: "center",
          }}
        />
      )}
    </LogoContainer>
  );
}

export default DefaultLogo;
