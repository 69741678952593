import React from "react";

import Styles from "./styles";

import { useLocation, useNavigate } from "react-router-dom";
import { hooks, masks, LocalStorage } from "../../utils";
import { api } from "../../services";
import { StrongText } from "../../components";

import SelectComponent from "../../components/inputs/Select";
import { FormFull } from "form-full";
import { LuMapPin } from "react-icons/lu";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

import { Spacing } from "../../config";
import ImageGallery from "react-image-gallery";

import "react-image-gallery/styles/css/image-gallery.css";
import {
  Backdrop,
  CircularProgress,
  ClickAwayListener,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import texts from "../../config/texts";
import base64 from "../../utils/storage/Base64";

const PAGE_SIZE = 5;

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function ForestArea(props) {
  const classes = useStyles();
  const textForest =
    texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].forests;

  const location = useLocation();

  const [menuHeight, setHeight] = React.useState<any>(null);
  const [leftWidth, setLeftWidth] = React.useState<any>(null);
  const [isMounted, setMount] = React.useState<boolean>(false);
  const [galleryOpened, setGalleryOpened] = React.useState<boolean>(false);
  const [galleryIndex, setGalleryIndex] = React.useState<number>(0);
  const [areaType, setAreaType] = React.useState<number>(1);
  const [forestIndex, setForestIndex] = React.useState<number>(0);
  const [forestList, setForestList] = React.useState<any | null>(null);
  const [customDefaultData, setCustomDefaultData] = React.useState<any | null>(
    null
  );

  const { loading, call } = hooks.useRequest();
  const navigate = useNavigate();

  const renderVideo = (item: any): any => {
    return (
      <div style={{ width: "100%", flex: 1 }} className="video-wrapper">
        <video
          muted
          id={"video-" + 0}
          autoPlay
          playsInline
          height="100%"
          style={{
            minHeight: "80vh",
            maxHeight: "90vh",
            width: "100%",
          }}
          controls
        >
          <source
            width="100%"
            height="100%"
            style={{
              minHeight: "80vh",
              maxHeight: "90vh",
              width: "fit-content",
            }}
            src={item.embedUrl}
          />
          Seu navegador não suporta vídeos.
        </video>
      </div>
    );
  };

  const Mount = React.useCallback(async () => {
    const getAllData = async (forestExtra) => {
      const imageExtraList: any[] = [];
      const imageExtraGalleryList: any[] = [];
      let videoExtra: any = "";
      for (let index = 1; index < 5; index++) {
        await call(
          null,
          api.getForestImg(forestExtra?.id, forestExtra?.["imageId" + index]),
          (response) => {
            imageExtraList[
              index - 1
            ] = `data:image/jpeg;base64,${response.data}`;
            imageExtraGalleryList[index] = {
              original: `data:image/jpeg;base64,${response.data}`,
              thumbnail: `data:image/jpeg;base64,${response.data}`,
            };
          }
        );
      }
      await call(
        null,
        api.getForestVideo(forestExtra?.id, forestExtra?.videoId),
        async (rr) => {
          function decodeArrayBuffer(buffer) {
            var binary = "";
            var bytes = new Uint8Array(buffer);

            var len = bytes.byteLength;
            for (var i = 0; i < len; i++) {
              binary += String.fromCharCode(bytes[i]);
            }
            return window.btoa(binary);
          }

          const videoURL = decodeArrayBuffer(rr.data);
          const bseVideo = `data:video/mp4;base64,${videoURL}`;
          videoExtra = videoURL;
          const videoEmb = {
            embedUrl: bseVideo,
            original: bseVideo,
            thumbnail: bseVideo,
          };

          imageExtraGalleryList[0] = {
            ...videoEmb,
            renderItem: () => renderVideo(videoEmb),
          };
          return videoURL;
        }
      );

      return {
        ...forestExtra,
        imageList: imageExtraList,
        video: videoExtra,
        imageGalleryList: imageExtraGalleryList,
      };
    };

    call(
      "forestList",
      api.getForests({
        page: 0,
        size: PAGE_SIZE,
        acquired: location.pathname.includes("buy") ? false : true,
      }),
      async (response) => {
        if (response.ok) {
          let newList: any[] = new Array(response?.data?.content?.length);
          const imageList: any[] = [];
          const imageGalleryList: any[] = [];
          let video: any = "";

          const dataExtra = location?.state?.item;

          const isInBaseData = response?.data?.content?.findIndex((item) => {
            if (item?.id === dataExtra?.id) {
              return item;
            } else {
              return false;
            }
          });

          if (dataExtra && isInBaseData === -1) {
            const newData = await getAllData(dataExtra);
            setCustomDefaultData(newData);
          }

          if (isInBaseData !== -1) {
            setForestIndex(isInBaseData);

            const imageList0: any[] = [];
            const imageGalleryList0: any[] = [];

            for (let index = 1; index < 5; index++) {
              await call(
                null,
                api.getForestImg(
                  response?.data?.content?.[isInBaseData].id,
                  response?.data?.content?.[isInBaseData]["imageId" + index]
                ),
                (response) => {
                  imageList[
                    index - 1
                  ] = `data:image/jpeg;base64,${response.data}`;
                  imageGalleryList[index] = {
                    original: `data:image/jpeg;base64,${response.data}`,
                    thumbnail: `data:image/jpeg;base64,${response.data}`,
                  };
                }
              );
              await call(
                null,
                api.getForestImg(
                  response?.data?.content?.[0].id,
                  response?.data?.content?.[0]["imageId" + index]
                ),
                (response) => {
                  imageList0[
                    index - 1
                  ] = `data:image/jpeg;base64,${response.data}`;
                  imageGalleryList0[index] = {
                    original: `data:image/jpeg;base64,${response.data}`,
                    thumbnail: `data:image/jpeg;base64,${response.data}`,
                  };
                }
              );
            }
            await call(
              null,
              api.getForestVideo(
                response?.data?.content?.[isInBaseData].id,
                response?.data?.content?.[isInBaseData].videoId
              ),
              async (rr) => {
                function decodeArrayBuffer(buffer) {
                  var binary = "";
                  var bytes = new Uint8Array(buffer);

                  var len = bytes.byteLength;
                  for (var i = 0; i < len; i++) {
                    binary += String.fromCharCode(bytes[i]);
                  }
                  return window.btoa(binary);
                }

                const videoURL = decodeArrayBuffer(rr.data);
                const bseVideo = `data:video/mp4;base64,${videoURL}`;
                video = videoURL;
                const videoEmb = {
                  embedUrl: bseVideo,
                  original: bseVideo,
                  thumbnail: bseVideo,
                };

                imageGalleryList[isInBaseData] = {
                  ...videoEmb,
                  renderItem: () => renderVideo(videoEmb),
                };
                return videoURL;
              }
            );
            newList[0] = {
              ...response?.data?.content?.[0],
              imageList: imageList0,
              video: null,
              imageGalleryList: imageGalleryList0,
            };

            newList[isInBaseData] = {
              ...response?.data?.content?.[isInBaseData],
              imageList: imageList,
              video: video,
              imageGalleryList: imageGalleryList,
            };
          } else {
            for (let index = 1; index < 5; index++) {
              await call(
                null,
                api.getForestImg(
                  response?.data?.content?.[0].id,
                  response?.data?.content?.[0]["imageId" + index]
                ),
                (response) => {
                  imageList[
                    index - 1
                  ] = `data:image/jpeg;base64,${response.data}`;
                  imageGalleryList[index] = {
                    original: `data:image/jpeg;base64,${response.data}`,
                    thumbnail: `data:image/jpeg;base64,${response.data}`,
                  };
                }
              );
            }
            await call(
              null,
              api.getForestVideo(
                response?.data?.content?.[0].id,
                response?.data?.content?.[0].videoId
              ),
              async (rr) => {
                function decodeArrayBuffer(buffer) {
                  var binary = "";
                  var bytes = new Uint8Array(buffer);

                  var len = bytes.byteLength;
                  for (var i = 0; i < len; i++) {
                    binary += String.fromCharCode(bytes[i]);
                  }
                  return window.btoa(binary);
                }

                const videoURL = decodeArrayBuffer(rr.data);
                const bseVideo = `data:video/mp4;base64,${videoURL}`;
                video = videoURL;
                const videoEmb = {
                  embedUrl: bseVideo,
                  original: bseVideo,
                  thumbnail: bseVideo,
                };

                imageGalleryList[0] = {
                  ...videoEmb,
                  renderItem: () => renderVideo(videoEmb),
                };
                return videoURL;
              }
            );

            newList[0] = {
              ...response?.data?.content?.[0],
              imageList: imageList,
              video: video,
              imageGalleryList: imageGalleryList,
            };
          }

          for (let i = 1; i < response?.data?.content?.length; i++) {
            const imageListA: any[] = [];
            const imageGalleryListA: any[] = [];
            if (isInBaseData !== i) {
              await call(
                null,
                api.getForestImg(
                  response?.data?.content?.[i].id,
                  response?.data?.content?.[i]["imageId1"]
                ),
                (response) => {
                  imageListA[0] = `data:image/jpeg;base64,${response.data}`;
                  imageGalleryListA[0] = {
                    original: `data:image/jpeg;base64,${response.data}`,
                    thumbnail: `data:image/jpeg;base64,${response.data}`,
                  };
                }
              );
              newList[i] = {
                ...response?.data?.content?.[i],
                imageList: imageListA,
                video: null,
                imageGalleryList: imageGalleryListA,
              };
            }
          }

          setForestList({ ...response.data, content: newList });
          let a: any = document.getElementById("test");
          if (a) {
            a.load();
          }
        }
      }
    );
  }, [location]);

  React.useEffect(() => {
    if (!isMounted) {
      Mount();
      setMount(true);
    }
  }, [Mount, isMounted]);

  React.useLayoutEffect(() => {
    if (!menuHeight) {
      const mh = document.getElementById("header-menu")?.offsetHeight;

      setHeight(mh);
    }
    if (!loading) {
      const lw = document.getElementById("side-forest")?.offsetWidth;

      setLeftWidth(lw);
    }
  }, [menuHeight, loading]);

  const openGallery = (index: number) => {
    setGalleryOpened(true);
    setGalleryIndex(index);
  };
  const closeGallery = () => {
    setGalleryOpened(false);
  };

  const checkLoadNex = async (index) => {
    setForestIndex(index);
    setCustomDefaultData(null);
    if (forestList?.content?.[index]?.video === null) {
      const imageList: any[] = [];
      const imageGalleryList: any[] = [];
      let video: any = "";

      for (let i = 1; i < 5; i++) {
        await call(
          null,
          api.getForestImg(
            forestList?.content?.[index].id,
            forestList?.content?.[index]["imageId" + i]
          ),
          (response) => {
            imageList[i - 1] = `data:image/jpeg;base64,${response.data}`;
            imageGalleryList[i] = {
              original: `data:image/jpeg;base64,${response.data}`,
              thumbnail: `data:image/jpeg;base64,${response.data}`,
            };
          }
        );
      }
      await call(
        null,
        api.getForestVideo(
          forestList?.content?.[index].id,
          forestList?.content?.[index].videoId
        ),
        async (rr) => {
          function decodeArrayBuffer(buffer) {
            var binary = "";
            var bytes = new Uint8Array(buffer);

            var len = bytes.byteLength;
            for (var i = 0; i < len; i++) {
              binary += String.fromCharCode(bytes[i]);
            }
            return window.btoa(binary);
          }

          const videoURL = decodeArrayBuffer(rr.data);
          const bseVideo = `data:video/mp4;base64,${videoURL}`;
          video = videoURL;
          const videoEmb = {
            embedUrl: bseVideo,
            original: bseVideo,
            thumbnail: bseVideo,
          };

          imageGalleryList[0] = {
            ...videoEmb,
            renderItem: () => renderVideo(videoEmb),
          };
          return videoURL;
        }
      );
      let newContent = [...forestList?.content];

      newContent[index] = {
        ...forestList?.content?.[index],
        imageList: imageList,
        video: video,
        imageGalleryList: imageGalleryList,
      };

      setForestList({
        ...forestList,
        content: newContent,
      });

      let a: any = document.getElementById("test");
      if (a) {
        a.load();
      }
    } else {
      let a: any = document.getElementById("test");
      if (a) {
        a.load();
      }
    }
  };

  return (
    <>
      <Styles.Container $menuHeight={menuHeight}>
        <Styles.BackgroundVideo
          $menuHeight={menuHeight}
          loop
          autoPlay
          playsInline
          controls={false}
          muted
          preload="auto"
        >
          <source
             style={{
              maxWidth: "322px",
              maxHeight: "214px",
              width: "100%",
              height: "100%",
            }}
            src={process.env.REACT_APP_ASSETS_URL + "/videos/forestGalleryBg.webm"}
            type="video/webm"
          />
        </Styles.BackgroundVideo>
        {!forestList && loading ? (
          <div
            style={{
              height: "100vh",
              width: "100%",
              zIndex: 999,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Styles.BlurContainerEmpty $all>
              <CircularProgress />
            </Styles.BlurContainerEmpty>
          </div>
        ) : forestList?.content?.length > 0 ? (
          <>
            <Styles.BlurContainer id="side-forest">
              {customDefaultData ? (
                <>
                  <Styles.PagTitle>{textForest.info}</Styles.PagTitle>
                  <div
                    style={{
                      display: "flex",

                      flexWrap: "wrap",
                    }}
                  >
                    {loading ? (
                      <Skeleton
                        style={{ backgroundColor: "#ffffff4D" }}
                        variant="rect"
                        width={"100%"}
                        height={182}
                      />
                    ) : (
                      <Styles.ForestImg
                        onClick={() => openGallery(0)}
                        loop
                        autoPlay
                        playsInline
                        controls={false}
                        muted
                        preload="auto"
                        id="test"
                      >
                        <source
                          src={`data:video/mp4;base64,${customDefaultData?.video}`}
                          type="video/mp4"
                        />
                      </Styles.ForestImg>
                    )}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      paddingTop: "20px",
                      gap: "8px",
                      flexWrap: "wrap",
                    }}
                  >
                    {loading ? (
                      <Skeleton
                        style={{ backgroundColor: "#ffffff4D" }}
                        variant="rect"
                        width={"100%"}
                        height={70}
                      />
                    ) : (
                      customDefaultData.imageList?.map((item, index) => (
                        <Styles.ForestImgGallery
                          $img={item}
                          onClick={() => openGallery(index + 1)}
                        ></Styles.ForestImgGallery>
                      ))
                    )}
                  </div>

                  <Styles.WhiteLine></Styles.WhiteLine>
                  {loading ? (
                    <Skeleton
                      style={{ backgroundColor: "#ffffff4D" }}
                      variant="rect"
                      width={"100%"}
                      height={"100%"}
                    />
                  ) : (
                    <>
                      <Styles.NameTitle>{textForest.name}</Styles.NameTitle>
                      <Styles.NameTitle>
                        <StrongText echo>{customDefaultData.name}</StrongText>
                      </Styles.NameTitle>

                      <Styles.LocText>
                        {textForest.country}{" "}
                        <StrongText echo>
                          {customDefaultData.address?.country}
                        </StrongText>
                        ⠀⠀{textForest.state}{" "}
                        <StrongText echo>
                          {customDefaultData.address?.state}
                        </StrongText>
                        ⠀⠀{textForest.city}{" "}
                        <StrongText echo>
                          {customDefaultData.address?.city}
                        </StrongText>
                      </Styles.LocText>

                      <Styles.BoxArea>
                        <FormFull onSubmit={() => {}}>
                          <Styles.LineBox>
                            <Styles.LineBoxTitle>
                              {textForest.totalArea}
                            </Styles.LineBoxTitle>
                            <Styles.LineBoxSubTitle>
                              {areaType !== 1
                                ? customDefaultData.areaAcres
                                : customDefaultData.areaMeters}
                            </Styles.LineBoxSubTitle>
                          </Styles.LineBox>
                          <SelectComponent
                            inputLabel={textForest.unity}
                            name="teste"
                            defaultValue={1}
                            onChange={(value, item) => {
                              setAreaType(value);
                            }}
                            options={[
                              { label: textForest.metro, value: 1 },
                              { label: textForest.hec, value: 2 },
                            ]}
                          />
                        </FormFull>
                      </Styles.BoxArea>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Tooltip
                          title="Abrir no Google Maps"
                          aria-label="open-maps"
                        >
                          <div
                            onClick={() => {
                              window.open(
                                `https://www.google.com/maps?q=${customDefaultData.address.lat},${customDefaultData.address.lon}`,
                                "_blank",
                                "noreferrer"
                              );
                            }}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            <Styles.SmallLocText>
                              <LuMapPin
                                style={{
                                  color: "#E7FF00",
                                  marginRight: Spacing(0.6),
                                }}
                              />
                              {textForest.loc}
                            </Styles.SmallLocText>
                            <Styles.SmallLocBoldText
                              style={{ textDecoration: "underline" }}
                            >
                              {customDefaultData.address?.street +
                                ", " +
                                customDefaultData.address?.number}
                            </Styles.SmallLocBoldText>
                          </div>
                        </Tooltip>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Styles.LatLongText>
                            {textForest.lat}{" "}
                            <StrongText>
                              {customDefaultData.address?.lat}
                            </StrongText>
                          </Styles.LatLongText>
                          <Styles.LatLongText>
                            {textForest.long}{" "}
                            <StrongText>
                              {customDefaultData.address?.lon}
                            </StrongText>
                          </Styles.LatLongText>
                        </div>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <Styles.PagTitle>{textForest.info}</Styles.PagTitle>
                  <div
                    style={{
                      display: "flex",

                      flexWrap: "wrap",
                    }}
                  >
                    {loading ? (
                      <Skeleton
                        style={{ backgroundColor: "#ffffff4D" }}
                        variant="rect"
                        width={"100%"}
                        height={182}
                      />
                    ) : (
                      <Styles.ForestImg
                        onClick={() => openGallery(0)}
                        loop
                        autoPlay
                        playsInline
                        controls={false}
                        muted
                        preload="auto"
                        id="test"
                      >
                        <source
                          src={`data:video/mp4;base64,${forestList?.content[forestIndex]?.video}`}
                          type="video/mp4"
                        />
                      </Styles.ForestImg>
                    )}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      paddingTop: "20px",
                      gap: "8px",
                      flexWrap: "wrap",
                    }}
                  >
                    {loading ? (
                      <Skeleton
                        style={{ backgroundColor: "#ffffff4D" }}
                        variant="rect"
                        width={"100%"}
                        height={70}
                      />
                    ) : (
                      forestList?.content[forestIndex]?.imageList?.map(
                        (item, index) => (
                          <Styles.ForestImgGallery
                            $img={item}
                            onClick={() => openGallery(index + 1)}
                          ></Styles.ForestImgGallery>
                        )
                      )
                    )}
                  </div>

                  <Styles.WhiteLine></Styles.WhiteLine>
                  {loading ? (
                    <Skeleton
                      style={{ backgroundColor: "#ffffff4D" }}
                      variant="rect"
                      width={"100%"}
                      height={"100%"}
                    />
                  ) : (
                    <>
                      <Styles.NameTitle>{textForest.name}</Styles.NameTitle>
                      <Styles.NameTitle>
                        <StrongText echo>
                          {forestList?.content[forestIndex]?.name}
                        </StrongText>
                      </Styles.NameTitle>

                      <Styles.LocText>
                        {textForest.country}{" "}
                        <StrongText echo>
                          {forestList?.content[forestIndex]?.address?.country}
                        </StrongText>
                        ⠀⠀{textForest.state}{" "}
                        <StrongText echo>
                          {forestList?.content[forestIndex]?.address?.state}
                        </StrongText>
                        ⠀⠀{textForest.city}{" "}
                        <StrongText echo>
                          {forestList?.content[forestIndex]?.address?.city}
                        </StrongText>
                      </Styles.LocText>

                      <Styles.BoxArea>
                        <FormFull onSubmit={() => {}}>
                          <Styles.LineBox>
                            <Styles.LineBoxTitle>
                              {textForest.totalArea}
                            </Styles.LineBoxTitle>
                            <Styles.LineBoxSubTitle>
                              {areaType !== 1
                                ? forestList?.content[forestIndex]?.areaAcres
                                : forestList?.content[forestIndex]?.areaMeters}
                            </Styles.LineBoxSubTitle>
                          </Styles.LineBox>
                          <SelectComponent
                            inputLabel={textForest.unity}
                            name="teste"
                            defaultValue={1}
                            onChange={(value, item) => {
                              setAreaType(value);
                            }}
                            options={[
                              { label: textForest.metro, value: 1 },
                              { label: textForest.hec, value: 2 },
                            ]}
                          />
                        </FormFull>
                      </Styles.BoxArea>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Tooltip
                          title="Abrir no Google Maps"
                          aria-label="open-maps"
                        >
                          <div
                            onClick={() => {
                              window.open(
                                `https://www.google.com/maps?q=${forestList?.content[forestIndex]?.address.lat},${forestList?.content[forestIndex]?.address.lon}`,
                                "_blank",
                                "noreferrer"
                              );
                            }}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            <Styles.SmallLocText>
                              <LuMapPin
                                style={{
                                  color: "#E7FF00",
                                  marginRight: Spacing(0.6),
                                }}
                              />
                              {textForest.loc}
                            </Styles.SmallLocText>
                            <Styles.SmallLocBoldText
                              style={{ textDecoration: "underline" }}
                            >
                              {forestList?.content[forestIndex]?.address
                                ?.street +
                                ", " +
                                forestList?.content[forestIndex]?.address
                                  ?.number}
                            </Styles.SmallLocBoldText>
                          </div>
                        </Tooltip>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Styles.LatLongText>
                            {textForest.lat}{" "}
                            <StrongText>
                              {forestList?.content[forestIndex]?.address?.lat}
                            </StrongText>
                          </Styles.LatLongText>
                          <Styles.LatLongText>
                            {textForest.long}{" "}
                            <StrongText>
                              {forestList?.content[forestIndex]?.address?.lon}
                            </StrongText>
                          </Styles.LatLongText>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </Styles.BlurContainer>

            <Styles.BoxForests maxW={leftWidth}>
              <Styles.MoreBox>
                <Styles.PagTitle style={{ width: "100%" }}>
                  {textForest.relation}
                </Styles.PagTitle>

                {location.pathname.includes("buy") && (
                  <Styles.ValueForest>
                    <Styles.ValueTitle>{textForest.values}</Styles.ValueTitle>
                    <Styles.ValueSymbol>
                      R$
                      <Styles.ValueNumber>
                        {" "}
                        {masks
                          .formatarValorMonetario(
                            forestList?.content[forestIndex]?.value
                          )
                          ?.substring(2)}
                      </Styles.ValueNumber>
                    </Styles.ValueSymbol>
                  </Styles.ValueForest>
                )}
                <Styles.MoreForest
                  loading={loading}
                  disabled={loading}
                  onClick={() => {
                    navigate(
                      `/allForest/${
                        location.pathname.includes("buy") ? "buy" : "acquired"
                      }`
                    );
                  }}
                  fullWidth={false}
                >
                  {textForest.more}
                </Styles.MoreForest>
              </Styles.MoreBox>
              <div>
                <div
                  style={{
                    gap: "28px",
                    display: "flex",
                  }}
                >
                  {forestList?.content?.map((forest, index) => {
                    if (forestIndex <= index) {
                      return (
                        <Styles.CardContainer
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Styles.CardForests
                            loading={loading}
                            onClick={() => {
                              if (!loading) {
                                checkLoadNex(index);
                              }
                            }}
                            $img={forest.imageList[0]}
                          >
                            {loading && <CircularProgress />}
                          </Styles.CardForests>
                          <Styles.NameTitleCard>
                            <StrongText echo>
                              {forest?.name ? forest?.name : "-"}
                            </StrongText>
                          </Styles.NameTitleCard>
                        </Styles.CardContainer>
                      );
                    }
                  })}
                </div>
                <Styles.NavForestBox>
                  <Styles.NavForest
                    loading={loading}
                    disabled={forestIndex <= 0 || loading}
                    $disabled={forestIndex <= 0}
                    onClick={() => {
                      checkLoadNex(forestIndex - 1);
                    }}
                  >
                    <BiChevronLeft />
                  </Styles.NavForest>
                  <Styles.NavForest
                    loading={loading}
                    disabled={
                      forestIndex >= forestList?.content.length - 1 || loading
                    }
                    $disabled={forestIndex >= forestList?.content.length - 1}
                    onClick={() => {
                      checkLoadNex(forestIndex + 1);
                    }}
                  >
                    <BiChevronRight />
                  </Styles.NavForest>
                  <div
                    style={{
                      backgroundColor: "#E7FF00",
                      height: "1px",
                      width: "100%",
                      marginLeft: "10px",
                    }}
                  ></div>
                </Styles.NavForestBox>
              </div>
              {/* <Styles.ValueForestSec>
                <Styles.ValueTitle>{textForest.values}</Styles.ValueTitle>
                <Styles.ValueSymbol>
                  R$
                  <Styles.ValueNumber>
                    {" "}
                    {masks
                      .formatarValorMonetario(
                        forestList?.content[forestIndex]?.value
                      )
                      ?.substring(2)}
                  </Styles.ValueNumber>
                </Styles.ValueSymbol>
              </Styles.ValueForestSec> */}
            </Styles.BoxForests>
          </>
        ) : (
          <div
            style={{
              height: "100vh",
              width: "100%",
              zIndex: 999,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Styles.BlurContainerEmpty>
              <Styles.EmptyTitle>
                {location.pathname.includes("buy")
                  ? textForest.noPurchasingOp
                  : textForest.noAcquiredForests}{" "}
                <br></br> {textForest.tryAgain}
              </Styles.EmptyTitle>
            </Styles.BlurContainerEmpty>
          </div>
        )}
      </Styles.Container>
      {galleryOpened && (
        <Backdrop
          className={classes.backdrop}
          open={galleryOpened}
          onClick={() => {}}
        >
          <ClickAwayListener
            onClickAway={() => {
              if (galleryOpened) {
                closeGallery();
              }
            }}
          >
            <ImageGallery
              items={
                customDefaultData
                  ? customDefaultData.imageGalleryList
                  : forestList?.content[forestIndex]?.imageGalleryList
              }
              startIndex={galleryIndex}
              infinite={false}
              showThumbnails={false}
              showFullscreenButton={false}
              useBrowserFullscreen={false}
              useTranslate3D={false}
              showPlayButton={false}
              renderRightNav={(onClick, disabled) => {
                return (
                  <Styles.NavGalleryRight
                    $disabled={disabled}
                    onClick={() => {
                      onClick();
                      const previousVideoId = `video-${galleryIndex}`;
                      const previousVideo = document.getElementById(
                        previousVideoId
                      ) as any;
                      if (previousVideo) {
                        if (previousVideo?.tagName === "VIDEO") {
                          previousVideo?.pause();
                        }
                      }
                    }}
                    aria-label="right"
                  >
                    <BiChevronRight />
                  </Styles.NavGalleryRight>
                );
              }}
              renderLeftNav={(onClick, disabled) => {
                return (
                  <Styles.NavGalleryLeft
                    $disabled={disabled}
                    onClick={() => {
                      onClick();
                      const previousVideoId = `video-${galleryIndex}`;
                      const previousVideo = document.getElementById(
                        previousVideoId
                      ) as any;
                      if (previousVideo) {
                        if (previousVideo?.tagName === "VIDEO") {
                          previousVideo?.pause();
                        }
                      }
                    }}
                    aria-label="left"
                  >
                    <BiChevronLeft />
                  </Styles.NavGalleryLeft>
                );
              }}
            />
          </ClickAwayListener>
        </Backdrop>
      )}
    </>
  );
}

export default ForestArea;
