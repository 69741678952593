import Styles from "../../styles";

import texts from "../../../../config/texts";
import { useContext, useRef, useState } from "react";
import { BiSolidCamera } from "react-icons/bi";
import {
  customModal,
  customModalBlur,
} from "../../../../components/modals/utils";
import { api } from "../../../../services";
import { hooks, LocalStorage } from "../../../../utils";
import { ButtonContained } from "../../../../components";
import { fonts, Spacing, SVG } from "../../../../config";
import { StorageContext } from "../../../../contexts/StorageContext";
import alerts from "../../../../utils/Alerts";
interface Props {
  $style?: any;
}

function SelfieModal({ $style }: Props): JSX.Element {
  const currentText =
    texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].config;
  const actionText =
    texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].actionButton;
  const { setUserData, userData } = useContext<any>(StorageContext);
  const { call } = hooks.useRequest();
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [otherLoading, setOtherLoading] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<any | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > 8 * 1024 * 1024) {
        alerts.alertError("Os tamanho do arquivo deve ser inferior a 8mb");
        const inputV = document.getElementById("selfie-img") as any;
        if (inputV) {
          inputV.value = null;
        }

        return;
      } else {
        setSelectedImage(file);
      }
    }
  };
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Simula um clique no input de arquivo
    }
  };

  const convertToBase64 = (file: File) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target) {
          resolve(event.target.result as string);
        } else {
          reject("Failed to convert to base64");
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const convertImage = async () => {
    setOtherLoading(true);
    if (selectedImage) {
      try {
        const file = await convertToBase64(selectedImage);

        const data = {
          selfieImage: file
            .replace("data:image/png;base64,", "")
            .replace("data:image/jpeg;base64,", ""),
        };

        call(null, api.editUserDocs(data), (response) => {
          if (response.ok) {
            setUserData({ ...userData, ...response?.data });

            setOtherLoading(false);
            setConfirmed(true);
          } else {
            setOtherLoading(false);
          }
        });
      } catch (error) {
        setOtherLoading(false);
      }
    } else {
      setOtherLoading(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: Spacing(2.625),
      }}
    >
      <SVG.SelfieIcon
        style={{
          fill: "#E7FF00",
          stroke: "#E7FF00",
          color: "#E7FF00",
          maxWidth: "109.287px",
          maxHeight: "109.287px",
          width: "100%",
          height: "auto",
        }}
      />
      <p
        style={{
          fontSize: Spacing(3.125),
          fontFamily: fonts.heavy,
          textAlign: "center",
          color: "white",
          margin: 0,
        }}
      >
        {confirmed || userData?.docs?.selfieStatus === "PENDING"
          ? currentText.selfie.under
          : currentText.selfie.send}
      </p>
      {!confirmed && userData?.docs?.selfieStatus !== "PENDING" && (
        <>
          <p
            style={{
              fontSize: Spacing(1.25),
              fontFamily: fonts.mediumCf,
              textAlign: "center",
              lineHeight: "198%",
              color: "white",
              margin: 0,
            }}
          >
            {currentText.selfie.sub}
          </p>
          <input
            id="selfie-img"
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            ref={fileInputRef}
            style={{ display: "none" }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: " center",
              alignItems: "center",
              gap: Spacing(1),
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            <Styles.ModalImport
              loading={otherLoading}
              disabled={otherLoading}
              $full
              fullWidth={false}
              onClick={handleButtonClick}
            >
              {selectedImage ? (
                <Styles.ModalImportText>
                  {selectedImage.name}
                </Styles.ModalImportText>
              ) : (
                currentText.selfie.inputOne
              )}
            </Styles.ModalImport>
            {selectedImage && (
              <Styles.ModalImportSend
                loading={otherLoading}
                onClick={convertImage}
                disabled={otherLoading}
                $full
                fullWidth={false}
              >
                {actionText.send}
              </Styles.ModalImportSend>
            )}
          </div>
        </>
      )}
      {confirmed ||
        (userData?.docs?.selfieStatus === "PENDING" && (
          <Styles.ModalImportSend
            onClick={() => customModalBlur.close()}
            $full
            fullWidth={false}
          >
            {actionText.close}
          </Styles.ModalImportSend>
        ))}
    </div>
  );
}

export default SelfieModal;
