import { api } from "../../services";
import hooks from "../hooks";

export default async function getImagesLIst(response: any) {
  const { loading, call } = hooks.useRequest();
  let data: any[] = [];

  for (const item of response?.data?.content) {
    await call(null, api.getImage(item.imageId, item.id), (response) => {
      data.push({ ...item, img: response?.data });
    });
  }

  return { ...response, content: data };
}
