export const prefix = "";

export const paths = {
  login: prefix + "/login",
  home: prefix + "/",
  forgetPassword: prefix + "/forgotPassword",
  about: prefix + "/about",
  register: prefix + "/register",
  termsOfUse: prefix + "/termsOfUse",
  privacyPolicy: prefix + "/privacyPolicy",

  forestArea: prefix + "/forest/:type",
  allForest: prefix + "/allForest/:type",
  profile: prefix + "/profile",
  geral: prefix + "/geral",
  general: prefix + "/general",
  detail: prefix + "/detail",
  system: prefix + "/system",
  blog: prefix + "/blog",
  faq: prefix + "/faq",
  blogReader: prefix + "/blog/article/:id",
  config: prefix + "/config",
  payment: prefix + "/payment",
  extract: prefix + "/extract",
  dashboard: prefix + "/dashboard",
  nfts: prefix + "/nfts",
  nftTransfer: prefix + "/nftTransfer",
};
