import React from "react";
import ptBr from "../../config/texts/pt-br";
import Styles from "./styles";
import { Spacing, fonts } from "../../config";
import { LocalStorage } from "../../utils";
import Texts from "../../config/texts";

function TermsOfUse() {
  const texts = Texts[LocalStorage.getItem("language") ?LocalStorage.getItem("language"): 'pt'].termsOfUse;

  const [menuHeight, setHeight] = React.useState<any>(null);

  React.useLayoutEffect(() => {
    if (!menuHeight) {
      const mh = document.getElementById("header-menu")?.offsetHeight;

      setHeight(mh);
    }
  }, [menuHeight]);

  return (
    <>
      <Styles.Container $menuHeight={menuHeight}>
        <Styles.Logo />
      </Styles.Container>
      <Styles.TermsContainer>
        <Styles.Item>
          <Styles.Title>{texts.title}</Styles.Title>
          <Styles.Description
            style={{
              fontFamily: fonts.regular,
              fontSize: Spacing(2),
              padding: 0,
              margin: 0,
            }}
          >
            {texts.text}
          </Styles.Description>
        </Styles.Item>
      </Styles.TermsContainer>
    </>
  );
}

export default TermsOfUse;
