import Styles from "../styles/StylesProDiferencial";

type BoxProps = {
  icon: any;
  title: string;
  text: string;
  index?: number;
};

function Box({ icon, title, text, index }: BoxProps) {
  return (
    <>
      <Styles.BoxContent index={index}>
        <Styles.CoinIcon src={icon} />
        <Styles.BoxTitle>{title}</Styles.BoxTitle>
        <Styles.BoxText>{text}</Styles.BoxText>
      </Styles.BoxContent>
    </>
  );
}

export default Box;
