import React, { useContext } from "react";
import Styles from "./styles";
const GoldenCoin = process.env.REACT_APP_ASSETS_URL + "/imgs/goldenCoin.webp";
import Differentials from "../about/components/Differentials";
import Purpose from "../about/components/Purpose";
import NFT from "../about/components/NFT";
import WhitePaper from "../about/components/WhitePaper";
import texts from "../../config/texts";
import { StorageContext } from "../../contexts/StorageContext";
import { LocalStorage } from "../../utils";

function Home() {
  const currentText =
    texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ];
  const [isMounted, setMount] = React.useState<boolean>(false);
  const [menuHeight, setHeight] = React.useState<any>(null);

  React.useLayoutEffect(() => {
    if (!menuHeight) {
      const mh = document.getElementById("header-menu")?.offsetHeight;

      setHeight(mh);
    }
  }, [menuHeight]);

  const Mount = React.useCallback(() => {}, []);

  React.useEffect(() => {
    if (!isMounted) {
      Mount();
      setMount(true);
    }
  }, [Mount, isMounted]);

  return (
    <>
      <Styles.Container $menuHeight={menuHeight}>
        <Styles.BgVideoContainer>
          <Styles.BgVideo
            $menuHeight={menuHeight}
            loop
            autoPlay
            playsInline
            controls={false}
            muted
            preload="auto"
          >
            <source
              src={process.env.REACT_APP_ASSETS_URL + "/videos/aboutVideo.webm"}
              type="video/webm"
            />
          </Styles.BgVideo>
          <Styles.TextContainer>
            <Styles.BgCoinImg src={GoldenCoin} />
            <Styles.TextBox>
              <Styles.AboutTitle>
                {currentText.home.aboutTitle}
              </Styles.AboutTitle>

              <Styles.AboutDescription>
                {currentText.home.about}
              </Styles.AboutDescription>
            </Styles.TextBox>
          </Styles.TextContainer>
        </Styles.BgVideoContainer>
      </Styles.Container>

      <Differentials />
      <Purpose />
      <WhitePaper />
      <NFT />
    </>
  );
}

export default Home;
