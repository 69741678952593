import { Button } from "@material-ui/core";
import { url } from "inspector";
import styled from "styled-components";
const leafBackgorund = process.env.REACT_APP_ASSETS_URL + "/imgs/leafBackgorund.webp";
import {
  ButtonContained,
  ButtonOutlined,
  FontStyles,
} from "../../../components";
import { fonts, SVG } from "../../../config";
import colors from "../../../config/colors";
const Container = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    position: "relative",
    display: "flex",
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "664px",
    height: "100%",
    marginTop: `-${$menuHeight}px`,

    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "flex-end",

    [`@media (max-width:${1024 - 1}px)`]: {
      marginTop: 0,

      height: "100%",
      maxHeight: "573px",
      minHeight: "auto",
      paddingTop: spacing(12.5),
      paddingBottom: spacing(6.5),
      paddingRight: spacing(6.5),
    },

    [`@media (max-width:${470 - 1}px)`]: {
      maxHeight: "303px",
      paddingTop: spacing(6.75),
      paddingBottom: spacing(3.5),
      paddingRight: spacing(3.5),
    },
  };
});

const BgVideoContainer = styled.div(({ theme, $menuHeight }) => {
  return {
    position: "relative",
    width: "100%",
    zIndex: 0,
    display: "flex",
    justifyContent: "center",
    height: theme.spacing(82.875),

    [`@media (max-width:${745 - 1}px)`]: {
      height: theme.spacing(72.5),
    },
    [`@media (max-width:${470 - 1}px)`]: {
      width: "100%",
      height: theme.spacing(34.375),
    },
    [`@media (min-width:${1440}px)`]: {
      height: theme.spacing(82.875),
    },
  };
});

const HomeButtons = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    borderRadius: "13.493px",
    border: "0.587px solid #FFF",
    background: "rgba(217, 217, 217, 0.05)",
    backdropFilter: "blur(1.5927602052688599px)",
    ...FontStyles.bold12,
    fontFamily: fonts.heavy,
    color: "white",
    minWidth: spacing(13.125),
    minHeight: spacing(13.125),
    flex: 1,
    width: "100%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    gap: spacing(5.25),
    textTransform: "capitalize",
    lineHeight: "normal",
    padding: "8px",
  };
});

const LeftButtons = styled.div(({ theme, widthFull }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    gap: spacing(1),
    [`@media (max-width:${1024 - 1}px)`]: {
      flexDirection: "column",
      width: widthFull ? "300px" : "200px",
    },
    [`@media (max-width:${470 - 1}px)`]: {
      flexDirection: "column",
      width: "104px",
    },
  };
});
const LeftButtonsContainer = styled.div(({ theme, widthFull }) => {
  const { spacing } = theme;
  return {
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    flexDirection: "row",
  };
});

const RegistryButton = styled(ButtonContained)(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      ...FontStyles.bold16,
      fontFamily: fonts.heavy,
      [`@media (max-width:${1024 - 1}px)`]: {
        fontSize: spacing(1.75),
      },
      [`@media (max-width:${470 - 1}px)`]: {
        fontSize: spacing(0.9),
        padding: spacing(1),
      },
    },
  };
});

const RegistrySecondButton = styled(ButtonContained)(
  ({ theme, $menuHeight }) => {
    const { spacing } = theme;
    return {
      "&&.MuiButton-root": {
        width: "100%",
        display: "none",
        ...FontStyles.bold16,
        fontFamily: fonts.heavy,

        alignItens: "center",
        justifyContent: "center",
        [`@media (max-width:${1024 - 1}px)`]: {
          display: "flex",
          fontSize: spacing(1.75),
        },
        [`@media (max-width:${470 - 1}px)`]: {
          fontSize: spacing(0.9),
          padding: spacing(0.45),
        },
      },
    };
  }
);

const AreaIcon = styled(SVG.AreaIcon)(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    width: "auto",
    height: "auto",
    [`@media (max-width:${1024 - 1}px)`]: {},
    [`@media (max-width:${470 - 1}px)`]: {
      maxWidth: spacing(1),
      maxHeight: spacing(1),
    },
  };
});

const WhitelistButton = styled(ButtonOutlined)(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      ...FontStyles.bold16,
      fontFamily: fonts.heavy,
      color: colors.yellow,
      borderColor: colors.yellow,
      [`@media (max-width:${1024 - 1}px)`]: {
        fontSize: spacing(1.75),
      },
      [`@media (max-width:${470 - 1}px)`]: {
        fontSize: spacing(1),
      },
    },
  };
});

const WhitelistSecondButton = styled(ButtonOutlined)(
  ({ theme, $menuHeight }) => {
    const { spacing } = theme;
    return {
      "&&.MuiButton-root": {
        ...FontStyles.bold16,
        fontSize: spacing(1.75),
        color: "black",
        borderColor: "black",
        display: "none",
        fontFamily: fonts.heavy,
        [`@media (max-width:${1024 - 1}px)`]: {
          display: "flex",
        },
        [`@media (max-width:${470 - 1}px)`]: {
          fontSize: spacing(0.9),
          padding: spacing(0.45),
        },
      },
    };
  }
);

const LandingText = styled.p(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    lineHeight: "127.5%",
    ...FontStyles.regular14,
    fontSize: spacing(5.25),
    maxWidth: "640px",
    padding: 0,
    margin: 0,
    marginBottom: spacing(2),
    color: "white",
    [`@media (max-width:${1024 - 1}px)`]: {
      marginBottom: spacing(1.5),
      fontSize: spacing(4.5),
      maxWidth: "317px",
    },

    [`@media (max-width:${470 - 1}px)`]: {
      fontSize: spacing(2.25),
      maxWidth: "197px",
    },
  };
});

const LandingDesctiption = styled.p(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    fontFamily: fonts.regularCf,
    fontSize: spacing(2.25),
    maxWidth: "524px",
    padding: 0,
    margin: 0,
    marginBottom: spacing(2),
    width: "100%",
    lineHeight: "168.5%",
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(2.25),
      maxWidth: "293px",
    },

    [`@media (max-width:${470 - 1}px)`]: {
      fontSize: spacing(1.25),
      marginBottom: spacing(1.75),
      maxWidth: "155px",
    },
  };
});

const LandingFirst = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    zIndex: 3,
    paddingLeft: spacing(13.125),
    width: "100%",
    [`@media (max-width:${1024 - 1}px)`]: {
      paddingLeft: spacing(6.125),
    },

    [`@media (max-width:${470 - 1}px)`]: {
      paddingLeft: spacing(3.5),
    },
  };
});

const LandingSecond = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",

    paddingBottom: spacing(5.75),
    paddingRight: spacing(8),
    gap: spacing(1),
    [`@media (max-width:${1024 - 1}px)`]: {
      display: "none",
    },

    [`@media (max-width:${470 - 1}px)`]: {
      display: "none",
    },
  };
});

const LandingButtonContainer = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    gap: spacing(2.25),
    paddingBottom: spacing(5.75),
    paddingLeft: spacing(13.125),
    marginTop: spacing(8),
    alignItems: "stretch",
    [`@media (max-width:${1024 - 1}px)`]: {
      display: "none",
      marginTop: 0,
    },

    [`@media (max-width:${470 - 1}px)`]: {
      display: "none",
    },
  };
});

const BgVideo = styled.video(({ theme, $menuHeight }) => {
  return {
    position: "absolute",
    objectFit: "cover",
    width: "100%",
    height: "100%",
    top: 0,
    pointerEvents: "none",
    [`@media (max-width:${745 - 1}px)`]: {},
    [`@media (max-width:${470 - 1}px)`]: {},
    [`@media (min-width:${1440}px)`]: {},
  };
});

const ProblemContainer = styled.div(({ theme, $bgImage }) => {
  const { spacing } = theme;
  return {
    padding: spacing(5.75),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    backgroundImage: `url(${$bgImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    [`@media (max-width:${1024 - 1}px)`]: {
      paddingBlock: spacing(6),
      paddingInline: spacing(4),
    },

    [`@media (max-width:${470 - 1}px)`]: { padding: spacing(3.25) },
  };
});

const ProblemTitle = styled.p(({ theme, $bgImage }) => {
  const { spacing } = theme;
  return {
    padding: 0,
    margin: 0,
    color: "white",
    fontFamily: fonts.heavy,
    fontSize: spacing(6.5),
    textAlign: "center",
    maxWidth: "700px",
    [`@media (max-width:${1024 - 1}px)`]: {
      maxWidth: "100%",
      fontSize: spacing(7.5),
    },

    [`@media (max-width:${470 - 1}px)`]: { fontSize: spacing(4) },
  };
});

const ProblemSubTitle = styled.p(({ theme, $bgImage }) => {
  const { spacing } = theme;
  return {
    padding: 0,
    margin: 0,
    color: "white",
    fontFamily: fonts.regularCf,
    fontSize: spacing(2.75),
    paddingBottom: spacing(2),
    textAlign: "center",
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(3.125),
    },

    [`@media (max-width:${470 - 1}px)`]: { fontSize: spacing(2) },
  };
});

const ProblemBulletsContainer = styled.div(({ theme, $bgImage }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    gap: `clamp(${spacing(1.75)}px, 3vw, ${spacing(5.75)}px)`,
    alignItems: "center",
    justifyContent: "center",
    [`@media (max-width:${1024 - 1}px)`]: {
      maxWidth: "546.183px",
      flexWrap: "wrap",
      gap: `clamp(${spacing(1.75)}px, 6vw, ${spacing(5.75)}px)`,
    },

    [`@media (max-width:${470 - 1}px)`]: {
      gap: spacing(3.5),
      width: "100%",
    },
  };
});

const ProblemBullet = styled.div(({ theme, $bgImage }) => {
  const { spacing } = theme;
  return {
    borderRadius: "26.833px",
    border: "1.167px solid #FFF",
    background: "rgba(217, 217, 217, 0.05)",
    backdropFilter: "blur(10px)",
    minWidth: "210px",
    minHeight: "210px",
    paddingInline: spacing(1.5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [`@media (max-width:${1024 - 1}px)`]: {
      minWidth: "210px",
      minHeight: "210px",
    },

    [`@media (max-width:${470 - 1}px)`]: {
      minWidth: "auto",
      minHeight: "auto",
      width: "clamp(100px, 32vw, 129px)",
      height: "clamp(100px, 32vw, 129px)",
    },
  };
});

const ProblemBulletTitle = styled.p(({ theme, $bgImage }) => {
  const { spacing } = theme;
  return {
    padding: 0,
    margin: 0,
    color: "white",
    fontFamily: fonts.heavy,
    fontSize: spacing(3.75),
    textAlign: "center",
    [`@media (max-width:${1024 - 1}px)`]: { fontSize: spacing(4.5) },

    [`@media (max-width:${470 - 1}px)`]: {
      fontSize: `clamp(${spacing(1.75)}px, 5vw, ${spacing(2.375)}px)`,
    },
  };
});

const ProblemBulletText = styled.p(({ theme, $bgImage }) => {
  const { spacing } = theme;
  return {
    padding: 0,
    margin: 0,
    color: "white",
    fontFamily: fonts.regularCf,
    fontSize: spacing(1.25),
    textAlign: "center",
    maxWidth: "170px",
    paddingTop: spacing(1.75),
    [`@media (max-width:${1024 - 1}px)`]: { fontSize: spacing(1.3) },

    [`@media (max-width:${470 - 1}px)`]: {
      fontSize: spacing(0.75),
      lineHeight: "149%",
      maxWidth: "105px",
      paddingTop: spacing(1.25),
    },
  };
});

const BuyContainer = styled.div(({ theme, $menuHeight }) => {
  return {
    maxHeight: "333px",
    height: "fit-content",
    width: "100%",
    display: "flex",
    backgroundColor: "#1A2024",

    [`@media (max-width:${1024 - 1}px)`]: {
      flexDirection: "column",
      maxHeight: "100%",
    },
    [`@media (max-width:${470 - 1}px)`]: {},
  };
});

const BuyVideo = styled.video(({ theme, $menuHeight }) => {
  return {
    maxWidth: " 769px",
    width: "53%",
    height: "100%",
    objectFit: "cover",
    pointerEvents: "none",
    [`@media (max-width:${1024 - 1}px)`]: {
      width: "100%",
      maxWidth: "100%",
      maxHeight: "351.945px",
      height: "100%",
    },
    [`@media (max-width:${470 - 1}px)`]: { maxHeight: "185.907px" },
  };
});

const BuyContent = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    paddingBlock: spacing(7.75),
    paddingInline: spacing(5),
    flexDirection: "column",
    gap: spacing(2),
    [`@media (max-width:${1024 - 1}px)`]: {
      paddingBlock: spacing(6.5),
      paddingInline: spacing(6),
      gap: spacing(2),
    },
    [`@media (max-width:${470 - 1}px)`]: {
      padding: spacing(3.25),
      gap: spacing(1),
    },
  };
});

const BuyTitle = styled.p(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    color: "white",
    margin: 0,
    padding: 0,
    fontFamily: fonts.boldCf,
    fontSize: spacing(5),
    lineHeight: "100%",
    [`@media (max-width:${1024 - 1}px)`]: {
      maxWidth: "483.155px",
    },
    [`@media (max-width:${470 - 1}px)`]: {
      maxWidth: "255.215px",
      fontSize: spacing(2.5),
    },
  };
});

const BuyContext = styled.p(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    color: "white",
    margin: 0,
    padding: 0,
    fontFamily: fonts.regularCf,
    fontSize: spacing(2.5),
    [`@media (max-width:${1024 - 1}px)`]: { fontSize: spacing(2.375) },
    [`@media (max-width:${470 - 1}px)`]: { fontSize: spacing(1.25) },
  };
});

const BuyButtonsContainer = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    display: "flex",

    flexDirection: "row",
    gap: spacing(1),
    [`@media (max-width:${1024 - 1}px)`]: {},
    [`@media (max-width:${470 - 1}px)`]: {},
  };
});

const BuyButtonConteined = styled(ButtonContained)(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      maxWidth: "139.7px",
      fontSize: spacing(2),
      [`@media (max-width:${1024 - 1}px)`]: {},
      [`@media (max-width:${470 - 1}px)`]: {
        maxWidth: "73.794px",
        fontSize: spacing(1),
        padding: spacing(0.6),
        paddingInline: 0,
      },
    },
  };
});

const BuyButtonsOutlined = styled(ButtonOutlined)(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      maxWidth: "139.7px",
      fontSize: spacing(2),
      [`@media (max-width:${1024 - 1}px)`]: {},
      [`@media (max-width:${470 - 1}px)`]: {
        maxWidth: "73.794px",
        fontSize: spacing(1),
        padding: spacing(0.6),
        paddingInline: 0,
      },
    },
  };
});

const Styles = {
  Container,
  BgVideoContainer,
  BgVideo,

  WhitelistButton,
  RegistryButton,
  LandingText,
  LandingFirst,
  LandingButtonContainer,
  LandingSecond,
  WhitelistSecondButton,
  HomeButtons,
  LeftButtons,
  RegistrySecondButton,
  LandingDesctiption,
  AreaIcon,

  ProblemContainer,
  ProblemTitle,
  ProblemSubTitle,
  ProblemBulletsContainer,
  ProblemBullet,
  ProblemBulletTitle,
  ProblemBulletText,

  BuyContainer,
  BuyVideo,
  BuyContent,
  BuyTitle,
  BuyContext,
  BuyButtonsContainer,
  BuyButtonConteined,
  BuyButtonsOutlined,
  LeftButtonsContainer,
};

export default Styles;
