import { Input, InputPassword } from "../../../components";
import Styles from "../EsqueceuSenhaStyles";
import { FormFull } from "form-full";
import { LocalStorage, validations } from "../../../utils";
import { fonts, Spacing } from "../../../config";
import Countdown from "./Countdown";
import Texts from "../../../config/texts";

function StepPassword({ onSubmit, goBack, loading, lastData, goResend }) {
  const texts =
    Texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].forgetPassword;

  return (
    <FormFull onSubmit={onSubmit}>
      <Styles.Content>
        <Styles.Title>{texts.titleStepTree}</Styles.Title>
        <div>
          <Styles.InputTitle>{texts.email}</Styles.InputTitle>
          <Input
            defaultValue={lastData?.email}
            readOnly
            inputStyle={{
              border: "1.588px solid #FFF",
              background: "rgba(27, 30, 34, 0.70)",
              borderRadius: "53.201px",
              color: "white",
              fontFamily: fonts.regularCf,
              fontSize: Spacing(2.75),
              paddingInline: Spacing(3.75),
              paddingBlock: Spacing(2),
              marginTop: Spacing(0.75),
              "& .MuiInputBase-input": {
                padding: 0,

                boxShadow: "none !important",
              },

              [`@media (max-width:${1024 - 1}px)`]: {
                paddingInline: Spacing(3.5),
                fontSize: Spacing(2.5),
              },
              [`@media (max-width:${581 - 1}px)`]: {
                paddingInline: Spacing(1.25),
                paddingBlock: Spacing(1),
                fontSize: Spacing(1.5),
              },
            }}
            name="email"
            required={texts.userMessage}
            validation={validations.isEmailValid}
          />
        </div>
        <div>
          <Styles.InputTitle>Token</Styles.InputTitle>
          <Input
            mask={(value) => {
              if (value?.length > 5) {
                return value.slice(0, 6)?.toUpperCase();
              } else {
                return value?.toUpperCase();
              }
            }}
            maskToSubmit={(value) => {
              return value?.toUpperCase();
            }}
            inputStyle={{
              border: "1.588px solid #FFF",
              background: "rgba(27, 30, 34, 0.70)",
              borderRadius: "53.201px",
              color: "white",
              fontFamily: fonts.regularCf,
              fontSize: Spacing(2.75),
              paddingInline: Spacing(3.75),
              paddingBlock: Spacing(2),
              marginTop: Spacing(0.75),
              "& .MuiInputBase-input": {
                padding: 0,

                boxShadow: "none !important",
              },

              [`@media (max-width:${1024 - 1}px)`]: {
                paddingInline: Spacing(3.5),
                fontSize: Spacing(2.5),
              },
              [`@media (max-width:${581 - 1}px)`]: {
                paddingInline: Spacing(1.25),
                paddingBlock: Spacing(1),
                fontSize: Spacing(1.5),
              },
            }}
            required={texts.userMessage}
            key="code"
            name="code"
          />
        </div>
        <div>
          <Styles.InputTitle>{texts.newPassword}</Styles.InputTitle>
          <InputPassword
            inputStyle={{
              border: "1.588px solid #FFF",
              background: "rgba(27, 30, 34, 0.70)",
              borderRadius: "53.201px",
              color: "white",
              fontFamily: fonts.regularCf,
              fontSize: Spacing(2.75),
              paddingInline: Spacing(3.75),
              paddingBlock: Spacing(2),
              marginTop: Spacing(0.75),
              "& .MuiInputBase-input": {
                padding: 0,

                boxShadow: "none !important",
              },

              [`@media (max-width:${1024 - 1}px)`]: {
                paddingInline: Spacing(3.5),
                fontSize: Spacing(2.5),
              },
              [`@media (max-width:${581 - 1}px)`]: {
                paddingInline: Spacing(1.25),
                paddingBlock: Spacing(1),
                fontSize: Spacing(1.5),
              },
            }}
            required={texts.passwordMessage}
            key="password"
            name="password"
          />
          <Countdown setReSend={goResend} />
        </div>
        <Styles.SubmitButton
          loading={loading}
          disabled={loading}
          feedback={true}
          action="submit"
          label={texts.continue[2]}
        />

        <Styles.BackButton onClick={() => goBack()}>
          {texts.cancel}
        </Styles.BackButton>
      </Styles.Content>
    </FormFull>
  );
}

export default StepPassword;
