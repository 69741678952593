import styled from "styled-components";
import { fonts } from "../../../config";
import { ButtonContained, ButtonOutlined } from "../../../components";

const Container = styled.div(() => {
  return {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    backgroundColor: "#050500",

    flex: 1,
    width: "100%",
    height: "auto",
    minHeight: "680px",
    zIndex: 99,
    [`@media (max-width:${1024 - 1}px)`]: {
      width: "130%",
      minHeight: "510px",
    },
    [`@media (max-width:${581 - 1}px)`]: { minHeight: "267px" },
  };
});

const BgVideoContainer = styled.div(() => {
  return {
    display: "flex",
    position: "relative",
    width: "100%",
    zIndex: 0,
    height: "auto",
    minHeight: "860px",
    [`@media (max-width:${1920 - 1}px)`]: {
      minHeight: "680px",
    },
    [`@media (max-width:${1024 - 1}px)`]: { minHeight: "510px" },
    [`@media (max-width:${581 - 1}px)`]: { minHeight: "267px" },
  };
});

const BgVideo = styled.video(() => {
  return {
    position: "absolute",
    objectFit: "cover",
    width: "100%",
    height: "100%",
    maxHeight: "860px", pointerEvents: "none",
    [`@media (max-width:${1920 - 1}px)`]: {
      maxHeight: "680px",
    },
    [`@media (max-width:${1024 - 1}px)`]: {
      maxHeight: "510px",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      maxHeight: "267px",
    },
  };
});

const TextContainer = styled.div(({ right }) => {
  return {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    zIndex: 3,
    margin: 60,
    marginTop: "200px",
    width: "fit-content",
    alignSelf: "flex-start",
    [`@media (max-width:${1024 - 1}px)`]: {
      display: right ? "none" : "flex",
      margin: 0,
      marginBlock: 120,
      marginLeft: 60,
      alignSelf: "center",
    },
    [`@media (max-width:${581 - 1}px)`]: { margin: 20, marginTop: "92px" },
  };
});

const BuyBiocoin = styled.p(({ theme }) => {
  return {
    fontFamily: fonts.boldCf,
    color: "#D3D527",
    margin: 0,
    padding: 0,
    fontSize: theme.spacing(5.87),

    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: theme.spacing(4.45),
    },

    [`@media (max-width:${581 - 1}px)`]: { fontSize: theme.spacing(2.3) },
  };
});

const BuyBiocoinSpan = styled.span(({ theme, last, green }) => {
  return {
    fontFamily: fonts.boldCf,
    color: green ? "#D3D527" : "white",
    margin: 0,
    padding: 0,
    fontSize: last ? theme.spacing(5) : theme.spacing(5.87),

    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: last ? theme.spacing(3.8) : theme.spacing(4.45),
    },

    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: last ? theme.spacing(2) : theme.spacing(2.3),
    },
  };
});

const NFTColletcion = styled.p(({ theme }) => {
  return {
    fontFamily: fonts.lightCf,
    color: "white",
    margin: 0,
    padding: 0,
    fontSize: theme.spacing(5),
    textAlign: "end",
  };
});

const StyledButton = styled(ButtonContained)(({ theme }) => {
  return {
    "&&.MuiButton-root": {
      width: 180,
      fontSize: theme.spacing(2.25),
      marginTop: 16,

      [`@media (max-width:${1024 - 1}px)`]: {
        width: 138,
        height: 47,
        fontSize: theme.spacing(2.05),
      },
      [`@media (max-width:${581 - 1}px)`]: {
        width: 73,
        height: 25,
        fontSize: theme.spacing(1.09),
      },
    },
  };
});

const StyledButtonOutlined = styled(ButtonOutlined)(({ theme }) => {
  return {
    "&&.MuiButton-root": {
      width: 180,
      fontSize: theme.spacing(2.25),
      marginTop: 16,
    },
  };
});

const OneKIcon = styled.img(({ theme }) => {
  return {
    position: "absolute",
    top: -60,
    right: 0,
    width: theme.spacing(24.125),

    [`@media (max-width:${1024 - 1}px)`]: {
      top: -25,
      left: 135,
      width: theme.spacing(16),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      top: -20,
      left: 65,
      width: theme.spacing(10),
    },
  };
});

const Styles = {
  Container,
  BgVideoContainer,
  BgVideo,
  TextContainer,
  BuyBiocoin,
  BuyBiocoinSpan,
  NFTColletcion,
  StyledButton,
  StyledButtonOutlined,
  OneKIcon,
};

export default Styles;
