import React, { useContext, useEffect, useState } from "react";
import { Styles } from "../styles";
import { ButtonContained, DefaultLogo, SelectIcons } from "../../../components";
import UserInfos from "../components/UserInfos";
import { StorageContext } from "../../../contexts/StorageContext";
import { pagesConfig, SVG } from "../../../config";
import { HiMenu } from "react-icons/hi";
import { CircularProgress, IconButton } from "@material-ui/core";
import { useBeforeUnload, useLocation, useNavigate } from "react-router-dom";
import { paths } from "../../../navigation/navigate";
import texts from "../../../config/texts";
import { FormFull } from "form-full";
import { api } from "../../../services";
import { hooks, LocalStorage } from "../../../utils";
import SkeletonLoader from "../../../components/others/SkeletonLoader";
type CustomHeaderProps = {
  openMenu: () => void;
  userData: any;
  defaultLg: any;
  loading?: any;
  coins?: any;
};

function Header({
  openMenu,
  userData,
  defaultLg,
  loading,
  coins,
}: CustomHeaderProps) {
  const currentText =
    texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ];
  const { isLogged, setIsLogged } = useContext<any>(StorageContext);
  const { call } = hooks.useRequest();
  const location = useLocation();
  const navigate = useNavigate();

  const listCountries = [
    { value: "pt", label: <Styles.Br /> },
    { value: "en", label: <Styles.Us /> },
  ];

  // Detect Route Change
  useEffect(() => {
    return () => {
      handleRouteComponentUnmount();
    };
  }, [location?.pathname]);

  function handleRouteComponentUnmount() {
    api.cancelToken.cancel();
  }
  const onLogin = () => {
    navigate(paths.login);
  };
  const goHome = () => {
    navigate(paths.home);
  };
  const goBuy = () => {
    navigate(paths.payment);
  };

  const goConfig = () => {
    navigate(paths.config);
  };

  return (
    <Styles.Container id="header-menu">
      <Styles.LogoContainer>
        {loading ? (
          <CircularProgress size="small" />
        ) : (
          <FormFull onSubmit={(data) => {}}>
            {" "}
            <SelectIcons
              defaultValue={defaultLg}
              name="flags"
              options={listCountries}
              onChange={(value) => {
                if (isLogged) {
                  const newUserData = {
                    name: userData.name,
                    phone: userData.phone,
                    language: value,
                  };
                  call(null, api.editUser(newUserData), (response) => {
                    if (response.ok) {
                      LocalStorage.setItem("language", value);
                      navigate(0);
                    }
                  });
                } else {
                  LocalStorage.setItem("language", value);
                  navigate(0);
                }
              }}
            />{" "}
          </FormFull>
        )}

        <DefaultLogo
          onClick={goHome}
          styles={{
            cursor: "pointer",
            maxWidth: "176px",
            [`@media (max-width:${975 - 1}px)`]: { maxWidth: "333px" },
            [`@media (max-width:${526 - 1}px)`]: { maxWidth: "176px" },
          }}
        />
      </Styles.LogoContainer>
      <Styles.FirstContentGroup>
        {isLogged
          ? pagesConfig?.logged?.map(
              (item: any, index) =>
                !item?.noHeader && (
                  <Styles.NavButton
                    key={"navL" + index}
                    onClick={() => navigate(item.path)}
                    $current={item.path === location.pathname}
                  >
                    {LocalStorage.getItem("language") !== "pt"
                      ? item["title" + LocalStorage.getItem("language")]
                        ? item["title" + LocalStorage.getItem("language")]
                        : item.title
                      : item.title}
                  </Styles.NavButton>
                )
            )
          : pagesConfig?.notLogged?.map(
              (item, index) =>
                !item?.noHeader && (
                  <Styles.NavButton
                    key={"nav" + index}
                    onClick={() => navigate(item.path)}
                    $current={item.path === location.pathname}
                  >
                    {LocalStorage.getItem("language") !== "pt"
                      ? item["title" + LocalStorage.getItem("language")]
                        ? item["title" + LocalStorage.getItem("language")]
                        : item.title
                      : item.title}
                  </Styles.NavButton>
                )
            )}
      </Styles.FirstContentGroup>
      <Styles.UserContentGroupMobile
        onClick={() => {
          isLogged && openMenu();
        }}
      >
        {isLogged ? (
          <Styles.MenuIconA />
        ) : (
          <>
            <Styles.MenuButton color="primary" onClick={openMenu}>
              <SVG.MenuIcon />
            </Styles.MenuButton>
            <Styles.ButtonLogin onClick={onLogin}>
              {currentText.actionButton.enter}
            </Styles.ButtonLogin>
            <Styles.ButtonRegister
              disableRipple
              onClick={() => navigate("/register")}
            >
              {currentText.actionButton.register}
            </Styles.ButtonRegister>
          </>
        )}
      </Styles.UserContentGroupMobile>

      <Styles.UserContentGroup>
        {isLogged ? (
          <UserInfos
            coins={coins}
            goConfig={goConfig}
            goBuy={goBuy}
            userInfos={userData}
          />
        ) : (
          <>
            <Styles.MenuButton color="primary" onClick={openMenu}>
              <SVG.MenuIcon />
            </Styles.MenuButton>
            <Styles.ButtonLogin onClick={onLogin}>
              {currentText.actionButton.enter}
            </Styles.ButtonLogin>
            <Styles.ButtonRegister
              disableRipple
              onClick={() => navigate("/register")}
            >
              {currentText.actionButton.register}
            </Styles.ButtonRegister>
          </>
        )}
      </Styles.UserContentGroup>
    </Styles.Container>
  );
}

export default Header;
