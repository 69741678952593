import Texts from "../../config/texts";
import { LocalStorage } from "../../utils";

export default function errorResponseMessage(response) {
  const texts =
    Texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].errorMessages;

  if (response?.data) {
    const message = responseWidthData(response.data);
    if (message) {
      return message;
    }
  }
  if (response.problem === "CANCEL_ERROR") {
    return null;
  }
  if (response.status >= 500 || !response?.status) {
    return texts.status500;
  }
  if (response.status === 403) {
    return texts.status403;
  }
  if (response.status === 404) {
    return texts.status404;
  }
  if (response.status === 401) {
    return texts.status401;
  }
}

function responseWidthData(data) {
  if (data.log) {
    return data.log;
  } else if (data.message && data.message !== "error from api") {
    return data.message;
  }
}
