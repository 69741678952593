import React, { useContext } from "react";

import { SVG, fonts, Spacing } from "../../config";
const ProjectRiver =
  process.env.REACT_APP_ASSETS_URL + "/imgs/ProjectRiver.webp";

import Purpose from "../home/components/Purpose";

import Styles from "./styles";
import NFT from "../about/components/NFT";
import WhitePaper from "../about/components/WhitePaper";
import { useNavigate } from "react-router-dom";
import { paths } from "../../navigation/navigate";
import texts from "../../config/texts";
import { StorageContext } from "../../contexts/StorageContext";
import { LocalStorage } from "../../utils";

function Project() {
  const currentText =
    texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].project;
  const actionText =
    texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].actionButton;
  const [menuHeight, setHeight] = React.useState<any>(null);
  const navigate = useNavigate();
  const { isLogged } = useContext<any>(StorageContext);
  const handleDownload = () => {
    const pdfPath =
      process.env.REACT_APP_ASSETS_URL + "/documents/WhitePaper.pdf";
    window.open(pdfPath, "_blank");
  };

  const goBuy = () => {
    navigate("/payment");
  };
  const goLogin = () => {
    navigate(paths.login);
  };
  const landingButtons = [
    {
      icon: SVG.ImpactIcon,
      title: currentText.landing.buttons[0],
      onClick: () => {},
    },

    {
      icon: SVG.ValueIcon,
      title: currentText.landing.buttons[1],
      onClick: () => {},
    },

    {
      icon: SVG.InovationIcon,
      title: currentText.landing.buttons[2],
      onClick: () => {},
    },
  ];

  const descriptionBullets = currentText.bullets;
  React.useLayoutEffect(() => {
    if (!menuHeight) {
      const mh = document.getElementById("header-menu")?.offsetHeight;

      setHeight(mh);
    }
  }, [menuHeight]);

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        overflow: "hidden",
        flexDirection: "column",
      }}
    >
      <Styles.Container $menuHeight={menuHeight}>
        <Styles.BgVideo
          $menuHeight={menuHeight}
          loop
          autoPlay
          playsInline
          controls={false}
          muted
          preload="auto"
        >
          <source
            src={process.env.REACT_APP_ASSETS_URL + "/videos/ProjectLoop.webm"}
            type="video/webm"
          />
        </Styles.BgVideo>

        <Styles.LandingFirst>
          <Styles.LandingText>{currentText.landing.title}</Styles.LandingText>
          <Styles.LandingDesctiption>
            {currentText.landing.content}
          </Styles.LandingDesctiption>
          <Styles.LeftButtonsContainer>
            <Styles.LeftButtons>
              <Styles.RegistryButton
                onClick={isLogged ? goBuy : goLogin}
                fullWidth={true}
              >
                {actionText.buy}
              </Styles.RegistryButton>{" "}
              <Styles.WhitelistButton onClick={handleDownload} fullWidth={true}>
                {actionText.whitepaper}
              </Styles.WhitelistButton>
            </Styles.LeftButtons>
            <Styles.LeftButtons widthFull>
              <Styles.RegistrySecondButton
                onClick={() => navigate("/forest/acquired")}
                fullWidth={false}
              >
                <Styles.AreaIcon /> {actionText.area}
              </Styles.RegistrySecondButton>
              <Styles.RegistrySecondButton
                onClick={() => navigate("/forest/buy")}
                fullWidth={false}
              >
                <Styles.AreaIcon /> {actionText.areasBuy}
              </Styles.RegistrySecondButton>
            </Styles.LeftButtons>
          </Styles.LeftButtonsContainer>
        </Styles.LandingFirst>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <Styles.LandingButtonContainer>
            {landingButtons.map((item) => (
              <Styles.HomeButtons
                fullWidth={false}
                onClick={() => item.onClick()}
              >
                {<item.icon />} {item.title}
              </Styles.HomeButtons>
            ))}
          </Styles.LandingButtonContainer>
          <Styles.LandingSecond>
            <Styles.RegistryButton
              onClick={() => navigate("/forest/acquired")}
              fullWidth={true}
            >
              <SVG.AreaIcon /> {actionText.area}
            </Styles.RegistryButton>
            <Styles.RegistryButton
              onClick={() => navigate("/forest/buy")}
              fullWidth={true}
            >
              <SVG.AreaIcon /> {actionText.areasBuy}
            </Styles.RegistryButton>
          </Styles.LandingSecond>
        </div>
      </Styles.Container>

      <Purpose $style={{ height: "100%" }} />

      <Styles.ProblemContainer $bgImage={ProjectRiver}>
        <Styles.ProblemTitle> {currentText.problem.title}</Styles.ProblemTitle>
        <Styles.ProblemSubTitle>
          {currentText.problem.content}
        </Styles.ProblemSubTitle>
        <Styles.ProblemBulletsContainer>
          {descriptionBullets.map((item) => (
            <Styles.ProblemBullet>
              <Styles.ProblemBulletTitle>
                {item.title}
              </Styles.ProblemBulletTitle>
              <Styles.ProblemBulletText>{item.text}</Styles.ProblemBulletText>
            </Styles.ProblemBullet>
          ))}
        </Styles.ProblemBulletsContainer>
      </Styles.ProblemContainer>

      <Styles.BuyContainer>
        <Styles.BuyVideo
          autoPlay
          playsInline
          controls={false}
          loop
          muted
          preload="auto"
        >
          <source
            src={
              process.env.REACT_APP_ASSETS_URL + "/videos/ProjectForest.webm"
            }
            type="video/webm"
          />
        </Styles.BuyVideo>
        <Styles.BuyContent>
          <Styles.BuyTitle>{currentText.buy.title}</Styles.BuyTitle>
          <Styles.BuyContext>{currentText.buy.content}</Styles.BuyContext>
          <Styles.BuyButtonsContainer>
            <Styles.BuyButtonConteined
              fullWidth={true}
              onClick={isLogged ? goBuy : goLogin}
            >
              {isLogged ? actionText.buy : actionText.enter}
            </Styles.BuyButtonConteined>
            {!isLogged && (
              <Styles.BuyButtonsOutlined
                onClick={() => navigate(paths.register)}
                fullWidth={true}
              >
                {actionText.register}
              </Styles.BuyButtonsOutlined>
            )}
          </Styles.BuyButtonsContainer>
        </Styles.BuyContent>
      </Styles.BuyContainer>

      <NFT />
      <WhitePaper $style={{ zIndex: 200 }} />
    </div>
  );
}

export default Project;
