import styled from "styled-components";
import { ButtonContained, FontStyles } from "../../../components";
import { fonts } from "../../../config";
const coin = process.env.REACT_APP_ASSETS_URL + "/imgs/GOLDEN_COIN.webp";
const grainImage = process.env.REACT_APP_ASSETS_URL + "/imgs/grainBackground.webp";
import { Typography } from "@material-ui/core";

const Container = styled.div(({ $menuHeight }) => {
  return {
    backgroundColor: "rgba(5, 5, 0, 1)",

    width: "100%",
    position: "relative",
    height: `auto`,
    minHeight: `calc(100vh - ${$menuHeight + 16}px)`,

    overflow: "hidden",
    backgroundImage: `url(${grainImage})`,
    backgroundSize: "cover",
  };
});

const ImageBG = styled.div(({ $menuHeight }) => {
  return {
    display: "flex",
    width: "100%",
    position: "relative",
    height: `auto`,
    minHeight: `calc(100vh - ${$menuHeight + 16}px)`,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    backgroundImage: `url(${coin})`,
    backgroundPositionY: "center",
    backgroundRepeat: "no-repeat",
    overflow: "hidden",
    backgroundSize: "auto 90vh",
    backgroundPositionX: "-8vw",
    [`@media (max-width:${1024 - 1}px)`]: {
      backgroundImage: "none",
    },
    [`@media (max-width:${581 - 1}px)`]: {},
  };
});

const Content = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    position: "relative",

    justifyContent: "center",
    flexDirection: "column",
    padding: spacing(6.25),
    paddingInline: "clamp(18px, 11vw, 173px)",
    overflowX: "auto",

    background: "rgba(5, 5, 0, 0.82)",
    backdropFilter: "blur(9px)",

    width: "100%",
    maxWidth: "63vw",
    height: "100%",
    flex: 1,
    [`@media (max-width:${1024 - 1}px)`]: {
      maxWidth: "100vw",
      backdropFilter: "none",
      background: "none",
    },
    [`@media (max-width:${581 - 1}px)`]: {},
  };
});

const InputTitle = styled.p(({ theme }) => {
  const { spacing } = theme;
  return {
    ...FontStyles.regular18,
    fontSize: spacing(2.75),
    margin: 0,
    padding: 0,
    color: "white",
    lineHeight: "140.791%",

    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: spacing(1.5),
    },
  };
});

const Title = styled.p(({ theme }) => {
  const { spacing } = theme;
  return {
    color: "white",
    margin: 0,

    ...FontStyles.bold42,
    lineHeight: "140.791%",
    textAlign: "center",
    paddingBottom: spacing(5),
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(4.75),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: spacing(2.5),
      paddingBottom: spacing(2.25),
    },
  };
});

const GoToLoginButton = styled.p(({ theme, noPointer }) => {
  const { spacing } = theme;
  return {
    textAlign: noPointer ? "center" : "left",
    ...FontStyles.regular18,
    fontSize: spacing(2.875),
    color: "white",
    margin: 0,
    marginBottom: !noPointer && spacing(4),
    cursor: !noPointer && "pointer",

    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(2.5),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: spacing(1.375),
      marginBottom: spacing(2.25),
    },
  };
});

const YellowBall = styled.div(() => {
  return {
    position: "absolute",

    top: 0,
    right: 0,
    transform: "translate(90%, -50%)",
    borderRadius: "996px",
    width: "798.961px",
    height: "996px",
    filter: "blur(225px)",
    background: "#E7FF00E6",

    zIndex: 0,

    [`@media (max-width:${1024 - 1}px)`]: {
      borderRadius: "514.6px",
      width: "268.364px",
      height: "268.364px",
      filter: "blur(116.25px)",
      top: -250,
      right: 200,
    },
    [`@media (max-width:${581 - 1}px)`]: {
      borderRadius: "514.6px",
      width: "292.1024px",
      height: "247.19px",
      filter: "blur(116.25px)",
      top: -250,
      right: 200,
    },
  };
});

const YellowBallTwo = styled.div(() => {
  return {
    position: "absolute",
    transform: "translate(-40%, 50%)",
    bottom: 0,
    left: 0,
    borderRadius: "631px",
    width: "631px",
    height: "601px",
    background: "#E7FF00E6",
    filter: "blur(300px)",

    [`@media (max-width:${1024 - 1}px)`]: {
      width: "554.205px",
      height: "467.963px",
      filter: "blur(155px)",
      transform: "translate(-75%, 75%)",
      borderRadius: "554.205px",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      width: "292.1024px",
      height: "247.19px",
      filter: "blur(155px)",
      transform: "translate(-75%, 75%)",
      borderRadius: "554.205px",
    },
  };
});

const SubmitButton = styled(ButtonContained)(({ theme }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      fontFamily: fonts.heavy,
      fontSize: spacing(3.75),
      lineHeight: "140.791%",
      [`@media (max-width:${1024 - 1}px)`]: {
        fontSize: spacing(2.5),
      },
      [`@media (max-width:${581 - 1}px)`]: {
        fontSize: spacing(1.5),
      },
    },
  };
});

const Line = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    backgroundColor: "white",
    width: "100vw",
    height: "1px",
    maxWidth: "304px",
    marginBlock: spacing(4),
    alignSelf: "center",
    [`@media (max-width:${1024 - 1}px)`]: {
      maxWidth: "286.8px",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      maxWidth: "151px",
      marginBlock: spacing(2.25),
    },
  };
});

const CheckboxContainer = styled.div(() => {
  return {
    position: "relative",
    display: "flex",
  };
});

const CheckboxLabel = styled(Typography)(({ theme }) => {
  const { spacing } = theme;
  return {
    "&&.MuiTypography-root": {
      position: "absolute",
      marginTop: spacing(0.8),
      marginLeft: spacing(5),
      color: "rgba(255, 255, 255, 0.72)",
      fontFamily: fonts.regularCf,
      fontSize: spacing(2.78),

      [`@media (max-width:${581 - 1}px)`]: {
        marginTop: spacing(1.06),
        marginLeft: spacing(2.5),
        fontSize: spacing(1.38),
      },
    },
  };
});

const Styles = {
  Content,
  Title,
  ImageBG,
  GoToLoginButton,
  InputTitle,
  YellowBallTwo,
  YellowBall,
  Container,
  SubmitButton,
  Line,
  CheckboxContainer,
  CheckboxLabel,
};

export default Styles;
