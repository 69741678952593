import React from "react";
import {
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
  Input,
} from "@material-ui/core";

import styled from "styled-components";
import { FontStyles } from "../index";
import { useFormFull } from "form-full";
import { fonts, Spacing, SVG } from "../../config";

const StyledFormControl = styled(FormControl)(({ theme }) => {
  return {
    "&&.MuiFormControl-root": {
      minWidth: "fit-content",
      width: "fit-content",
      marginRight: theme.spacing(1),
    },
  };
});

const StyledMenuItem = styled(MenuItem)(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    "&&.MuiMenuItem-root": {
      margin: 0,
      width: "fit-content",

      background: "#2d3231",
      padding: spacing(1.625),
      fontFamily: fonts.boldCf,
      fontSize: spacing(1.625),

      lineHeight: "140%",
      fontWeight: "900",
      color: "white",
      paddingInline: spacing(1.75),
      paddingBlock: spacing(0.3),
      display: "flex",

      alignItems: "center",

      [`@media (max-width:${1024 - 1}px)`]: { fontSize: spacing(3) },
      [`@media (max-width:${581 - 1}px)`]: { fontSize: spacing(1.625) },
      "&.MuiListItem-button:hover": {
        background: "#2d3231",
      },
      "&.Mui-focusVisible": {
        background: "#2d3231",
      },
      "&.Mui-selected": {
        background: "#2d3231",
      },

      "& .MuiPaper-root": {
        background: "#2d3231",
        padding: 0,
      },
    },
  };
});

const StyledSelect = styled(Select)(({ theme, $withError, $maxHeight }) => {
  const { palette: colors, spacing } = theme;
  return {
    "&&.MuiInput-root": {
      position: "relative",
      fontFamily: fonts.boldCf,
      fontSize: spacing(1.625),
      padding: 0,
      lineHeight: "140%",
      fontWeight: "900",
      width: "fit-content",
      color: "white ",
      minWidth: "fit-content",
      backgroundColor: "transparent",
      [`@media (max-width:${1024 - 1}px)`]: { fontSize: spacing(3) },
      [`@media (max-width:${581 - 1}px)`]: { fontSize: spacing(1.625) },
      "& .MuiSelect-select.MuiSelect-select": {
        alignItems: "center",
        padding: 0,
        display: "flex",
        maxWidth: "25px",
        maxHeight: "25px",
        [`@media (max-width:${1066 - 1}px)`]: {
          maxWidth: "20px",
          maxHeight: "20px",
        },
        [`@media (max-width:${780 - 1}px)`]: {
          maxWidth: "10px",
          maxHeight: "10px",
        },
      },
      "& .MuiSelect-icon": {
        color: "#E7FF00",
        zIndex: 33,
        right: "-20px",
      },
    },
    "& .MuiMenu-paper": {
      backgroundColor: "red",
    },
  };
});

const LineBox = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    width: "100%",
    borderRadius: spacing(80),
    border: "1px solid #E7FF00",
    background: "#2d3231",
    padding: spacing(0.3),
    paddingRight: spacing(3),
    cursor: "pointer",
    [`@media (max-width:${975 - 1}px)`]: { display: "none" },
  };
});

function SelectComponent(props) {
  const { value, error, onBlur, ref, valid, onChange, ffHandler } =
    useFormFull.field(props);
  const {
    inputLabel,
    options,

    name,
  } = props;
  const onSelectValue = React.useCallback(
    (event) => {
      try {
        onChange(event, event.target.value);
      } catch (err) {}
      setTimeout(() => {
        onBlur(event);
        ffHandler?.testFieldError(name, true);
        ffHandler?.testErrorsAndReturnData();
      }, 10);
    },
    [onBlur, ffHandler, name, onChange]
  );
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const getWidth = (value) => {
    return Boolean(value) ? value + Spacing(3.25) : value;
  };
  return (
    <StyledFormControl
      fullWidth
      error={Boolean(error)}
      onMouseUp={(event) => event.stopPropagation()}
    >
      <LineBox
        onClick={() => {
          open ? setOpen(false) : setOpen(true);
        }}
      >
        <StyledSelect
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          id={name + "-select"}
          $withError={Boolean(error)}
          disableUnderline
          ref={ref}
          value={value}
          onChange={onSelectValue}
          onMouseUp={(event) => event.stopPropagation()}
          MenuProps={{
            PaperProps: {
              style: {
                marginLeft: "-4px",

                borderRadius: "18px",
                background: "#2d3231",
                border: "1px solid #E7FF00",
                width: "min-content",
                boxShadow: "none",
                padding: 0,
                position: "relative",

                "& .MuiMenu-list": {
                  padding: 0,
                  "&.Mui-selected": { padding: 0 },
                },
                "&.MuiMenu-list.Mui-selected": { padding: 0 },
              },
            },
          }}
        >
          {options?.map((option) => (
            <StyledMenuItem value={option.value} disabled={option.disabled}>
              {option.label}
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </LineBox>
    </StyledFormControl>
  );
}

export default SelectComponent;
