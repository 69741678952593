import Styles from "../styles";

import texts from "../../../config/texts";

import { customModalBlur } from "../../../components/modals/utils";

import { fonts, Spacing, SVG } from "../../../config";
import { IoWarningOutline } from "react-icons/io5";
import { LocalStorage } from "../../../utils";
interface Props {
  $style?: any;
}

function WarningDash({ $style }: Props): JSX.Element {
  const currentText =
    texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].modals.WarningDash;

  return (
    <Styles.WarningModal>
      <Styles.WarningIcon />
      <Styles.WarningTitle>{currentText.title}</Styles.WarningTitle>
      <Styles.WarningSubTitle>{currentText.content}</Styles.WarningSubTitle>
    </Styles.WarningModal>
  );
}

export default WarningDash;
