import Styles from "../styles/PurposeStyles";
const Animals = process.env.REACT_APP_ASSETS_URL + "/imgs/homeAnimals.webp";
import texts from "../../../config/texts";
import { paths } from "../../../navigation/navigate";
import { useNavigate } from "react-router-dom";
import { LocalStorage } from "../../../utils";

function Purpose() {
  const currentText = texts[LocalStorage.getItem("language") ?LocalStorage.getItem("language"): 'pt'].home;
  const navigate = useNavigate();
  const goBlog = () => {
    navigate(paths.blog);
  };
  return (
    <>
      <Styles.AboutContainer>
        <Styles.AboutMobileContainer>
          <Styles.AnimalsImg src={Animals} />
          <Styles.AboutTitleMobile onClick={goBlog}>
            {currentText.purpose.title}
          </Styles.AboutTitleMobile>
        </Styles.AboutMobileContainer>

        <Styles.AboutTexts>
          <Styles.AboutTitle>{currentText.purpose.title}</Styles.AboutTitle>

          <Styles.AboutDescription>
            {currentText.purpose.about}
          </Styles.AboutDescription>
        </Styles.AboutTexts>
      </Styles.AboutContainer>
    </>
  );
}

export default Purpose;
