import React, { useState } from "react";
import { Swiper } from "../../components/index";
import Styles from "./PaymentStyles";

import { hooks, LocalStorage, masks } from "../../utils";
import StepInformation from "./Steps/StepInformation";

import { api } from "../../services";
import StepBuy from "./Steps/StepBuy";
import StepPaymentMethod from "./Steps/StepPaymentMethod";
import StepFinal from "./Steps/StepFinal";
import { StorageContext } from "../../contexts/StorageContext";
import texts from "../../config/texts";
import ResponseError from "../../services/helpers/ResponseError";
import alerts from "../../utils/Alerts";

function Payment() {
  const currentText = texts[LocalStorage.getItem("language") ?LocalStorage.getItem("language"): 'pt'].payment.table;
  const { loading, call } = hooks.useRequest();
  const [formData, setFormData] = useState<any>({});
  const [menuHeight, setHeight] = React.useState<any>(null);
  const [step, setStep] = React.useState<number>(1);
  const [currentValue, setCurrentValue] = React.useState<number>(10);
  const [biocoinQuantity, setBiocoinQuantity] = React.useState<number>(0);
  const [purchaseValue, setPurchaseValue] = React.useState<number>(0);
  const { isLogged } = React.useContext<any>(StorageContext);
  const { getData, addData } = React.useContext<any>(StorageContext);
  const [userData, setUserData] = React.useState<any>(null);
  const [seconds, setSeconds] = React.useState<number>(60);
  const [deletePurchase, setDeletePurchase] = React.useState<boolean>(false);
  const [canNextPage, setCanNextPage] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (isLogged) {
      call(null, api.getCurrency(), (data) => {
        if (data.ok) {
          setCurrentValue(data.data.value);
        }
      });
      const user = getData("userData");
      if (!user && isLogged) {
        call(null, api.getUserData(), (data) => {
          if (data.ok) {
            if (data.data.imageId) {
              call(
                null,
                api.getUserImage(),
                (response) => {
                  call(null, api.getWallet(), (dataWallet) => {
                    if (dataWallet.ok) {
                      addData("userData", {
                        ...data?.data,
                        image: response?.data,
                        ...dataWallet?.data,
                      });
                      setUserData({
                        ...data?.data,
                        image: response?.data,
                        ...dataWallet?.data,
                      });
                    } else {
                      addData("userData", {
                        ...data?.data,
                        image: response?.data,
                      });
                      setUserData({ ...data?.data, image: response?.data });
                    }
                  });
                },
                (response) => {
                  if (response.status !== 404) {
                    const errorResponse = new ResponseError(response);

                    if (errorResponse.message) {
                      alerts.alertError(errorResponse.message);
                    }
                  }
                }
              );
            } else {
              call(null, api.getWallet(), (dataWallet) => {
                if (dataWallet.ok) {
                  addData("userData", {
                    ...data?.data,
                    image: null,
                    ...dataWallet?.data,
                  });
                  setUserData({
                    ...data?.data,
                    image: null,
                    ...dataWallet?.data,
                  });
                } else {
                  addData("userData", { ...data?.data, image: null });
                  setUserData({ ...data?.data, image: null });
                }
              });
            }
          }
        });
      } else {
        setUserData(user);
      }
    }
  }, [isLogged]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevTime) => {
        if (prevTime <= 0) {
          call(null, api.getCurrency(), (data) => {
            if (data.ok) {
              setCurrentValue(data.data.value);
            }
          });
          clearInterval(interval);
          return 60;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds]);

  React.useLayoutEffect(() => {
    if (!menuHeight) {
      const mh = document.getElementById("header-menu")?.offsetHeight;

      setHeight(mh);
    }
  }, [menuHeight]);

  const FinishStepOne = (data, Callback) => {
    deletePurchase && setFormData({});
    setFormData({ ...formData, ...data, biocoinQuantity });
    Callback();
  };

  const FinishStepTwo = (data, Callback) => {
    deletePurchase && setFormData({});
    setFormData({ ...formData, ...data });
    Payment({ ...formData, ...data });
    Callback();
  };

  const Payment = (formInfo) => {
    let paymentMethod = "";

    formInfo.method === "pix"
      ? (paymentMethod = "PIX")
      : formInfo.method === "credit"
      ? (paymentMethod = "CREDIT_CARD")
      : formInfo.method === "debit"
      ? (paymentMethod = "DEBIT_CARD")
      : formInfo.method === "boleto"
      ? (paymentMethod = "BILLET")
      : null;

    call(
      null,
      api.putPayment({
        biocoinQuantity: formInfo?.biocoinQuantity,
        value:
          formInfo?.checkbox === "true"
            ? formInfo?.inputValue
            : formInfo?.sliderValue,
        method: paymentMethod,
      }),
      (response) => {
        if (response.ok) {
          window.open(response.data.invoiceUrl, "_blank");
        } else {
          setCanNextPage(false);
        }
      }
    );
  };

  const tableData = [
    {
      header: currentText.requestNumber,
      data: "#2738299",
    },
    {
      header: currentText.name,
      data: userData?.name,
    },
    {
      header: currentText.totalBiocoin,
      data: biocoinQuantity,
    },
    {
      header: currentText.value,
      data: masks.money(purchaseValue),
    },
    {
      header: currentText.totalPay,
      data: masks.money(purchaseValue),
    },
  ];

  return (
    <Styles.Container $menuHeight={menuHeight}>
      <Styles.YellowBall></Styles.YellowBall>
      <Styles.YellowBallTwo></Styles.YellowBallTwo>
      <Swiper
        screens={[
          ({ goNext }) => (
            <StepBuy
              biocoinQuantity={biocoinQuantity}
              setBiocoinQuantity={setBiocoinQuantity}
              purchaseValue={purchaseValue}
              setPurchaseValue={setPurchaseValue}
              currentValue={currentValue}
              seconds={seconds}
              submit={FinishStepOne}
              goNext={() => goNext()}
            />
          ),
          ({ goNext }) => (
            <StepInformation
              step={step}
              setStep={setStep}
              tableData={tableData}
              goNext={() => goNext()}
              userData={userData}
              setDeletePurchase={setDeletePurchase}
            />
          ),
          ({ goNext }) => (
            <StepPaymentMethod
              step={step}
              tableData={tableData}
              goNext={() => goNext()}
              submit={FinishStepTwo}
              setDeletePurchase={setDeletePurchase}
            />
          ),
          ({ goNext, goPrevious }) => (
            <StepFinal
              goBack={() => goPrevious()}
              loading={loading}
              canNextPage={canNextPage}
            />
          ),
        ]}
      />
    </Styles.Container>
  );
}

export default Payment;
