import React from "react";
import { UserInfosStyles as Styles } from "../styles";
import { Skeleton } from "@material-ui/lab";

import { HiPlus } from "react-icons/hi";

function UserInfosPresentation(props) {
  return (
    <>
      <Styles.AvatarContainer
        src={"data:image/png;base64," + props.userInfos?.image}
        noMediaQuery={props.noMediaQuery}
      ></Styles.AvatarContainer>
      <Styles.PersonInfo noMediaQuery={props.noMediaQuery}>
        <Styles.Name onClick={props?.goConfig}>
          {props.userInfos?.name ? props.userInfos?.name : "..."}
        </Styles.Name>
        <Styles.Role
          id={"value-" + props.userInfos?.biocoin}
          onClick={props?.goBuy}
        >
          {props?.coinst?.biocoin
            ? props?.coinst?.biocoin?.toFixed(2)
            : props.userInfos?.biocoin
            ? props.userInfos?.biocoin?.toFixed(2)
            : "0,00"}{" "}
          BIOC <HiPlus style={{ color: "#E7FF00" }} />
        </Styles.Role>
      </Styles.PersonInfo>
    </>
  );
}

export default UserInfosPresentation;
