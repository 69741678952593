import { useState } from "react";

import { customModalBlur } from "../../../../components/modals/utils";
import { api } from "../../../../services";
import { hooks, LocalStorage } from "../../../../utils";
import { ButtonContained } from "../../../../components";
import { fonts, Spacing, SVG } from "../../../../config";
import alerts from "../../../../utils/Alerts";
import { CircularProgress } from "@material-ui/core";
import Texts from "../../../../config/texts";
interface Props {
  $style?: any;
}

function EmailModal({ $style }: Props): JSX.Element {
  const texts =
    Texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].config;
  const actionText =
    Texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].actionButton;

  const { call, loading } = hooks.useRequest();

  const [otherLoading, setOtherLoading] = useState<boolean>(false);

  const sendEmail = async () => {
    setOtherLoading(true);
    call(null, api.validationEmail(), (response) => {
      setOtherLoading(false);
      if (response.ok) {
        customModalBlur.close();
        alerts.alertSuccess(texts.emailSucess);
      } else {
        setOtherLoading(false);
      }
    });
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: Spacing(2.625),
      }}
    >
      <SVG.EmailIcon
        style={{
          fill: "#E7FF00",
          maxWidth: "109.287px",
          maxHeight: "109.287px",
          width: "100%",
          height: "auto",
        }}
      />
      <p
        style={{
          fontSize: Spacing(3.125),
          fontFamily: fonts.heavy,
          textAlign: "center",
          color: "white",
          margin: 0,
        }}
      >
        {texts.securityOptions[0].modal?.title}
      </p>
      <p
        style={{
          fontSize: Spacing(1.25),
          fontFamily: fonts.mediumCf,
          textAlign: "center",
          lineHeight: "198%",
          color: "white",
          margin: 0,
        }}
      >
        {texts.securityOptions[0].modal?.sub}
      </p>
      <ButtonContained
        loading={otherLoading || loading}
        disabled={otherLoading || loading}
        fullWidth={false}
        onClick={() => {
          sendEmail();
        }}
        style={{
          fontSize: Spacing(2.25),
          paddingBlock: Spacing(2.25),
          paddingInline: Spacing(5),
        }}
      >
        {otherLoading || loading ? (
          <CircularProgress
            size={20}
            style={{
              color: "black",
              alignSelf: "center",
              justifyContent: "center",
              marginInline: Spacing(6.5),
            }}
          />
        ) : (
          texts.securityOptions[0].modal?.action
        )}
      </ButtonContained>
    </div>
  );
}

export default EmailModal;
