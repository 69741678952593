import styled from "styled-components";
import { fonts } from "../../../config";

const AboutContainer = styled.div(({ theme }) => {
  return {
    zIndex: 1,
    minHeight: theme.spacing(60.25),
    width: "100%",
    paddingBlock: theme.spacing(8.25),
    paddingInline: theme.spacing(13.125),
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(8.625),
    backgroundColor: "white",
    [`@media (max-width:${1440 - 1}px)`]: { minHeight: theme.spacing(60.25) },

    [`@media (max-width:${1024 - 1}px)`]: {
      minHeight: theme.spacing(31.125),
      padding: theme.spacing(2.75),
      flexDirection: "column",
      gap: theme.spacing(4.375),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      minHeight: theme.spacing(43.5),
      padding: theme.spacing(2.75),
      flexDirection: "column",
      gap: theme.spacing(2.5),
    },
  };
});

const AboutMobileContainer = styled.p(() => {
  return {
    display: "flex",
    padding: 0,
    margin: 0,
    flexDirection: "row",
    [`@media (max-width:${296 - 1}px)`]: { flexDirection: "column" },
  };
});

const AnimalsImg = styled.img(({ theme }) => {
  return {
    height: "fit-content",
    width: theme.spacing(63.375),
    maxWidth: "40vw",

    [`@media (max-width:${1024 - 1}px)`]: {
      width: theme.spacing(32.74),
    },
    [`@media (max-width:${426 - 1}px)`]: {
      width: theme.spacing(24.875),
      maxWidth: "45vw",
    },
    [`@media (max-width:${296 - 1}px)`]: { maxWidth: "100%" },
  };
});

const AboutTitleMobile = styled.p(({ theme, $menuHeight }) => {
  return {
    cursor: "pointer",
    padding: 0,
    margin: 0,

    fontFamily: fonts.medium,
    lineHeight: "normal",
    display: "none",
    alignSelf: "flex-end",
    paddingLeft: theme.spacing(0.9),
    [`@media (max-width:${1024 - 1}px)`]: {
      display: "block",
      fontSize: theme.spacing(5.25),
    },
    [`@media (max-width:${581 - 1}px)`]: { fontSize: theme.spacing(2.75) },
    [`@media (max-width:${296 - 1}px)`]: { alignSelf: "flex-start" },
  };
});

const AboutTexts = styled.div(({ theme, white }) => {
  return {
    fontSize: theme.spacing(3),
    color: white && "white",
    fontFamily: fonts.medium,

    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: theme.spacing(1.5),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: theme.spacing(2.3),
    },
  };
});
const AboutTitle = styled.p(({ theme }) => {
  return {
    padding: 0,
    margin: 0,
    marginBottom: 16,
    fontSize: theme.spacing(5.8),
    color: "black",
    fontFamily: fonts.medium,
    lineHeight: "normal",

    [`@media (max-width:${1024 - 1}px)`]: {
      display: "none",
    },
  };
});

const AboutDescription = styled.p(({ theme }) => {
  return {
    padding: 0,
    margin: 0,
    fontSize: theme.spacing(1.75),
    fontFamily: fonts.regularCf,
    lineHeight: "198%",
    color: "black",

    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: theme.spacing(2.15),
      lineHeight: "188%",
    },

    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: theme.spacing(1.1),
    },
  };
});

const Styles = {
  AboutContainer,
  AboutMobileContainer,
  AnimalsImg,
  AboutTitleMobile,
  AboutTexts,
  AboutTitle,
  AboutDescription,
};

export default Styles;
