import { Button } from "@material-ui/core";
import { url } from "inspector";
import styled from "styled-components";
const leafBackgorund = process.env.REACT_APP_ASSETS_URL + "/imgs/blogBg.webp";
import { ButtonContained, ButtonOutlined } from "../../../components";
import { fonts, SVG } from "../../../config";
const Container = styled.div(({ theme, $menuHeight, $image }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    backgroundColor: "#D9D9D9",
    background: `linear-gradient(0deg, rgba(0, 0, 31, 0) 0%, rgba(0, 0, 31, 0) 100%), url(${$image}), lightgray 50% / cover no-repeat`,
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    overflow: "hidden",
    height: "100vh",
    maxHeight: `calc(${$menuHeight}px + 563px)`,

    marginTop: `${spacing(-16.25) - $menuHeight}px`,

    alignItems: "flex-end",
    justifyContent: "space-between",

    paddingInline: spacing(15.625),
    paddingBlock: spacing(16.25),
    gap: spacing(2),

    [`@media (max-width:${1024 - 1}px)`]: {
      marginTop: 0,
      maxHeight: `454px`,
    },
    [`@media (max-width:${581 - 1}px)`]: {
      maxHeight: `240px`,
    },
  };
});

const PagTitle = styled.p(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    fontFamily: fonts.boldCf,
    color: "white",
    fontSize: spacing(5),
    padding: 0,
    margin: 0,

    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(4.75),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: spacing(3.25),
    },
  };
});

const FaqContainer = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    paddingBlock: spacing(9),
    display: "flex",
    flexDirection: "column",
    width: "100%",
    overflow: "hidden",
    paddingInline: spacing(15.625),
    maxWidth: "2200px",
    alignSelf: "center",
    gap: spacing(4),
    [`@media (max-width:${1024 - 1}px)`]: {
      padding: spacing(6),
      gap: spacing(5.75),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      padding: "clamp(12px, 7vw, 26px)",
      gap: spacing(3),
    },
  };
});

const PostTitle = styled.p(({ theme, $center }) => {
  const { spacing } = theme;
  return {
    margin: 0,
    fontFamily: fonts.mediumCf,
    fontWeight: "600",
    fontSize: spacing(7.5),
    lineHeight: "normal",
    textTransform: "uppercase",
    color: "#00001F",
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(4.75),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: spacing(2.5),
    },
  };
});

const PostDescription = styled.div(({ theme, $center }) => {
  const { spacing } = theme;
  return {
    margin: 0,
    fontFamily: fonts.regularCf,
    fontSize: spacing(2.5),
    paddingLeft: spacing(4),
    maxWidth: "69vw",
    lineHeight: "normal",

    color: "#000",

    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(2.25),
      paddingLeft: 0,
      maxWidth: "100%",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: spacing(1),
    },
  };
});

const Back = styled(ButtonContained)(({ theme, $center, $image }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      marginTop: 0,
      paddingInline: spacing(4),
      paddingBlock: spacing(2.25),
      fontSize: spacing(2),
      borderRadius: spacing(2),
      marginLeft: spacing(4),
      [`@media (max-width:${1024 - 1}px)`]: {
        borderRadius: spacing(3),
        paddingInline: spacing(5.25),
        paddingBlock: spacing(2.5),
        fontSize: spacing(2.5),
        marginLeft: 0,
      },
      [`@media (max-width:${581 - 1}px)`]: {
        borderRadius: spacing(1.5),
        paddingInline: spacing(2.25),
        paddingBlock: spacing(1.5),
        fontSize: spacing(1.5),
      },
    },
  };
});

const TitleBorder = styled.div(({ theme, $center, $image }) => {
  const { spacing } = theme;
  return {
    borderBottom: "2px solid #00001F",
    borderLeft: "2px solid #00001F",
    borderBottomLeftRadius: "48px",
    paddingBottom: "22px",
    paddingLeft: "32px",
    width: "fit-content",
    [`@media (max-width:${1024 - 1}px)`]: {
      paddingBottom: "10px",
      paddingLeft: "38px",
      borderBottomLeftRadius: "58px",
      borderBottom: "3.8px solid #00001F",
      borderLeft: "3.8px solid #00001F",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      paddingLeft: "22px",
      paddingBottom: "10px",
      borderBottomLeftRadius: "48px",
      borderBottom: "2px solid #00001F",
      borderLeft: "2px solid #00001F",
    },
  };
});

const Styles = {
  Container,
  PagTitle,
  FaqContainer,

  PostTitle,

  PostDescription,

  Back,
  TitleBorder,
};

export default Styles;
