import { ButtonContained } from "../../../components";

import Styles from "../styles";

import alerts from "../../../utils/Alerts";

import { useNavigate } from "react-router-dom";

import { fonts, Spacing, SVG } from "../../../config";
import { hooks, LocalStorage } from "../../../utils";
import ptBr from "../../../config/texts/pt-br";

import BoxHeader from "./BoxHeader";
import { customModalBlur } from "../../../components/modals/utils";
import DocumentModal from "./modais/DocumentModal";
import SelfieModal from "./modais/SelfieModal";
import EmailModal from "./modais/EmailModal";
import Texts from "../../../config/texts";
interface Props {
  $style?: any;
}

function Security({ goBack, user, goNext }: any): JSX.Element {
  const texts = Texts[LocalStorage.getItem("language") ?LocalStorage.getItem("language"): 'pt'].config;
  const actionText = Texts[LocalStorage.getItem("language") ?LocalStorage.getItem("language"): 'pt'].actionButton;
  const classes = Styles.useStyles();
  const { loading, call } = hooks.useRequest();
  const navigate = useNavigate();
  const options: any[] = [
    {
      icon: SVG.EmailIcon,
      title: texts.securityOptions[0].title,
      sub: texts.securityOptions[0].sub,
      actionLabel: texts.securityOptions[0].actionLabel,
      action: () => {
        customModalBlur.setInfos(
          <EmailModal />,
          { height: "fit-content", maxWidth: "700px" },
          true
        );
      },
    },
    {
      icon: SVG.DocumentIcon,
      title: texts.securityOptions[1].title,
      sub: texts.securityOptions[1].sub,
      actionLabel: texts.securityOptions[1].actionLabel,
      action: () => {
        if (user?.docs?.registerStatus === "ACCEPTED") {
          alerts.alertSuccess(texts.securityOptions[1].modal?.alert);
        } else {
          customModalBlur.setInfos(
            <DocumentModal />,
            { height: "fit-content", maxWidth: "700px" },
            true
          );
        }
      },
    },
    {
      icon: SVG.SelfieIcon,
      title: texts.securityOptions[2].title,
      sub: texts.securityOptions[2].sub,
      actionLabel: texts.securityOptions[2].actionLabel,

      action: () => {
        if (user?.docs?.selfieStatus === "ACCEPTED") {
          alerts.alertSuccess(texts.securityOptions[2].modal?.alert);
        } else {
          customModalBlur.setInfos(
            <SelfieModal />,
            { height: "fit-content", maxWidth: "700px" },
            true
          );
        }
      },
    },
    {
      icon: SVG.TwoFactorIcon,
      title: texts.securityOptions[3].title,
      sub: texts.securityOptions[3].sub,
      actionLabel: texts.securityOptions[3].actionLabel,
      action: () => {
        goNext(5);
      },
    },
  ];
  return (
    <>
      <Styles.WhiteBox>
        <Styles.TitleBox>
          <Styles.TitleConfig>{texts.security.title}</Styles.TitleConfig>
        </Styles.TitleBox>
        <Styles.BoxUserContent>
          <BoxHeader user={user} />

          {options.map((item, index) => (
            <Styles.OptionSecBox
              key={index + "-sec"}
              $last={index >= options.length - 1}
            >
              <Styles.OptionSecBoxContainer>
                <Styles.IconSecContainer>
                  <item.icon className={classes.iconSec} />
                </Styles.IconSecContainer>
                <Styles.TextSecContainer>
                  <Styles.TitleSecurity>{item.title}</Styles.TitleSecurity>
                  <Styles.SubTitleSecurity>{item.sub}</Styles.SubTitleSecurity>
                </Styles.TextSecContainer>
              </Styles.OptionSecBoxContainer>{" "}
              <Styles.ActionButtonSecurity
                onClick={item.action}
                fullWidth={false}
              >
                {item.actionLabel}
              </Styles.ActionButtonSecurity>
            </Styles.OptionSecBox>
          ))}
        </Styles.BoxUserContent>
      </Styles.WhiteBox>
      <Styles.Back fullWidth={false} onClick={goBack}>
        {actionText.back}
      </Styles.Back>
    </>
  );
}

export default Security;
