import { StrongText } from "../../components";

const baseRequiredMessage = "Mandatory field.";
// const baseRequiredFieldsDescription = "* Mandatory field";

const baseReload = {
  message: "No data to display.",
  buttonLabel: "Reload",
};
const enUs = {
  months: [
    { initials: "Jan", fullName: "January" },
    { initials: "Feb", fullName: "February" },
    { initials: "Mar", fullName: "March" },
    { initials: "Apr", fullName: "April" },
    { initials: "May", fullName: "May" },
    { initials: "Jun", fullName: "June" },
    { initials: "Jul", fullName: "July" },
    { initials: "Aug", fullName: "August" },
    { initials: "Sep", fullName: "September" },
    { initials: "Oct", fullName: "October" },
    { initials: "Nov", fullName: "November" },
    { initials: "Dec", fullName: "December" },
  ],
  version: "v ",
  modals: {
    WarningDash: {
      title: "Withdrawal Unavailable",
      content:
        "Withdrawal is currently unavailable. We will notify you as soon as it becomes available.",
    },
    success: {
      buttonLabel: "OK",
    },
    errors: {
      buttonLabel: "Ok",
    },
  },
  home: {
    modal: {
      title: "Attention",
      content: (
        <>
          BIOCOIN (BIOC) is an innovative and sustainable cryptocurrency
          designed to fund forest ecosystem preservation and restoration
          projects around the world. <br /> <br />
          Cryptocurrency'92s own investments (50%): Half of the raised funds
          will be allocated directly to conservation and reforestation projects,
          as well as to the development of sustainable technologies and
          practices. This investment will ensure the progress and implementation
          of BioCoin's core objectives. <br />
          <br />
          Reserve (20%): A reserve fund will be established to ensure the
          financial stability of the project and to address unforeseen
          circumstances or market condition changes. This fund will be used to
          cover operational expenses and ensure the project's continuity. <br />
          <br />
          Marketing (15%): The resources allocated to marketing will be used to
          promote BioCoin, increase awareness about the cryptocurrency and its
          objectives, and attract new investors and partners. Marketing actions
          will include advertising campaigns, events, social media promotion,
          and strategic partnerships. <br />
          <br />
          The ICO will last for 12 months, with a token appreciation plan
          established to increase the price each month. The following table
          details the token appreciation schedule during the ICO period: Over
          the 12 months of the ICO, the price of the BIOCOIN token will
          gradually increase from R$ 0.25 cents to R$ 1.00, encouraging early
          investor participation and rewarding those who support the project
          from the beginning.
        </>
      ),
      close: "Close",
    },
    landingText: (
      <>
        <StrongText>BioCoin:</StrongText> Your <br />{" "}
        <StrongText>Digital Currency</StrongText> for a{" "}
        <StrongText>Greener Planet</StrongText>
      </>
    ),
    landingButtons: ["Impact", "Appreciation", "Innovation"],
    register: "Register",
    buy: "Buy",
    whitepaper: "Whitepaper",
    areas: "Acquired Areas",
    areasBuy: "Areas with Purchase Option",
    aboutTitle: "About Biocoin",
    about: (
      <>
        Biocoin is a sustainable and innovative cryptocurrency, designed to fund
        the preservation and restoration of Brazil's main biomes and forest
        ecosystems. With the uniqueness of being the first cryptocurrency backed
        by forest areas, Biocoin brings to the investment world a way to
        actively contribute to environmental preservation while promoting a
        smart investment opportunity.
      </>
    ),
    whyTitle: "Why Biocoin",
    why: (
      <>
        The <StrongText>right choice</StrongText> for a{" "}
        <StrongText>Green</StrongText> and <StrongText>Profitable</StrongText>{" "}
        Investment
      </>
    ),
    mission: (
      <>
        The Challenges of <StrongText>Biocoin</StrongText>
      </>
    ),
    missionSub: "CHALLENGES AND MISSION",
    more: "Learn More",
    securityTitle: "Security and Transparency of",
    security: (
      <>
        Biocoin, using the Binance Smart Chain blockchain platform, ensures
        maximum security and transparency in all operations. This blockchain,
        known for its high efficiency and reliability, provides robust
        protection against fraud, allowing the tracking of each transaction from
        its origin to the final recipient. With this, we offer our investors
        complete and unquestionable transparency.
      </>
    ),
    roadmap: {
      title: "BIOCOIN ROADMAP",
      t1: "Quarter 1 (2023 Q4)",
      t2: "Quarter 2 (2024 Q1)",
      t3: "Quarter 3 (2024 Q2)",
      maps: [
        [
          {
            title: "Launch of Biocoin",
            text: "Start of Biocoin token sales, opening a new path for sustainable investments.",
          },
          {
            title: "Purchase of the First Forest Areas",
            text: "Start of Biocoin token sales, opening a new path for sustainable investments.",
          },
          {
            title: "Partnerships with Environmental Organizations",
            text: "Start of Biocoin token sales, opening a new path for sustainable investments.",
          },
        ],
        [
          {
            title: "Listing of Biocoin on Exchanges",
            text: "Listing of Biocoin on various cryptocurrency exchanges, allowing secondary trading and broadening access to the currency.",
          },
          {
            title: "Launch of the First NFT Collection",
            text: "Launch of the first series of thematic NFTs, related to the biome supported in the first quarter.",
          },
          {
            title: "Environmental Impact Report",
            text: "Publication of the first impact report, showing the difference that Biocoin is making in the supported biomes.",
          },
        ],
        [
          {
            title: "Launch of the Second NFT Collection",
            text: "Launch of the second series of thematic NFTs, this time representing a new supported biome.",
          },
          {
            title: "Start of the Second Conservation Project",
            text: "Start of funding for the second biome conservation project.",
          },
          {
            title: "Platform Updates",
            text: "Implementation of improvements and new features on the Biocoin platform, based on user feedback and cryptocurrency market trends.",
          },
        ],
      ],
    },
    exchanges: "Exchanges",
    partners: "Partners",
    bullets: [
      {
        title: "Deforestation",
        description: (
          <>
            Deforestation in the Amazon has reached alarming levels, leading to
            biodiversity loss, carbon emissions, and disruption of indigenous
            communities. Biocoin will fund projects that seek to combat this
            practice, promoting sustainable use of forests.
          </>
        ),
      },
      {
        title: "Fires",
        description: (
          <>
            The Pantanal has faced a worrying increase in the number of fires,
            destroying habitats, threatening wildlife, and exacerbating the
            climate crisis. Biocoin will support actions to prevent and combat
            fires, protecting this unique biome.
          </>
        ),
      },
      {
        title: "Fragmentation",
        description: (
          <>
            The Atlantic Forest, already highly fragmented due to urban
            development and agriculture, faces habitat loss and species
            extinction. Biocoin is committed to funding projects that aim to
            restore and reconnect habitats, safeguarding the rich biodiversity
            of this biome.
          </>
        ),
      },
      {
        title: "Sustainable Agriculture",
        description: (
          <>
            The Cerrado, important for Brazil's water security, is under
            pressure due to the expansion of unsustainable agriculture. Biocoin
            will contribute to projects promoting sustainable agricultural
            practices, thus protecting the water resources and biodiversity of
            this biome.
          </>
        ),
      },
    ],
    whyList: [
      {
        title: "Impact",
        subtitle:
          "By purchasing Biocoin, you are directly contributing to the preservation and recovery of Brazilian ecosystems. Each Biocoin represents a real investment in the sustainability of our planet, allowing you to make a tangible and significant difference.",
      },
      {
        title: "Appreciation",
        subtitle:
          "Biocoin combines two growth trends: the increasing popularity of cryptocurrencies and the growing need for sustainable solutions. This creates a strong potential for appreciation for Biocoin, making it an attractive investment for those seeking innovative and meaningful opportunities.",
      },
      {
        title: "Innovation",
        subtitle:
          "Public blockchain provides visible and traceable records, increasing trust between consumers and merchants and preventing manipulation of information.",
      },
    ],
    purpose: {
      title: (
        <>
          The purpose <br /> <StrongText>of Biocoin</StrongText>
        </>
      ),
      about: (
        <>
          Biocoin is born with the purpose of creating a new form of financing
          for the preservation and restoration of Brazilian biomes, through a
          sustainable and innovative cryptocurrency. Each Biocoin represents a
          direct investment in forest areas, ensuring a tangible contribution to
          environmental sustainability. At the same time, we offer investors a
          safe and transparent way to get involved in the digital economy while
          helping to protect our ecosystems vital for future generations. In
          essence, Biocoin is a bridge between environmental awareness and a
          green and sustainable investment, allowing everyone to be part of the
          solution to today's environmental challenges.
        </>
      ),
    },
  },
  purpose: {
    title: (
      <>
        The purpose <br /> <StrongText>of Biocoin</StrongText>
      </>
    ),
    about: (
      <>
        Biocoin is born with the purpose of creating a new form of financing for
        the preservation and restoration of Brazilian biomes, through a
        sustainable and innovative cryptocurrency. Each Biocoin represents a
        direct investment in forest areas, ensuring a tangible contribution to
        environmental sustainability. At the same time, we offer investors a
        safe and transparent way to get involved in the digital economy while
        helping to protect our ecosystems vital for future generations. In
        essence, Biocoin is a bridge between environmental awareness and a green
        and sustainable investment, allowing everyone to be part of the solution
        to today's environmental challenges.
      </>
    ),
  },
  pagesConfig: { about: "About" },
  header: {},
  login: {
    noAcount: "Don't have an account yet?",
    registry: "Register",
    authValidation: "The code must contain 6 digits",
    auth: "Authentication Code",
    title: "Log In",
    titleTwo: "Two-Factor Authentication",
    remember: "Remember Me",
    forgotPass: "Forgot your password?",
    enter: "Enter",
    continue: "Continue",
    email: "Email",
    password: "Password",
    passwordMessage: baseRequiredMessage,
    user: "User",
    userMessage: baseRequiredMessage,
  },
  forgetPassword: {
    title: "Forget Password",
    titleStepOne: "Forgot Password",
    titleStepTwo: "FORGOT YOUR PASSWORD",
    titleStepTree: "FORGOT YOUR PASSWORD",
    titleStepFour: "Password successfully reset",
    subTitle: [
      "A Token will be sent to your email to reset your password.",
      "Enter the code we sent to your registered email below:",
      "Create your new password by entering the data below:",
      "Your password has been successfully reset, we will redirect you to the login screen or you can click to go back.",
      "Password reset",
    ],
    continue: ["Send Token", "VALIDATE CODE", "Reset Password", "Log in"],
    resendCode: "Resend confirmation code",
    forgotPassword: "Forgot my password",
    email: "Email",
    enter: "Enter",
    password: "NEW PASSWORD",
    newPassword: "New Password",
    onBackMenu: "Back to login",
    onBack: "Back",
    cancel: "Cancel",
    resend: "Resend email",
    onNext: "Next",
    nwPassword: "Confirm Password",
    passwordMessage: baseRequiredMessage,
    user: "EMAIL",
    userMessage: baseRequiredMessage,
    modal: {
      title: "NEW PASSWORD REGISTERED",
      content:
        "You have successfully created your new password! Access your account now from the Login screen.",
      button: "GO TO LOGIN",
    },
    countdown: {
      limitTime: "Timeout for token validation⠀",
      resendCode: "Resend Code",
    },
  },

  actionButton: {
    register: "Register",
    enter: "Enter",
    out: "Logout",
    newsletterText: "Subscribe and receive our newsletters",
    send: "Send",
    buy: "Buy",
    transfer: "Transfer",
    nfts: "View NFTs",
    downloadHere: "Download here",
    whitepaper: "Whitepaper",
    area: "Preserved Area",
    areasBuy: "Areas with Purchase Option",
    back: "Back",
    save: "Save",
    cancel: "Cancel",
    reRegistry: "Reset Registration",
    close: "Close",
    empty: "Empty",
  },
  settings: {
    userSettings: {
      users: "Users",
      userRegister: {
        userRegister: "User Registration",
        fullName: "Full Name",
        fullNameMessage: baseRequiredMessage,
        email: "Email",
        emailMessage: baseRequiredMessage,
        password: "Password",
        passwordMessage: baseRequiredMessage,
        passwordConfirm: "Confirm Password",
        passwordConfirmMessage: baseRequiredMessage,
        userAdmin: "Administrator User?",
        register: "Register",
        successResponse: "User successfully registered.",
      },
    },
    changePassword: {
      changePassword: "Change Password",
      passwordChange: "Password Change",
      successResponse: "Password successfully changed.",
      actualPassword: "Current Password",
      newPassword: "New Password",
      passwordConfirmation: "Confirm New Password",
      passwordMessage: baseRequiredMessage,
      confirm: "Confirm",
    },
  },
  register: {
    have: ["Already have an account?", "Log in now"],
    declaration: [
      "I declare that I have read and",
      "understood the",
      "terms of use.",
    ],
    policy: ["I agree with the", "privacy policy."],
    title: "Register",
    fullName: "Full Name",
    password: "Password",
    passwordMessage: baseRequiredMessage,
    fullNameMessage: baseRequiredMessage,
    email: "Email",
    emailMessage: baseRequiredMessage,
    phone: "Phone",
    phoneMessage: baseRequiredMessage,
    cpf: "CPF",
    cpfMessage: baseRequiredMessage,
    birthDate: "Date of Birth",
    birthDatemMessage: baseRequiredMessage,
    news: "I agree to receive news and updates.",
    register: "Create Account",
    modal: {
      title: "USER REGISTERED",
      content:
        "You have successfully created your new account! Access it now from the Login screen.",
      button: "GO TO LOGIN",
    },
  },
  termsOfUse: {
    title: "Terms of Use",
    text: "More information coming soon",
  },
  privacyPolicy: {
    title: "Privacy Policy",
    text: "More information coming soon",
  },
  footer: {
    menu: [
      "About Us",
      "Frequently Asked Questions",
      "Blog",
      "Terms of Use",
      "Privacy Policy",
    ],
    connected: "Stay connected",
    modalTitle: "Newsletter registration",
    modalText: "Your email has been successfully registered!",
    emailPlaceholder: "name@email.com",
  },

  about: {
    title: "About",
    boxData: [
      {
        title: "Backed by Forest Areas",
        text: "Biocoin is the world's first cryptocurrency backed by forest areas. Each token represents a fraction of an actual forest, offering greater stability for the currency. Unlike other cryptocurrencies that are not backed by physical assets, Biocoin, being linked to a real and tangible asset, can provide greater confidence and predictability for investors, mitigating the volatility commonly associated with cryptocurrencies.",
      },
      {
        title: "Direct Contribution to Environmental Preservation",
        text: "By purchasing Biocoins, investors are directly financing projects for the preservation and restoration of Brazil's main biomes and forest ecosystems such as the Atlantic Forest, Amazon, Cerrado, and others, effectively and continuously promoting environmental preservation actions.",
      },
      {
        title: "Sustainable Investment Opportunity",
        text: "Biocoin offers an opportunity for conscious investors to benefit financially while contributing to a more sustainable and responsible future.",
      },
      {
        title: "Innovation with the Use of NFTs",
        text: "Biocoin is innovating by planning the launch of thematic NFT collections that represent the various Brazilian biomes. This not only creates an even closer connection between the investor and environmental preservation but also makes each investment unique and meaningful. Moreover, 50% of everything raised from the primary sales of these NFTs and subsequent transaction fees will be directly invested in projects for the preservation and restoration of these biomes, ensuring an even greater environmental impact.",
      },
      {
        title: "Potential for Appreciation",
        text: "In a world increasingly aware of environmental impacts and the importance of sustainability, the theme of forest preservation is very much in vogue. This is reflected in the potential for appreciation of Biocoin. By combining the growing trend of cryptocurrencies with the urgent need to preserve our ecosystems, Biocoin emerges as a digital asset of high relevance and potential for appreciation. The demand for investments that generate positive impacts on the environment is high and only tends to increase, which may boost the valuation of Biocoin in the market.",
      },
      {
        title: "Partnerships with Environmental Organizations",
        text: "Biocoin is not alone in its mission of environmental preservation. We plan to establish partnerships with organizations that share similar purposes. Working together, we can increase the effectiveness of the preservation projects funded by Biocoin. These partnerships not only amplify the positive impact on the preservation of biomes but also provide an additional level of credibility and transparency to Biocoin, strengthening investor confidence.",
      },
      {
        title: "Backing by Forest Areas",
        text: "BioCoin is the world's first cryptocurrency backed by forest areas. Each token represents a fraction of real forest, providing greater stability to the currency. Unlike other cryptocurrencies without backing in physical assets, BioCoin, being linked to a real and tangible asset, can offer greater trust and predictability to investors, mitigating the volatility commonly associated with cryptocurrencies.",
      },
      {
        title: "Direct Contribution to Environmental Preservation",
        text: "By purchasing BioCoins, investors are directly financing projects for the preservation and restoration of major Brazilian biomes and forest ecosystems such as the Atlantic Forest, Amazon, Cerrado, and others, promoting effective and continuous environmental preservation actions.",
      },
      {
        title: "Sustainable Investment Opportunity",
        text: "BioCoin offers an opportunity for conscious investors to benefit financially while contributing to a more sustainable and responsible future.",
      },
      {
        title: "Innovation with NFTs Usage",
        text: "BioCoin is innovating by planning the release of thematic collections of NFTs (Non-Fungible Tokens) representing various Brazilian biomes. This not only creates an even closer connection between the investor and environmental preservation but also makes each investment unique and meaningful. Moreover, 50% of all proceeds from the primary sales of these NFTs and subsequent transaction fees will be directly invested in preservation and restoration projects for these biomes, ensuring an even greater environmental impact.",
      },
      {
        title: "Appreciation Potential",
        text: "In an increasingly environmentally conscious world, the theme of forest preservation is highly relevant. This is reflected in the appreciation potential of BioCoin. By combining the growing trend of cryptocurrencies with the urgent need to preserve our ecosystems, BioCoin emerges as a digitally relevant asset with high appreciation potential. The demand for investments generating positive environmental impacts is high and likely to increase, potentially boosting the appreciation of BioCoin in the market.",
      },
      {
        title: "Partnerships with Environmental Organizations",
        text: "BioCoin is not alone in its mission for environmental preservation. We plan to establish partnerships with organizations that share similar purposes. By working together, we can enhance the effectiveness of preservation projects funded by BioCoin. These partnerships not only amplify the positive impact on biome preservation but also provide an additional level of credibility and transparency to BioCoin, strengthening investor confidence.",
      },
    ],
    differentialTitle: "Biocoin Differentiators",
    differential: (
      <>
        The<StrongText echo>&nbsp;right choice</StrongText>&nbsp;for a{" "}
        <StrongText echo>&nbsp;Green</StrongText>&nbsp;and{" "}
        <StrongText echo>&nbsp;Profitable</StrongText>&nbsp;Investment
      </>
    ),
    nfts: {
      title: ["Buy", "An", "Unique", "NFT", "and get"],
      content: (
        <>
          Check out our <br /> collection of{" "}
          <StrongText echo={true}>NFTs</StrongText>
        </>
      ),
    },
    whiteapaper: {
      title: "Download our White Paper",
      content:
        "Our White Paper is available for reading and provides detailed information about our project, including its purpose, technology, features, and how it plans to achieve its goals.",
    },
  },
  payment: {
    bar: {
      title: ["First Step", "Second Step", "Third Step"],
      subtitle: ["Information", "Payment Method", "Payment"],
    },
    buy: {
      buyBiocoin: ["Buy", "Biocoin"],
      price: "Quotation for purchase",
      howMuch: "Define how much you would like to buy",
      otherValue: "Other value",
      required: baseRequiredMessage,
      toReceive: "You will receive:",
      buyButton: "Buy",
      minimal: "Minimum value of 5 reais",
    },
    information: {
      title: "Payment",
      titleMobile: "Information",
      subtitle: "Name and Information",
      firstname: "First Name",
      lastname: "Last Name",
      contact: "Contact",
      required: baseRequiredMessage,
      continueButton: "Continue",
    },
    method: {
      payment: "Payment",
      method: "Payment Method",
      pix: "PIX",
      credit: "Credit Card",
      debit: "Debit Card",
      boleto: "Boleto",
      continueButton: "Continue",
    },
    final: {
      thanks: "THANK YOU",
      wrong: "Invalid payment",
      proccess: "Your payment is being processed.",
      tryAgain: "Your payment is invalid, try again.",
      closeButton: "Close",
    },
    table: {
      requestNumber: "Order N°",
      name: "Name",
      totalBiocoin: "Total Biocoin",
      value: "Value",
      totalPay: "Total to Pay",
      seePurchase: "See purchase",
      delete: "Delete",
      modalTitle: "Delete purchase",
      modalText: "Do you want to delete this purchase?",
      no: "No",
      close: "Close",
    },
  },
  tech: {
    title: "Technology",
    whysIcons: [
      {
        title: "Impact",
        subtitle:
          "By buying Biocoin, you are directly contributing to the preservation and recovery of Brazilian ecosystems. Each Biocoin represents a real investment in the sustainability of our planet, allowing you to make a tangible and significant difference.",
      },
      {
        title: "Appreciation",
        subtitle:
          "Biocoin combines two growth trends: the increasing popularity of cryptocurrencies and the growing need for sustainable solutions. This creates strong potential for the appreciation of Biocoin, making it an attractive investment for those looking for innovative and meaningful opportunities.",
      },
    ],
    biocoin: (
      <>
        The <StrongText echo>Biocoin</StrongText> combines the power of
        blockchain technology with a passion for environmental preservation to
        create a unique and innovative investment platform. Our platform uses
        the Binance Smart Chain, one of the most efficient and secure
        blockchains in the world. This allows us to offer a safe, transparent,
        and fast investment experience. Every{" "}
        <StrongText echo>Biocoin</StrongText> transaction can be traced back to
        its origin, providing maximum transparency for our investors. In
        addition, <StrongText echo>Biocoin</StrongText> also employs smart
        contracts to ensure the security and efficiency of each operation.
        Through these contracts, we ensure that each{" "}
        <StrongText echo>Biocoin</StrongText> purchased directly contributes to
        the preservation and restoration of Brazilian biomes. Innovation is at
        the heart of <StrongText echo>Biocoin</StrongText>, and we are
        constantly seeking new ways to improve the efficiency and effectiveness
        of our platform. By investing in <StrongText echo>Biocoin</StrongText>,
        you are not only contributing to a more sustainable future but also
        being part of the vanguard of blockchain technology.
      </>
    ),
    plantTitle: (
      <>
        Forest Backing: <br />
        The Solidity of Biocoin
      </>
    ),
    plantContent: (
      <>
        The <StrongText echo>Biocoin</StrongText> is a pioneer in establishing
        its backing in forest areas, being the first cryptocurrency in the world
        to adopt this model. By acquiring <StrongText echo>Biocoin</StrongText>,
        you are directly contributing to the protection and restoration of
        important biomes in Brazil, a tangible commitment that adds stability
        and sustainability to your investment.
      </>
    ),
    token: "TOKEN CONTRACT",
    tokenContent: (
      <>
        Check the <StrongText echo>Biocoin</StrongText> token contract on
        BSCscan for detailed tracking of transactions
      </>
    ),
    paper: "WHITEPAPER",
    paperContent: (
      <>
        Download our Whitepaper and learn more about the vision and technology
        of <StrongText>Biocoin</StrongText>.
      </>
    ),
  },
  project: {
    title: "Project",
    landing: {
      title: (
        <>
          <StrongText>BioCoin:</StrongText> Your Digital Currency for a Greener
          Planet
        </>
      ),
      content: (
        <>
          Acquire your <StrongText>BioCoin</StrongText> Tokens and Start Making
          a Difference for Brazilian Biomes Today
        </>
      ),
      buttons: ["Preservation", "Reforestation", "Environmental Education"],
    },
    bullets: [
      {
        title: "10,000Km²",
        text: "Is the deforested area of the Amazon from August/22 to July/2023. An increase of 50% compared to the same period last year",
      },
      {
        title: "12%",
        text: "Is what remains of the original Atlantic Forest",
      },
      {
        title: "+1,000",
        text: "Is the number of species threatened with extinction in Brazil.",
      },
      {
        title: "50%",
        text: "Is the percentage that has already been lost of the Brazilian Cerrado due to deforestation",
      },
    ],
    problem: {
      title: (
        <>
          Buy <span style={{ color: "#D3D527" }}>Biocoin</span> and become{" "}
          <br />
          part of this <span style={{ color: " #D3D527" }}>Community</span>
        </>
      ),
      content: "Start your journey towards a more sustainable future today",
    },
    buy: {
      title: (
        <>
          Buy{" "}
          <StrongText color="#D3D527" echo>
            Biocoin
          </StrongText>{" "}
          and become <br /> part of this{" "}
          <StrongText color="#D3D527" echo>
            Community
          </StrongText>
        </>
      ),
      content: (
        <>
          Start your journey towards a more <br /> sustainable future today
        </>
      ),
    },
  },
  faq: {
    list: [
      {
        title: "What is Biocoin?",
        description: (
          <>
            The <StrongText>Biocoin</StrongText> is an innovative and
            sustainable cryptocurrency designed to fund preservation and
            restoration projects for the main Brazilian biomes. It is the first
            cryptocurrency in the world with backing in forest areas.
          </>
        ),
      },
      {
        title: "How does Biocoin finance environmental preservation?",
        description: (
          <>
            Each <StrongText>Biocoin</StrongText> purchased directly contributes
            to the protection and restoration of Brazilian biomes. The sales of{" "}
            <StrongText>Biocoin</StrongText> tokens and NFTs finance
            environmental conservation projects.
          </>
        ),
      },
      {
        title: "What are Biocoin's NFTs?",
        description: (
          <>
            The NFTs of <StrongText>Biocoin</StrongText> are unique digital
            assets that represent different aspects of Brazilian biomes. They
            are a fun and interactive way to support environmental preservation.
          </>
        ),
      },
      {
        title: "How does Biocoin offer stability to investors?",
        description: (
          <>
            The <StrongText>Biocoin</StrongText> is backed by forest areas,
            which act as an anchor of stability for the token's value. This
            provides greater reliability for investors.
          </>
        ),
      },
      {
        title: "Where can I buy Biocoin?",
        description: (
          <>
            The <StrongText>Biocoin</StrongText> can be acquired on our official
            website and in some cryptocurrency exchanges. Stay tuned to our
            social media for updates on new listings.
          </>
        ),
      },
      {
        title: "How does Biocoin use blockchain technology?",
        description: (
          <>
            The <StrongText>Biocoin</StrongText> uses the Binance Smart Chain,
            one of the most secure and efficient blockchains in the world. This
            ensures the transparency and security of all{" "}
            <StrongText>Biocoin</StrongText> transactions.
          </>
        ),
      },
      {
        title: "How does Biocoin benefit the environment?",
        description: (
          <>
            Each <StrongText>Biocoin</StrongText> purchased directly finances
            environmental preservation and restoration projects. By buying{" "}
            <StrongText>Biocoins</StrongText>, you are contributing to the
            protection of Brazilian biomes.
          </>
        ),
      },
      {
        title: "What are the next steps for Biocoin?",
        description: (
          <>
            The <StrongText>Biocoin</StrongText> has a comprehensive roadmap for
            the coming quarters, which includes the acquisition of the first
            forest areas, listing on new exchanges, and the launch of new NFT
            collections.
          </>
        ),
      },
      {
        title: "How can I get more involved with Biocoin?",
        description: (
          <>
            You can get involved in several ways! In addition to buying{" "}
            <StrongText>Biocoins</StrongText>, you can participate in our
            community initiatives, buy themed NFTs, and share about{" "}
            <StrongText>Biocoin</StrongText> on your social networks. Together,
            we can make a big difference for Brazilian biomes!
          </>
        ),
      },
    ],
    title: (
      <>
        Get your question answered, <br /> talk to us.
      </>
    ),
    question: "Still have questions?",
    chat: "Send a message",
    startChat: "Send an email",
  },
  blog: {
    title: (
      <>
        Read our Blog <br />
        and stay up to date with everything <br />
        in the world of Cryptos.
      </>
    ),
    more: "Show more",
  },
  config: {
    required: baseRequiredMessage,
    title: "Configuration",
    dataSucess: "Data successfully updated!",
    emailSucess: "Email sent successfully!",
    refundSucess: "Your refund has been sent for review.",
    samePassword: "Passwords must be the same",
    userReset: "User reset successfully!",
    data: {
      title: "Data Settings",
    },
    image: {
      title: "New Image:",
    },
    list: [
      { title: "Account Security", desc: "Check out the security items." },
      {
        title: "Change Data",
        desc: "Change name, phone, Email.",
      },
      {
        title: "Change Password",
        desc: "Reset your current password.",
      },
      {
        title: "Reset Registration",
        desc: "Reset numeric password and two factors.",
      },
      {
        title: "Payment Information",
        desc: "Manage your payment information.",
      },
    ],
    securityOptions: [
      {
        title: "Validate Email",
        sub: "Via link sent to your email account.",
        actionLabel: "Send Email",
        modal: {
          title: "Validate Email",
          sub: "An email will be sent to your inbox. Please also check your spam folder and mark it as safe if necessary.",
          action: "Send Email",
        },
      },
      {
        title: "Send Photo ID",
        sub: "Send a legible image of one of your photo IDs.",
        actionLabel: "Send Document",
        modal: {
          alert: "Documents have already been approved",
        },
      },
      {
        title: "Send Security Selfie",
        sub: "Take a selfie holding a document in your hand.",
        actionLabel: "Send Selfie",
        modal: {
          alert: "Selfie has already been approved",
        },
      },
      {
        title: "Create Two-Factor Authentication",
        sub: "Enable Google's two-step authentication to increase your security.",
        actionLabel: "Enable authentication",
      },
      {
        title: "Enter the reason for the request",
        sub: "Enter your PIX Key",
        actionLabel: "Send",
      },
    ],
    security: {
      title: "Registration Settings",
    },
    reRegistry: {
      title: "Registration Settings",
      input: "Access Password",
      sub: "By resetting your registration, you will need to resend your documents for new validation.",
    },
    password: {
      title: "Password Settings",
      modal: "Password successfully changed!",
      current: "Current Password",
      new: "New Password",
      newConfirm: "Repeat Password",
      submit: "Change Password",
    },
    auth: {
      title: "Data Settings",
      code: "Authentication Code",
      token: "Verifier",
      send: "Send Data",
      authValidation: "The code must contain 6 digits",
    },
    docs: {
      under: "Your Document is being evaluated by our team.",
      send: "Send Photo ID",
      sub: "Send a legible image of one of your photo IDs.",
      inputOne: "Attach Front",
      inputTwo: "Attach Back",
    },
    selfie: {
      under: "Your Document is being evaluated by our team.",
      send: "Send Security Selfie",
      sub: "Take a selfie holding a document in your hand.",
      inputOne: "Attach Selfie",
    },
  },
  dash: {
    header: "Welcome, ",
    headerTwo: "Login to continue",
    loginCard: [
      {
        text: (
          <>
            {"Balance "} <br /> {"in Biocoin"}
          </>
        ),

        subText: "Log in to see your balance",
      },
      {
        text: "Acquired Areas",
      },
      {
        text: "Areas with Purchase Option",
      },
    ],
    configCards: [
      {
        text: "Statement",
      },
      {
        text: "Withdraw",
      },
      {
        text: "Settings",
      },
      {
        text: "My NFTs",
      },
    ],
    nft: (
      <>
        Check out our <StrongText echo>NFT Collection</StrongText>
      </>
    ),
    sea: "Go to Open Sea",
    buy: "Buy Biocoin",
    growth: "Overall Growth",
    times: ["1min", "5min", "30min", "1h", "24h", "1 day", "1 week"],
    await: "Awaiting Sales Start",
  },
  nfts: {
    title: "My NFTs",
    nft: (
      <>
        Check out our <StrongText echo>NFT Collection</StrongText>
      </>
    ),
    sea: "Go to Open Sea",
    toSell: "For sale",
    redeemed: "Redeemed",
  },
  nftTransfer: {
    title: "Redeem NFT",
    nameAndInfo: "Name and Information",
    firstname: "First Name",
    lastname: "Last Name",
    wallet: "Digital Wallet",
    selfie: "Selfie with Document",
    selfieInput: "Attach Selfie with Document",
    attach: "Attach",
    request: "Request Transfer",
    back: "Back",
    required: baseRequiredMessage,
    modalTitle: "Transfer of NFT",
    modalText: "NFT has been transferred successfully!",
  },
  forests: {
    relation: "List of forests",
    placeholder: "Search Forest",
    info: "Forest information",
    name: "Forest Name",
    country: "Country:",
    state: "State:",
    city: "City:",
    totalArea: "Total Area",
    unity: "Unit of Measurement",
    loc: "Location:",
    lat: "Latitude:",
    long: "Longitude",
    more: "See More",
    metro: "Area in m",
    hec: "Hectares",
    landingText: (
      <>
        <StrongText>BioCoin:</StrongText> Your <br />{" "}
        <StrongText>Digital Currency</StrongText> for a{" "}
        <StrongText>Greener Planet</StrongText>
      </>
    ),
    landingButtons: ["Impact", "Appreciation", "Innovation"],
    register: "Register",
    buy: "Buy",
    whitepaper: "Whitepaper",
    areas: "Acquired Areas",
    areasBuy: "Areas with Purchase Option",
    noArea: (
      <>
        Forests not found <br></br> Please try again
      </>
    ),
    value: "Value",
    values: "Value to Acquire",
    noPurchasingOp: "No purchasing options",
    noAcquiredForests: "No acquired forests",
    tryAgain: "Try again later",
  },
  extract: {
    refundButton: "Refund",
    extract: "Statement",
    deposit: "Deposit",
    purchase: "Currency Purchase",

    refundDeposit: "Deposit Refund",
    refundPurchase: "Refund Currency Purchase",
    unknow: "Unknow",
    billet: "Billet",
    creditCard: "Credit Card",
    pix: "PIX",
    wallet: "Wallet",
    inactive: "Awaiting Deposit",
    pending: "Pending",
    canceled: "Canceled",
    completed: "Completed",
    refunded: "Refunded",
    refundRequested: "Refund Requested",
    quotation: "Quotation: ",
    back: "Back",
  },
  modalAlert: {
    error: "Error",
    warning: "Warning",
    success: "Success",
  },
  errorMessages: {
    status500:
      "Something went wrong when contacting the server, try again later.",
    status403: "User and/or password wrong. Please, try again.",
    status404: "Error 404 - Not Found",
    status401: "Unauthenticated user.",
  },
  validationsTexts: {
    // AQUI
    validateCPF: {
      cpfNumber: "Enter a CPF number",
      cpfErrorFormat: "The CPF entered is not in the valid format",
    },
    emailValid: "The field must contain a valid email.",
    // AQUI
    checkPhone: {
      DDDandNumber: "The field must be filled in with DDD and number.",
      validDDD: "A valid DDD must be entered (between 11 and 99).",
    },
    validFullname:
      "The field must contain a valid full name. With first name, last name and cannot contain numbers.",
    validName:
      "The field must contain a valid name. It cannot contain numbers or special characters.",
    validNumber: "Value entered is not valid",
    validPassword: [
      "Password must contain: ",
      "At least 6 (six) characters",
      "; Made up of numbers",
      "; Uppercase and lowercase letters",
      "; Special symbols (@, #, %, =, &, !).",
    ],
    validDate: "Enter a valid date",
  },
};
export default enUs;
