import {
  FormControlLabel,
  FormControl,
  FormHelperText,
  withTheme,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { useFormFull } from "form-full";
import styled from "styled-components";

const StyledRadioGroup = styled(RadioGroup)(() => {
  return {
    flexWrap: "nowrap",
    flexDirection: "row",
    justifyContent: "flex-start",
  };
});

const StyledChips = styled(FormControlLabel)(() => {
  return {
    "& .MuiRadio-root": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "#D9D9D9",
      margin: "25px 10px",
      padding: 0,
      color: "#D9D9D9",
      width: "45px",
      height: "45px",

      "&:hover": {
        background: "#D9D9D9",
        color: "#D9D9D9",
      },

      [`@media (max-width:${581 - 1}px)`]: {
        width: "25px",
        height: "25px",
        margin: "15px 5px 15px 10px",
      },
    },
    "& .MuiRadio-colorSecondary.Mui-checked": {
      "&:hover": {
        background: "#D9D9D9",
        color: "#D9D9D9",
      },
    },
  };
});

function RadioList(props) {
  const { value, error, onChange } = useFormFull.field(props);

  const { required, disableError, name, options, checkedIcon } = props;

  return (
    <FormControl required={Boolean(required)} error={Boolean(error)}>
      <StyledRadioGroup
        name={name}
        value={value}
        onChange={(event) => {
          onChange(event, event.target.value);
        }}
      >
        {options?.map((item, index) => (
          <StyledChips
            key={index + "_radio"}
            value={item?.value}
            control={<Radio checkedIcon={checkedIcon} />}
            label={item?.label}
          />
        ))}
      </StyledRadioGroup>

      {disableError ? null : (
        <FormHelperText>{error ? error : " "}</FormHelperText>
      )}
    </FormControl>
  );
}

export default withTheme(RadioList);
