import Styles from "../styles";

import { useContext, useRef } from "react";
import { BiSolidCamera } from "react-icons/bi";

import { StorageContext } from "../../../contexts/StorageContext";
import alerts from "../../../utils/Alerts";
import { CircularProgress } from "@material-ui/core";
interface Props {
  cropToSquare: (data: string) => void;
  fileInputRef?: any;
  loading?: any;
}

function UploadImage({
  cropToSquare,
  fileInputRef,
  loading,
}: Props): JSX.Element {
  const { setUserData, userData } = useContext<any>(StorageContext);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > 8 * 1024 * 1024) {
        alerts.alertError("Os tamanho do arquivo deve ser inferior a 8mb");
        const inputV = document.getElementById("input-user-img") as any;
        if (inputV) {
          inputV.value = null;
        }

        return;
      } else {
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64Image = e?.target?.result as string;
          cropToSquare(base64Image);
        };
        reader.readAsDataURL(file);
      }
    }
  };
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Simula um clique no input de arquivo
    }
  };

  return (
    <>
      <Styles.SendImg
        size="small"
        onClick={() => {
          loading !== true && handleButtonClick();
        }}
      >
        <input
          id="input-user-img"
          type="file"
          accept="image/jpeg, image/png"
          onChange={handleImageChange}
          ref={fileInputRef}
          style={{ display: "none" }}
        />
        {loading ? <CircularProgress size={12} /> : <BiSolidCamera />}
      </Styles.SendImg>
    </>
  );
}

export default UploadImage;
