import styled from "styled-components";

import { Avatar } from "@material-ui/core";
import { fonts } from "../../../config";
import { FontStyles } from "../../../components";
const PersonInfo = styled.div(({ noMediaQuery }) => ({
  display: "flex",
  flexDirection: "column",
  [`@media (max-width:${430 - 1}px)`]: {
    display: noMediaQuery ? "block" : "none",
  },
}));

const Name = styled.p((props) => ({
  color: props.theme.palette.secondary.main,
  ...FontStyles.regular16,
  marginBottom: 0,
  marginTop: 5,
  textAlign: "left",
  maxWidth: "180px",
  lineHeight: "18px",
  width: "fit-content",
  paddingBottom: props.theme.spacing(0.5),
  cursor: "pointer",
  [`@media (max-width:${745 - 1}px)`]: {
    lineHeight: "11px",
    fontSize: props.theme.spacing(1.375),
    paddingBottom: props.theme.spacing(1),
  },
}));

const Role = styled.p((props) => ({
  color: props.theme.palette.secondary.main,
  ...FontStyles.bold14,
  marginBottom: 0,
  marginTop: -5,
  textAlign: "left",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  gap: props.theme.spacing(0.6),
  [`@media (max-width:${745 - 1}px)`]: {
    fontSize: props.theme.spacing(0.8),
  },
}));

const AvatarContainer = styled(Avatar)(({ theme, noMediaQuery, $img }) => {
  const { spacing } = theme;
  return {
    "&&.MuiAvatar-root": {
      width: noMediaQuery ? "68px" : "34px",
      height: noMediaQuery ? "68px" : "34px",

      border: "1.413px solid #E7FF00",

      [`@media (max-width:${745 - 1}px)`]: {
        width: noMediaQuery ? "36px" : "26px",

        height: noMediaQuery ? "36px" : "26px",
      },
    },
  };
});

const AvatarIcon = styled.div(({ theme, $img }) => {
  const { palette: colors } = theme;
  return {
    height: "100%",
    width: "100%",
    backgroundImage: `url(${$img})`,
  };
});

const Styles = {
  PersonInfo,
  AvatarContainer,
  AvatarIcon,
  Role,
  Name,
};

export default Styles;
