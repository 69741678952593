import { Button, IconButton, makeStyles } from "@material-ui/core";
import { url } from "inspector";
import { IoLogOutOutline } from "react-icons/io5";
import styled from "styled-components";

import {
  ButtonContained,
  ButtonOutlined,
  ButtonText,
  FontStyles,
} from "../../../components";
import { fonts, SVG } from "../../../config";
import colors from "../../../config/colors";
const useStyles = makeStyles((theme) => {
  const { palette: colors, spacing } = theme;
  return {
    icon: {
      maxWidth: spacing(6.25),
      maxHeight: spacing(6.25),
      width: "100%",
      height: "auto",
      fill: "black",
      color: "black",
      [`@media (max-width:${581 - 1}px)`]: {
        maxWidth: spacing(3),
        maxHeight: spacing(3),
      },
    },
    iconSec: {
      maxWidth: spacing(3.125),
      maxHeight: spacing(3.125),
      width: "100%",
      height: "auto",
      fill: "black",
      stroke: "black",
      color: "black",
    },
  };
});

const Container = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    position: "relative",
    display: "flex",

    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: `calc(448px + ${$menuHeight}px)`,
    height: "100%",
    marginTop: `-${$menuHeight}px`,

    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "flex-end",
    width: "100%",
    [`@media (max-width:${1024 - 1}px)`]: {
      marginTop: 0,
      width: "100%",
      height: "100%",

      minHeight: "650px",
    },

    [`@media (max-width:${470 - 1}px)`]: {
      minHeight: "343px",
    },
  };
});

const BgVideoContainer = styled.div(({ theme, $menuHeight }) => {
  return {
    position: "relative",
    width: "100%",
    zIndex: 0,
    display: "flex",
    justifyContent: "center",
    height: theme.spacing(82.875),

    [`@media (max-width:${745 - 1}px)`]: {
      height: theme.spacing(72.5),
    },
    [`@media (max-width:${470 - 1}px)`]: {
      width: "100%",
      height: theme.spacing(34.375),
    },
    [`@media (min-width:${1440}px)`]: {
      height: theme.spacing(82.875),
    },
  };
});
const LandingText = styled.p(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    lineHeight: "127.5%",
    ...FontStyles.regular14,
    fontSize: spacing(4.375),
    maxWidth: "620px",
    padding: 0,
    margin: 0,
    marginBottom: spacing(2),
    color: "white",
    [`@media (max-width:${1024 - 1}px)`]: {
      marginBottom: spacing(1.5),
      fontSize: spacing(4.375),
      maxWidth: "317px",
    },

    [`@media (max-width:${470 - 1}px)`]: {
      fontSize: spacing(2.25),
      maxWidth: "197px",
    },
  };
});

const LandingFirst = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    zIndex: 3,
    paddingLeft: spacing(13.125),
    paddingBottom: spacing(9),
    width: "100%",
    maxWidth: "618px",
    [`@media (max-width:${1024 - 1}px)`]: {
      paddingLeft: spacing(6),
      paddingBottom: spacing(6),
      maxWidth: "42vw",
      minWidth: "318px",
      width: "100%",
    },

    [`@media (max-width:${470 - 1}px)`]: {
      paddingLeft: spacing(3.25),
      paddingBottom: spacing(4.25),
    },
  };
});

const BgVideo = styled.video(({ theme, $menuHeight }) => {
  return {
    position: "absolute",
    objectFit: "cover",
    width: "100%",
    height: "100%",
    top: 0,
    zIndex: 2, pointerEvents: "none",
    [`@media (max-width:${745 - 1}px)`]: {},
    [`@media (max-width:${470 - 1}px)`]: {},
    [`@media (min-width:${1440}px)`]: {},
  };
});

const YellowBall = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    position: "absolute",
    zIndex: -1,

    top: 0,
    right: 0,
    transform: "translate(90%, -50%)",
    borderRadius: "996px",
    width: "798.961px",
    height: "996px",
    filter: "blur(225px)",
    background: "#E7FF00E6",

    [`@media (max-width:${1024 - 1}px)`]: {
      borderRadius: "514.6px",
      width: "412.797px",
      height: "514.6px",
      filter: "blur(116.25px)",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      transform: "translate(50%, -50%)",
      borderRadius: "310px",
      width: "310px",
      height: "310px",
      filter: "blur(100px)",
    },
  };
});

const YellowBallTwo = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    position: "absolute",
    transform: "translate(-40%, 50%)",
    bottom: 0,
    left: 0,
    borderRadius: "631px",
    width: "631px",
    height: "601px",
    background: "#E7FF00E6",
    filter: "blur(300px)",
    zIndex: -1,

    [`@media (max-width:${1024 - 1}px)`]: {
      borderRadius: "310.517px",
      width: "326.017px",
      height: "310.517px",
      filter: "blur(155px)",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      transform: "translate(-105%, 40%)",
      borderRadius: "540.567px",
      width: "640.189px",
      height: "540.567px",
      filter: "blur(300px)",
    },
  };
});

const DashContainer = styled.div(({ theme, $image }) => {
  const { spacing } = theme;
  return {
    position: "relative",
    width: "100%",

    backgroundColor: "black",
    zIndex: 1,
    backgroundImage: `url(${$image})`,
    paddingBlock: spacing(9.25),
    paddingInline: spacing(12.5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    [`@media (max-width:${1024 - 1}px)`]: {
      padding: spacing(6),
    },
    [`@media (max-width:${581 - 1}px)`]: { padding: spacing(2.5) },
  };
});

const WhiteBox = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    borderRadius: "43.919px",
    background: "#F2F2F2",
    backdropFilter: "blur(18.822629928588867px)",

    width: "100%",
    zIndex: 3,
    padding: spacing(10),

    [`@media (max-width:${1024 - 1}px)`]: {
      paddingInline: `clamp(48px, 8vw, 90px)`,
    },
    [`@media (max-width:${581 - 1}px)`]: {
      paddingInline: `clamp(24px, 11vw, 468px)`,
      paddingBlock: `clamp(18px, 8vw, 32px)`,
    },
  };
});
const TitleBox = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    paddingBottom: spacing(2),
    [`@media (max-width:${1024 - 1}px)`]: {},
    [`@media (max-width:${581 - 1}px)`]: { flexDirection: "row" },
  };
});
const TitleConfig = styled.p(({ theme }) => {
  const { spacing } = theme;
  return {
    margin: 0,
    fontSize: spacing(4.375),
    fontFamily: fonts.heavy,
    [`@media (max-width:${1024 - 1}px)`]: { fontSize: spacing(3.5) },
    [`@media (max-width:${581 - 1}px)`]: { fontSize: spacing(2) },
  };
});

const BoxContent = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    [`@media (max-width:${1024 - 1}px)`]: {},
    [`@media (max-width:${581 - 1}px)`]: {},
  };
});

const BoxUserContent = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    [`@media (max-width:${1024 - 1}px)`]: {},
    [`@media (max-width:${581 - 1}px)`]: {},
  };
});

const FloatingBox = styled.div(({ theme, $img }) => {
  const { spacing } = theme;
  return {
    top: 0,
    position: "absolute",
    backgroundImage: `url(${$img})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    borderRadius: "43.848px",
    border: "1.253px solid #E7FF00",
    zIndex: -1,
    width: "100%",
    height: "144.072px",
    [`@media (max-width:${1024 - 1}px)`]: {},
    [`@media (max-width:${581 - 1}px)`]: {
      height: "64.084px",
      borderRadius: "19.514px",
    },
  };
});
const HeaderBox = styled.div(({ theme, $img }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    gap: spacing(2),
    alignItems: "center",
    paddingInline: spacing(5.75),
    backgroundImage: `url(${$img})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    borderRadius: "43.848px",
    border: "1.253px solid #E7FF00",
    zIndex: -1,
    width: "100%",
    height: "144.072px",
    marginBottom: spacing(4),
    [`@media (max-width:${1024 - 1}px)`]: {
      height: "181.74px",
      paddingInline: spacing(5),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      height: "96px",
      paddingInline: spacing(3),
      gap: spacing(1),
      borderRadius: "26px",
    },
  };
});
const UserImg = styled.div(({ theme, $img }) => {
  const { spacing } = theme;
  return {
    marginTop: "20px",
    borderRadius: "243.848px",
    border: "5.148px solid #E7FF00",
    backgroundImage: `url(${$img})`,
    width: "193px",
    height: "193px",
    zIndex: 2,
    backgroundSize: "cover",
    backgroundColor: "gray",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [`@media (max-width:${1024 - 1}px)`]: {},
    [`@media (max-width:${581 - 1}px)`]: {
      width: "85.909px",
      height: "85.909px",
      border: "2px solid #E7FF00",
    },
  };
});

const SmallUserImg = styled.div(({ theme, $img }) => {
  const { spacing } = theme;
  return {
    borderRadius: "243.848px",
    border: "5.148px solid #E7FF00",
    backgroundImage: `url(${$img})`,
    width: "86px",
    height: "86px",
    zIndex: 2,
    backgroundSize: "cover",
    [`@media (max-width:${1024 - 1}px)`]: {
      width: "106px",
      height: "106px",
      border: "2px solid #E7FF00",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      width: "56px",
      height: "56px",
      border: "1px solid #E7FF00",
    },
  };
});

const SendImg = styled(IconButton)(({ theme, $img }) => {
  const { spacing } = theme;
  return {
    "&&.MuiIconButton-root": {
      position: "absolute",
      right: 22,
      backgroundColor: "rgba(26, 32, 36, 0.40)",
      backdropFilter: "blur(5px)",
      bottom: 0,
      border: "1.997px solid #FFF",
      color: "white",
      padding: "11px",
      height: "fit-content",
      "& .MuiIconButton-label": {
        [`@media (max-width:${581 - 1}px)`]: { fontSize: "9px" },
      },
      [`@media (max-width:${1024 - 1}px)`]: {},
      [`@media (max-width:${581 - 1}px)`]: { right: 6, padding: "5px" },
    },
  };
});

const MotalSubtText = styled.p(({ theme, $img }) => {
  const { spacing } = theme;
  return {
    ...FontStyles.medium16,
    color: "white",
    marginBottom: 0,
    textAlign: "left",
    width: "100%",
    [`@media (max-width:${1024 - 1}px)`]: {},
    [`@media (max-width:${581 - 1}px)`]: {},
  };
});
const ModalContainer = styled.div(({ theme, $img }) => {
  const { spacing } = theme;
  return {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    minWidth: "400px",
    paddingInline: spacing(3),
    [`@media (max-width:${1024 - 1}px)`]: {},
    [`@media (max-width:${581 - 1}px)`]: {},
  };
});

const NameUser = styled.p(({ theme, small }) => {
  const { spacing } = theme;
  return {
    margin: 0,
    fontFamily: fonts.heavy,
    fontSize: small ? spacing(3.5) : spacing(7.75),
    color: small ? "white" : "#1A2024",
    textTransform: "capitalize",
    lineHeight: "140.791%",
    paddingTop: small ? 0 : "16px",
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: small ? spacing(3) : spacing(7.75),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: small ? spacing(2.75) : spacing(3.375),
    },
  };
});

const OptionsList = styled.div(({ theme, $img }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "row",
    maxWidth: "100%",
    overflowX: "auto",
    overflowY: "hidden",
    gap: "32px",
    paddingBlock: "90px",
    [`@media (max-width:${1024 - 1}px)`]: {},
    [`@media (max-width:${581 - 1}px)`]: {
      flexDirection: "column",
      gap: "20px",
      paddingBlock: "20px",
    },

    "::-webkit-scrollbar": {
      height: "22px",
    },
    "::-webkit-scrollbar-thumb": {
      border: "4px solid #0000",
      borderRadius: "11px",
      background:
        "linear-gradient(#E7FF00,#E7FF00) padding-box, linear-gradient(0deg, #D5D5D5 0%, #E9E9E9 30%, #E9E9E9 70%, #D5D5D5 100%) border-box",
    },
    "::-webkit-scrollbar-track": {
      borderRadius: "11px",
      background: "rgba(97, 97, 97, 0.05)",
      boxShadow:
        "0px 1px 11px 0px rgba(0, 0, 0, 0.18) inset, 4px 4px 8px 0px rgba(0, 0, 0, 0.05)",
    },
  };
});

const OptionContainer = styled.div(({ theme, $img }) => {
  const { spacing } = theme;
  return {
    position: "relative",
    paddingTop: "44px",
    minWidth: "287px",
    userSelect: "none",
    [`@media (max-width:${1024 - 1}px)`]: {},
    [`@media (max-width:${581 - 1}px)`]: {
      minWidth: "100%",
      paddingTop: "0px",
    },
  };
});

const OptionIcon = styled.div(({ theme, $img }) => {
  const { spacing } = theme;
  return {
    position: "absolute",
    left: "65px",
    top: 0,
    borderRadius: "18px",
    backgroundColor: "#E7FF00",
    padding: "10px",
    width: "84px",
    height: "84px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [`@media (max-width:${1024 - 1}px)`]: {},
    [`@media (max-width:${581 - 1}px)`]: { display: "none" },
  };
});

const OptionIconModal = styled.div(({ theme, $img }) => {
  const { spacing } = theme;
  return {
    display: "none",
    borderRadius: "18px",
    backgroundColor: "#E7FF00",
    padding: "10px",
    width: "84px",
    height: "84px",

    justifyContent: "center",
    alignItems: "center",
    [`@media (max-width:${1024 - 1}px)`]: {},
    [`@media (max-width:${581 - 1}px)`]: {
      display: "flex",
      width: "37px",
      height: "37px",
      padding: "8px",
      borderRadius: "8px",
      marginBottom: "13px",
    },
  };
});
OptionIconModal;

const OptionBox = styled.div(({ theme, $img }) => {
  const { spacing } = theme;
  return {
    cursor: "pointer",
    borderRadius: "45px",
    border: "2.509px solid rgba(231, 255, 0, 0.51)",
    background:
      "linear-gradient(232deg, rgba(231, 255, 0, 0.14) 3.16%, rgba(231, 255, 0, 0.00) 40.87%), #050500",
    boxShadow: "15px 15px 100px 0px rgba(0, 0, 0, 0.1)",
    padding: "45px",
    maxWidth: "287px",
    width: "100%",
    height: "100%",
    [`@media (max-width:${1024 - 1}px)`]: {},
    [`@media (max-width:${581 - 1}px)`]: {
      padding: "30px",
      paddingBlock: "24px",
      boxShadow: "2px 2px 12px 0px rgba(0, 0, 0, 0.05)",
      border: "1px solid rgba(231, 255, 0, 0.51)",
      borderRadius: "24px",
    },
  };
});

const OptionTitle = styled.p(({ theme, $img }) => {
  const { spacing } = theme;
  return {
    color: "white",
    margin: 0,
    fontFamily: fonts.heavy,
    fontSize: spacing(3.125),
    lineHeight: "normal",
    [`@media (max-width:${1024 - 1}px)`]: {},
    [`@media (max-width:${581 - 1}px)`]: { fontSize: spacing(2) },
  };
});
const OptionSubTitle = styled.p(({ theme, $img }) => {
  const { spacing } = theme;
  return {
    paddingTop: spacing(3.5),
    color: "white",
    margin: 0,
    fontFamily: fonts.light,
    fontSize: spacing(2.25),
    lineHeight: "normal",
    [`@media (max-width:${1024 - 1}px)`]: {},
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: spacing(1),
      paddingTop: spacing(1.1),
    },
  };
});

const Back = styled(ButtonContained)(({ theme, $center, $image }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      paddingInline: spacing(4),
      paddingBlock: spacing(2.25),
      fontSize: spacing(2),
      borderRadius: spacing(2),
      marginTop: spacing(4),
      [`@media (max-width:${1024 - 1}px)`]: {
        borderRadius: spacing(3),
        paddingInline: spacing(5.25),
        paddingBlock: spacing(2.5),
        fontSize: spacing(2.5),
        marginLeft: 0,
      },
      [`@media (max-width:${581 - 1}px)`]: {
        borderRadius: spacing(1.5),
        paddingInline: spacing(2.25),
        paddingBlock: spacing(1.5),
        fontSize: spacing(1.5),
        marginTop: spacing(1.5),
      },
    },
  };
});
const InputTitle = styled.p(({ theme }) => {
  const { spacing } = theme;
  return {
    ...FontStyles.regular18,
    fontSize: spacing(2.75),
    margin: 0,
    padding: 0,
    color: "#1A2024",
    lineHeight: "140.791%",
    fontFamily: fonts.bold,

    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: spacing(1.5),
    },
  };
});
const SubText = styled.p(({ theme }) => {
  const { spacing } = theme;
  return {
    fontSize: spacing(2.25),
    margin: 0,
    padding: 0,
    color: "#1A2024",
    lineHeight: "140.791%",
    fontFamily: fonts.medium,
    marginBottom: spacing(1),
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(2),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: spacing(1.25),
    },
  };
});
const SendButton = styled(ButtonContained)(({ theme }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      fontSize: spacing(2.5),
      color: "#1A2024",
      lineHeight: "140.791%",
      fontFamily: fonts.heavy,
      paddingInline: spacing(6.5),
      paddingBlock: spacing(3),
      [`@media (max-width:${1024 - 1}px)`]: {
        fontSize: spacing(1.5),
        paddingInline: spacing(5),
        paddingBlock: spacing(2),
      },

      [`@media (max-width:${581 - 1}px)`]: {
        fontSize: spacing(1.25),
        paddingInline: spacing(2.5),
        paddingBlock: spacing(1.5),
      },
    },
  };
});

const OptionSecBox = styled.div(({ theme, $last }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    background:
      "linear-gradient(232deg, rgba(231, 255, 0, 0.14) 3.16%, rgba(231, 255, 0, 0.00) 40.87%), #050500",
    boxShadow: "10.0207px 15.03106px 53.86129px 0px rgba(0, 0, 0, 0.15)",
    padding: spacing(4.5),
    borderRadius: "27px",
    marginBottom: !$last && spacing(5.75),
    [`@media (max-width:${1024 - 1}px)`]: {},
    [`@media (max-width:${581 - 1}px)`]: {
      flexDirection: "column",
      padding: spacing(2),
      paddingInline: spacing(2.25),
      gap: spacing(1.75),
      marginBottom: !$last && spacing(2),
    },
  };
});

const OptionSecBoxContainer = styled.div(({ theme, $img }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    gap: "16px",
    alignItems: "center",
    [`@media (max-width:${1024 - 1}px)`]: {},
    [`@media (max-width:${581 - 1}px)`]: { gap: "11px" },
  };
});

const IconSecContainer = styled.div(({ theme, $img }) => {
  const { spacing } = theme;
  return {
    backgroundColor: "#E7FF00",
    borderRadius: "16px",
    display: "flex",
    flexDirection: "row",
    maxWidth: "41px",
    minHeight: "41px",
    width: "100%",
    height: "100%",
    padding: spacing(0.9),
    alignItems: "center",
    justifyContent: "center",
    [`@media (max-width:${1024 - 1}px)`]: {},
    [`@media (max-width:${581 - 1}px)`]: {
      maxWidth: "24px",
      minHeight: "24px",
      borderRadius: "6px",
      padding: spacing(0.5),
    },
  };
});

const TextSecContainer = styled.div(({ theme, $img }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: spacing(0.6),
    alignItems: "flex-start",
    justifyContent: "center",
    [`@media (max-width:${1024 - 1}px)`]: {},
    [`@media (max-width:${581 - 1}px)`]: {},
  };
});

const TitleSecurity = styled.p(({ theme, $img }) => {
  const { spacing } = theme;
  return {
    color: "white",
    margin: 0,
    lineHeight: "normal",
    fontSize: spacing(2.25),
    fontFamily: fonts.heavy,
    [`@media (max-width:${1024 - 1}px)`]: {},
    [`@media (max-width:${581 - 1}px)`]: { fontSize: spacing(1.25) },
  };
});

const SubTitleSecurity = styled.p(({ theme, $img }) => {
  const { spacing } = theme;
  return {
    color: "white",
    margin: 0,
    lineHeight: "normal",
    fontSize: spacing(2.25),
    fontFamily: fonts.regular,
    [`@media (max-width:${1024 - 1}px)`]: {},
    [`@media (max-width:${581 - 1}px)`]: { fontSize: spacing(1) },
  };
});

const ActionButtonSecurity = styled(ButtonContained)(({ theme, $img }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      fontSize: spacing(2.25),

      maxWidth: "286px",
      width: "100%",
      paddingBlock: spacing(2.25),
      [`@media (max-width:${1024 - 1}px)`]: {},
      [`@media (max-width:${581 - 1}px)`]: {
        paddingBlock: spacing(0.7),
        fontSize: spacing(1.1),
      },
    },
  };
});

const ModalImport = styled(ButtonContained)(({ theme, $full, $image }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      maxWidth: $full ? "fit-content" : "calc(50% - 8px)",

      fontSize: spacing(2.25),
      paddingBlock: spacing(2.25),
      paddingInline: spacing(5),
      width: "100%",
      [`@media (max-width:${1024 - 1}px)`]: {},
      [`@media (max-width:${581 - 1}px)`]: {},
    },
  };
});
const ModalImportSend = styled(ButtonContained)(({ theme, $full, $image }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      maxWidth: $full ? "fit-content" : "45%",

      fontSize: spacing(1.5),
      paddingBlock: spacing(1.5),
      paddingInline: spacing(5),

      [`@media (max-width:${1024 - 1}px)`]: {},
      [`@media (max-width:${581 - 1}px)`]: {},
    },
  };
});

const ModalImportText = styled.p(({ theme, $center, $image }) => {
  const { spacing } = theme;
  return {
    fontSize: spacing(2.25),
    margin: 0,
    color: "#079CFF",
    textDecorationLine: "underline",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    [`@media (max-width:${1024 - 1}px)`]: {},
    [`@media (max-width:${581 - 1}px)`]: {},
  };
});

const ExitButton = styled(ButtonText)(({ theme }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      color: "black",
      fontSize: spacing(3.25),
      [`@media (max-width:${1024 - 1}px)`]: { fontSize: spacing(3) },
      [`@media (max-width:${581 - 1}px)`]: { fontSize: spacing(1.1) },
    },
  };
});

const ExitButtonIcon = styled(IoLogOutOutline)(({ theme }) => {
  const { spacing } = theme;
  return {
    minHeight: spacing(6.25),
    width: "auto",
    [`@media (max-width:${1024 - 1}px)`]: { minHeight: spacing(5.5) },
    [`@media (max-width:${581 - 1}px)`]: { minHeight: spacing(2) },
  };
});

const Styles = {
  Container,
  BgVideoContainer,
  BgVideo,
  LandingFirst,
  LandingText,

  YellowBallTwo,
  YellowBall,

  WhiteBox,
  DashContainer,
  TitleBox,
  TitleConfig,
  useStyles,

  BoxUserContent,
  FloatingBox,
  UserImg,
  SendImg,
  MotalSubtText,
  ModalContainer,
  NameUser,
  OptionsList,
  OptionContainer,
  OptionIcon,
  OptionBox,
  OptionTitle,
  OptionSubTitle,
  Back,
  HeaderBox,
  SmallUserImg,

  InputTitle,
  SendButton,
  SubText,

  OptionSecBox,
  OptionSecBoxContainer,
  IconSecContainer,
  TextSecContainer,
  SubTitleSecurity,
  TitleSecurity,
  ActionButtonSecurity,

  ModalImport,
  ModalImportText,
  ModalImportSend,
  ExitButton,
  ExitButtonIcon,
  OptionIconModal,
};

export default Styles;
