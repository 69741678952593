import { errorModal } from "../components/modals/utils";
import Texts from "../config/texts";
import LocalStorage from "../utils/storage/localStorage";

const title =
  Texts[
    LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
  ].modalAlert;

function setErrorModal(title, texts, icon) {
  errorModal.setInfos(
    title,
    texts,
    {
      label: "Ok",
      onClick: errorModal.close,
    },
    undefined,
    icon
  );
}

function alertError(msg) {
  const texts = typeof msg === "string" ? [msg] : msg;
  setErrorModal(title.error, texts, "error");
}

function alertWarning(msg) {
  const texts = typeof msg === "string" ? [msg] : msg;
  setErrorModal(title.warning, texts, "warning");
}

function alertSuccess(msg) {
  const texts = typeof msg === "string" ? [msg] : msg;
  setErrorModal(title.success, texts, "success");
}

const alerts = {
  alertError,
  alertWarning,
  alertSuccess,
};

export default alerts;
