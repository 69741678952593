import React, { useContext } from "react";
import Styles from "../styles/Styles";
import Drawer from "@material-ui/core/Drawer";
import { IconButton, makeStyles } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import { ButtonText, CustomText, DefaultLogo } from "../../../components";
import { Colors, fonts, Spacing } from "../../../config";
import styled from "styled-components";
import { MenuGroupWithPages } from "../components";
import { RxExit } from "react-icons/rx";
import pagesConfig from "../../../config/pagesConfig";
import { StorageContext } from "../../../contexts/StorageContext";
import UserInfosPresentation from "../../header/components/UserInfos";
import { useLocation, useNavigate } from "react-router-dom";
import { paths } from "../../../navigation/navigate";
import { hooks, LocalStorage, SessionStorage } from "../../../utils";
import { api } from "../../../services";
import texts from "../../../config/texts";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawerPaper: {
    backgroundColor: "#050500",

    borderBottomLeftRadius: "17px",
    height: "fit-content",
    display: "unset !important",
    maxWidth: "100vw",
    width: "fit-content",
    minWidth: "360px",
    maxHeight: "100vh",

    [`@media (max-width:${581 - 1}px)`]: {
      maxWidth: "100vw",
      width: "260px",
      minWidth: "65vw",
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function Menu({ openMenu, isOpen, userData, coins }) {
  const currentText =
    texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ];
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const { loading, call } = hooks.useRequest();
  const { isLogged, setIsLogged, getData, addData } =
    useContext<any>(StorageContext);

  const onLogout = () => {
    setIsLogged(false);
    LocalStorage.removeItem("token");
    LocalStorage.removeItem("userData");
    openMenu(false);
  };

  const onLogin = () => {
    navigate(paths.login);
    openMenu(false);
  };

  const drawerItens = (
    <>
      <Styles.Container>
        <Styles.ScrollContainer>
          <Styles.Header>
            <DefaultLogo maxWidth={140} />
          </Styles.Header>

          {isLogged ? (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <UserInfosPresentation
                  coins={coins}
                  goConfig={() => {
                    navigate(paths.config);
                    openMenu(false);
                  }}
                  goBuy={() => {
                    navigate(paths.payment);
                    openMenu(false);
                  }}
                  noMediaQuery
                  userInfos={userData}
                />
              </div>

              <Styles.Line />
              {pagesConfig?.logged?.map(
                (item, index) =>
                  !item?.noMenu && (
                    <MenuGroupWithPages
                      close={() => openMenu(false)}
                      key={"navGroup" + index}
                      index={index}
                      item={item}
                      firstItem={index === 0}
                      pathname={location.pathname}
                    />
                  )
              )}
              <Styles.ButtonRExit
                startIcon={<RxExit />}
                fullWidth={true}
                onClick={onLogout}
              >
                {currentText.actionButton.out}
              </Styles.ButtonRExit>
            </>
          ) : (
            <>
              <Styles.ButtonLogin fullWidth={true} onClick={onLogin}>
                {currentText.actionButton.enter}
              </Styles.ButtonLogin>
              <Styles.ButtonRegister
                fullWidth={true}
                onClick={() => navigate("/register")}
              >
                {currentText.actionButton.register}
              </Styles.ButtonRegister>
              <Styles.Line />
              {pagesConfig?.notLogged?.map(
                (item, index) =>
                  !item?.noMenu && (
                    <MenuGroupWithPages
                      close={() => openMenu(false)}
                      key={"navGroupNL" + index}
                      index={index}
                      item={item}
                      firstItem={index === 0}
                      pathname={location.pathname}
                    />
                  )
              )}
            </>
          )}
        </Styles.ScrollContainer>
        <CustomText
          fontSize={1.5}
          fontFamily={fonts.medium}
          textColor={Colors.white}
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBlock: Spacing(3),
          }}
        >
          {process.env.REACT_APP_ENV + " v"}
          {process.env.REACT_APP_VERSION}
        </CustomText>
      </Styles.Container>
    </>
  );

  const MyHidden = styled(Hidden)(() => {
    return {};
  });

  return (
    <Drawer
      variant="temporary"
      anchor={"right"}
      open={isOpen}
      onClose={() => openMenu(!isOpen)}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      {drawerItens}
    </Drawer>
  );
}

export default Menu;
