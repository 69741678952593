import { SVG, Spacing, fonts } from "../../../config";
import Styles from "../PaymentStyles";
import { Input } from "../../../components";
import { LocalStorage, masks } from "../../../utils";
import { FormFull } from "form-full";
import texts from "../../../config/texts";
import { Slider } from "../../../components";
import React from "react";
import Checkbox from "../../../components/inputs/Checkbox";

interface PageProps {
  goNext: () => void;
  setBiocoinQuantity: any;
  setPurchaseValue: any;
  currentValue: number;
  seconds: number;
  biocoinQuantity: number;
  submit: any;
  purchaseValue: number;
}

function StepBuy({
  goNext,
  setBiocoinQuantity,
  setPurchaseValue,
  currentValue,
  seconds,
  biocoinQuantity,
  submit,
  purchaseValue,
}: PageProps): JSX.Element {
  const currentText =
    texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].payment.buy;
  const [sliderValue, setSliderValue] = React.useState<number>(0);
  const [inputValue, setInputValue] = React.useState<any>(0);
  const [checked, setChecked] = React.useState<boolean>(false);
  const [ref, setRef] = React.useState<any>(null);

  const updateValue = (value) => {
    let toReceive = value / currentValue;
    setBiocoinQuantity(toReceive);
    setPurchaseValue(value);
  };

  function valueLabelFormat(value: number) {
    return (
      <Styles.SliderLabelFormat>
        <Styles.SliderLabelContainer>
          <Styles.SliderLabelFont>{`R$${value}`}</Styles.SliderLabelFont>
        </Styles.SliderLabelContainer>
        <Styles.SliderLabelFormatPin />
      </Styles.SliderLabelFormat>
    );
  }

  const barWidth = (seconds / 60) * 100 + "%";
  const validation5 = (value) => {
    const trueValue = value.replaceAll(".", "").replaceAll(",", ".");

    if (checked) {
      return null;
    }

    if (trueValue) {
      if (parseFloat(trueValue) >= 5) {
        return null;
      } else {
        return currentText.minimal;
      }
    } else {
      return currentText.minimal;
    }
  };
  return (
    <>
      <FormFull formRef={setRef} onSubmit={() => {}}>
        <Styles.Content>
          <Styles.PriceContainer>
            <Styles.Title style={{ marginBottom: 30 }}>
              <span style={{ fontFamily: fonts.regularCf }}>
                {currentText.buyBiocoin[0]}
              </span>{" "}
              {currentText.buyBiocoin[1]}
            </Styles.Title>
            <Styles.Subtitle>{currentText.price}</Styles.Subtitle>
            <Styles.PriceContent>
              <Styles.PriceDot>
                <SVG.GreenRS />
              </Styles.PriceDot>
              <Styles.ValueText style={{ marginLeft: -1 }}>
                {`${masks.money(currentValue?.toString()).replace("R$", "")} =`}
              </Styles.ValueText>
              <Styles.PriceDot>
                <SVG.GreenTree />
              </Styles.PriceDot>
              <Styles.ValueText>1</Styles.ValueText>
            </Styles.PriceContent>
          </Styles.PriceContainer>

          <Styles.PriceContainer $mobile>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Styles.Title style={{ marginBottom: 8 }}>
                <span style={{ fontFamily: fonts.regularCf }}>
                  {currentText.buyBiocoin[0]}
                </span>{" "}
                {currentText.buyBiocoin[1]}
              </Styles.Title>
              <Styles.Subtitle>{currentText.price}</Styles.Subtitle>
            </div>
            <Styles.PriceContentMobile>
              <Styles.PriceDot>
                <Styles.RSBlackIcon />
              </Styles.PriceDot>
              <Styles.ValueText style={{ marginLeft: -1 }}>
                {`${masks.money(currentValue?.toString()).replace("R$", "")} =`}
              </Styles.ValueText>
              <Styles.PriceDot>
                <Styles.TreeBlackIcon />
              </Styles.PriceDot>
              <Styles.ValueText>1</Styles.ValueText>
            </Styles.PriceContentMobile>
          </Styles.PriceContainer>

          <Styles.ProgressBar>
            <Styles.ProgressGreenBar style={{ width: barWidth }} />
          </Styles.ProgressBar>
        </Styles.Content>

        <Styles.ContentBlack>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Styles.Subtitle $green>{currentText.howMuch}</Styles.Subtitle>
            <Styles.TreeGreenIcon />
          </div>
          <Slider
            step={10}
            min={0}
            max={1000}
            name="sliderValue"
            onChange={(value) => {
              setSliderValue(value), setInputValue(value), updateValue(value);
            }}
            defaultValue={sliderValue}
            disabled={checked}
            valueLabelFormat={valueLabelFormat(sliderValue)}
          />
          <div style={{ display: "flex" }}>
            <Checkbox
              defaultValue={false}
              name="activedInput"
              onChange={() => {
                updateValue(0);
                setInputValue(0);
                setSliderValue(0);
                setChecked(!checked);
                if (checked) {
                  ref?.setValue("inputValue", 0);
                  ref?.setFieldValidation("inputValue", (value) => {
                    return null;
                  });

                  ref?.testFieldError("inputValue", true);
                } else {
                  ref?.setFieldValidation("inputValue", validation5);
                }
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Styles.InputTitle $green={checked ? "#E7FF00" : "#8a9900"}>
                {currentText.otherValue}
              </Styles.InputTitle>
              <Input
                onChange={(value: any, a: any) => {
                  const trueValue = masks
                    .inputMoney(value)
                    .replaceAll(".", "")
                    .replaceAll(",", ".");

                  if (typeof value === "string" && parseFloat(trueValue) >= 5) {
                    updateValue(masks.removeNumericMask(value));
                    setInputValue(masks.removeNumericMask(value));
                    setSliderValue(masks.removeNumericMask(value));
                  } else {
                    updateValue(0);
                    setInputValue(0);
                    setSliderValue(0);
                  }
                }}
                inputStyle={{
                  background: "#1A2024",
                  borderRadius: "95px",
                  color: checked ? "#E7FF00" : "#8a9900",
                  fontFamily: fonts.regular,
                  fontSize: Spacing(2.75),
                  paddingInline: Spacing(3.75),
                  paddingBlock: Spacing(2),
                  marginTop: Spacing(0.75),
                  "& .MuiInputBase-input": {
                    padding: 0,
                    boxShadow: "none !important",
                  },
                  [`@media (max-width:${1024 - 1}px)`]: {
                    paddingBlock: Spacing(1.5),
                    paddingInline: Spacing(1.75),
                    fontSize: Spacing(1.6),
                  },
                  [`@media (max-width:${581 - 1}px)`]: {
                    paddingInline: Spacing(1),
                    paddingBlock: Spacing(0.5),
                    fontSize: Spacing(1),
                    marginTop: Spacing(0.15),
                  },
                }}
                validation={validation5}
                name="inputValue"
                required={checked ? currentText.required : null}
                readOnly={!checked}
                mask={masks.inputMoney}
                maskToSubmit={masks.removeNumericMask}
              />
            </div>
          </div>
        </Styles.ContentBlack>

        <Styles.Content>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Styles.Subtitle $bigger>{currentText.toReceive}</Styles.Subtitle>
            <Styles.PriceDot $square style={{ marginInline: 10 }}>
              <Styles.TreeBlackIcon />
              <Styles.TreeGreenIcon $square />
            </Styles.PriceDot>
            <Styles.Title $bigger style={{ fontFamily: fonts.heavy }}>
              {biocoinQuantity}
            </Styles.Title>
          </div>
        </Styles.Content>

        <Styles.StyledButton
          name="buy"
          fullWidth={false}
          disabled={purchaseValue === 0}
          onClick={() => {
            const data = {
              inputValue: masks.removeNumericMask(ref.getValue("inputValue")),
              sliderValue: ref.getValue("sliderValue"),
              checkbox: ref.getValue("activedInput"),
            };
            submit(data, goNext());
          }}
        >
          {currentText.buyButton}
        </Styles.StyledButton>
      </FormFull>
    </>
  );
}

export default StepBuy;
