import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ptBr from "../../config/texts/pt-br";
import { Swiper } from "../../components/index";
import Styles from "./styles";

import { hooks, LocalStorage, SessionStorage } from "../../utils";
import { StorageContext } from "../../contexts/StorageContext";

import { api } from "../../services";
import { paths } from "../../navigation/navigate";

import ResponseError from "../../services/helpers/ResponseError";
import { CancelToken } from "apisauce";
import alerts from "../../utils/Alerts";
import LoginStep from "./components/LoginStep";
import TwoFactorStep from "./components/TwoFactorStep";
import Texts from "../../config/texts";

function Login() {
  const texts =
    Texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].login;

  const navigate = useNavigate();
  const { loading, call } = hooks.useRequest();
  const { addData, setIsLogged, setCancelToken, setUserData } =
    useContext<any>(StorageContext);
  const [menuHeight, setHeight] = React.useState<any>(null);

  React.useLayoutEffect(() => {
    if (!menuHeight) {
      const mh = document.getElementById("header-menu")?.offsetHeight;

      setHeight(mh);
    }
  }, [menuHeight]);

  const Submit = async (data, callback) => {
    const source = CancelToken.source();

    setCancelToken(source);
    call(
      null,
      api.getToken(data, { cancelToken: source.token }),
      (response) => {
        if (response.ok) {
          if (response.data.role === "2FA") {
            callback();
          } else {
            setIsLogged(true);
            LocalStorage.setItem("userData", response.data);
            LocalStorage.setItem("user", response.data);
            call(null, api.getUserData(), (data) => {
              if (data.ok) {
                if (data.data.imageId) {
                  call(
                    null,
                    api.getUserImage(),
                    (response) => {
                      call(null, api.getWallet(), (dataWallet) => {
                        if (response.ok) {
                          addData("userData", {
                            ...data?.data,
                            image: response?.data,
                            ...dataWallet?.data,
                          });
                          setUserData({
                            ...data?.data,
                            image: response?.data,
                            ...dataWallet?.data,
                          });
                        } else {
                          addData("userData", {
                            ...data?.data,
                            image: response?.data,
                          });
                          setUserData({
                            ...data?.data,
                            image: response?.data,
                          });
                        }
                      });
                    },
                    (response) => {
                      if (response.status !== 404) {
                        const errorResponse = new ResponseError(response);

                        if (errorResponse.message) {
                          alerts.alertError(errorResponse.message);
                        }
                      }
                    }
                  );
                } else {
                  call(null, api.getWallet(), (dataWallet) => {
                    if (response.ok) {
                      addData("userData", {
                        ...data?.data,
                        image: null,
                        ...dataWallet?.data,
                      });
                      setUserData({
                        ...data?.data,
                        image: null,
                        ...dataWallet?.data,
                      });
                    } else {
                      addData("userData", { ...data?.data, image: null });
                      setUserData({ ...data?.data, image: null });
                    }
                  });
                }
              }
            });
            navigate(paths.home);
          }
        }
      },
      (response) => {
        if (response.status === 400) {
          alerts.alertError(texts.invalidLogin);
        } else {
          const errorResponse = new ResponseError(response);
          errorResponse.alertMessage();
        }
      }
    );
  };

  const SubmitTwoFactor = async (data) => {
    const source = CancelToken.source();

    setCancelToken(source);
    call(
      null,
      api.send2FA(data.code, { cancelToken: source.token }),
      (response) => {
        if (response.ok) {
          setIsLogged(true);
          LocalStorage.setItem("userData", response.data);
          LocalStorage.setItem("user", response.data);
          call(null, api.getUserData(), (data) => {
            if (data.ok) {
              if (data.data.imageId) {
                call(
                  null,
                  api.getUserImage(),
                  (response) => {
                    call(null, api.getWallet(), (dataWallet) => {
                      if (response.ok) {
                        addData("userData", {
                          ...data?.data,
                          image: response?.data,
                          ...dataWallet?.data,
                        });
                        setUserData({
                          ...data?.data,
                          image: response?.data,
                          ...dataWallet?.data,
                        });
                      } else {
                        addData("userData", {
                          ...data?.data,
                          image: response?.data,
                        });
                        setUserData({
                          ...data?.data,
                          image: response?.data,
                        });
                      }
                    });
                  },
                  (response) => {
                    if (response.status !== 404) {
                      const errorResponse = new ResponseError(response);

                      if (errorResponse.message) {
                        alerts.alertError(errorResponse.message);
                      }
                    }
                  }
                );
              } else {
                call(null, api.getWallet(), (dataWallet) => {
                  if (response.ok) {
                    addData("userData", {
                      ...data?.data,
                      image: null,
                      ...dataWallet?.data,
                    });
                    setUserData({
                      ...data?.data,
                      image: null,
                      ...dataWallet?.data,
                    });
                  } else {
                    addData("userData", { ...data?.data, image: null });
                    setUserData({ ...data?.data, image: null });
                  }
                });
              }
            }
          });
          navigate(paths.home);
        } else {
          const errorResponse = new ResponseError(response);
          errorResponse.alertMessage();
        }
      }
    );
  };

  return (
    <Styles.Container $menuHeight={menuHeight}>
      <Styles.ImageBG $menuHeight={menuHeight}>
        <Styles.YellowBall></Styles.YellowBall>
        <Styles.YellowBallTwo></Styles.YellowBallTwo>
        <Swiper
          screens={[
            ({ goNext }) => (
              <LoginStep
                loading={loading}
                onSubmit={(data) => {
                  Submit(data, goNext);
                }}
              />
            ),
            ({ goPrevious }) => (
              <TwoFactorStep
                onSubmit={(data) => {
                  SubmitTwoFactor(data);
                }}
                goBack={() => goPrevious()}
                loading={loading}
              />
            ),
          ]}
        />
      </Styles.ImageBG>
    </Styles.Container>
  );
}

export default Login;
