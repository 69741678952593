import { Button, IconButton, makeStyles } from "@material-ui/core";
import { url } from "inspector";
import { IoCashOutline } from "react-icons/io5";
import styled from "styled-components";

import {
  ButtonContained,
  ButtonOutlined,
  FontStyles,
} from "../../../components";
import { fonts, SVG } from "../../../config";
import colors from "../../../config/colors";
const useStyles = makeStyles((theme) => {
  const { palette: colors, spacing } = theme;
  return {
    icon: {
      maxWidth: spacing(6.25),
      maxHeight: spacing(6.25),
      width: "100%",
      height: "auto",
      fill: "black",
      color: "black",
    },
  };
});

const Container = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    position: "relative",
    display: "flex",

    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: `calc(448px + ${$menuHeight}px)`,
    height: "100%",
    marginTop: `-${$menuHeight}px`,

    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "flex-end",
    width: "100%",
    [`@media (max-width:${1024 - 1}px)`]: {
      marginTop: 0,
      width: "100%",
      height: "100%",

      minHeight: "650px",
    },

    [`@media (max-width:${470 - 1}px)`]: {
      minHeight: "343px",
    },
  };
});

const BgVideoContainer = styled.div(({ theme, $menuHeight }) => {
  return {
    position: "relative",
    width: "100%",
    zIndex: 0,
    display: "flex",
    justifyContent: "center",
    height: theme.spacing(82.875),

    [`@media (max-width:${745 - 1}px)`]: {
      height: theme.spacing(72.5),
    },
    [`@media (max-width:${470 - 1}px)`]: {
      width: "100%",
      height: theme.spacing(34.375),
    },
    [`@media (min-width:${1440}px)`]: {
      height: theme.spacing(82.875),
    },
  };
});
const LandingText = styled.p(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    lineHeight: "127.5%",
    ...FontStyles.regular14,
    fontSize: spacing(4.375),
    maxWidth: "620px",
    padding: 0,
    margin: 0,
    marginBottom: spacing(2),
    color: "white",
    [`@media (max-width:${1024 - 1}px)`]: {
      marginBottom: spacing(1.5),
      fontSize: spacing(4.375),
      maxWidth: "317px",
    },

    [`@media (max-width:${470 - 1}px)`]: {
      fontSize: spacing(2.25),
      maxWidth: "197px",
    },
  };
});

const LandingFirst = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    zIndex: 3,
    paddingLeft: spacing(13.125),
    paddingBottom: spacing(9),
    width: "100%",
    maxWidth: "618px",
    [`@media (max-width:${1024 - 1}px)`]: {
      paddingLeft: spacing(6),
      paddingBottom: spacing(6),
      maxWidth: "42vw",
      minWidth: "318px",
      width: "100%",
    },

    [`@media (max-width:${470 - 1}px)`]: {
      paddingLeft: spacing(3.25),
      paddingBottom: spacing(4.25),
    },
  };
});

const BgVideo = styled.video(({ theme, $menuHeight }) => {
  return {
    position: "absolute",
    objectFit: "cover",
    width: "100%",
    height: "100%",
    top: 0,
    zIndex: 2,
    pointerEvents: "none",
    [`@media (max-width:${745 - 1}px)`]: {},
    [`@media (max-width:${470 - 1}px)`]: {},
    [`@media (min-width:${1440}px)`]: {},
  };
});

const YellowBall = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    position: "absolute",
    zIndex: -1,

    top: 0,
    right: 0,
    transform: "translate(90%, -50%)",
    borderRadius: "996px",
    width: "798.961px",
    height: "996px",
    filter: "blur(225px)",
    background: "#E7FF00E6",

    [`@media (max-width:${1024 - 1}px)`]: {
      borderRadius: "514.6px",
      width: "412.797px",
      height: "514.6px",
      filter: "blur(116.25px)",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      transform: "translate(50%, -50%)",
      borderRadius: "310px",
      width: "310px",
      height: "310px",
      filter: "blur(100px)",
    },
  };
});

const YellowBallTwo = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    position: "absolute",
    transform: "translate(-40%, 50%)",
    bottom: 0,
    left: 0,
    borderRadius: "631px",
    width: "631px",
    height: "601px",
    background: "#E7FF00E6",
    filter: "blur(300px)",
    zIndex: -1,

    [`@media (max-width:${1024 - 1}px)`]: {
      borderRadius: "310.517px",
      width: "326.017px",
      height: "310.517px",
      filter: "blur(155px)",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      transform: "translate(-105%, 40%)",
      borderRadius: "540.567px",
      width: "640.189px",
      height: "540.567px",
      filter: "blur(300px)",
    },
  };
});

const DashContainer = styled.div(({ theme, $image }) => {
  const { spacing } = theme;
  return {
    position: "relative",
    width: "100%",

    backgroundColor: "black",
    zIndex: 1,
    backgroundImage: `url(${$image})`,
    paddingBlock: spacing(9.25),
    paddingInline: spacing(12.5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    [`@media (max-width:${1024 - 1}px)`]: {
      padding: spacing(6),
    },
    [`@media (max-width:${581 - 1}px)`]: { padding: spacing(2.5) },
  };
});

const WhiteBox = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    borderRadius: "43.919px",
    background: "#F2F2F2",
    backdropFilter: "blur(18.822629928588867px)",

    width: "100%",
    zIndex: 3,
    padding: spacing(10),
    paddingBlock: spacing(6.25),
    flexDirection: "column",
    [`@media (max-width:${1024 - 1}px)`]: {
      paddingInline: `clamp(48px, 8vw, 90px)`,
    },
    [`@media (max-width:${581 - 1}px)`]: {
      paddingInline: `clamp(18px, 8vw, 32px)`,
      paddingBlock: `clamp(18px, 8vw, 32px)`,
    },
  };
});
const TitleBox = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",

    paddingBottom: spacing(4.5),
    [`@media (max-width:${1024 - 1}px)`]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: spacing(5),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      gap: spacing(3.5),
      paddingBottom: spacing(3),
    },
  };
});
const TitleConfig = styled.p(({ theme }) => {
  const { spacing } = theme;
  return {
    margin: 0,
    fontSize: spacing(4.375),
    fontFamily: fonts.heavy,
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(3.5),
    },
    [`@media (max-width:${581 - 1}px)`]: { fontSize: spacing(2) },
  };
});
const Back = styled(ButtonContained)(({ theme, $center, $image }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      paddingInline: spacing(4),
      paddingBlock: spacing(2.25),
      fontSize: spacing(2),
      borderRadius: spacing(2),
      marginTop: spacing(4),
      [`@media (max-width:${1024 - 1}px)`]: {
        borderRadius: spacing(3),
        paddingInline: spacing(5.25),
        paddingBlock: spacing(2.5),
        fontSize: spacing(2.5),
        marginLeft: 0,
      },
      [`@media (max-width:${581 - 1}px)`]: {
        borderRadius: spacing(1.5),
        paddingInline: spacing(2.25),
        paddingBlock: spacing(1.5),
        fontSize: spacing(1.5),
        marginTop: spacing(1.5),
      },
    },
  };
});

const OptionSecBox = styled.div(({ theme, $last }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    background:
      "linear-gradient(232deg, rgba(231, 255, 0, 0.14) 3.16%, rgba(231, 255, 0, 0.00) 40.87%), #050500",
    boxShadow: "10.0207px 15.03106px 53.86129px 0px rgba(0, 0, 0, 0.15)",
    padding: spacing(4.5),
    borderRadius: spacing(3.25),
    marginBottom: !$last && spacing(5.75),
    [`@media (max-width:${1024 - 1}px)`]: {
      justifyContent: "flex-start",
      gap: spacing(3),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      gap: "clamp(6px,3vw,14px)",
      marginBottom: !$last && spacing(2),
    },
  };
});

const OptionSecBoxContainer = styled.div(({ theme, $img }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    gap: "16px",
    alignItems: "center",
    [`@media (max-width:${1024 - 1}px)`]: { display: "none" },
    [`@media (max-width:${581 - 1}px)`]: {},
  };
});

const OptionSecBoxContainerMobile = styled.div(({ theme, secondary }) => {
  const { spacing } = theme;
  return {
    display: "none",
    flexDirection: "row",
    width: "100%",
    gap: "16px",
    alignItems: "center",
    [`@media (max-width:${1024 - 1}px)`]: {
      display: "flex",
      width: secondary ? "100%" : "fit-content",
      flexDirection: secondary && "column",
    },
    [`@media (max-width:${581 - 1}px)`]: {},
  };
});

const IconSecContainer = styled.div(({ theme, $img }) => {
  const { spacing } = theme;
  return {
    backgroundColor: "#E7FF00",
    borderRadius: "16px",
    display: "flex",
    flexDirection: "row",
    width: "41px",
    height: "41px",

    padding: spacing(1.1),
    alignItems: "center",
    justifyContent: "center",
    [`@media (max-width:${1024 - 1}px)`]: {
      width: "64px",
      height: "64px",
      padding: spacing(1.9),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      width: "33px",
      height: "33px",
      borderRadius: "9px",
      padding: spacing(0.9),
    },
  };
});

const TextSecContainer = styled.div(({ theme, end, row }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: end ? "row" : "column",
    width: "100%",
    gap: spacing(0.6),
    alignItems: end ? "center" : "flex-start",
    justifyContent: end ? "flex-end" : "center",
    [`@media (max-width:${1024 - 1}px)`]: {
      display: end && "none",
      flexDirection: row && "row ",
      justifyContent: row && "space-between",
    },
    [`@media (max-width:${581 - 1}px)`]: {},
  };
});

const TitleSecurity = styled.p(({ theme, $img }) => {
  const { spacing } = theme;
  return {
    color: "white",
    margin: 0,
    lineHeight: "normal",
    fontSize: spacing(2.25),
    fontFamily: fonts.heavy,
    [`@media (max-width:${1024 - 1}px)`]: { fontSize: spacing(3.25) },
    [`@media (max-width:${581 - 1}px)`]: { fontSize: spacing(1.75) },
  };
});
const TitleSecurityM = styled.p(({ theme, $img }) => {
  const { spacing } = theme;
  return {
    color: "white",
    margin: 0,
    lineHeight: "normal",
    fontSize: spacing(2),
    fontFamily: fonts.semibold,
    paddingTop: spacing(0.5),
    [`@media (max-width:${1024 - 1}px)`]: { fontSize: spacing(3) },
    [`@media (max-width:${581 - 1}px)`]: { fontSize: spacing(1.5) },
  };
});
const SubTitleSecurity = styled.p(({ theme, secondary }) => {
  const { spacing } = theme;
  return {
    color: "white",
    margin: 0,
    lineHeight: "normal",
    fontSize: spacing(2.25),
    fontFamily: fonts.regular,
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: secondary ? spacing(3.25) : spacing(2.25),
      textAlign: "end",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: secondary ? spacing(1.75) : spacing(1.1),
    },
  };
});

const MonthTitle = styled.p(({ theme, year }) => {
  const { spacing } = theme;
  return {
    margin: 0,
    fontFamily: fonts.heavy,
    fontSize: spacing(3.125),
    lineHeight: spacing(3.125) + "px",
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(5.875),
      lineHeight: spacing(5.875) + "px",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: spacing(3.125),
      lineHeight: spacing(3.125) + "px",
      display: year && "none",
    },
  };
});

const MonthContainer = styled.div(({ theme, $img }) => {
  const { spacing } = theme;
  return {
    flexDirection: "row",
    display: "flex",
    alignItems: "flex-end",
    paddingBottom: spacing(3.25),
    [`@media (max-width:${1024 - 1}px)`]: { paddingBottom: spacing(6.25) },
    [`@media (max-width:${581 - 1}px)`]: { paddingBottom: spacing(3.25) },
  };
});

const LineTitle = styled.div(({ theme, $img }) => {
  const { spacing } = theme;
  return {
    height: "1px",
    backgroundColor: "black",
    width: "100%",
    [`@media (max-width:${1024 - 1}px)`]: {},
    [`@media (max-width:${581 - 1}px)`]: {},
  };
});

const ButtonCashback = styled(ButtonContained)(({ theme, $img }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      paddingInline: spacing(1.1),
      paddingBlock: spacing(2.2),

      borderRadius: spacing(2),
      marginLeft: spacing(2),

      [`@media (max-width:${1024 - 1}px)`]: {
        paddingInline: spacing(1),
        paddingBlock: spacing(1.25),
        marginLeft: spacing(0),
        borderRadius: spacing(2),
      },
      [`@media (max-width:${581 - 1}px)`]: {
        paddingInline: spacing(1),
        paddingBlock: spacing(1.25),
        marginLeft: spacing(0),
        borderRadius: spacing(2),
      },
    },
  };
});
const ContentButtonCashback = styled.div(({ theme, $img }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      display: "flex",

      [`@media (max-width:${1024 - 1}px)`]: {},
      [`@media (max-width:${581 - 1}px)`]: {},
    },
  };
});

const ButtonCashbackMob = styled(ButtonContained)(({ theme, $img }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      display: "none",

      [`@media (max-width:${1024 - 1}px)`]: {
        display: "flex",
        paddingInline: spacing(1),
        paddingBlock: spacing(1.25),
        marginLeft: spacing(0),
        borderRadius: spacing(2),
      },
      [`@media (max-width:${581 - 1}px)`]: {
        paddingInline: spacing(1),
        paddingBlock: spacing(1.25),
        marginLeft: spacing(0),
        borderRadius: spacing(2),
      },
    },
  };
});

const IconCash = styled(IoCashOutline)(({ theme, $img }) => {
  const { spacing } = theme;
  return {
    fontSize: "28px",
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: "18px",
    },
  };
});
const CashText = styled.p(({ theme, year }) => {
  const { spacing } = theme;
  return {
    margin: 0,
    fontFamily: fonts.heavy,
    fontSize: spacing(1.9),

    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: spacing(1.25),
    },
  };
});
const Styles = {
  ButtonCashbackMob,
  ButtonCashback,
  ContentButtonCashback,
  IconCash,
  CashText,

  Container,
  BgVideoContainer,
  BgVideo,
  LandingFirst,
  LandingText,

  YellowBallTwo,
  YellowBall,

  WhiteBox,
  DashContainer,
  TitleBox,
  TitleConfig,
  useStyles,
  Back,

  OptionSecBox,
  OptionSecBoxContainer,
  IconSecContainer,
  TextSecContainer,
  TitleSecurity,
  SubTitleSecurity,
  MonthTitle,
  LineTitle,
  MonthContainer,
  OptionSecBoxContainerMobile,
  TitleSecurityM,
};

export default Styles;
