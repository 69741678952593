import React from "react";
import Styles from "../PaymentStyles";
import { FormFull } from "form-full";
import Bar from "../components/Bar";
import TableGrid from "../components/TableGrid";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  ThemeProvider,
  createTheme,
} from "@material-ui/core";
import texts from "../../../config/texts";
import { RadioList } from "../../../components";
import { LocalStorage } from "../../../utils";

interface PageProps {
  goNext: () => void;
  step: number;
  tableData: any;
  submit: any;
  setDeletePurchase: any;
}

function StepPaymentMethod({
  step,
  tableData,
  submit,
  goNext,
  setDeletePurchase,
}: PageProps): JSX.Element {
  const currentText = texts[LocalStorage.getItem("language") ?LocalStorage.getItem("language"): 'pt'].payment.method;
  const [value, setValue] = React.useState("");
  const [ref, setRef] = React.useState<any>(null);
  const [disabled, setDisabled] = React.useState<boolean>(true);

  const optionsRadio = [
    {
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Styles.IconContainer>
            <Styles.PixIcon />
          </Styles.IconContainer>
          <Styles.RadioText>{currentText.pix}</Styles.RadioText>
        </div>
      ),
      value: "pix",
    },
    {
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Styles.IconContainer>
            <Styles.CreditIcon />
          </Styles.IconContainer>
          <Styles.RadioText>{currentText.credit}</Styles.RadioText>
        </div>
      ),
      value: "credit",
    },
    {
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Styles.IconContainer>
            <Styles.CreditIcon />
          </Styles.IconContainer>
          <Styles.RadioText>{currentText.debit}</Styles.RadioText>
        </div>
      ),
      value: "debit",
    },
    {
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Styles.IconContainer>
            <Styles.BoletoIcon />
          </Styles.IconContainer>
          <Styles.RadioText>{currentText.boleto}</Styles.RadioText>
        </div>
      ),
      value: "boleto",
    },
  ];

  return (
    <>
      <FormFull formRef={setRef} onSubmit={() => {}}>
        <Styles.Content>
          <Styles.HeaderPayment>
            <Styles.Title>{currentText.payment}</Styles.Title>
            <Bar step={step} />
            <Styles.Title>{currentText.method}</Styles.Title>
          </Styles.HeaderPayment>

          <Styles.HeaderPaymentMobile>
            <Styles.Title>{currentText.method}</Styles.Title>
            <Styles.Title $bigger style={{ marginBlock: 20 }}>
              {currentText.method}
            </Styles.Title>
          </Styles.HeaderPaymentMobile>

          <RadioList
            name="method"
            value={value}
            options={optionsRadio}
            onChange={() => {
              setValue(value);
              setDisabled(false);
            }}
            checkedIcon={<Styles.RadioChecked />}
          />

          <TableGrid data={tableData} setDeletePurchase={setDeletePurchase} />
        </Styles.Content>
        <Styles.StyledButton
          name="continue"
          fullWidth={false}
          disabled={disabled}
          onClick={() => {
            const data = {
              method: ref.getValue("method"),
            };
            submit(data, goNext());
          }}
        >
          {currentText.continueButton}
        </Styles.StyledButton>
      </FormFull>
    </>
  );
}

export default StepPaymentMethod;
