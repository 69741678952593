import { StrongText } from "../../components";

const baseRequiredMessage = "Campo obrigatório.";
// const baseRequiredFieldsDescription = "* Preenchimento obrigatório";

const baseReload = {
  message: "Não há dados para exibição.",
  buttonLabel: "Recarregar",
};

const ptBr = {
  months: [
    { initials: "Jan", fullName: "Janeiro" },
    { initials: "Fev", fullName: "Fevereiro" },
    { initials: "Mar", fullName: "Março" },
    { initials: "Abr", fullName: "Abril" },
    { initials: "Mai", fullName: "Maio" },
    { initials: "Jun", fullName: "Junho" },
    { initials: "Jul", fullName: "Julho" },
    { initials: "Ago", fullName: "Agosto" },
    { initials: "Set", fullName: "Setembro" },
    { initials: "Out", fullName: "Outubro" },
    { initials: "Nov", fullName: "Novembro" },
    { initials: "Dez", fullName: "Dezembro" },
  ],
  version: "v ",
  modals: {
    WarningDash: {
      title: "Saque Indisponível",
      content:
        "Saque indisponível no momento. assim que liberado iremos lhe notificar",
    },
    success: {
      buttonLabel: "OK",
    },
    errors: {
      buttonLabel: "Ok",
    },
  },
  home: {
    modal: {
      title: "Atenção",
      content: (
        <>
          A <StrongText echo>BIOCOIN (BIOC)</StrongText> é uma criptomoeda
          inovadora e sustentável projetada para financiar projetos de
          preservação e restauração de ecossistemas florestais em todo o mundo.{" "}
          <br /> <br />
          Investimentos próprios do objetivo da criptomoeda (50%): Metade dos
          recursos captados será alocada diretamente aos projetos de conservação
          e reflorestamento, bem como ao desenvolvimento de tecnologias e
          práticas sustentáveis. Esse investimento garantirá o progresso e a
          implementação dos objetivos centrais da BioCoin. <br />
          <br />
          Reserva (20%): Um fundo de reserva será estabelecido para assegurar a
          estabilidade financeira do projeto e permitir o enfrentamento de
          imprevistos ou mudanças nas condições do mercado. Esse fundo será
          usado para cobrir despesas operacionais e garantir a continuidade do
          projeto. <br />
          <br />
          Marketing (15%): Os recursos destinados ao marketing serão utilizados
          para promover a BioCoin, aumentar a conscientização sobre a
          criptomoeda e seus objetivos, e atrair novos investidores e parceiros.
          Ações de marketing incluirão campanhas publicitárias, eventos,
          divulgação em mídias sociais e parcerias estratégicas. <br />
          <br />O ICO terá duração de 12 meses, com um plano de valorização do
          token estabelecido para aumentar o preço a cada mês. A tabela a seguir
          detalha o calendário de valorização do token durante o período do ICO:
          Ao longo dos 12 meses do ICO, o preço do token BIOCOIN aumentará
          gradualmente de R$ 0,25 centavos para R$ 1,00, incentivando a
          participação antecipada dos investidores e recompensando aqueles que
          apoiarem o projeto desde o início.
        </>
      ),
      close: "Fechar",
    },
    landingText: (
      <>
        <StrongText>BioCoin:</StrongText> A sua <br />{" "}
        <StrongText>Moeda Digital</StrongText> para um Planeta{" "}
        <StrongText>mais Verde</StrongText>
      </>
    ),
    landingButtons: ["Impacto", "Valorização", "Inovação"],
    register: "Registre-se",
    buy: "Comprar",
    whitepaper: "Whitepaper",
    areas: "Áreas adquiridas ",
    areasBuy: "Área com opção de compra",
    aboutTitle: "Sobre Biocoin",
    about: (
      <>
        A <StrongText>Biocoin</StrongText> é uma criptomoeda sustentável e
        inovadora, concebida para financiar a preservação e restauração dos
        principais biomas e ecossistemas florestais brasileiros. Com a
        singularidade de ser a primeira criptomoeda lastreada em áreas
        florestais, a <StrongText>Biocoin</StrongText> traz para o mundo dos
        investimentos uma maneira de contribuir ativamente para a preservação
        ambiental, ao mesmo tempo em que promove uma oportunidade de
        investimento inteligente.
      </>
    ),
    whyTitle: "Porque Biocoin ",
    why: (
      <>
        A <StrongText echo>escolha certa</StrongText> para um{" "}
        <StrongText echo>Investimento Verde</StrongText> e{" "}
        <StrongText echo>Rentável</StrongText>
      </>
    ),
    mission: (
      <>
        Os Desafios da <StrongText>Biocoin</StrongText>
      </>
    ),
    missionSub: "DESAFIOS E MISSÃO ",
    more: "Saiba Mais",
    securityTitle: "Segurança e Transparência da",
    security: (
      <>
        A <StrongText>Biocoin</StrongText>, utilizando a plataforma blockchain
        Binance Smart Chain, garante a máxima segurança e transparência em todas
        as operações. Esta blockchain, conhecida por sua alta eficiência e
        confiabilidade, assegura proteção robusta contra fraudes, possibilitando
        o rastreamento de cada transação desde sua origem até o destinatário
        final. Com isso, oferecemos aos nossos investidores uma transparência
        completa e inquestionável.
      </>
    ),
    roadmap: {
      title: "ROADMAP BIOCOIN",
      t1: "Trimestre 1 (2023 Q4)",
      t2: "Trimestre 2 (2024 Q1)",
      t3: "Trimestre 3 (2024 Q2)",
      maps: [
        [
          {
            title: "Lançamento da Biocoin",
            text: "Início da venda de tokens Biocoin, abrindo um novo caminho para investimentos sustentáveis.",
          },
          {
            title: "Compra das Primeiras Áreas Florestais",
            text: "Início da venda de tokens Biocoin, abrindo um novo caminho para investimentos sustentáveis.",
          },
          {
            title: "Parcerias com Organizações Ambientais",
            text: "Início da venda de tokens Biocoin, abrindo um novo caminho para investimentos sustentáveis.",
          },
        ],
        [
          {
            title: "Listagem da Biocoin em Exchanges",
            text: "Listagem da Biocoin em várias exchanges de criptomoedas, permitindo a negociação secundária e ampliando o acesso à moeda.",
          },
          {
            title: "Lançamento da Primeira Coleção de NFTs",
            text: "Lançamento da primeira série de NFTs temáticos, relacionados ao bioma apoiado no primeiro trimestre.",
          },
          {
            title: "Relatório de Impacto Ambiental",
            text: "Publicação do primeiro relatório de impacto, mostrando a diferença que a Biocoin está fazendo nos biomas apoiados.",
          },
        ],
        [
          {
            title: "Lançamento da Segunda Coleção de NFTs",
            text: "Lançamento da segunda série de NFTs temáticos, desta vez representando um novo bioma apoiado.",
          },
          {
            title: "Início do Segundo Projeto de Conservação",
            text: "Início do financiamento do segundo projeto de conservação de bioma.",
          },
          {
            title: "Atualizações da Plataforma",
            text: "Implementação de melhorias e novas funcionalidades na plataforma Biocoin, com base nos feedbacks dos usuários e tendências do mercado de criptomoedas.",
          },
        ],
      ],
    },

    exchanges: "Exchanges",
    partners: "Parceiros",
    bullets: [
      {
        title: "Desmatamento",
        description: (
          <>
            O desmatamento na Amazônia tem alcançado níveis alarmantes, levando
            à perda de biodiversidade, emissões de carbono e perturbação das
            comunidades indígenas. A <StrongText echo>Biocoin</StrongText>{" "}
            financiará projetos que buscam combater essa prática, promovendo o
            uso sustentável das florestas.
          </>
        ),
      },
      {
        title: "Incêndios",
        description: (
          <>
            O Pantanal tem enfrentado um aumento preocupante no número de
            incêndios, destruindo habitats, ameaçando a vida selvagem e
            agravando a crise climática. A <StrongText echo>Biocoin</StrongText>{" "}
            apoiará ações para prevenir e combater incêndios, protegendo este
            bioma único.
          </>
        ),
      },
      {
        title: "Fragmentação",
        description: (
          <>
            A Mata Atlântica, já altamente fragmentada devido ao desenvolvimento
            urbano e à agricultura, enfrenta a perda de habitats e a extinção de
            espécies. A <StrongText echo>Biocoin</StrongText> se compromete a
            financiar projetos que visam a restaurar e reconectar habitats,
            salvaguardando a rica biodiversidade deste bioma.
          </>
        ),
      },
      {
        title: "Agricultura Sustentável",
        description: (
          <>
            O Cerrado, importante para a segurança hídrica do Brasil, está sob
            pressão devido à expansão da agricultura insustentável. A{" "}
            <StrongText echo>Biocoin</StrongText> contribuirá para projetos que
            promovem práticas agrícolas sustentáveis, protegendo assim os
            recursos hídricos e a biodiversidade deste bioma.
          </>
        ),
      },
    ],
    whyList: [
      {
        title: "Impacto",
        subtitle:
          "Ao comprar Biocoin, você está contribuindo diretamente para a preservação e recuperação dos ecossistemas brasileiros. Cada Biocoin representa um investimento real na sustentabilidade do nosso planeta, permitindo que você faça a diferença de maneira tangível e significativa.",
      },
      {
        title: "Valorização",
        subtitle:
          "A Biocoin une duas tendências de crescimento: a crescente popularidade das criptomoedas e a crescente necessidade de soluções sustentáveis. Isso cria um forte potencial de valorização para a Biocoin, tornando-a um investimento atraente para aqueles que procuram oportunidades inovadoras e significativas.",
      },
      {
        title: "Inovação",
        subtitle:
          "O blockchain público proporciona registros visíveis e rastreáveis, aumentando a confiança entre consumidores e comerciantes e prevenindo manipulação de informações.",
      },
    ],
    purpose: {
      title: (
        <>
          O propósito <br /> <StrongText>da Biocoin</StrongText>
        </>
      ),

      about: (
        <>
          A <StrongText echo>Biocoin</StrongText> nasce com o propósito de criar
          uma nova forma de financiamento para a preservação e restauração dos
          biomas brasileiros, através de uma criptomoeda sustentável e
          inovadora. Cada <StrongText echo>Biocoin</StrongText> representa um
          investimento direto em áreas florestais, garantindo uma contribuição
          tangível para a sustentabilidade ambiental. Ao mesmo tempo, oferecemos
          aos investidores uma maneira segura e transparente de se envolverem na
          economia digital, enquanto ajudam a proteger nossos ecossistemas
          vitais para as gerações futuras. Em essência, a{" "}
          <StrongText echo>Biocoin</StrongText> é uma ponte entre a consciência
          ambiental e um investimento verde e sustentável, permitindo a todos
          fazerem parte da solução para os desafios ambientais de hoje.
        </>
      ),
    },
  },
  pagesConfig: { about: "Sobre" },
  header: {},
  login: {
    noAcount: "Ainda não tem conta?",
    registry: "Registre-se",
    authValidation: "O código deve conter 6 dígitos",
    auth: "Código de Autenticação",
    invalidLogin:
      "E-mail e/ou senha informados estão incorretos. Tente novamente ou clique em 'Esqueci a senha'.",
    title: "Entrar",
    titleTwo: "Autenticação de 2 Fatores",
    remember: "Lembrar-me",
    forgotPass: "Esqueci a senha",
    enter: "Entrar",
    continue: "Continuar",
    email: "E-Mail",
    password: "Senha",

    passwordMessage: baseRequiredMessage,
    user: "Usuário",
    userMessage: baseRequiredMessage,
  },
  forgetPassword: {
    title: "Esqueceu Senha",
    titleStepOne: "Esqueci a Senha",
    titleStepTwo: "ESQUECEU SUA SENHA",
    titleStepTree: "ESQUECEU SUA SENHA",
    titleStepFour: "Senha redefinida com sucesso",
    subTitle: [
      "Será enviado um Token em seu e-mail para você redefinir sua senha.",
      "Informe abaixo o código que enviamos para seu email cadastrado:",
      "Crie sua nova senha informando os dados abaixo:",
      "Sua senha foi redefinida com sucesso, vamos redirecionar você para a tela de login ou você pode clicar para voltar.",
      "Senha redefinida",
    ],
    continue: [
      "Enviar Token",
      "VALIDAR CÓDIGO",
      "Redefinir Senha",
      "Fazer login",
    ],
    resendCode: "Reenviar código de confirmação",
    forgotPassword: "Esqueci minha senha",
    email: "E-mail",
    enter: "Entrar",
    password: "NOVA SENHA",
    newPassword: "Nova Senha",
    onBackMenu: "Voltar para o login",
    onBack: "Voltar",
    cancel: "Cancelar",
    resend: "Reenviar e-mail",
    onNext: "Avançar",
    nwPassword: "Confirmar Senha",
    passwordMessage: baseRequiredMessage,
    user: "EMAIL",
    userMessage: baseRequiredMessage,
    modal: {
      title: "NOVA SENHA CADASTRADA",
      content:
        "Você criou sua nova senha com sucesso! Acesse sua conta agora mesmo a partir da tela de Login.",
      button: "IR PARA LOGIN",
    },
    countdown: {
      limitTime: "Tempo limite para validação do Token⠀",
      resendCode: "Reenviar Código",
    },
  },
  actionButton: {
    register: "Registre-se",
    enter: "Entrar",
    out: "Sair",
    newsletterText: "Inscreve-se e receba nossas newsletter ",
    send: "Enviar",
    buy: "Comprar",
    transfer: "Transferir",
    nfts: "Ver NFTs",
    downloadHere: "Baixe aqui",
    whitepaper: "Whitepaper",
    area: "Áreas adquiridas",
    areasBuy: "Área com opção de compra",
    back: "Voltar",
    save: "Salvar",
    cancel: "Cancelar",
    reRegistry: "Redefinir Cadastro",
    close: "Fechar",
    empty: "Vazio",
  },
  settings: {
    userSettings: {
      users: "Usuários",
      userRegister: {
        userRegister: "Cadastro de Usuário",
        fullName: "Nome Completo",
        fullNameMessage: baseRequiredMessage,
        email: "E-mail",
        emailMessage: baseRequiredMessage,
        password: "Senha",
        passwordMessage: baseRequiredMessage,
        passwordConfirm: "Confirmar Senha",
        passwordConfirmMessage: baseRequiredMessage,
        userAdmin: "Usuário Administrador?",
        register: "Cadastrar",
        successResponse: "Usuário cadastrado com sucesso.",
      },
    },
    changePassword: {
      changePassword: "Alterar Senha",
      passwordChange: "Mudança de Senha",
      successResponse: "Senha alterada com sucesso.",
      actualPassword: "Senha Atual",
      newPassword: "Nova Senha",
      passwordConfirmation: "Confirmação de Nova Senha",
      passwordMessage: baseRequiredMessage,
      confirm: "Confirmar",
    },
  },

  register: {
    have: ["Já possui uma conta?", "Entre agora"],
    declaration: ["Declaro que li e", "compreendi os", "termos de uso."],
    policy: ["Concordo com a", "política de privacidade."],
    title: "Registre-se",
    fullName: "Nome Completo",
    password: "Senha",
    passwordMessage: baseRequiredMessage,
    fullNameMessage: baseRequiredMessage,
    email: "E-mail",
    emailMessage: baseRequiredMessage,
    phone: "Telefone",
    phoneMessage: baseRequiredMessage,
    cpf: "CPF",
    cpfMessage: baseRequiredMessage,
    birthDate: "Data do nascimento",
    birthDatemMessage: baseRequiredMessage,
    news: "Aceito receber novidades e noticias.",
    register: "Criar Conta",
    modal: {
      title: "USUÁRIO CADASTRADO",
      content:
        "Você criou sua nova conta com sucesso! Acesse agora mesmo a partir da tela de Login.",
      button: "IR PARA LOGIN",
    },
  },

  termsOfUse: {
    title: "Termos de uso",
    text: "Em breve mais informações",
  },

  privacyPolicy: {
    title: "Política de Privacidade",
    text: "Em breve mais informações",
  },
  footer: {
    menu: [
      "Sobre Nós",
      "Perguntas Frequentes",
      "Blog",
      "Termos de Uso",
      "Política de Privacidade",
    ],
    conected: "Fique conectado",
    modalTitle: "Cadastro Newsletter",
    modalText: "Seu e-mail foi cadastrado com sucesso!",
    emailPlaceholder: "nome@email.com",
  },
  about: {
    title: "Sobre",
    boxData: [
      {
        title: "Lastro em Áreas Florestais",
        text: "A BioCoin é a primeira criptomoeda do mundo a ser lastreada em áreas florestais. Cada token representa uma fração de floresta real, o que oferece uma maior estabilidade para a moeda. Diferentemente de outras criptomoedas que não possuem lastro em ativos físicos, a Biocoin, ao estar vinculada a um ativo real e tangível, pode proporcionar uma maior confiança e previsibilidade para os investidores, atenuando a volatilidade comumente associada às criptomoedas.",
      },
      {
        title: "Contribuição Direta para a Preservação Ambiental",
        text: "Ao comprar BioCoins, os investidores estão diretamente financiando projetos de preservação e restauração dos principais biomas e ecossistemas florestais brasileiros como a Mata Atlântica, Amazônia Cerrado e outros, promovendo ações de preservação ambiental de maneira efetiva e contínua.",
      },
      {
        title: "Oportunidade de Investimento Sustentável:",
        text: "A Biocoin oferece uma oportunidade para investidores conscientes se beneficiarem financeiramente, enquanto contribuem para um futuro mais sustentável e responsável.",
      },
      {
        title: "Inovação com Uso de NFTs",
        text: "A Biocoin está inovando ao planejar o lançamento de coleções temáticas de NFTs (Tokens Não Fungíveis) que representam os diversos biomas brasileiros. Isso não apenas cria uma conexão ainda mais próxima entre o investidor e a preservação ambiental, mas também torna cada investimento único e significativo. Além disso, 50% de tudo que for arrecadado com as vendas primárias desses NFTs e das taxas de transações subsequentes serão diretamente investidos em projetos de preservação e restauração desses biomas, garantindo um impacto ambiental ainda maior.",
      },
      {
        title: "Potencial de Valorização",
        text: "Em um mundo cada vez mais consciente dos impactos ambientais e da importância da sustentabilidade, o tema da preservação florestal está muito em voga. Isso se reflete no potencial de valorização da Biocoin. Ao unir a tendência crescente das criptomoedas com a necessidade urgente de preservar nossos ecossistemas, a Biocoin surge como um ativo digital de alta relevância e potencial de valorização. A demanda por investimentos que gerem impactos positivos no meio ambiente é alta e só tende a aumentar, o que pode impulsionar a valorização da Biocoin no mercado.",
      },
      {
        title: "Parcerias com Organizações Ambientais",
        text: "A Biocoin não está sozinha em sua missão de preservação ambiental. Planejamos estabelecer parcerias com organizações que compartilham de propósitos similares. Trabalhando juntos, podemos aumentar a eficácia dos projetos de preservação financiados pela Biocoin. Essas parcerias não apenas amplificam o impacto positivo na preservação dos biomas, mas também proporcionam um nível adicional de credibilidade e transparência à Biocoin, fortalecendo a confiança dos investidores.",
      },
      {
        title: "Lastro em Áreas Florestais",
        text: "A BioCoin é a primeira criptomoeda do mundo a ser lastreada em áreas florestais. Cada token representa uma fração de floresta real, o que oferece uma maior estabilidade para a moeda. Diferentemente de outras criptomoedas que não possuem lastro em ativos físicos, a Biocoin, ao estar vinculada a um ativo real e tangível, pode proporcionar uma maior confiança e previsibilidade para os investidores, atenuando a volatilidade comumente associada às criptomoedas.",
      },
      {
        title: "Contribuição Direta para a Preservação Ambiental",
        text: "Ao comprar BioCoins, os investidores estão diretamente financiando projetos de preservação e restauração dos principais biomas e ecossistemas florestais brasileiros como a Mata Atlântica, Amazônia Cerrado e outros, promovendo ações de preservação ambiental de maneira efetiva e contínua.",
      },
      {
        title: "Oportunidade de Investimento Sustentável:",
        text: "A Biocoin oferece uma oportunidade para investidores conscientes se beneficiarem financeiramente, enquanto contribuem para um futuro mais sustentável e responsável.",
      },
      {
        title: "Inovação com Uso de NFTs",
        text: "A Biocoin está inovando ao planejar o lançamento de coleções temáticas de NFTs (Tokens Não Fungíveis) que representam os diversos biomas brasileiros. Isso não apenas cria uma conexão ainda mais próxima entre o investidor e a preservação ambiental, mas também torna cada investimento único e significativo. Além disso, 50% de tudo que for arrecadado com as vendas primárias desses NFTs e das taxas de transações subsequentes serão diretamente investidos em projetos de preservação e restauração desses biomas, garantindo um impacto ambiental ainda maior.",
      },
      {
        title: "Potencial de Valorização",
        text: "Em um mundo cada vez mais consciente dos impactos ambientais e da importância da sustentabilidade, o tema da preservação florestal está muito em voga. Isso se reflete no potencial de valorização da Biocoin. Ao unir a tendência crescente das criptomoedas com a necessidade urgente de preservar nossos ecossistemas, a Biocoin surge como um ativo digital de alta relevância e potencial de valorização. A demanda por investimentos que gerem impactos positivos no meio ambiente é alta e só tende a aumentar, o que pode impulsionar a valorização da Biocoin no mercado.",
      },
      {
        title: "Parcerias com Organizações Ambientais",
        text: "A Biocoin não está sozinha em sua missão de preservação ambiental. Planejamos estabelecer parcerias com organizações que compartilham de propósitos similares. Trabalhando juntos, podemos aumentar a eficácia dos projetos de preservação financiados pela Biocoin. Essas parcerias não apenas amplificam o impacto positivo na preservação dos biomas, mas também proporcionam um nível adicional de credibilidade e transparência à Biocoin, fortalecendo a confiança dos investidores.",
      },
    ],
    differentialTitle: "Diferenciais Biocoin",
    differential: (
      <>
        A <StrongText echo> &nbsp;escolha certa</StrongText> &nbsp;para um
        <StrongText echo> &nbsp;Investimento Verde</StrongText> &nbsp;e
        <StrongText echo> &nbsp;Rentável</StrongText>
      </>
    ),
    nfts: {
      title: ["Compre", "Uma", "NFT", "exclusiva", "e ganhe"],
      content: (
        <>
          Confira nossa <br /> coleção de{" "}
          <StrongText echo={true}>NFTs</StrongText>
        </>
      ),
    },
    whiteapaper: {
      title: "Baixe nosso White Paper",
      content:
        "Nosso White Paper está disponível para leitura e fornece informações detalhadas sobre o nosso projeto, incluindo sua finalidade, tecnologia, recursos e como ele planeja alcançar seus objetivos.",
    },
  },
  payment: {
    bar: {
      title: ["Primeiro Passo", "Segundo Passo", "Terceiro Passo"],
      subtitle: ["Informações", "Meio de Pagamento", "Pagamento"],
    },
    buy: {
      buyBiocoin: ["Compre", "Biocoin"],
      price: "Cotação para compra",
      howMuch: "Defina quanto gostaria de comprar",
      otherValue: "Outro valor",
      required: baseRequiredMessage,
      toReceive: "Você vai receber:",
      buyButton: "Comprar",
      minimal: "Valor mínimo de 5 reais",
    },
    information: {
      title: "Pagamento",
      titleMobile: "Informações",
      subtitle: "Nome e Informações",
      firstname: "Primeiro Nome",
      lastname: "Sobrenome",
      contact: "Contato",
      required: baseRequiredMessage,
      continueButton: "Continue",
    },
    method: {
      payment: "Pagamento",
      method: "Meio de Pagamento",
      pix: "PIX",
      credit: "Cartão de Crédito",
      debit: "Cartão de Débito",
      boleto: "Boleto",
      continueButton: "Continue",
    },
    final: {
      thanks: "OBRIGADO",
      wrong: "Pagamento inválido",
      proccess: "Seu pagamento está sendo processado.",
      tryAgain: "Seu pagamento está inválido, tente novamente.",
      closeButton: "Fechar",
    },
    table: {
      requestNumber: "N° Pedido",
      name: "Nome",
      totalBiocoin: "Total Biocoin",
      value: "Valor",
      totalPay: "Total a Pagar",
      seePurchase: "Ver compra",
      delete: "Excluir",
      modalTitle: "Excluir compra",
      modalText: "Você deseja excluir esta compra?",
      no: "Não",
      close: "Fechar",
    },
  },
  tech: {
    title: "Tecnologia",
    whysIcons: [
      {
        title: "Impacto",
        subtitle:
          "Ao comprar Biocoin, você está contribuindo diretamente para a preservação e recuperação dos ecossistemas brasileiros. Cada Biocoin representa um investimento real na sustentabilidade do nosso planeta, permitindo que você faça a diferença de maneira tangível e significativa.",
      },
      {
        title: "Valorização",
        subtitle:
          "A Biocoin une duas tendências de crescimento: a crescente popularidade das criptomoedas e a crescente necessidade de soluções sustentáveis. Isso cria um forte potencial de valorização para a Biocoin, tornando-a um investimento atraente para aqueles que procuram oportunidades inovadoras e significativas.",
      },
    ],
    biocoin: (
      <>
        A <StrongText echo>Biocoin</StrongText> combina a potência da tecnologia
        blockchain com a paixão pela preservação ambiental para criar uma
        plataforma de investimento única e inovadora. Nossa plataforma utiliza a
        Binance Smart Chain, uma das blockchains mais eficientes e seguras do
        mundo. Isso nos permite oferecer uma experiência de investimento segura,
        transparente e rápida. Cada transação{" "}
        <StrongText echo>Biocoin</StrongText> pode ser rastreada até sua origem,
        proporcionando a máxima transparência para nossos investidores. Além
        disso, a <StrongText echo>Biocoin</StrongText> também emprega contratos
        inteligentes para garantir a segurança e eficiência de cada operação.
        Através desses contratos, garantimos que cada
        <StrongText echo>Biocoin</StrongText> comprada contribui diretamente
        para a preservação e restauração dos biomas brasileiros. A inovação está
        no cerne da
        <StrongText echo>Biocoin</StrongText>, e estamos constantemente buscando
        novas maneiras de melhorar a eficiência e eficácia de nossa plataforma.
        Ao investir na <StrongText echo>Biocoin</StrongText>, você não está
        apenas contribuindo para um futuro mais sustentável, mas também está
        fazendo parte da vanguarda da tecnologia blockchain.
      </>
    ),
    plantTitle: (
      <>
        Lastro Florestal: <br />A Solidez da Biocoin
      </>
    ),
    plantContent: (
      <>
        A <StrongText echo>Biocoin</StrongText> é pioneira ao estabelecer seu
        lastro em áreas florestais, sendo a primeira criptomoeda do mundo a
        adotar este modelo. Ao adquirir a <StrongText echo>Biocoin</StrongText>,
        você está diretamente contribuindo para a proteção e restauração de
        biomas importantes no Brasil, um compromisso tangível que adiciona
        estabilidade e sustentabilidade ao seu investimento.
      </>
    ),
    token: "CONTRATO DO TOKEN",
    tokenContent: (
      <>
        Verifique o contrato do token <StrongText echo>Biocoin</StrongText> na
        BSCscan para um rastreamento detalhado das transações
      </>
    ),
    paper: "WHITEPAPER",
    paperContent: (
      <>
        Baixe nosso Whitepaper e descubra mais sobre a visão e tecnologia da{" "}
        <StrongText>Biocoin</StrongText>.
      </>
    ),
  },
  project: {
    title: "O Projeto",
    landing: {
      title: (
        <>
          <StrongText>BioCoin:</StrongText> A sua Moeda Digital para um Planeta
          mais Verde
        </>
      ),
      content: (
        <>
          Adquira seus Tokens <StrongText>BioCoin</StrongText> e Comece a Fazer
          a Diferença para os Biomas Brasileiros Hoje
        </>
      ),
      buttons: ["Preservação", "Reflorestamento", "Educação Ambiental"],
    },
    bullets: [
      {
        title: "10.000Km²",
        text: "É a área desmatada da Amazônia entre agosto/22 à Julho/2023. Aumento de 50% em comparação ao mesmo período do ano anterior",
      },
      {
        title: "12%",
        text: "É o que resta da floresta original da Mata Atlântica",
      },
      {
        title: "+1.000",
        text: "É o numero de espécies ameaçadas de extinção no Brasil.",
      },
      {
        title: "50%",
        text: "É o percentual que já foi perdido do Cerrado brasileiro em razão do desmatamento",
      },
    ],
    problem: {
      title: (
        <>
          Compre <span style={{ color: "#D3D527" }}>Biocoin</span> e faça <br />
          parte dessa <span style={{ color: " #D3D527" }}>Comunidade</span>
        </>
      ),
      content:
        "Comece hoje mesmo sua jornada em direção a um futuro mais sustentável",
    },
    buy: {
      title: (
        <>
          Compre{" "}
          <StrongText color="#D3D527" echo>
            Biocoin
          </StrongText>{" "}
          e faça <br /> parte dessa{" "}
          <StrongText color="#D3D527" echo>
            Comunidade
          </StrongText>
        </>
      ),
      content: (
        <>
          Comece hoje mesmo sua jornada em direção a um <br /> futuro mais
          sustentável
        </>
      ),
    },
  },
  faq: {
    list: [
      {
        title: "O que é a Biocoin?",
        description: (
          <>
            A <StrongText>Biocoin</StrongText> é uma criptomoeda inovadora e
            sustentável projetada para financiar projetos de preservação e
            restauração dos principais biomas brasileiros. É a primeira
            criptomoeda do mundo com lastro em áreas florestais.
          </>
        ),
      },
      {
        title: "Como a Biocoin financia a preservação ambiental?",
        description: (
          <>
            Cada <StrongText>Biocoin</StrongText> comprada contribui diretamente
            para a proteção e restauração dos biomas brasileiros. As vendas de
            tokens e NFTs da <StrongText>Biocoin</StrongText> financiam projetos
            de conservação ambiental.
          </>
        ),
      },
      {
        title: "O que são os NFTs da Biocoin?",
        description: (
          <>
            Os NFTs da <StrongText>Biocoin</StrongText> são ativos digitais
            únicos que representam diferentes aspectos dos biomas brasileiros.
            Eles são uma forma divertida e interativa de apoiar a preservação
            ambiental.
          </>
        ),
      },
      {
        title: "Como a Biocoin oferece estabilidade para os investidores?",
        description: (
          <>
            A <StrongText>Biocoin</StrongText> é lastreada em áreas florestais,
            que agem como uma âncora de estabilidade para o valor do token. Isso
            proporciona uma maior confiabilidade para os investidores.
          </>
        ),
      },
      {
        title: "Onde posso comprar a Biocoin?",
        description: (
          <>
            A <StrongText>Biocoin</StrongText> pode ser adquirida em nosso site
            oficial e em algumas exchanges de criptomoedas. Fique atento às
            nossas redes sociais para atualizações sobre novas listagens.
          </>
        ),
      },
      {
        title: "Como a Biocoin utiliza a tecnologia blockchain?",
        description: (
          <>
            A <StrongText>Biocoin</StrongText> utiliza a Binance Smart Chain,
            uma das blockchains mais seguras e eficientes do mundo. Isso garante
            a transparência e a segurança de todas as transações da{" "}
            <StrongText>Biocoin</StrongText>.
          </>
        ),
      },
      {
        title: "Como a Biocoin beneficia o meio ambiente?",
        description: (
          <>
            Cada <StrongText>Biocoin</StrongText> comprada financia diretamente
            projetos de preservação e restauração ambiental. Ao comprar{" "}
            <StrongText>Biocoins</StrongText>, você está contribuindo para a
            proteção dos biomas brasileiros.
          </>
        ),
      },
      {
        title: "Quais são os próximos passos para a Biocoin?",
        description: (
          <>
            A <StrongText>Biocoin</StrongText> tem um roadmap abrangente para os
            próximos trimestres, que inclui a aquisição das primeiras áreas
            florestais, a listagem em novas exchanges e o lançamento de novas
            coleções de NFTs.
          </>
        ),
      },
      {
        title: "Como posso me envolver mais com a Biocoin?",
        description: (
          <>
            Você pode se envolver de várias maneiras! Além de comprar{" "}
            <StrongText>Biocoins</StrongText>, você pode participar de nossas
            iniciativas de comunidade, comprar NFTs temáticos e compartilhar
            sobre a <StrongText>Biocoin</StrongText> em suas redes sociais.
            Juntos, podemos fazer uma grande diferença para os biomas
            brasileiros!
          </>
        ),
      },
    ],
    title: (
      <>
        Retire sua dúvida, <br /> converse com a gente.
      </>
    ),
    question: " Ainda tem dúvidas?",
    chat: "Envia uma mensagem",
    startChat: "Enviar e-mail",
  },
  blog: {
    title: (
      <>
        Leia nosso Blog <br />
        e fique por dentro de tudo <br />
        do mundo das Criptos.
      </>
    ),
    more: "Mostrar mais",
  },
  config: {
    required: baseRequiredMessage,
    title: "Configuração",
    dataSucess: "Dados atualizados com sucessos!",
    emailSucess:
      "Sucesso! Seu e-mail foi enviado. Verifique sua caixa de entrada para atualizações.",
    refundSucess:
      "Sucesso! Seu estorno foi enviado para analise.",
    samePassword: "As senhas devem ser iguais",
    userReset: "Usuário redefinido com sucesso!",
    data: {
      title: "Configurações de Dados",
    },
    image: {
      title: "Nova Imagem:",
    },
    list: [
      { title: "Segurança da Conta", desc: "Confira os itens de segurança." },
      {
        title: "Alterar Dados",
        desc: "Alterar nome e telefone.",
      },
      {
        title: "Alterar Senha",
        desc: "Redefinir sua  senha atual.",
      },
      {
        title: "Redefinir Cadastro",
        desc: "Resetar senha numérica e dois fatores.",
      },
      {
        title: "Informações de Pagamento",
        desc: "Administre suas informações de pagamento.",
      },
    ],
    securityOptions: [
      {
        title: "Validar E-mail",
        sub: "Através de link enviado para sua conta de e-mail.",
        actionLabel: "Enviar E-mail",
        modal: {
          title: "Validar E-mail",
          sub: "Um e-mail será encaminhado para sua caixa de entrada. Por favor, verifique também a pasta de spam e, se necessário, marque-o como seguro.",
          action: "Enviar E-mail",
        },
      },
      {
        title: "Enviar Documento com Foto",
        sub: "Envie uma imagem legível de um de seus documentos com foto.",
        actionLabel: "Enviar Documento",
        modal: {
          alert: "Documentos já foram aprovados",
        },
      },
      {
        title: "Enviar Selfie de Segurança",
        sub: "Faça uma selfie segurando um documento na sua mão.",
        actionLabel: "Enviar Selfie",
        modal: {
          alert: "Selfie já foi aprovada",
        },
      },
      {
        title: "Criar Autenticação de Dois Fatores",
        sub: "Habilite a autenticação em dois passos do Google para aumentar a sua segurança.",
        actionLabel: "Habilitar autentificação",
      },
      {
        title: "Digite o motivo da solicitação",
        sub: "Digite sua Chave PIX",
        actionLabel: "Continuar",
      },
    ],
    security: {
      title: "Configurações de Cadastro",
    },
    reRegistry: {
      title: "Configurações de Cadastro",
      input: "Senha De Acesso",
      sub: "Ao redefinir seu cadastro você precisará reenviar seus documentos para nova validação.",
    },
    password: {
      title: "Configurações de Senha",
      modal: "Senha trocada com sucesso!",
      current: "Senha Atual",
      new: "Nova Senha",
      newConfirm: "Repetir Senha",
      submit: "Alterar Senha",
    },
    auth: {
      title: "Configurações de Dados",
      code: "Código de Autentificação",
      token: "Verificador",
      send: " Enviar Dados",
      authValidation: "O código deve conter 6 dígitos",
    },
    docs: {
      under: "Seu Documento está sendo avaliado pela nossa equipe.",
      send: "Enviar Documento com Foto",
      sub: "Envie uma imagem legível de um de seus documentos com foto.",
      inputOne: "Anexar Frente",
      inputTwo: "Anexar Verso",
    },
    selfie: {
      under: "Seu Documento está sendo avaliado pela nossa equipe.",
      send: "Enviar Selfie de Segurança",
      sub: "Faça uma selfie segurando um documento na sua mão.",
      inputOne: "Anexar Selfie",
    },
  },
  dash: {
    header: "Seja Bem-Vindo, ",
    headerTwo: "Faça login para continuar",
    loginCard: [
      {
        text: (
          <>
            {"Saldo "} <br /> {"em Biocoin"}
          </>
        ),

        subText: "Entre para ver seu saldo",
      },
      {
        text: "Áreas adquiridas",
      },
      {
        text: "Área com opção de compra",
      },
    ],
    configCards: [
      {
        text: "Extrato",
      },
      {
        text: "Sacar",
      },
      {
        text: "Configurações",
      },
      {
        text: "Minhas NFTS",
      },
    ],
    nft: (
      <>
        Confira nossa <StrongText echo>Coleção de NFTs</StrongText>
      </>
    ),
    sea: "Ir para Open Sea",
    buy: "Comprar Biocoin",
    growth: "Crescimento Geral",
    times: ["1min", "5min", "30min", "1h", "24h", "1 dia", "1 semana"],
    await: "Aguardando Start de Vendas",
  },
  nfts: {
    title: "Minhas NFT's",
    nft: (
      <>
        Confira nossa <StrongText echo>Coleção de NFTs</StrongText>
      </>
    ),
    sea: "Ir para Open Sea",
    toSell: "Á Venda",
    redeemed: "Resgatadas",
  },
  nftTransfer: {
    title: "Resgatar NFT",
    nameAndInfo: "Nome e Informações",
    firstname: "Primeiro Nome",
    lastname: "Sobrenome",
    wallet: "Carteira Digital",
    selfie: "Selfie com Documento",
    selfieInput: "Anexar Selfie com Documento",
    attach: "Anexar",
    request: "Solicitar Transferência",
    back: "Voltar",
    required: baseRequiredMessage,
    modalTitle: "Transferência de NFT",
    modalText: "NFT foi transferida com sucesso!",
  },
  forests: {
    relation: "Relação de florestas",
    placeholder: "Pesquisar Floresta",
    info: "Informações da floresta",
    name: "Nome da Floresta",
    country: "País:",
    state: "Estado:",
    city: "Cidade:",
    totalArea: "Área total",
    unity: "Unidade de Medida",
    loc: "Localidade:",
    lat: "Latitude:",
    long: "Longitude",
    more: "Ver Mais",
    metro: "Área em m",
    value: "Valor",
    hec: "Hectares",
    landingText: (
      <>
        <StrongText>BioCoin:</StrongText> A sua <br />{" "}
        <StrongText>Moeda Digital</StrongText> para um Planeta{" "}
        <StrongText>mais Verde</StrongText>
      </>
    ),
    landingButtons: ["Impacto", "Valorização", "Inovação"],
    register: "Registre-se",
    buy: "Comprar",
    whitepaper: "Whitepaper",
    areas: "Área Preservada ",

    noArea: (
      <>
        Florestas não encontradas <br></br> Tente novamente
      </>
    ),
    values: "Valor para adquirir",
    noPurchasingOp: "Sem opções de compras",
    noAcquiredForests: "Sem florestas adquiridas",
    tryAgain: "Tente novamente mais tarde",
  },
  extract: {
    refundButton: "Estorno",
    extract: "Extrato",
    deposit: "Depósito",
    purchase: "Compra de Moeda",
    refundDeposit: "Reembolso Depósito",
    refundPurchase: "Reembolso Compra de Moeda",
    unknow: "Desconhecido",
    billet: "Boleto",
    creditCard: "Cartão de Crédito",
    pix: "PIX",
    wallet: "Carteira",
    inactive: "Aguardando depósito",
    pending: "Pendente",
    canceled: "Cancelado",
    completed: "Completado",
    refunded: "Reembolsado",
    refundRequested: "Estorno solicitado",
    quotation: "Cotação: ",
    back: "Voltar",
  },
  modalAlert: {
    error: "Erro",
    warning: "Aviso",
    success: "Sucesso",
  },
  errorMessages: {
    status500:
      "Algo deu errado ao contactar o servidor, tente novamente mais tarde.",
    status403: "Usuário e/ou senha incorretos. Por favor, tente novamente.",
    status404: "Erro 404 - Não Encontrado",
    status401: "Usuário não autenticado.",
  },
  validationsTexts: {
    validateCPF: {
      cpfNumber: "Insira um número de CPF",
      cpfErrorFormat: "O CPF digitado não está no formato válido",
    },
    emailValid: "O campo deve conter um e-mail válido.",
    checkPhone: {
      DDDandNumber: "O campo deve ser preenchido com DDD e número.",
      validDDD: "Deve ser informado um DDD válido (entre 11 e 99).",
    },
    validFullname:
      "O campo deve conter um nome completo valido. Com nome, sobrenome e não pode conter números.",
    validName:
      "O campo deve conter um nome válido. Não pode conter números ou caracteres especiais.",
    validNumber: "Valor inserido não é válido",
    validPassword: [
      "Senha deve conter: ",
      "No mínimo 6 (seis) caracteres",
      "; Composta por números",
      "; Letras (maiúsculas e minúsculas)",
      "; Símbolos especiais (@, #, %, =, &, !).",
    ],
    validDate: "Insira uma data válida",
  },
};

export default ptBr;
