import { ReactComponent as Mug } from "../assets/svg/mug-hot-alt.svg";
import { ReactComponent as BioLogo } from "../assets/svg/BioLogo.svg";
import { ReactComponent as MenuIcon } from "../assets/svg/MenuIcon.svg";
import { ReactComponent as Impact } from "../assets/svg/Impact_home.svg";
import { ReactComponent as Value } from "../assets/svg/Value_home.svg";
import { ReactComponent as Inovation } from "../assets/svg/Inovation_home.svg";

import { ReactComponent as FractureIcon } from "../assets/svg/FractureIcon.svg";
import { ReactComponent as FireIcon } from "../assets/svg/FireIcon.svg";
import { ReactComponent as ForestIcon } from "../assets/svg/ForestIcon.svg";
import { ReactComponent as WoodIcon } from "../assets/svg/WoodIcon.svg";

import { ReactComponent as ImpactIcon } from "../assets/svg/ImpactIcon.svg";
import { ReactComponent as InovationIcon } from "../assets/svg/InovationIcon.svg";
import { ReactComponent as ValueIcon } from "../assets/svg/ValueIcon.svg";

import { ReactComponent as CirclesBackground } from "../assets/svg/CiclesBackground.svg";
import { ReactComponent as WhitepaperIcon } from "../assets/svg/WhitepaperIcon.svg";
import { ReactComponent as TokenIcon } from "../assets/svg/TokenIcon.svg";

import { ReactComponent as CartaContorno } from "../assets/svg/CartaContorno.svg";
import { ReactComponent as CartaCor } from "../assets/svg/CartaCor.svg";

import { ReactComponent as ArrowIcon } from "../assets/svg/arrow.svg";
import { ReactComponent as BeachCoin } from "../assets/svg/beachCoin.svg";
import { ReactComponent as CloudCoin } from "../assets/svg/cloudCoin.svg";
import { ReactComponent as CubeCoin } from "../assets/svg/cubeCoin.svg";
import { ReactComponent as FlorestCoin } from "../assets/svg/florestCoin.svg";
import { ReactComponent as PlantCoin } from "../assets/svg/plantCoin.svg";
import { ReactComponent as NFTCoin } from "../assets/svg/NFTCoin.svg";
import { ReactComponent as DotIcon } from "../assets/svg/dot.svg";

import { ReactComponent as AreaIcon } from "../assets/svg/AreaIcon.svg";
import { ReactComponent as TechIcon } from "../assets/svg/techIcon.svg";
import { ReactComponent as AboutIcon } from "../assets/svg/aboutIcon.svg";
import { ReactComponent as DashIcon } from "../assets/svg/dashIcon.svg";
import { ReactComponent as DashThreeIcon } from "../assets/svg/dashThreeIcon.svg";
import { ReactComponent as BranchIcon } from "../assets/svg/Branch.svg";
import { ReactComponent as SelectIcon } from "../assets/svg/SelectIcon.svg";
import { ReactComponent as MoneyIcon } from "../assets/svg/moneyIcon.svg";

import { ReactComponent as brFlag } from "../assets/svg/brFlag.svg";
import { ReactComponent as usFlag } from "../assets/svg/usFlag.svg";

import { ReactComponent as Briefcase } from "../assets/svg/configIcons/biefcase.svg";
import { ReactComponent as Folder } from "../assets/svg/configIcons/folder.svg";
import { ReactComponent as Locker } from "../assets/svg/configIcons/locker.svg";
import { ReactComponent as Sefaty } from "../assets/svg/configIcons/safaty.svg";

import { ReactComponent as PixIcon } from "../assets/svg/CurrencyExchange.svg";
import { ReactComponent as CreditCardIcon } from "../assets/svg/CreditCard.svg";
import { ReactComponent as BoletoIcon } from "../assets/svg/ContractPayment.svg";

import { ReactComponent as GreenRS } from "../assets/svg/cifrao.svg";
import { ReactComponent as BlackRS } from "../assets/svg/cifraoBlack.svg";
import { ReactComponent as GreenTree } from "../assets/svg/treeGreen.svg";
import { ReactComponent as BlackTree } from "../assets/svg/treeBlack.svg";

import { ReactComponent as ConfigIcon } from "../assets/svg/configIcon.svg";
import { ReactComponent as GoldIcon } from "../assets/svg/goldIcon.svg";
import { ReactComponent as InvoiceIcon } from "../assets/svg/invoiceIcon.svg";
import { ReactComponent as NftsIcons } from "../assets/svg/nftsIcons.svg";
import { ReactComponent as SearchIcon } from "../assets/svg/searchIcon.svg";
import { ReactComponent as PixIconSec } from "../assets/svg/pixIcon.svg";

import { ReactComponent as DocumentIcon } from "../assets/svg/documentIcon.svg";
import { ReactComponent as EmailIcon } from "../assets/svg/emailIcon.svg";
import { ReactComponent as SelfieIcon } from "../assets/svg/selfieIcon.svg";
import { ReactComponent as TwoFactorIcon } from "../assets/svg/twoFactorIcon.svg";

import { ReactComponent as MissionRecycle } from "../assets/svg/missionIcons/RecycleIcon.svg";
import { ReactComponent as MissionConection } from "../assets/svg/missionIcons/ConectionIcon.svg";
import { ReactComponent as MissionThree } from "../assets/svg/missionIcons/ThreeIcon.svg";
import { ReactComponent as MissionThreeCoin } from "../assets/svg/missionIcons/ThreeIconCoin.svg";

const SVG = {
  DocumentIcon,
  EmailIcon,
  SelfieIcon,
  TwoFactorIcon,

  Mug,
  BioLogo,
  MenuIcon,
  Impact,
  Value,
  Inovation,

  FractureIcon,
  FireIcon,
  ForestIcon,
  WoodIcon,

  ValueIcon,
  InovationIcon,
  ImpactIcon,
  CirclesBackground,
  WhitepaperIcon,
  TokenIcon,

  CartaContorno,
  CartaCor,

  AreaIcon,
  ArrowIcon,
  BeachCoin,
  CloudCoin,
  CubeCoin,
  FlorestCoin,
  PlantCoin,
  NFTCoin,
  DotIcon,

  TechIcon,
  AboutIcon,
  DashIcon,
  DashThreeIcon,
  BranchIcon,
  SelectIcon,

  brFlag,
  Briefcase,
  Folder,
  Locker,
  Sefaty,

  PixIcon,
  CreditCardIcon,
  BoletoIcon,

  GreenRS,
  BlackRS,
  GreenTree,
  BlackTree,

  NftsIcons,
  InvoiceIcon,
  GoldIcon,
  ConfigIcon,
  MoneyIcon,
  SearchIcon,
  PixIconSec,

  MissionRecycle,
  MissionConection,
  MissionThree,
  MissionThreeCoin,
  usFlag,
};

export default SVG;
