import React from "react";

import { FormFull } from "form-full";
import { LocalStorage, validations } from "../../../utils";
import Styles from "../EsqueceuSenhaStyles";
import { Input } from "../../../components";
import { fonts, Spacing } from "../../../config";
import Texts from "../../../config/texts";

interface PageProps {
  goBack: () => void;
  onSubmit: (data: any) => void;
  loading: boolean;
}
function StepEmail({ goBack, onSubmit, loading }: PageProps): JSX.Element {
  const texts =
    Texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].forgetPassword;
  const [buttonDisabled, setButton] = React.useState(true);

  return (
    <FormFull onSubmit={onSubmit}>
      <Styles.Content>
        <Styles.Title>{texts.titleStepOne}</Styles.Title>

        <div>
          <Styles.InputTitle>{texts.email}</Styles.InputTitle>
          <Input
            inputStyle={{
              border: "1.588px solid #FFF",
              background: "rgba(27, 30, 34, 0.70)",
              borderRadius: "53.201px",
              color: "white",
              fontFamily: fonts.regularCf,
              fontSize: Spacing(2.75),
              paddingInline: Spacing(3.75),
              paddingBlock: Spacing(2),
              marginTop: Spacing(0.75),
              "& .MuiInputBase-input": {
                padding: 0,

                boxShadow: "none !important",
              },

              [`@media (max-width:${1024 - 1}px)`]: {
                paddingInline: Spacing(3.5),
                fontSize: Spacing(2.5),
              },
              [`@media (max-width:${581 - 1}px)`]: {
                paddingInline: Spacing(1.25),
                paddingBlock: Spacing(1),
                fontSize: Spacing(1.5),
              },
            }}
            name="email"
            required={texts.userMessage}
            validation={validations.isEmailValid}
          />
        </div>
        <Styles.Subtitle> {texts.subTitle[0]}</Styles.Subtitle>
        <div>
          <Styles.SubmitButton
            name="continue"
            loading={loading}
            disabled={loading}
            feedback={true}
            action="submit"
            label={texts.continue[0]}
          />
          <Styles.BackButton onClick={goBack}>{texts.onBack}</Styles.BackButton>
        </div>
      </Styles.Content>
    </FormFull>
  );
}

export default StepEmail;
