import { InputPassword } from "../../../components";

import Styles from "../styles";

import { FormFull } from "form-full";
import { fonts, Spacing } from "../../../config";
import { hooks, LocalStorage } from "../../../utils";

import BoxHeader from "./BoxHeader";
import { api } from "../../../services";
import alerts from "../../../utils/Alerts";
import ResponseError from "../../../services/helpers/ResponseError";
import { useContext } from "react";
import { StorageContext } from "../../../contexts/StorageContext";
import Texts from "../../../config/texts";

function ReRegister({ goBack, user }: any): JSX.Element {
  const texts =
    Texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].config;
  const buttonTexts =
    Texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].actionButton;
  const { loading, call } = hooks.useRequest();
  const { setUserData, userData } = useContext<any>(StorageContext);

  const changeProcess = (data) => {
    call(null, api.resetUser(data), (response) => {
      if (response.ok) {
        alerts.alertSuccess(texts.userReset);
        setUserData({
          ...userData,
          ...response?.data,
        });
      } else {
        const errorResponse = new ResponseError(response);
        errorResponse.alertMessage();
      }
    });
  };
  return (
    <>
      <Styles.WhiteBox>
        <Styles.TitleBox>
          <Styles.TitleConfig>{texts.reRegistry.title}</Styles.TitleConfig>
        </Styles.TitleBox>

        <Styles.BoxUserContent>
          <BoxHeader user={user} />

          <FormFull clearOnSubmit={true} onSubmit={changeProcess}>
            <div style={{ width: "100%" }}>
              <Styles.InputTitle>{texts.reRegistry.input}</Styles.InputTitle>
              <InputPassword
                required={texts.required}
                key={21}
                inputStyle={{
                  background: "rgba(27, 30, 34, 0.70)",
                  borderRadius: "53.201px",
                  color: "white",
                  fontFamily: fonts.regularCf,
                  fontSize: Spacing(2.75),
                  paddingInline: Spacing(3.75),
                  paddingBlock: Spacing(2),
                  marginTop: Spacing(0.75),
                  border: "1.988px solid #D3D527",
                  "& .MuiInputBase-input": {
                    padding: 0,

                    boxShadow: "none !important",
                  },

                  [`@media (max-width:${1024 - 1}px)`]: {
                    paddingInline: Spacing(3.5),
                    fontSize: Spacing(2.5),
                  },
                  [`@media (max-width:${581 - 1}px)`]: {
                    paddingInline: Spacing(1.25),
                    paddingBlock: Spacing(1),
                    fontSize: Spacing(1.5),
                  },
                }}
                name="password"
              />
              <Styles.SubText>{texts.reRegistry.sub}</Styles.SubText>
            </div>

            <div>
              <Styles.SendButton
                disabled={loading}
                loading={loading}
                action="submit"
                name="submit"
              >
                {buttonTexts.reRegistry}
              </Styles.SendButton>
            </div>
          </FormFull>
        </Styles.BoxUserContent>
      </Styles.WhiteBox>
      <Styles.Back
        disabled={loading}
        loading={loading}
        fullWidth={false}
        onClick={goBack}
      >
        {buttonTexts.back}
      </Styles.Back>
    </>
  );
}

export default ReRegister;
