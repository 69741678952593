import masks from "./masks";
import LocalStorage from "../utils/storage/localStorage";
import Texts from "../config/texts";

const text =
  Texts[
    LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
  ].validationsTexts;

function validateCPF(cpf: string) {
  // ONLY FOR ADMIN USER
  if (cpf === "000.000.000-00" || cpf === "00000000000") {
    return null;
  }
  if (!cpf) {
    return text.validateCPF.cpfNumber;
  }
  let clearCpf = "";
  let errorMessage = text.validateCPF.cpfErrorFormat;
  if (cpf) {
    clearCpf = masks.removeNumberMask(cpf);
  } else {
    return text.validateCPF.cpfNumber;
  }
  let sum = 0;
  let rest;
  if (
    clearCpf.length !== 11 ||
    clearCpf === "00000000000" ||
    clearCpf === "11111111111" ||
    clearCpf === "22222222222" ||
    clearCpf === "33333333333" ||
    clearCpf === "44444444444" ||
    clearCpf === "55555555555" ||
    clearCpf === "66666666666" ||
    clearCpf === "77777777777" ||
    clearCpf === "88888888888" ||
    clearCpf === "99999999999"
  ) {
    return errorMessage;
  }
  for (let i = 1; i <= 9; i++) {
    sum += parseInt(clearCpf.substring(i - 1, i), 10) * (11 - i);
  }
  rest = (sum * 10) % 11;
  if (rest === 10 || rest === 11) {
    rest = 0;
  }
  if (rest !== parseInt(clearCpf.substring(9, 10), 10)) {
    return errorMessage;
  }
  sum = 0;
  for (let i = 1; i <= 10; i++) {
    sum += parseInt(clearCpf.substring(i - 1, i), 10) * (12 - i);
  }
  rest = (sum * 10) % 11;
  if (rest === 10 || rest === 11) {
    rest = 0;
  }
  if (rest !== parseInt(clearCpf.substring(10, 11), 10)) {
    return errorMessage;
  }
  return null;
}

function isEmailValid(email: string) {
  const isValid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*\.\w{2,}$/.test(email);

  return isValid ? null : text.emailValid;
}

function checkPhone(cellphone: any) {
  let errorMessage = text.checkPhone.DDDandNumber;
  if (!cellphone) {
    return errorMessage;
  }
  const number = masks.removeNumberMask(cellphone);
  const isCellphoneValid = number.length === 10 || number.length === 11;
  const ddd = Number(number.substring(0, 2));
  const idDDDValid = ddd > 10 && ddd < 100;

  if (!idDDDValid) {
    errorMessage = text.checkPhone.validDDD;
  }
  return isCellphoneValid && idDDDValid ? null : errorMessage;
}

function isValidFullname(fullname: any) {
  const isFullnameValid =
    Boolean(fullname) &&
    fullname
      .trim()
      .match(/^[A-zÀ-Ÿ][A-zÀ-ÿ']+\s([A-zÀ-ÿ']\s?)*[A-zÀ-Ÿ][A-zÀ-ÿ']+$/);

  return isFullnameValid ? null : text.validFullname;
}

function isValidName(name: any) {
  const isNameValid = name.match(/^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/);

  return isNameValid ? null : text.validName;
}

function validNumber(number: any) {
  return number && Number(masks.removeNumberMask(number)) !== 0
    ? null
    : text.validNumber;
}

function isPassword(value: any) {
  const isValid =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#%=&!])[A-Za-z\d@#%=&!]{6,}$/.test(
      value
    );
  return isValid
    ? null
    : text.validPassword[0] +
        text.validPassword[1] +
        text.validPassword[2] +
        text.validPassword[3] +
        text.validPassword[4];
}

const inputDate = (data: any, maxDate: any, minDate: any) => {
  if (isNaN(Date.parse(data))) {
    return text.validDate;
  } else {
    if (data > maxDate) {
      return LocalStorage.getItem("language")
        ? `Enter a date before ${new Date(maxDate).toLocaleDateString("en-US")}`
        : `Insira uma data anterior a ${new Date(maxDate).toLocaleDateString(
            "pt-BR"
          )}`;
    } else if (data < minDate) {
      return LocalStorage.getItem("language")
        ? `Enter a date after ${new Date(minDate).toLocaleDateString("en-US")}`
        : `Insira uma data posterior a ${new Date(minDate).toLocaleDateString(
            "pt-BR"
          )}`;
    }
    return null;
  }
};

const validations = {
  validateCPF,
  isEmailValid,

  checkPhone,
  isValidFullname,
  isValidName,
  validNumber,

  isPassword,

  inputDate,
};

export default validations;
