import React, { useContext } from "react";

import { ButtonText, FontStyles, StrongText } from "../../components";

const BscLogo = process.env.REACT_APP_ASSETS_URL + "/imgs/BscLogo.webp";
const Lock = process.env.REACT_APP_ASSETS_URL + "/imgs/Lock.webp";
const ExchangesImg = process.env.REACT_APP_ASSETS_URL + "/imgs/Exchanges.webp";
const PartnersImg = process.env.REACT_APP_ASSETS_URL + "/imgs/Parceiros.webp";
const CoinImg = process.env.REACT_APP_ASSETS_URL + "/imgs/Biocoin.webp";

import Styles from "./styles";
import { Colors, fonts, Spacing, SVG } from "../../config";
import { RxChevronRight, RxChevronLeft } from "react-icons/rx";
import { IconButton } from "@material-ui/core";
import colors from "../../config/colors";

import { customModalBlur } from "../../components/modals/utils";
import Purpose from "./components/Purpose";
import RoadmapMobile from "./components/RoadmapMobile";
import Roadmap from "./components/Roadmap";
import BlogListFooter from "../blog/components/BlogListFooter";
import { useLocation, useNavigate } from "react-router-dom";
import { paths } from "../../navigation/navigate";
import texts from "../../config/texts";
import { StorageContext } from "../../contexts/StorageContext";
import { LocalStorage } from "../../utils";
import alerts from "../../utils/Alerts";

function Home() {
  const currentText =
    texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].home;
  const [isMounted, setMount] = React.useState<boolean>(false);
  const [menuHeight, setHeight] = React.useState<any>(null);
  const [currentBulletIndex, setCurrentBulletIndex] = React.useState(0);
  const { isLogged } = useContext<any>(StorageContext);
  const { search } = useLocation();
  const navigate = useNavigate();

  const handleDownload = () => {
    const pdfPath =
      process.env.REACT_APP_ASSETS_URL + "/documents/WhitePaper.pdf";
    window.open(pdfPath, "_blank");
  };

  const [arrowRoadmapDirection, setArrowRoadmapDirection] =
    React.useState<string>("1");
  const nextItem = () => {
    setCurrentBulletIndex((prevIndex) => (prevIndex + 1) % BulletsCards.length);
  };

  const prevItem = () => {
    setCurrentBulletIndex((prevIndex) =>
      prevIndex === 0 ? BulletsCards.length - 1 : prevIndex - 1
    );
  };

  const BulletsCards = [
    {
      icon: isLogged ? <Styles.MissionConectionIcon /> : <Styles.WoodIcon />,
      title: currentText.bullets[0].title,
      description: currentText.bullets[0].description,
    },
    {
      icon: isLogged ? <Styles.MissionThreeCoinIcon /> : <Styles.FireIcon />,
      title: currentText.bullets[1].title,
      description: currentText.bullets[1].description,
    },
    {
      icon: isLogged ? <Styles.MissionRecycleIcon /> : <Styles.FractureIcon />,
      title: currentText.bullets[2].title,
      description: currentText.bullets[2].description,
    },
    {
      icon: isLogged ? <Styles.MissionThreeIcon /> : <Styles.ForestIcon />,
      title: currentText.bullets[3].title,
      description: currentText.bullets[3].description,
    },
  ];
  const whyIcons = [
    {
      icon: SVG.Impact,
      title: currentText.whyList[0].title,
      subtitle: currentText.whyList[0].subtitle,
    },

    {
      icon: SVG.Value,
      title: currentText.whyList[1].title,
      subtitle: currentText.whyList[1].subtitle,
    },

    {
      icon: SVG.Inovation,
      title: currentText.whyList[2].title,
      subtitle: currentText.whyList[2].subtitle,
    },
  ];
  const landingButtons = [
    {
      icon: SVG.ImpactIcon,
      title: currentText.landingButtons[0],
      onClick: () => {},
    },

    {
      icon: SVG.ValueIcon,
      title: currentText.landingButtons[1],
      onClick: () => {},
    },

    {
      icon: SVG.InovationIcon,
      title: currentText.landingButtons[2],
      onClick: () => {},
    },
  ];

  React.useLayoutEffect(() => {
    if (search === "?validated") {
      alerts.alertSuccess("E-mail validado com sucesso!");
    }
    if (!menuHeight) {
      const mh = document.getElementById("header-menu")?.offsetHeight;

      setHeight(mh);
    }
  }, [menuHeight, search]);

  const Mount = React.useCallback(() => {
    if (!LocalStorage.getItem("modalHome")) {
      if (!LocalStorage.getItem("language")) {
        LocalStorage.setItem("language", "pt");
      }
      LocalStorage.setItem("modalHome", "true");
      customModalBlur.setInfos(
        <>
          <Styles.CoinModalImg src={CoinImg} />
          <Styles.ModalTextContainer>
            <Styles.ModalTextTitle>
              {currentText.modal.title}
            </Styles.ModalTextTitle>
            <Styles.ModalText>{currentText.modal.content}</Styles.ModalText>
          </Styles.ModalTextContainer>
          <ButtonText
            onClick={() => customModalBlur.close()}
            fullWidth={false}
            style={{
              alignSelf: "center",
              color: Colors.yellow,
              textDecorationLine: "underline",
              textTransform: "capitalize",
              ...FontStyles.regular18,
              fontFamily: fonts.boldCf,
            }}
          >
            {currentText.modal.close}
          </ButtonText>
        </>
      );
    }
  }, [customModalBlur]);

  React.useEffect(() => {
    if (!isMounted) {
      Mount();
      setMount(true);
    }
  }, [Mount, isMounted]);
  const goBlog = () => {
    navigate(paths.blog);
  };
  const roadmapData1 = currentText.roadmap.maps[0];
  const roadmapData2 = currentText.roadmap.maps[1];
  const roadmapData3 = currentText.roadmap.maps[2];
  return (
    <>
      <Styles.Container $menuHeight={menuHeight}>
        <Styles.BgVideoContainer $menuHeight={menuHeight}>
          <Styles.BgVideo
            $menuHeight={menuHeight}
            muted
            loop
            autoPlay
            playsInline
            controls={false}
            preload="auto"
          >
            <source
              src={
                process.env.REACT_APP_ASSETS_URL + "/videos/homeLogoVideo.webm"
              }
              type="video/webm"
            />
          </Styles.BgVideo>
          <Styles.BgForest></Styles.BgForest>
          <div
            style={{
              position: "absolute",
              top: 0,
              zIndex: 3,
              left: 0,
            }}
          >
            <Styles.LandingFirst>
              <Styles.LandingText>{currentText.landingText}</Styles.LandingText>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  gap: Spacing(1),
                }}
              >
                <Styles.RegistryButton
                  fullWidth={false}
                  onClick={() =>
                    isLogged ? navigate("/payment") : navigate(paths.register)
                  }
                >
                  {isLogged ? currentText.buy : currentText.register}
                </Styles.RegistryButton>
                <Styles.WhitelistButton
                  fullWidth={false}
                  onClick={handleDownload}
                >
                  {currentText.whitepaper}
                </Styles.WhitelistButton>
              </div>
            </Styles.LandingFirst>
          </div>
          <div
            style={{
              position: "absolute",
              top: 0,
              zIndex: 3,
              right: 0,
            }}
          >
            <Styles.LandingSecond>
              <Styles.WhitelistSecondButton
                fullWidth={true}
                onClick={handleDownload}
              >
                {currentText.whitepaper}
              </Styles.WhitelistSecondButton>

              <Styles.RegistryButton
                onClick={() => navigate("/forest/acquired")}
                fullWidth={true}
              >
                <Styles.AreaIcon /> {currentText.areas}
              </Styles.RegistryButton>
              <Styles.RegistryButton
                onClick={() => navigate("/forest/buy")}
                fullWidth={true}
              >
                <Styles.AreaIcon /> {currentText.areasBuy}
              </Styles.RegistryButton>
            </Styles.LandingSecond>
          </div>
          <div style={{ position: "absolute", bottom: 0, zIndex: 3, left: 0 }}>
            <Styles.LandingButtonContainer>
              {landingButtons.map((item) => (
                <Styles.HomeButtons
                  fullWidth={false}
                  onClick={() => item.onClick()}
                >
                  {<item.icon />} {item.title}
                </Styles.HomeButtons>
              ))}
            </Styles.LandingButtonContainer>
          </div>
        </Styles.BgVideoContainer>
      </Styles.Container>

      <Purpose />

      <Styles.WhyContainer
        imgSrc={process.env.REACT_APP_ASSETS_URL + "/videos/homeLogoVideo.webm"}
      >
        <Styles.YellowBall></Styles.YellowBall>
        <Styles.YellowBallTwo></Styles.YellowBallTwo>

        <Styles.WhyFirst>
          <Styles.LoopVideo
            autoPlay
            playsInline
            controls={false}
            loop
            muted
            preload="auto"
          >
            <source
              src={process.env.REACT_APP_ASSETS_URL + "/videos/homeLoop.webm"}
              type="video/webm"
            />
          </Styles.LoopVideo>
          <div>
            <Styles.WhyFirstTitle>
              {currentText.aboutTitle}
            </Styles.WhyFirstTitle>
            <Styles.AboutDesciption white>
              {currentText.about}
            </Styles.AboutDesciption>
          </div>
        </Styles.WhyFirst>

        <Styles.WhyLine></Styles.WhyLine>

        <Styles.WhySecond>
          <Styles.WhySecondTitle> {currentText.whyTitle}</Styles.WhySecondTitle>
          <Styles.WhySecondSubTitle>{currentText.why}</Styles.WhySecondSubTitle>
        </Styles.WhySecond>

        <Styles.WhyThird>
          {whyIcons.map((item) => (
            <Styles.WhyCard>
              <item.icon
                className="iconBox"
                style={{
                  maxWidth: "118px",
                  maxHeight: "118px",
                  stroke: "#0B0D0A",
                }}
              />
              <Styles.WhyCardTitle className="titleBox">
                {item.title}
              </Styles.WhyCardTitle>
              <Styles.WhyCardSubTitle className="subtitleBox">
                {item.subtitle}
              </Styles.WhyCardSubTitle>
            </Styles.WhyCard>
          ))}
        </Styles.WhyThird>
      </Styles.WhyContainer>

      <Styles.MissionContainer>
        <Styles.MissionFirst>
          <Styles.MissionFirstTitle onClick={goBlog}>
            {currentText.mission}
          </Styles.MissionFirstTitle>
          <Styles.MissionFirstSubTitle>
            {currentText.missionSub}
          </Styles.MissionFirstSubTitle>
          <Styles.MissionButton onClick={goBlog} fullWidth={false}>
            {currentText.more}
          </Styles.MissionButton>
        </Styles.MissionFirst>

        <Styles.MissionSecond>
          {BulletsCards.map((item) => (
            <Styles.MissionBulletsContainer>
              <Styles.MissionBulletsIcon>{item.icon}</Styles.MissionBulletsIcon>

              <Styles.MissionBulletTitle>
                {item.title}
              </Styles.MissionBulletTitle>
              <Styles.MissionBulletDescription>
                {item.description}
              </Styles.MissionBulletDescription>
            </Styles.MissionBulletsContainer>
          ))}
        </Styles.MissionSecond>

        <Styles.MissionSecondMobile>
          <IconButton
            style={{
              opacity: currentBulletIndex === 0 ? 0 : 1,
              pointerEvents: currentBulletIndex === 0 ? "none" : "auto",
              marginTop: "10%",
              height: "fit-content",
              width: "fit-content",
              padding: "3px",
              color: colors.black,
              background:
                "linear-gradient(315deg, rgba(210,212,40,1) 0%, rgba(231,255,0,1) 100%)",
            }}
            onClick={prevItem}
          >
            <RxChevronLeft style={{ maxWidth: "20px", maxHeight: "20px" }} />
          </IconButton>
          <div
            style={{
              height: "fit-content",
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
              //zIndex: 2,
              //position: "relative",
            }}
          >
            {BulletsCards.map((item, index) => (
              <Styles.MissionBulletsContainer
                key={index}
                index={index}
                currentBulletIndex={currentBulletIndex}
              >
                <Styles.MissionBulletsIcon>
                  {item.icon}
                </Styles.MissionBulletsIcon>

                <Styles.MissionBulletTitle>
                  {item.title}
                </Styles.MissionBulletTitle>
                <Styles.MissionBulletDescription>
                  {item.description}
                </Styles.MissionBulletDescription>
              </Styles.MissionBulletsContainer>
            ))}
          </div>
          <IconButton
            style={{
              opacity: currentBulletIndex === BulletsCards.length - 1 ? 0 : 1,
              pointerEvents:
                currentBulletIndex === BulletsCards.length - 1
                  ? "none"
                  : "auto",
              marginTop: "10%",
              height: "fit-content",
              width: "fit-content",
              padding: "3px",
              color: colors.black,
              background:
                "linear-gradient(315deg, rgba(210,212,40,1) 0%, rgba(231,255,0,1) 100%)",
            }}
            onClick={nextItem}
          >
            <RxChevronRight style={{ maxWidth: "20px", maxHeight: "20px" }} />
          </IconButton>
        </Styles.MissionSecondMobile>

        <Styles.SecurityContainer>
          <Styles.SecurityLock src={Lock} />
          <div>
            <Styles.SecurityFirst>
              <Styles.SecurityTitle>
                {currentText.securityTitle}
              </Styles.SecurityTitle>
              <Styles.SecurityLogo src={BscLogo} />
            </Styles.SecurityFirst>
            <Styles.SecurityDesciption>
              {currentText.security}
            </Styles.SecurityDesciption>
          </div>
        </Styles.SecurityContainer>
      </Styles.MissionContainer>

      <div
        style={{
          backgroundColor: "#1A2024",
          width: "100%",

          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingBlock: Spacing(5),
        }}
      >
        <Styles.DifferentialTitle roadmap>
          {currentText.roadmap.title}
        </Styles.DifferentialTitle>
        <Styles.AboutRoadTexts>
          {arrowRoadmapDirection === "1"
            ? "Trimestre 1 (2023 Q4)"
            : arrowRoadmapDirection === "2"
            ? "Trimestre 2 (2024 Q1)"
            : "Trimestre 3 (2024 Q2)"}
        </Styles.AboutRoadTexts>

        <Styles.RoadmapContainer>
          {arrowRoadmapDirection === "1" ? (
            <>
              <Roadmap data={roadmapData1} />
              <Styles.ArrowIcon
                right
                roadmap
                onClick={() => setArrowRoadmapDirection("2")}
              />
            </>
          ) : arrowRoadmapDirection === "2" ? (
            <>
              <Roadmap data={roadmapData2} />
              <Styles.ArrowIcon
                roadmap
                onClick={() => setArrowRoadmapDirection("1")}
              />
              <Styles.ArrowIcon
                right
                roadmap
                onClick={() => setArrowRoadmapDirection("3")}
              />
            </>
          ) : (
            <>
              <Roadmap data={roadmapData3} />
              <Styles.ArrowIcon
                roadmap
                onClick={() => setArrowRoadmapDirection("2")}
              />
            </>
          )}
        </Styles.RoadmapContainer>

        <Styles.RoadmapContainer mobile>
          <RoadmapMobile title={currentText.roadmap.t1} data={roadmapData1} />
          <RoadmapMobile title={currentText.roadmap.t2} data={roadmapData2} />
          <RoadmapMobile title={currentText.roadmap.t3} data={roadmapData3} />
        </Styles.RoadmapContainer>
      </div>

      <Styles.PartnersContainer>
        <Styles.PartnersContent>
          <Styles.PartnersText>{currentText.exchanges}</Styles.PartnersText>
          <Styles.PartnersImg src={PartnersImg} onClick={goBlog} />
        </Styles.PartnersContent>

        <Styles.PartnersLine></Styles.PartnersLine>

        <Styles.PartnersContent>
          <Styles.PartnersText>{currentText.partners}</Styles.PartnersText>{" "}
          <Styles.ExchangesImg src={ExchangesImg} onClick={goBlog} />
        </Styles.PartnersContent>

        <Styles.CoinImg src={CoinImg} />
      </Styles.PartnersContainer>
      <BlogListFooter noTablet={false} />
    </>
  );
}

export default Home;
