import Home from "./home/Home";
import Login from "./login/Login";
import EsqueceuSenha from "./esqueceuSenha/EsqueceuSenha";
import Technology from "./technology/Technology";
import About from "./about/About";
import Project from "./project/Project";
import BlogArticle from "./blogArticle/BlogArticle";
import ForestArea from "./forestArea/ForestArea";

import Register from "./register/Register";
import { Menu } from "./menu";
import { Header } from "./header";
import { Footer } from "./footer";
import Dashboard from "./dashboard/Dashboard";
import Blog from "./blog/Blog";
import Faq from "./faq/Faq";
import TermsOfUse from "./termsOfUse/TermsOfUse";
import PrivacyPolicy from "./privacyPolicy/PrivacyPolicy";
import Configs from "./configs/Configs";
import Payment from "./payment/Payment";
import Extract from "./extract/Extract";
import Nfts from "./nfts/Nfts";
import AllForest from "./allForest/AllForest";
import NFTTransfer from "./nftTransfer/NFTTransfer";

const pages = {
  Home,
  Login,
  EsqueceuSenha,
  About,
  Menu,
  Header,
  Footer,
  Technology,
  Faq,
  Project,
  Dashboard,
  Blog,
  BlogArticle,
  Register,
  TermsOfUse,
  PrivacyPolicy,
  ForestArea,
  Configs,
  Payment,
  Extract,
  Nfts,
  NFTTransfer,
  AllForest,
};
export default pages;
