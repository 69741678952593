export default function objectToQueryString(obj: Record<string, any>): string {
  const queryParams: any = [];

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];

      if (Array.isArray(value)) {
        value.forEach((item) => {
          queryParams.push(
            `${encodeURIComponent(key)}=${encodeURIComponent(item)}`
          );
        });
      } else {
        queryParams.push(
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        );
      }
    }
  }

  return "?" + queryParams.join("&");
}
