import { CircularProgress, Grid } from "@material-ui/core";

import React from "react";

import Styles from "./styles";
const leafBackgorund = process.env.REACT_APP_ASSETS_URL + "/imgs/leafBackgorund.webp";
import WhitePaper from "../about/components/WhitePaper";
import { useNavigate } from "react-router-dom";
import { hooks, LocalStorage } from "../../utils";
import { api } from "../../services";
import { ButtonContained } from "../../components";
import { Spacing } from "../../config";
import texts from "../../config/texts";

const PAGE_SIZE = 9;
function Blog() {
  const currentText = texts[LocalStorage.getItem("language") ?LocalStorage.getItem("language"): 'pt'].blog;
  const [menuHeight, setHeight] = React.useState<any>(null);
  const [isMounted, setMount] = React.useState<boolean>(false);
  const [blogList, setBlogList] = React.useState<any>({});
  const [pageNumber, setPageNumber] = React.useState<any>(0);
  const { loading, call } = hooks.useRequest();
  const navigate = useNavigate();
  const Mount = React.useCallback(() => {
    call(
      "blogList",
      api.getBlogList({
        page: 0,
        size: PAGE_SIZE,
      }),
      async (response) => {
        if (response.ok) {
          let newList: any[] = [];

          for (const item of response?.data?.content) {
            await call(
              null,
              api.getImage(item.imageId, item.id),
              (response) => {
                newList.push({ ...item, img: response?.data });
              }
            );
          }

          setBlogList({ ...response.data, content: newList });
        }
      }
    );
  }, []);

  React.useEffect(() => {
    if (!isMounted) {
      Mount();
      setMount(true);
    }
  }, [Mount, isMounted]);

  React.useLayoutEffect(() => {
    if (!menuHeight) {
      const mh = document.getElementById("header-menu")?.offsetHeight;

      setHeight(mh);
    }
  }, [menuHeight]);

  const convertTime = (item: Date): string => {
    const dia = item.getDate();
    const mes = item.getMonth() + 1;

    const diaFormatado = dia.toString().padStart(2, "0");
    const mesFormatado = mes.toString().padStart(2, "0");

    return `${diaFormatado}/${mesFormatado}`;
  };

  const blogCards = (): any => {
    const width = window.innerWidth;
    const cards: any[] = [];

    blogList?.content?.map((item, index) =>
      cards.push(
        <Grid key={index + "blog"} item xs={12} sm={12} md={6} lg={4} xl={3}>
          <Styles.BlogCard
            onClick={() => {
              navigate(`/blog/article/${item?.id}`, {
                state: { ...item },
              });
              document.getElementById("header-menu")?.scrollIntoView();
            }}
            $center={
              width < 1280 || width > 1599 ? index % 2 === 1 : index % 3 === 1
            }
            key={index}
          >
            <Styles.PostCard>
              <Styles.PostCardImg
                $center={
                  width < 1280 || width > 1599
                    ? index % 2 === 1
                    : index % 3 === 1
                }
                $image={`data:image/jpeg;base64,${item.img}`}
              ></Styles.PostCardImg>
              <Styles.PostDate
                $center={
                  width < 1280 || width > 1599
                    ? index % 2 === 1
                    : index % 3 === 1
                }
              >
                {convertTime(new Date(item.createdAt))}
              </Styles.PostDate>

              <Styles.PostTitle
                $center={
                  width < 1280 || width > 1599
                    ? index % 2 === 1
                    : index % 3 === 1
                }
              >
                {item.name}
              </Styles.PostTitle>
              <Styles.PostDescription
                $center={
                  width < 1280 || width > 1599
                    ? index % 2 === 1
                    : index % 3 === 1
                }
              >
                {item.resume}
              </Styles.PostDescription>
            </Styles.PostCard>
          </Styles.BlogCard>
        </Grid>
      )
    );

    return cards;
  };

  const loadMorePages = () => {
    call(
      "blogList",
      api.getBlogList({
        page: pageNumber + 1,
        size: PAGE_SIZE,
      }),
      async (response) => {
        if (response.ok) {
          let newList: any[] = [...blogList?.content];

          for (const item of response?.data?.content) {
            await call(
              null,
              api.getImage(item.imageId, item.id),
              (response) => {
                newList.push({ ...item, img: response?.data });
              }
            );
          }

          setBlogList({ ...response.data, content: newList });
          setPageNumber(pageNumber + 1);
        }
      }
    );
  };

  return (
    <>
      <Styles.Container $menuHeight={menuHeight}>
        <Styles.PagTitle> {currentText.title}</Styles.PagTitle>
      </Styles.Container>
      <Styles.FaqContainer>
        {loading ? (
          <CircularProgress
            size={64}
            style={{
              color: "black",
              alignSelf: "center",
              justifyContent: "center",
              marginBlock: Spacing(3),
            }}
          />
        ) : (
          <Grid spacing={2} container>
            {blogCards()}
          </Grid>
        )}
        {blogList?.totalPages > 1 && pageNumber + 1 < blogList?.totalPages && (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ButtonContained fullWidth={false} onClick={() => loadMorePages()}>
              {currentText.more}
            </ButtonContained>
          </div>
        )}
      </Styles.FaqContainer>
      <WhitePaper />
    </>
  );
}

export default Blog;
