import styled from "styled-components";
const GoldenCoin = process.env.REACT_APP_ASSETS_URL + "/imgs/goldenCoin.webp";
import { fonts } from "../../../config";

const Container = styled.div(({ theme, $menuHeight }) => {
  return {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    backgroundColor: "#050500",

    borderRadius: "5px",
    flex: 1,
    width: "100%",
    height: "auto",
    maxHeight: "747px",
    zIndex: 99,
    [`@media (max-width:${1024 - 1}px)`]: {
      maxHeight: "609px",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      maxHeight: "322px",
    },
  };
});

const BgVideoContainer = styled.div(({ theme }) => {
  return {
    display: "flex",
    position: "relative",
    width: "100%",
    zIndex: 0,
    height: "auto",

    [`@media (max-width:${1024 - 1}px)`]: {},
    [`@media (max-width:${581 - 1}px)`]: {},
  };
});

const BgVideo = styled.video(({ theme, $menuHeight }) => {
  return {
    position: "absolute",
    objectFit: "cover",
    width: "100%",
    height: "100%",
    pointerEvents: "none",
    maxHeight: "747px",
    [`@media (max-width:${1024 - 1}px)`]: {
      maxHeight: "609px",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      maxHeight: "322px",
    },
  };
});

const BgForest = styled.div(({ theme, bgImage }) => {
  return {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",

    overflow: "hidden",
    zIndex: 1,
    backgroundImage: `url(${GoldenCoin})`,
    backgroundSize: "1440px auto",
    backgroundPosition: "top",
    height: theme.spacing(50.625),
    backgroundRepeat: "no-repeat",
  };
});

const BgCoinImg = styled.img(() => {
  return {
    position: "absolute",
    top: "56px",
    right: "-48px",
    width: 409,
    height: "auto",
    zIndex: 1,
    transform: "rotate(-10.034deg) translate(45%, -45%)",
    [`@media (max-width:${1024 - 1}px)`]: {
      width: 220,
    },
    [`@media (max-width:${581 - 1}px)`]: {
      width: 135,
    },
  };
});

const TextContainer = styled.div(() => {
  return {
    position: "relative",
    zIndex: 3,
    margin: "200px 0px 200px 120px",
    width: "fit-content",
    alignSelf: "flex-end",
    [`@media (max-width:${1024 - 1}px)`]: {
      margin: "110px 0px 65px 55px",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      margin: "55px 0px 35px 30px",
    },
  };
});

const TextBox = styled.div(() => {
  return {
    background:
      "linear-gradient(148deg, rgba(255, 255, 0, 0.80) 1.24%, rgba(48, 69, 5, 0.00) 96.86%), linear-gradient(150deg, rgba(231, 255, 0, 0.40) 3.77%, rgba(211, 213, 39, 0.40) 95.67%)",
    backdropFilter: "blur(4.14px)",
    width: 295,
    padding: "29px 28px 29px 30px",
    borderRadius: 15,

    [`@media (max-width:${1024 - 1}px)`]: {
      width: 160,
      padding: 16,
    },
    [`@media (max-width:${581 - 1}px)`]: {
      width: 153,
      padding: 15,
      borderRadius: 13,
    },
  };
});

const AboutTitle = styled.p(({ theme }) => {
  return {
    padding: 0,
    margin: 0,
    fontSize: theme.spacing(4.125),
    fontFamily: fonts.heavy,
    color: "white",
    marginBottom: theme.spacing(2.15),
    width: 130,

    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: theme.spacing(2.15),
      marginBottom: theme.spacing(0.7),
      width: 60,
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: theme.spacing(1.94),
      marginBottom: theme.spacing(0.1),
      width: 120,
    },
  };
});

const AboutDescription = styled.p(({ theme }) => {
  return {
    padding: 0,
    margin: 0,
    fontSize: "10.764px",
    fontFamily: fonts.regular,

    color: "white",
    lineHeight: "185.5%",
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: theme.spacing(0.7),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: theme.spacing(0.75),
    },
  };
});

const Styles = {
  Container,
  BgVideoContainer,
  BgVideo,
  BgForest,
  BgCoinImg,
  TextContainer,
  TextBox,
  AboutTitle,
  AboutDescription,
};

export default Styles;
