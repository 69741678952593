import styled from "styled-components";
import { Container as DefaultContainer, IconButton } from "@material-ui/core";
import { Colors, fonts, SVG } from "../../../config";
import MenuIcon from "@material-ui/icons/Menu";
import { ButtonOutlined, FontStyles } from "../../../components";
import ButtonContainedComponent from "../../../components/buttons/ButtonContained";
import { HiMenu } from "react-icons/hi";
const Container = styled.div(({ theme }) => {
  return {
    display: "flex",
    width: "100%",
    backgroundColor: "#18181b",
    paddingBlock: theme.spacing(4.5),
    zIndex: 1,
    height: "min-content",
    justifyContent: "space-between",
    boxShadow: "0px 10px 20px #00000006",
    alignItems: "center",
    [`@media (max-width:${1300 - 1}px)`]: {
      paddingBlock: theme.spacing(5.25),
    },
    [`@media (max-width:${975 - 1}px)`]: {
      paddingBlock: theme.spacing(4.5),
    },
    [`@media (max-width:${526 - 1}px)`]: { paddingBlock: theme.spacing(2.75) },
    [`@media (max-width:${200 - 1}px)`]: {
      justifyContent: "flex-end",
    },
  };
});

const Content = styled(DefaultContainer)(({ theme }) => {
  const { spacing } = theme;
  return {
    maxWidth: "100vw",
    display: "flex",
    width: "100%",
    padding: spacing(0.5),
    paddingLeft: spacing(0),
    alignItems: "center",
    justifyContent: "space-between",
    zIndex: 2,
  };
});

const FirstContentGroup = styled.div(({ theme }) => {
  const { spacing, breakpoints } = theme;
  return {
    display: "flex",
    alignItems: "center",
    padding: spacing(1.75, 2),

    justifyContent: "center",

    [`@media (max-width:${975 - 1}px)`]: {
      display: "none",
    },
  };
});

const UserContentGroup = styled.div(({ theme }) => {
  const { spacing, palette: colors, breakpoints } = theme;
  return {
    display: "flex",
    alignItems: "center",
    paddingBlock: spacing(2),
    paddingRight: "clamp(18px,5vw,106px)",
    justifyContent: "flex-end",
    width: "min-content",
    maxWidth: spacing(37),
    minWidth: "300px",

    gap: spacing(1),
    [`@media (max-width:${1300 - 1}px)`]: {
      minWidth: "250px",
    },
    [`@media (max-width:${975 - 1}px)`]: {
      display: "none",
    },
  };
});

const UserContentGroupMobile = styled.div(({ theme }) => {
  const { spacing, palette: colors, breakpoints } = theme;
  return {
    display: "none",

    alignItems: "center",
    paddingBlock: spacing(2),
    paddingRight: "clamp(16px,5vw,106px)",
    justifyContent: "flex-end",
    width: "min-content",
    maxWidth: spacing(37),
    cursor: "pointer",
    gap: spacing(1),
    [`@media (max-width:${975 - 1}px)`]: {
      display: "flex",
    },
  };
});

const LogoContainer = styled.div(({ theme }) => {
  const { spacing, palette: colors, breakpoints } = theme;
  return {
    gap: spacing(1),
    display: "flex",
    alignItems: "center",
    //maxWidth: spacing(25.53),
    paddingLeft: "clamp(18px,5vw,106px)",
    [`@media (max-width:${1300 - 1}px)`]: {
      paddingLeft: spacing(4),
      maxWidth: spacing(15.25),
    },
    [`@media (max-width:${780 - 1}px)`]: {
      paddingLeft: spacing(2),
      maxWidth: spacing(13.25),
    },

    [`@media (max-width:${975 - 1}px)`]: {
      maxWidth: "fit-content",

      alignItems: "flex-end",
    },

    [`@media (max-width:${200 - 1}px)`]: {
      display: "none",
    },
  };
});

const Rectangle = styled(IconButton)(({ theme }) => {
  const { palette: colors, spacing, breakpoints } = theme;
  return {
    display: "none",

    [breakpoints.down("sm")]: {
      display: "inline-block",
      minWidth: 0,
      borderRadius: 0,
      padding: spacing(0.3),
      backgroundColor: colors.text.secondary,
      width: "auto",
      height: "auto",
      marginBlock: spacing(0.3),
      color: "white",
    },
  };
});

const RectangleTwo = styled.div(({ theme }) => {
  const { palette: colors, spacing, breakpoints } = theme;
  return {
    display: "inline-block",
    width: spacing(3),
    height: spacing(2.625),
    backgroundColor: colors.background.default,

    [breakpoints.down("sm")]: {
      display: "none",
    },
  };
});

const LineBody = styled.div(({ theme }) => {
  const { palette: colors, spacing, breakpoints } = theme;
  return {
    backgroundColor: "transparent",
    borderLeft: spacing(1) + "px solid " + colors.text.primary,
    paddingLeft: spacing(0.5),
    height: "100%",
    marginRight: spacing(2),

    [breakpoints.down("sm")]: {
      display: "none",
    },
  };
});

const SubLine = styled.div(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    backgroundColor: colors.text.primary,
    width: spacing(0.5),
    height: "100%",
  };
});

const IconMenu = styled(MenuIcon)(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    color: colors.text.primary,
  };
});

const NavButton = styled.a(({ theme, $current }) => {
  const { palette: colors, spacing } = theme;
  return {
    color: colors.secondary.main,
    cursor: "pointer",
    textTransform: "capitalize",
    paddingInline: spacing(2.375),
    paddingBlock: spacing(1),
    ...FontStyles.regular16,
    fontFamily: $current ? fonts.heavy : fonts.regular,
    "&:hover": {},

    [`@media (max-width:${1300 - 1}px)`]: {
      fontSize: spacing(1.5),
    },
    [`@media (max-width:${780 - 1}px)`]: {
      fontSize: spacing(1.125),
    },
  };
});
const ButtonLogin = styled(ButtonContainedComponent)(({ theme }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      maxWidth: spacing(13.5),
      paddingBlock: spacing(1),
      [`@media (max-width:${1300 - 1}px)`]: {
        maxWidth: spacing(11.45),
        fontSize: spacing(1.25),
      },
      [`@media (max-width:${780 - 1}px)`]: {
        maxWidth: spacing(8.7),
        fontSize: spacing(0.81525),
      },
      [`@media (max-width:${975 - 1}px)`]: {
        display: "none",
      },
    },
  };
});

const ButtonRegister = styled(ButtonOutlined)(({ theme }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      maxWidth: spacing(13.5),
      paddingBlock: spacing(1),
      whiteSpace: "nowrap",

      [`@media (max-width:${1300 - 1}px)`]: {
        maxWidth: spacing(11.45),
        fontSize: spacing(1.25),
      },
      [`@media (max-width:${780 - 1}px)`]: {
        maxWidth: spacing(8.7),
        fontSize: spacing(0.81525),
      },
      [`@media (max-width:${975 - 1}px)`]: {
        display: "none",
      },
      "&:hover": {
        backgroundColor: "transparent",
      },

      "&:active": {
        backgroundColor: "transparent",
      },
    },
  };
});

const MenuButton = styled(IconButton)(({ theme }) => {
  const { spacing } = theme;
  return {
    "&&.MuiIconButton-root": {
      maxWidth: spacing(5.5),
      maxHeight: spacing(6.5),
      padding: 0,
      [`@media (min-width:${975}px)`]: {
        display: "none",
      },
    },
  };
});

const Br = styled(SVG.brFlag)(({ theme }) => {
  const { spacing } = theme;
  return {
    maxWidth: "25px",
    maxHeight: "25px",
    [`@media (max-width:${1300 - 1}px)`]: {
      maxWidth: "20px",
      maxHeight: "20px",
    },
    [`@media (max-width:${780 - 1}px)`]: {
      maxWidth: "10px",
      maxHeight: "10px",
    },
  };
});
const Us = styled(SVG.usFlag)(({ theme }) => {
  const { spacing } = theme;
  return {
    maxWidth: "25px",
    maxHeight: "25px",
    [`@media (max-width:${1300 - 1}px)`]: {
      maxWidth: "20px",
      maxHeight: "20px",
    },
    [`@media (max-width:${780 - 1}px)`]: {
      maxWidth: "10px",
      maxHeight: "10px",
    },
  };
});

const MenuIconA = styled(HiMenu)(({ theme }) => {
  const { spacing } = theme;
  return {
    color: "#D2E702",

    height: "58.705px",
    width: "83.54px",
    [`@media (max-width:${780 - 1}px)`]: {
      width: "44.128px",
      height: "31.009px",
    },
  };
});

const Styles = {
  Container,
  Content,
  FirstContentGroup,
  UserContentGroup,
  UserContentGroupMobile,
  Rectangle,
  RectangleTwo,
  LineBody,
  SubLine,
  IconMenu,
  NavButton,
  ButtonLogin,
  ButtonRegister,
  LogoContainer,
  MenuButton,
  Br,
  MenuIconA,
  Us,
};

export default Styles;
