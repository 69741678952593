import styled from "styled-components";
import { SVG, fonts } from "../../../config";

const Container = styled.div(({ backgroundImg }) => {
  return {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: "100%",
    maxHeight: "1400px",

    backgroundImage: `url(${backgroundImg})`,
    backgroundSize: "100% 100%",
    backgroundRepeat: "repeat",
    backgroundPosition: "center",

    [`@media (max-width:${1024 - 1}px)`]: { maxHeight: "1570px" },
    [`@media (max-width:${581 - 1}px)`]: { maxHeight: "786px" },
  };
});

const GreenBorder = styled.div(() => {
  return {
    display: "none",

    [`@media (max-width:${1024 - 1}px)`]: {
      display: "flex",
      width: "100%",
      background: "#E7FF00",
      height: 7,
    },
    [`@media (max-width:${581 - 1}px)`]: {
      height: 4,
    },
  };
});

const AboutTexts = styled.div(({ theme }) => {
  return {
    fontSize: theme.spacing(3.12),
    color: "white",
    fontFamily: fonts.regularCf,

    [`@media (max-width:${1024 - 1}px)`]: {
      display: "none",
    },
  };
});

const DifferentialTitle = styled.p(({ theme, roadmap }) => {
  return {
    display: "flex",
    padding: 0,
    margin: 0,
    marginTop: 45,
    marginBottom: 16,
    fontSize: theme.spacing(5.6),
    color: "white",
    fontFamily: fonts.heavy,
    lineHeight: "normal",
    alignSelf: "center",
    zIndex: 1,

    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: roadmap ? theme.spacing(6.95) : theme.spacing(5.8),
      marginTop: 35,
      marginBottom: 6,
    },

    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: theme.spacing(3.1),
      marginTop: 15,
      marginBottom: 2,
    },
  };
});

const DifferentialText = styled.p(({ theme, desktop }) => {
  return {
    margin: 0,
    padding: 0,
    display: "flex",
    fontFamily: fonts.light,
    fontSize: theme.spacing(2.75),
    color: "white",
    alignSelf: "center",
    zIndex: 1,

    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: theme.spacing(2.8),
      marginBottom: theme.spacing(5),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: theme.spacing(1.5),
      marginBottom: theme.spacing(3),
    },
    [`@media (max-width:${321 - 1}px)`]: {
      fontSize: theme.spacing(1.2),
      marginBottom: theme.spacing(3),
    },
  };
});

const RoadmapContainer = styled.div(({ mobile }) => {
  return {
    display: !mobile ? "flex" : "none",
    justifyContent: !mobile ? "center" : null,
    marginLeft: 240,
    width: "100%",
    height: mobile && "100%",
    [`@media (max-width:${1600 - 1}px)`]: {
      marginLeft: 120,
    },
    [`@media (max-width:${1024 - 1}px)`]: {
      display: mobile ? "flex" : "none",
      width: mobile ? "100vw" : null,
      overflowX: mobile ? "scroll" : null,
      overflowY: mobile ? "hidden" : null,
      alignSelf: "flex-start",
      marginLeft: 43,
    },
    [`@media (max-width:${581 - 1}px)`]: {
      marginLeft: 30,
    },
  };
});

const RoadmapContentMobile = styled.div(() => {
  return {
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignContent: "flex-start",
    alignItems: "center",
    width: "100%",
    minWidth: "max-content",
    height: "520px",
    [`@media (max-width:${581 - 1}px)`]: {
      height: "auto",
    },
  };
});

const RoadmapText = styled.p(({ theme, title }) => {
  return {
    margin: 0,
    padding: 0,
    fontSize: title ? theme.spacing(2.1) : theme.spacing(1.75),
    fontFamily: title ? fonts.boldCf : fonts.lightCf,
    color: "white",
    height: title ? 37 : 400,
    maxWidth: 210,
    lineHeight: "180%",

    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: theme.spacing(2.08),
      height: title ? 52 : 100,
      maxWidth: 283,
      marginLeft: 30,
      marginTop: !title ? 60 : 0,
      marginBottom: title ? 20 : 0,
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: theme.spacing(1.1),
      height: title ? 27 : 52,
      maxWidth: 125,
      marginLeft: 0,
      marginTop: !title ? 20 : 0,
    },
  };
});

const RoadmapTitle = styled.p(({ theme }) => {
  return {
    fontFamily: fonts.regularCf,
    color: "white",
    zIndex: 1,
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: theme.spacing(4.35),
      marginLeft: -215,
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: theme.spacing(2.3),
      marginLeft: -125,
    },
  };
});

const RoadmapLineContainer = styled.div(() => {
  return {
    position: "relative",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginLeft: -205,
    marginBlock: 50,
    [`@media (max-width:${1600 - 1}px)`]: {
      marginLeft: -120,
    },
    [`@media (max-width:${581 - 1}px)`]: {
      marginLeft: -110,
      marginBlock: 6,
    },
  };
});

const GreenDot = styled.div(() => {
  return {
    position: "absolute",
    left: "50%",
    background: "#e7ff00",
    width: 14,
    height: 14,
    borderRadius: 50,
    zIndex: 1,

    [`@media (max-width:${1024 - 1}px)`]: {
      width: 19,
      height: 19,
    },
    [`@media (max-width:${581 - 1}px)`]: {
      width: 10,
      height: 10,
    },
  };
});

const Line = styled.div(({ middle, end }) => {
  return {
    position: "relative",
    display: "flex",
    background: "white",
    width: middle ? "100%" : "50%",
    transform: end && "translate(-101%,0%)",
    height: 1,
    zIndex: 0,
  };
});

const BoxContainer = styled.div(() => {
  return {
    display: "flex",
    marginTop: 40,

    [`@media (max-width:${1024 - 1}px)`]: {
      display: "none",
    },
  };
});

const BoxContainerMobile = styled.div(() => {
  return {
    display: "none",

    [`@media (max-width:${1024 - 1}px)`]: {
      display: "flex",
      height: "1900px",
      width: "100%",
      overflowX: "scroll",
      overflowY: "hidden",
      marginBottom: 35,
    },
    [`@media (max-width:${581 - 1}px)`]: {
      height: "1200px",
      marginBottom: 0,
    },
  };
});

const BoxContent = styled.div(({ index }: any) => {
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "start",
    borderRadius: 28,
    border: "0.71px solid #4C4F51",
    background:
      "linear-gradient(95deg, rgba(26, 32, 36, 0.64) -3.04%, rgba(5, 5, 0, 0.80) 100%)",
    backdropFilter: "blur(7.11px)",
    padding: "0px 35px 50px 35px",
    margin: 0,
    marginTop: 115,
    marginInline: 17,
    height: 510,

    [`@media (max-width:${1024 - 1}px)`]: {
      marginLeft: 35,
      marginRight: index === 5 ? 35 : 0,
      borderRadius: 31,
      height: "auto",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      padding: "0px 20px 27px 20px",
      marginTop: 50,
      marginLeft: 20,
      marginRight: index === 5 ? 20 : 0,
      borderRadius: 16,
    },
  };
});

const BoxTitle = styled.p(({ theme }) => {
  return {
    fontFamily: fonts.boldCf,
    fontSize: theme.spacing(1.75),
    color: "white",
    width: 235,
    textAlign: "center",

    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: theme.spacing(2),
      height: 40,
      width: 215,
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: theme.spacing(1.05),
      height: 22,
      width: 115,
    },
  };
});

const BoxText = styled.p(({ theme }) => {
  return {
    fontFamily: fonts.lightCf,
    fontSize: theme.spacing(1.5),
    color: "white",
    width: 235,
    lineHeight: "180%",

    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: theme.spacing(1.75),
      width: 265,
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: theme.spacing(0.9),
      width: 140,
    },
  };
});

const CoinIcon = styled.img(() => {
  return {
    width: 170,
    height: "auto",
    marginTop: -100,
    marginBottom: 20,

    [`@media (max-width:${1024 - 1}px)`]: {
      width: 194,
      marginTop: -115,
      marginBottom: 10,
    },
    [`@media (max-width:${581 - 1}px)`]: {
      width: 102,
      marginTop: -50,
      marginBottom: 7,
    },
  };
});

const ArrowIcon = styled(SVG.ArrowIcon)(({ right, roadmap }) => {
  return {
    position: "absolute",
    top: !roadmap ? 350 : null,
    bottom: roadmap ? 145 : null,
    right: roadmap & right ? 310 : right ? 310 : null,
    left: !right ? 310 : null,
    width: 70,
    height: "auto",
    cursor: "pointer",
    transform: !right ? "scaleX(-1)" : "none",

    [`@media (max-width:${1600 - 1}px)`]: {
      right: roadmap & right ? "7vw" : right ? "7vw" : null,
      left: !right ? "7vw" : null,
      bottom: roadmap ? 140 : null,
    },
    [`@media (max-width:${1300 - 1}px)`]: {
      right: roadmap & right ? "3vw" : right ? "3vw" : null,
      left: !right ? "3vw" : null,
      width: 40,
    },
  };
});

const GreenBlur = styled.div(({ right }) => {
  return {
    position: "absolute",
    width: 400,
    height: 820,
    top: right ? -200 : null,
    right: right ? -210 : null,
    bottom: !right ? -200 : null,
    left: !right ? -210 : null,
    borderRadius: right ? "920px 0 920px 920px" : "920px 920px 920px 0",
    background: "#E7FF00",
    filter: "blur(180px)",

    [`@media (max-width:${1024 - 1}px)`]: {
      height: 410,
      top: right ? -180 : null,
      right: right ? -200 : null,
      bottom: !right ? -80 : null,
      left: !right ? -200 : null,
      filter: "blur(160px)",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      width: 200,
      height: 410,
      top: right ? -180 : null,
      right: right ? -150 : null,
      bottom: !right ? -100 : null,
      left: !right ? -120 : null,
      filter: "blur(100px)",
    },
  };
});

const Styles = {
  Container,
  AboutTexts,
  DifferentialTitle,
  BoxContainer,
  BoxContainerMobile,
  BoxContent,
  BoxTitle,
  BoxText,
  CoinIcon,
  DifferentialText,
  ArrowIcon,
  GreenBlur,
  RoadmapContainer,
  RoadmapContentMobile,
  RoadmapText,
  RoadmapTitle,
  RoadmapLineContainer,
  GreenDot,
  Line,
  GreenBorder,
};

export default Styles;
