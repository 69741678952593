import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ptBr from "../../config/texts/pt-br";
import { Swiper } from "../../components/index";
import Styles from "./EsqueceuSenhaStyles";

import { hooks, LocalStorage, SessionStorage } from "../../utils";
import StepEmail from "./Steps/StepEmail";

import { customModal } from "../../components/modals/utils";
import StepPassword from "./Steps/StepPassword";
import { api } from "../../services";
import { paths } from "../../navigation/navigate";
import ResponseError from "../../services/helpers/ResponseError";
import Texts from "../../config/texts";

function EsqueceuSenha() {
  const texts =
    Texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].forgetPassword;
  //const version = packageJson.version;
  const navigate = useNavigate();
  const { loading, call } = hooks.useRequest();
  const [formData, setFormData] = useState<any>({});
  const [menuHeight, setHeight] = React.useState<any>(null);

  React.useLayoutEffect(() => {
    if (!menuHeight) {
      const mh = document.getElementById("header-menu")?.offsetHeight;

      setHeight(mh);
    }
  }, [menuHeight]);

  const FinishStepOne = async (data, Callback) => {
    setFormData({ ...formData, ...data });
    call(null, api.sendRecoveryPasswordRequest(data), (response) => {
      if (response.ok) {
        Callback();
      }
    });
  };

  const FinishStepTwo = async (data) => {
    call(
      null,
      api.recoveryPasswordValidate({ email: data?.email, code: data?.code }),
      (token) => {
        if (token.ok) {
          call(null, api.sendRecoveryPasswordNewPassword(data), (response) => {
            if (response.ok) {
              LocalStorage.removeItem("token");
              customModal.setInfos(
                texts.modal.title,
                [texts.modal.content],
                {
                  onClick: () => {
                    navigate(paths.login);
                    customModal.close();
                  },
                  label: texts.modal.button,
                },
                null,
                null,
                true
              );
            }
          });
        } else {
          const errorResponse = new ResponseError(token);
          errorResponse.alertMessage();
        }
      }
    );
  };

  return (
    <Styles.Container $menuHeight={menuHeight}>
      <Styles.ImageBG $menuHeight={menuHeight}>
        <Styles.YellowBall></Styles.YellowBall>
        <Styles.YellowBallTwo></Styles.YellowBallTwo>
        <Swiper
          screens={[
            ({ goNext }) => (
              <StepEmail
                loading={loading}
                onSubmit={(data) => {
                  FinishStepOne(data, goNext);
                }}
                goBack={() => navigate(paths.login)}
              />
            ),
            ({ goNext, goPrevious }) => (
              <StepPassword
                lastData={formData}
                onSubmit={(data) => {
                  FinishStepTwo(data);
                }}
                goBack={() => goPrevious()}
                goResend={(callback) => FinishStepOne(formData, callback)}
                loading={loading}
              />
            ),
          ]}
        />
      </Styles.ImageBG>
    </Styles.Container>
  );
}

export default EsqueceuSenha;
