import styled from "styled-components";

import { ButtonContained, FontStyles } from "../../../components";
import { fonts } from "../../../config";

const YellowBall = styled.div(() => {
  return {
    position: "absolute",
    zIndex: -1,

    top: 0,
    right: 0,
    transform: "translate(90%, -50%)",
    borderRadius: "996px",
    width: "798.961px",
    height: "996px",
    filter: "blur(225px)",
    background: "#E7FF00E6",

    [`@media (max-width:${1024 - 1}px)`]: {
      borderRadius: "514.6px",
      width: "412.797px",
      height: "514.6px",
      filter: "blur(116.25px)",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      transform: "translate(50%, -50%)",
      borderRadius: "310px",
      width: "310px",
      height: "310px",
      filter: "blur(100px)",
    },
  };
});

const YellowBallTwo = styled.div(() => {
  return {
    position: "absolute",
    transform: "translate(-40%, 50%)",
    bottom: 0,
    left: 0,
    borderRadius: "631px",
    width: "631px",
    height: "601px",
    background: "#E7FF00E6",
    filter: "blur(300px)",
    zIndex: -1,

    [`@media (max-width:${1024 - 1}px)`]: {
      borderRadius: "310.517px",
      width: "326.017px",
      height: "310.517px",
      filter: "blur(155px)",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      transform: "translate(-105%, 40%)",
      borderRadius: "540.567px",
      width: "640.189px",
      height: "540.567px",
      filter: "blur(300px)",
    },
  };
});

const DashContainer = styled.div(({ theme, $image }) => {
  const { spacing } = theme;
  return {
    position: "relative",
    width: "100%",

    backgroundColor: "black",
    zIndex: 1,
    backgroundImage: `url(${$image})`,
    paddingBlock: spacing(9.25),
    paddingInline: spacing(12.5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    [`@media (max-width:${1024 - 1}px)`]: {
      padding: spacing(6),
    },
    [`@media (max-width:${581 - 1}px)`]: { padding: spacing(2.5) },
  };
});

const WhiteBox = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    borderRadius: "43.919px",
    background: "#F2F2F2",
    backdropFilter: "blur(18.822629928588867px)",

    width: "100%",
    zIndex: 3,
    padding: spacing(10),

    [`@media (max-width:${1024 - 1}px)`]: {
      paddingInline: `clamp(48px, 8vw, 90px)`,
    },
    [`@media (max-width:${581 - 1}px)`]: {
      paddingInline: `clamp(24px, 11vw, 468px)`,
      paddingBlock: `clamp(18px, 8vw, 32px)`,
    },
  };
});

const TitleBox = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    paddingBottom: spacing(2),
    [`@media (max-width:${1024 - 1}px)`]: {},
    [`@media (max-width:${581 - 1}px)`]: { paddingBottom: spacing(0) },
  };
});

const TitleConfig = styled.p(({ theme }) => {
  const { spacing } = theme;
  return {
    margin: 0,
    fontSize: spacing(4.5),
    fontFamily: fonts.heavy,
    [`@media (max-width:${1024 - 1}px)`]: { fontSize: spacing(3.875) },
    [`@media (max-width:${581 - 1}px)`]: { fontSize: spacing(2) },
  };
});

const Title = styled.p(({ theme }) => {
  const { spacing } = theme;
  return {
    margin: 0,
    fontFamily: fonts.heavy,
    fontSize: spacing(5.25),
    lineHeight: "58.8px",
    [`@media (max-width:${1024 - 1}px)`]: { fontSize: spacing(4.75) },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: spacing(2),
      lineHeight: "27.937px",
    },
  };
});

const RequestTransfer = styled(ButtonContained)(({ theme }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      width: "673px",
      height: "89px",
      fontSize: spacing(5),
      borderRadius: spacing(8.3375),
      marginTop: spacing(8.4375),
      [`@media (max-width:${1024 - 1}px)`]: {
        width: "569.832px",
        height: "79.511px",
        fontSize: spacing(4.45),
        borderRadius: spacing(7.5),
        marginTop: spacing(11),
      },
      [`@media (max-width:${745 - 1}px)`]: {
        width: "450px",
        height: "60px",
        fontSize: spacing(3),
        marginTop: spacing(8),
      },
      [`@media (max-width:${581 - 1}px)`]: {
        width: "301px",
        height: "42px",
        fontSize: spacing(2.35),
        borderRadius: spacing(3.9625),
        marginTop: spacing(5.15),
      },
      [`@media (max-width:${426 - 1}px)`]: {
        width: "250px",
        height: "36px",
        fontSize: spacing(2),
        marginTop: spacing(4),
      },
    },
  };
});

const Back = styled(ButtonContained)(({ theme }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      paddingInline: spacing(4),
      paddingBlock: spacing(2.25),
      fontSize: spacing(2),
      borderRadius: spacing(2),
      marginTop: spacing(4),
      [`@media (max-width:${1024 - 1}px)`]: {
        borderRadius: spacing(3),
        paddingInline: spacing(5.25),
        paddingBlock: spacing(2.5),
        fontSize: spacing(2.5),
        marginLeft: 0,
      },
      [`@media (max-width:${581 - 1}px)`]: {
        borderRadius: spacing(1.5),
        paddingInline: spacing(2.25),
        paddingBlock: spacing(1.5),
        fontSize: spacing(1.5),
        marginTop: spacing(1.5),
      },
    },
  };
});

const InputTitle = styled.p(({ theme }) => {
  const { spacing } = theme;
  return {
    ...FontStyles.regular18,
    fontSize: spacing(2.75),
    margin: 0,
    padding: 0,
    color: "#1A2024",
    lineHeight: "140.791%",
    fontFamily: fonts.bold,

    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: spacing(1.5),
    },
  };
});

const ModalImport = styled(ButtonContained)(({ theme, $full, $image }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      maxWidth: $full ? "fit-content" : "calc(50% - 8px)",
      width: "100%",
      fontSize: spacing(2.25),
      paddingInline: spacing(2.5),
      marginRight: spacing(1.5),
      marginTop: 0,
      [`@media (max-width:${1024 - 1}px)`]: {
        paddingInline: spacing(2.1),
        marginRight: spacing(1),
      },
      [`@media (max-width:${581 - 1}px)`]: {
        marginRight: spacing(0),
        marginInline: spacing(0.5),
        fontSize: spacing(1.0775),
        paddingInline: spacing(2),
      },
    },
  };
});

const ModalImportSend = styled(ButtonContained)(({ theme, $full, $image }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      maxWidth: $full ? "fit-content" : "45%",

      fontSize: spacing(1.5),
      paddingBlock: spacing(1.5),
      paddingInline: spacing(5),

      [`@media (max-width:${1024 - 1}px)`]: {},
      [`@media (max-width:${581 - 1}px)`]: {},
    },
  };
});

const ModalImportText = styled.p(({ theme, $center, $image }) => {
  const { spacing } = theme;
  return {
    fontSize: spacing(2.25),
    margin: 0,
    color: "#079CFF",
    textDecorationLine: "underline",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    [`@media (max-width:${1024 - 1}px)`]: {},
    [`@media (max-width:${581 - 1}px)`]: {},
  };
});

const SelfieInput = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    background: "#FFF",
    borderRadius: "95px",
    border: "1.5px solid #01003B",
    paddingInline: spacing(3.75),
    paddingBlock: spacing(1),
    marginTop: spacing(0.75),
    [`@media (max-width:${1024 - 1}px)`]: {
      paddingBlock: spacing(1.5),
      paddingInline: spacing(1.75),
      fontSize: spacing(1.6),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      paddingInline: spacing(0),
      paddingBlock: spacing(0.5),
      fontSize: spacing(1),
      marginTop: spacing(0.15),
    },
  };
});

const SelfieName = styled.p(({ theme }) => {
  const { spacing } = theme;
  return {
    color: "black",
    margin: 0,
    fontFamily: fonts.medium,
    fontSize: spacing(2.625),
    [`@media (max-width:${1024 - 1}px)`]: { fontSize: spacing(2) },
    [`@media (max-width:${581 - 1}px)`]: { fontSize: spacing(1.336) },
  };
});

const Styles = {
  YellowBallTwo,
  YellowBall,

  WhiteBox,
  DashContainer,
  TitleBox,
  TitleConfig,

  Title,
  RequestTransfer,
  Back,

  InputTitle,

  ModalImport,
  ModalImportSend,
  ModalImportText,
  SelfieInput,
  SelfieName,
};

export default Styles;
