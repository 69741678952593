import React, { useContext } from "react";

const GrainImage = process.env.REACT_APP_ASSETS_URL + "/imgs/grainBackground.webp";

import Styles from "./styles";

import { FaRegCreditCard } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import { SVG } from "../../config";

import { Swiper } from "../../components";
import MainPage from "./components/MainPage";
import Password from "./components/Password";
import ptBr from "../../config/texts/pt-br";
import ReRegister from "./components/ReRegister";
import UserData from "./components/UserData";
import { SessionStorage, hooks, LocalStorage } from "../../utils";
import { StorageContext } from "../../contexts/StorageContext";
import { api } from "../../services";
import Security from "./components/Security";
import Authentication from "./components/Authentication";
import ResponseError from "../../services/helpers/ResponseError";
import alerts from "../../utils/Alerts";
import { CancelToken } from "apisauce";
import { paths } from "../../navigation/navigate";
import Texts from "../../config/texts";
type HorizontalScrollableDivProps = {
  className?: string;
};
function Configs() {
  const navigate = useNavigate();
  const { loading, call } = hooks.useRequest();
  const { isLogged, userData, setUserData, logOut, addData } =
    useContext<any>(StorageContext);
  const [isMounted, setMount] = React.useState<boolean>(false);
  const [qrCode, setQrCode] = React.useState<string>("");
  const texts =
    Texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].config;
  const [menuHeight, setHeight] = React.useState<any>(null);

  const Mount = React.useCallback(() => {
    if (!userData && isLogged) {
      call(
        null,
        api.getUserData(),
        (data) => {
          if (data.ok) {
            if (data.data.imageId) {
              call(
                null,
                api.getUserImage(),
                (response) => {
                  addData("userData", { ...data?.data, image: response?.data });
                  setUserData({ ...data?.data, image: response?.data });
                },
                (response) => {
                  if (response.status !== 404) {
                    const errorResponse = new ResponseError(response);

                    if (errorResponse.message) {
                      alerts.alertError(errorResponse.message);
                    }
                  }
                }
              );
            } else {
              addData("userData", { ...data?.data, image: null });
              setUserData({ ...data?.data, image: null });
            }
          }
        },
        (response) => {
          if (response.status === 401) {
            logOut();
          } else {
            const errorResponse = new ResponseError(response);

            if (errorResponse.message) {
              alerts.alertError(errorResponse.message);
            }
          }
        }
      );
    }
  }, [userData, isLogged]);

  React.useEffect(() => {
    if (!isMounted) {
      Mount();
      setMount(true);
    }
  }, [Mount, isMounted]);
  const lastPage = () => {
    navigate(-1);
  };

  const className = "null";
  const list = [
    {
      icon: SVG.Sefaty,

      id: 1,
      ...texts.list[0],
    },
    {
      icon: SVG.Folder,

      id: 2,
      ...texts.list[1],
    },
    {
      icon: SVG.Locker,

      id: 3,
      ...texts.list[2],
    },
    {
      icon: SVG.Briefcase,

      id: 4,
      ...texts.list[3],
    },
    // {
    //   icon: FaRegCreditCard,

    //   id: 5,
    //   ...texts.list[4],
    // },
  ];
  React.useLayoutEffect(() => {
    if (!menuHeight) {
      const mh = document.getElementById("header-menu")?.offsetHeight;

      setHeight(mh);
    }
  }, [menuHeight]);

  const getQrCode = () => {
    call(null, api.getQRCodeT2F(), (response) => {
      if (response.ok) {
        setQrCode(response.data);
      }
    });
  };

  const twoFactorAuthentication = (data) => {
    const source = CancelToken.source();
    call(null, api.send2FA(data, { cancelToken: source.token }), (response) => {
      if (response.ok) {
        LocalStorage.setItem("userData", response.data);
        call(
          null,
          api.getUserData(),
          (data) => {
            if (data.ok) {
              if (data.data.imageId) {
                call(
                  null,
                  api.getUserImage(),
                  (response) => {
                    addData("userData", {
                      ...data?.data,
                      image: response?.data,
                    });
                    setUserData({ ...data?.data, image: response?.data });
                  },
                  (response) => {
                    if (response.status !== 404) {
                      const errorResponse = new ResponseError(response);

                      if (errorResponse.message) {
                        alerts.alertError(errorResponse.message);
                      }
                    }
                  }
                );
              } else {
                addData("userData", { ...data?.data, image: null });
                setUserData({ ...data?.data, image: null });
              }
            }
          },
          (response) => {
            if (response.status === 401) {
              logOut();
            } else {
              const errorResponse = new ResponseError(response);

              if (errorResponse.message) {
                alerts.alertError(errorResponse.message);
              }
            }
          }
        );
        navigate(paths.dashboard);
      }
    });
  };

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        overflow: "hidden",
        flexDirection: "column",
      }}
    >
      <Styles.DashContainer $image={GrainImage}>
        <Styles.YellowBall></Styles.YellowBall>
        <Styles.YellowBallTwo></Styles.YellowBallTwo>
        <Swiper
          screens={[
            ({ goTo }) => (
              <MainPage
                user={userData}
                className={className}
                list={list}
                goBack={lastPage}
                goNext={goTo}
              />
            ),
            ({ goTo }) => (
              <Security
                fullWidth={false}
                user={userData}
                goBack={() => {
                  goTo(0);
                }}
                goNext={(index) => {
                  goTo(index);
                  getQrCode();
                }}
              >
                Voltar
              </Security>
            ),
            ({ goTo }) => (
              <UserData
                user={userData}
                goBack={() => {
                  goTo(0);
                }}
                goNext={() => {
                  goTo(0);
                }}
              />
            ),
            ({ goTo, goNext }) => (
              <Password
                user={userData}
                goBack={() => {
                  goTo(0);
                }}
                goNext={() => {
                  goTo(0);
                }}
              />
            ),
            ({ goTo, goNext }) => (
              <ReRegister
                user={userData}
                goBack={() => {
                  goTo(0);
                }}
                goNext={() => {
                  goTo(0);
                }}
              />
            ),
            ({ goTo, goNext }) => (
              <Authentication
                user={userData}
                qrCode={qrCode}
                loading={loading}
                twoFactorAuth={twoFactorAuthentication}
                goBack={() => {
                  goTo(1);
                }}
                goNext={() => {
                  goTo(0);
                }}
              />
            ),
          ]}
        />
      </Styles.DashContainer>
    </div>
  );
}

export default Configs;
