import { Button, IconButton } from "@material-ui/core";
import { url } from "inspector";
import styled from "styled-components";
const leafBackgorund = process.env.REACT_APP_ASSETS_URL + "/imgs/blogBg.webp";
import { ButtonContained, FontStyles } from "../../../components";
import { fonts, SVG } from "../../../config";
const Container = styled.div(({ theme, $menuHeight, $bgImg }) => {
  const { spacing } = theme;
  return {
    position: "relative",
    display: "flex",
    width: "100%",

    overflow: "hidden",
    height: "fit-content",

    marginTop: `-${spacing(-16.25) - $menuHeight}px`,
    backgroundColor: "black",
    alignItems: "flex-end",
    justifyContent: "flex-start",

    [`@media (max-width:${1024 - 1}px)`]: {
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      marginTop: 0,

      height: "fit-content",
      maxHeight: "none",
    },
    [`@media (max-width:${581 - 1}px)`]: {},
  };
});

const BackgroundVideo = styled.video(({ theme, $menuHeight, $bgImg }) => {
  const { spacing } = theme;
  return {
    objectFit: "cover",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    zIndex: 0,
    pointerEvents: "none",
    [`@media (max-width:${1024 - 1}px)`]: {},
  };
});
const PagTitle = styled.p(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    fontFamily: fonts.boldCf,
    lineHeight: "131%",
    color: "white",
    fontSize: spacing(3),
    padding: 0,
    margin: 0,
    paddingBottom: spacing(3.25),
    [`@media (max-width:${1024 - 1}px)`]: {
      lineHeight: "131%",
      color: "white",
      fontSize: spacing(5.5),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      lineHeight: "131%",
      fontSize: spacing(2.75),
      paddingBottom: spacing(2.25),
    },
  };
});

const EmptyTitle = styled.p(({ theme, all }) => {
  const { spacing } = theme;
  return {
    fontFamily: fonts.regularCf,
    lineHeight: "131%",
    color: "white",
    fontSize: spacing(3),
    padding: 0,
    margin: 0,
    textAlign: "center",
    [`@media (max-width:${1024 - 1}px)`]: {
      lineHeight: "131%",
      color: "black",
      fontSize: spacing(5.5),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      lineHeight: "131%",
      fontSize: spacing(2.75),
    },
  };
});

const BlurContainer = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    paddingInline: "100px 70px",
    paddingBlock: "60px",
    background: "rgba(255, 255, 255, 0.04)",
    backdropFilter: "blur(35px)",
    height: "100%",
    display: "flex",
    flexDirection: "column",

    [`@media (max-width:${1024 - 1}px)`]: {
      width: "100%",
      paddingInline: "clamp(32px, 9vw, 69px)",
      paddingBlock: "clamp(32px, 6vw, 47px)",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      paddingInline: "clamp(16px, 9vw, 36px)",
      paddingBlock: "clamp(10px, 6vw, 24px)",
    },
  };
});

const BlurContainerEmpty = styled.div(({ theme, $all }) => {
  const { spacing } = theme;
  return {
    padding: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(255, 255, 255, 0.04)",
    backdropFilter: "blur(35px)",
    width: $all && "100%",
    height: $all ? "100%" : "auto",

    [`@media (max-width:${1024 - 1}px)`]: {
      padding: "32px",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      padding: "16px",
    },
  };
});

const NameTitle = styled.p(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    fontFamily: fonts.regularCf,
    lineHeight: "140%",
    color: "white",
    fontSize: spacing(1.75),
    padding: 0,
    margin: 0,
    paddingBottom: spacing(1.25),
    [`@media (max-width:${1024 - 1}px)`]: {
      lineHeight: "140%",
      fontSize: spacing(3.5),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      lineHeight: "131%",
      paddingBottom: spacing(0.6),
      fontSize: spacing(1.75),
    },
  };
});
const NameTitleCard = styled.p(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    fontFamily: fonts.regularCf,
    lineHeight: "140%",
    color: "white",
    fontSize: spacing(2.25),
    padding: 0,
    margin: 0,

    [`@media (max-width:${1024 - 1}px)`]: {
      lineHeight: "140%",
      fontSize: spacing(4.25),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      lineHeight: "131%",

      fontSize: spacing(2.25),
    },
  };
});
const LocText = styled.p(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    fontFamily: fonts.mediumCf,
    lineHeight: "140%",
    color: "white",
    fontSize: spacing(1.5),
    padding: 0,
    margin: 0,
    paddingTop: spacing(1),
    [`@media (max-width:${1024 - 1}px)`]: {
      lineHeight: "140%",
      fontSize: spacing(2.75),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      lineHeight: "131%",
      paddingTop: spacing(0.6),
      fontSize: spacing(1.5),
    },
  };
});
const LineBox = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    width: "40%",
    borderRadius: spacing(1.625),
    border: "1px solid #E7FF00",
    background: "rgba(255, 255, 255, 0.12)",
    padding: spacing(1.625),
    [`@media (max-width:${1024 - 1}px)`]: {},
    [`@media (max-width:${581 - 1}px)`]: { padding: spacing(1.425) },
  };
});
const LineBoxTitle = styled.p(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    fontFamily: fonts.regularCf,
    fontSize: spacing(1.625),

    lineHeight: "140%",
    color: "white",

    padding: 0,
    margin: 0,
    paddingBottom: spacing(1),
    [`@media (max-width:${1024 - 1}px)`]: { fontSize: spacing(3) },
    [`@media (max-width:${581 - 1}px)`]: { fontSize: spacing(1.625) },
  };
});
const LineBoxSubTitle = styled.p(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    fontFamily: fonts.boldCf,
    fontSize: spacing(1.625),

    lineHeight: "140%",
    fontWeight: "900",
    color: "white",

    padding: 0,
    margin: 0,

    [`@media (max-width:${1024 - 1}px)`]: { fontSize: spacing(3) },
    [`@media (max-width:${581 - 1}px)`]: { fontSize: spacing(1.625) },
  };
});

const SmallLocText = styled.p(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    fontFamily: fonts.regularCf,
    fontSize: spacing(1.75),

    lineHeight: "normal",
    fontWeight: "500",
    color: "white",

    padding: 0,
    margin: 0,

    [`@media (max-width:${1024 - 1}px)`]: { fontSize: spacing(2.25) },
    [`@media (max-width:${581 - 1}px)`]: { fontSize: spacing(1.1) },
  };
});

const SmallLocBoldText = styled.p(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    fontFamily: fonts.boldCf,
    fontSize: spacing(1.75),

    lineHeight: "140%",
    fontWeight: "900",
    color: "white",

    padding: 0,
    margin: 0,

    [`@media (max-width:${1024 - 1}px)`]: { fontSize: spacing(2) },
    [`@media (max-width:${581 - 1}px)`]: { fontSize: spacing(1.1) },
  };
});
const LatLongText = styled.p(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    margin: 0,
    fontFamily: fonts.regularCf,
    fontSize: spacing(1.5),
    color: "white",

    [`@media (max-width:${1024 - 1}px)`]: { fontSize: spacing(2.75) },
    [`@media (max-width:${581 - 1}px)`]: { fontSize: spacing(1.5) },
  };
});
const NavGalleryRight = styled(IconButton)(({ theme, $disabled }) => {
  const { spacing } = theme;
  return {
    "&&.MuiIconButton-root": {
      display: $disabled && "none",
      background: "rgba(255, 255, 255, 0.04)",
      backdropFilter: "blur(35px)",
      width: "77px",
      height: "77px",
      border: "1px solid #E7FF00",
      transition: " all .3s ease-out",
      appearance: "none",
      position: "absolute",
      zIndex: 4,
      filter: "drop-shadow(0 2px 2px #1a1a1a)",
      top: "50%",
      transform: "translateY(-50%)",
      right: 0,
      color: "white",
      fontSize: spacing(5),
      marginRight: spacing(2.5),
      padding: 0,
      [`@media (max-width:${1024 - 1}px)`]: {},
      [`@media (max-width:${581 - 1}px)`]: {
        marginRight: spacing(1.5),
        width: "42px",
        height: "42px",
        fontSize: spacing(3.25),
      },
    },
  };
});

const NavGalleryLeft = styled(IconButton)(({ theme, $disabled }) => {
  const { spacing } = theme;
  return {
    "&&.MuiIconButton-root": {
      display: $disabled && "none",
      background: "rgba(255, 255, 255, 0.04)",
      backdropFilter: "blur(35px)",
      width: "77px",
      height: "77px",
      border: "1px solid #E7FF00",
      transition: " all .3s ease-out",
      appearance: "none",
      position: "absolute",
      zIndex: 4,
      filter: "drop-shadow(0 2px 2px #1a1a1a)",
      top: "50%",
      transform: "translateY(-50%)",
      left: 0,
      color: "white",
      fontSize: spacing(5),
      marginLeft: spacing(2.5),
      padding: 0,
      [`@media (max-width:${1024 - 1}px)`]: {},
      [`@media (max-width:${581 - 1}px)`]: {
        marginLeft: spacing(1.5),
        width: "42px",
        height: "42px",
        fontSize: spacing(3.25),
      },
    },
  };
});

const NavForest = styled(IconButton)(({ theme, $disabled }) => {
  const { spacing } = theme;
  return {
    "&&.MuiIconButton-root": {
      opacity: $disabled && 0,
      background: "rgba(255, 255, 255, 0.04)",
      backdropFilter: "blur(35px)",
      width: "77px",
      height: "77px",
      border: "1px solid #E7FF00",

      color: "white",
      fontSize: spacing(5),

      padding: 0,
      [`@media (max-width:${1024 - 1}px)`]: {
        width: "145px",
        height: "145px",
        fontSize: spacing(7.75),
      },
      [`@media (max-width:${581 - 1}px)`]: {
        width: "77px",
        height: "77px",
        fontSize: spacing(5),
      },
    },
  };
});

const MoreForest = styled(ButtonContained)(({ theme, $disabled }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      margin: 0,
      borderRadius: spacing(2.25),
      border: "1px solid #E7FF00",
      backgroundColor: "rgba(217, 217, 217, 0.10)",
      backdropFilter: "blur(5px)",
      paddingBlock: spacing(1),
      paddingInline: spacing(4),

      color: "white",
      fontSize: spacing(2.1),

      [`@media (max-width:${1024 - 1}px)`]: {
        fontSize: spacing(4),
        paddingBlock: spacing(1.9),
        borderRadius: 9999,
      },
      [`@media (max-width:${581 - 1}px)`]: {
        paddingBlock: spacing(1),
        fontSize: spacing(2.1),
      },
    },
  };
});
const ValueForest = styled.div(({ theme, $disabled }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "column",
    margin: 0,
    borderRadius: spacing(2.25),
    border: "1px solid #E7FF00",
    backgroundColor: "rgba(217, 217, 217, 0.10)",
    backdropFilter: "blur(5px)",
    paddingBlock: spacing(1.5),
    paddingInline: spacing(1.5),

    color: "white",
    fontSize: spacing(2.1),

    [`@media (max-width:${1024 - 1}px)`]: {
      display: "none",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      display: "none",
    },
  };
});
const ValueForestSec = styled.div(({ theme, $disabled }) => {
  const { spacing } = theme;
  return {
    display: "none",
    width: "fit-content",
    flexDirection: "column",
    margin: 0,
    borderRadius: spacing(2.25),
    border: "1px solid #E7FF00",
    backgroundColor: "rgba(217, 217, 217, 0.10)",
    backdropFilter: "blur(5px)",

    color: "white",
    fontSize: spacing(2.1),

    [`@media (max-width:${1024 - 1}px)`]: {
      display: "flex",
      borderRadius: spacing(9.5),
      paddingBlock: spacing(5.75),
      paddingInline: spacing(8.125),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      display: "flex",
      borderRadius: spacing(4.75),
      paddingBlock: spacing(2.75),
      paddingInline: spacing(4),
    },
  };
});
const ValueTitle = styled.p(({ theme, $disabled }) => {
  const { spacing } = theme;
  return {
    margin: 0,
    padding: 0,
    color: "white",
    fontSize: spacing(1.5),
    fontFamily: fonts.heavy,
    width: "max-content",
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(5.75),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: `clamp(14px, 6vw, 22px)`,
    },
  };
});

const ValueSymbol = styled.p(({ theme, $disabled }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    gap: spacing(0.5),
    margin: 0,
    padding: 0,
    color: "white",
    fontSize: spacing(1.25),
    fontFamily: fonts.heavy,

    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(5.75),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: `clamp(14px, 6vw, 22px)`,
    },
  };
});
const ValueNumber = styled.p(({ theme, $disabled }) => {
  const { spacing } = theme;
  return {
    margin: 0,
    padding: 0,
    color: "white",
    fontSize: spacing(1.625),
    fontFamily: fonts.heavy,

    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(8.125),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: `clamp(16px, 7vw, 32px)`,
    },
  };
});
const ForestImg = styled.video(({ theme, $img }) => {
  const { spacing } = theme;
  return {
    maxWidth: "322px",
    maxHeight: "214px",
    cursor: "pointer",
    height: "100%",
    backgroundImage: `url(${$img})`,
    backgroundSize: "cover",
    borderRadius: "24px",
    border: "0.5px solid #E7FF00",
    objectFit: "cover",
    pointerEvents: "none",
    [`@media (max-width:${1024 - 1}px)`]: {
      minHeight: "405.13px",

      minWidth: "100%",
    },
    [`@media (max-width:${581 - 1}px)`]: { minHeight: "214px" },
  };
});

const ForestImgGallery = styled.div(({ theme, $img }) => {
  const { spacing } = theme;
  return {
    minWidth: "74px",
    minHeight: "70px",
    backgroundImage: `url(${$img})`,
    backgroundSize: "cover",
    borderRadius: "10px",
    border: "0.5px solid #E7FF00",
    cursor: "pointer",

    [`@media (max-width:${1024 - 1}px)`]: {
      minWidth: "140px",
      minHeight: "133px",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      minWidth: "74px",
      minHeight: "70px",
    },
  };
});
const WhiteLine = styled.div(({ theme, $img }) => {
  const { spacing } = theme;
  return {
    backgroundColor: "#FFF",
    width: "100%",
    height: "1px",
    marginBlock: "20px",

    [`@media (max-width:${1024 - 1}px)`]: {
      marginBlock: "38px",
    },
    [`@media (max-width:${581 - 1}px)`]: { marginBlock: "20px" },
  };
});

const BoxArea = styled.div(({ theme, $img }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBlock: "20px",
    gap: "8px",

    [`@media (max-width:${1024 - 1}px)`]: {
      paddingBlock: "38px",
      gap: "12px",
    },
    [`@media (max-width:${581 - 1}px)`]: { gap: "8px", paddingBlock: "12px" },
  };
});

const BoxForests = styled.div(({ theme, maxW }) => {
  const { spacing } = theme;
  return {
    zIndex: 2,
    display: "flex",
    flexDirection: "column",
    paddingLeft: "97px",
    alignSelf: "center",
    width: `100%`,
    maxWidth: `calc(100vw - ${maxW}px)`,
    gap: "35px",
    marginBlock: spacing(2),
    [`@media (max-width:${1024 - 1}px)`]: {
      paddingLeft: "clamp(32px, 9vw, 69px)",
      paddingBlock: "38px",
      maxWidth: "100%",
      //flexDirection: "column-reverse",
    },
    [`@media (max-width:${1024 - 1}px)`]: {
      paddingLeft: "clamp(32px, 9vw, 69px)",
      paddingBlock: "38px",
      maxWidth: "100%",
      //flexDirection: "column-reverse",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      paddingLeft: "clamp(12px, 9vw, 38px)",
      paddingBlock: "24px",
    },
  };
});

const CardForests = styled.div(({ theme, $img, loading }) => {
  const { spacing } = theme;
  return {
    minWidth: "211px",
    minHeight: "310px",
    backgroundImage: `url(${$img})`,
    backgroundSize: "cover",
    borderRadius: "18px",
    border: "1px solid #E7FF00",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backdropFilter: loading && "blur(35px)",
    [`@media (max-width:${1024 - 1}px)`]: {
      minWidth: "399.45px",
      minHeight: "605.626px",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      minWidth: "211px",
      minHeight: "310px",
    },
  };
});

const CardContainer = styled.div(({ theme, $img, loading }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "column",

    minWidth: "211px",
    gap: "8px",
    [`@media (max-width:${1024 - 1}px)`]: {
      minWidth: "399.45px",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      minWidth: "211px",
    },
  };
});

const NavForestBox = styled.div(({ theme, $img }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    alignItems: "center",
    gap: spacing(2.1),
    marginTop: spacing(3.25),
    [`@media (max-width:${1024 - 1}px)`]: {
      marginTop: spacing(8),
    },
    [`@media (max-width:${581 - 1}px)`]: { marginTop: spacing(3.25) },
  };
});
const MoreBox = styled.div(({ theme, $img }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    maxWidth: "100vw",
    gap: spacing(2.25),
    paddingRight: "clamp(32px, 7vw, 105px)",
    [`@media (max-width:${1024 - 1}px)`]: {
      justifyContent: "center",
      paddingInline: 0,
    },
    [`@media (max-width:${581 - 1}px)`]: {},
  };
});

const Styles = {
  ForestImg,
  ForestImgGallery,
  Container,
  PagTitle,
  BlurContainer,
  NameTitle,
  LocText,
  LineBox,
  LineBoxTitle,
  LineBoxSubTitle,
  SmallLocText,
  SmallLocBoldText,
  NavGalleryRight,
  NavGalleryLeft,
  NavForest,
  BackgroundVideo,
  MoreForest,
  WhiteLine,
  BoxArea,
  LatLongText,
  BoxForests,
  CardForests,
  NavForestBox,
  MoreBox,
  BlurContainerEmpty,
  EmptyTitle,
  ValueForest,
  ValueTitle,
  ValueNumber,
  ValueSymbol,
  ValueForestSec,
  CardContainer,
  NameTitleCard,
};

export default Styles;
