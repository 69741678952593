import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import Styles from "../PaymentStyles";
import texts from "../../../config/texts";
import { LocalStorage } from "../../../utils";

function Bar({ step }) {
  const currentText =
    texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].payment.bar;

  return (
    <Styles.BarContainer>
      <div style={{ display: "flex" }}>
        <Styles.BarBackgroundIcon actived={step === 1}>
          <InfoOutlinedIcon
            style={{
              width: 51,
              height: "auto",
              fill: step === 1 ? "white" : "black",
            }}
          />
        </Styles.BarBackgroundIcon>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Styles.BarTextTitle>{currentText.title[0]}</Styles.BarTextTitle>
          <Styles.BarTextSubtitle>
            {currentText.subtitle[0]}
          </Styles.BarTextSubtitle>
        </div>
      </div>
      <Styles.BarLine />

      <div style={{ display: "flex" }}>
        <Styles.BarBackgroundIcon actived={step === 2}>
          <CreditCardIcon
            style={{
              width: 40,
              height: "auto",
              fill: step === 2 ? "white" : "black",
            }}
          />
        </Styles.BarBackgroundIcon>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Styles.BarTextTitle>{currentText.title[1]}</Styles.BarTextTitle>
          <Styles.BarTextSubtitle>
            {currentText.subtitle[1]}
          </Styles.BarTextSubtitle>
        </div>
      </div>

      <Styles.BarLine />

      <div style={{ display: "flex" }}>
        <Styles.BarBackgroundIcon actived={step === 3}>
          <MonetizationOnOutlinedIcon
            style={{
              width: 51,
              height: "auto",
              fill: step === 3 ? "white" : "black",
            }}
          />
        </Styles.BarBackgroundIcon>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Styles.BarTextTitle>{currentText.title[2]}</Styles.BarTextTitle>
          <Styles.BarTextSubtitle>
            {currentText.subtitle[2]}
          </Styles.BarTextSubtitle>
        </div>
      </div>
    </Styles.BarContainer>
  );
}

export default Bar;
