import { Grid } from "@material-ui/core";
import Styles from "../styles/StylesProDiferencial";

function RoadmapMobile({ data, title }) {
  return (
    <>
      <Styles.RoadmapContentMobile>
        <Styles.RoadmapTitle>{title}</Styles.RoadmapTitle>
        <Grid
          container
          style={{
            position: "relative",
          }}
        >
          {data.map((item, index) => {
            return (
              <Grid
                item
                xs={4}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                }}
              >
                <Styles.RoadmapText title>
                  <strong>{item.title}</strong>
                </Styles.RoadmapText>
              </Grid>
            );
          })}
          {data.map((item, index) => {
            return (
              <Grid item xs={4}>
                <Styles.RoadmapLineContainer>
                  <Styles.GreenDot
                    end={index === data.length - 1}
                    middle={index !== 0 && index !== data.length - 1}
                  />
                  <Styles.Line
                    middle={index !== 0 && index !== data.length - 1}
                    end={index === data.length - 1}
                  />
                </Styles.RoadmapLineContainer>
              </Grid>
            );
          })}
          {data.map((item, index) => {
            return (
              <Grid
                item
                xs={4}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <Styles.RoadmapText>{item.text}</Styles.RoadmapText>
              </Grid>
            );
          })}
        </Grid>
      </Styles.RoadmapContentMobile>
    </>
  );
}

export default RoadmapMobile;
