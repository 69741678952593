import { useState } from "react";

import { customModalBlur } from "../../../components/modals/utils";
import { api } from "../../../services";
import { hooks, LocalStorage } from "../../../utils";
import { ButtonContained, Input } from "../../../components";
import { fonts, Spacing, SVG } from "../../../config";
import alerts from "../../../utils/Alerts";
import { CircularProgress } from "@material-ui/core";
import Texts from "../../../config/texts";
import { FormFull } from "form-full";
interface Props {
  id: string | null;
  type?: string;
}

function EmailModal({ id, type }: Props): JSX.Element {
  const texts =
    Texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].config;

  const { call, loading } = hooks.useRequest();

  const [otherLoading, setOtherLoading] = useState<boolean>(false);

  const sendRefund = async (formData: any) => {
    setOtherLoading(true);

    call(
      null,
      api.refundRequest({ ...formData, transactionId: id }),
      (response) => {
        setOtherLoading(false);
        if (response.ok) {
          customModalBlur.close();
          alerts.alertSuccess(texts.refundSucess);
        } else {
          setOtherLoading(false);
        }
      }
    );
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: Spacing(2.625),
      }}
    >
      <FormFull onSubmit={sendRefund}>
        <SVG.EmailIcon
          style={{
            fill: "#E7FF00",
            maxWidth: "109.287px",
            maxHeight: "109.287px",
            width: "100%",
            height: "auto",
          }}
        />
        <div>
          <p
            style={{
              fontSize: Spacing(2),
              fontFamily: fonts.medium,
              textAlign: "center",
              color: "white",
              margin: 0,
            }}
          >
            {texts.securityOptions[4].title}
          </p>
          <Input
            
            maxLength={80}
            inputStyle={{
              backgroundColor: "#E7FF00",
              minHeight: "86px",
              borderRadius: "20px",
            }}
            name="motive"
            multiline
          />
          {type === "BILLET" ||
            (type === "PIX" && (
              <>
                <p
                  style={{
                    fontSize: Spacing(2),
                    fontFamily: fonts.medium,
                    textAlign: "center",
                    color: "white",
                    margin: 0,
                  }}
                >
                  {texts.securityOptions[4].sub}
                </p>
                <Input
                  inputStyle={{
                    backgroundColor: "#E7FF00",
                    borderRadius: "55px",

                    paddingBlock: "8px",
                  }}
                  name="pixKey"
                />
              </>
            ))}
        </div>

        <ButtonContained
          loading={otherLoading || loading}
          disabled={otherLoading || loading}
          fullWidth={false}
          action="submit"
          style={{
            fontSize: Spacing(2),
            paddingBlock: Spacing(2),
            paddingInline: Spacing(4),
          }}
        >
          {otherLoading || loading ? (
            <CircularProgress
              size={20}
              style={{
                color: "black",
                alignSelf: "center",
                justifyContent: "center",
                marginInline: Spacing(6.5),
              }}
            />
          ) : (
            texts.securityOptions[4].actionLabel
          )}
        </ButtonContained>
      </FormFull>
    </div>
  );
}

export default EmailModal;
