import React, { useContext, useEffect } from "react";
import { useBeforeUnload, useNavigate } from "react-router-dom";
import ptBr from "../../../config/texts/pt-br";
import {
  ButtonContained,
  CustomText,
  FontStyles,
  Input,
  InputEmail,
  InputPassword,
  StrongText,
} from "../../../components/index";
import Styles from "../styles";
import { FormFull } from "form-full";
import { LocalStorage, masks, validations } from "../../../utils";
import { hooks, SessionStorage } from "../../../utils";
import { StorageContext } from "../../../contexts/StorageContext";

import { api } from "../../../services";
import { paths } from "../../../navigation/navigate";
import { fonts, Spacing } from "../../../config";
import { errorModal } from "../../../components/modals/utils";
import ResponseError from "../../../services/helpers/ResponseError";
import { CancelToken } from "apisauce";
import alerts from "../../../utils/Alerts";
import Texts from "../../../config/texts";

function TwoFactorStep({ onSubmit, loading, goBack }) {
  const texts = Texts[LocalStorage.getItem("language") ?LocalStorage.getItem("language"): 'pt'].login;

  const navigate = useNavigate();
  return (
    <FormFull onSubmit={onSubmit}>
      <Styles.Content>
        <Styles.Title>{texts.titleTwo}</Styles.Title>

        <div
          style={{
            display: "flex",
            gap: Spacing(1.25),
            flexDirection: "column",
          }}
        >
          <Styles.InputTitle>{texts.auth}</Styles.InputTitle>
          <Input
            validation={(value) => {
              if (value.length > 5) {
                return null;
              } else {
                return texts.authValidation;
              }
            }}
            mask={(value) => {
              if (value?.length > 5) {
                return masks.removeNumberMask(value.slice(0, 6));
              } else {
                return masks.removeNumberMask(value);
              }
            }}
            inputStyle={{
              border: "1.588px solid #FFF",
              background: "rgba(27, 30, 34, 0.70)",
              borderRadius: "53.201px",
              color: "white",
              fontFamily: fonts.regularCf,
              fontSize: Spacing(2.75),
              paddingInline: Spacing(3.75),
              paddingBlock: Spacing(2),
              marginTop: Spacing(0.75),
              "& .MuiInputBase-input": {
                padding: 0,

                boxShadow: "none !important",
              },

              [`@media (max-width:${1024 - 1}px)`]: {
                paddingInline: Spacing(3.5),
                fontSize: Spacing(2.5),
              },
              [`@media (max-width:${581 - 1}px)`]: {
                paddingInline: Spacing(1.25),
                paddingBlock: Spacing(1),
                fontSize: Spacing(1.5),
              },
            }}
            required={texts.userMessage}
            key="code"
            name="code"
          />
        </div>

        <Styles.SubmitButton
          name="submit"
          loading={loading}
          disabled={loading}
          feedback={true}
          action="submit"
          type="submit"
          label={texts.enter}
        />
        <Styles.Line></Styles.Line>
        <Styles.ForgotPasswordButton noPointer>
          Ainda não tem conta?{" "}
          <StrongText onClick={() => navigate(paths.register)}>
            Registre-se
          </StrongText>
        </Styles.ForgotPasswordButton>
      </Styles.Content>
    </FormFull>
  );
}

export default TwoFactorStep;
