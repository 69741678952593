// TODO/HINT Atualizar fontes com base no mockup

const light = '"Ethos Nova Light", "Helvetica", "Arial", sans-serif';
const bold = '"Ethos Nova Bold", "Helvetica", "Arial", sans-serif';
const medium = '"Ethos Nova Medium", "Helvetica", "Arial", sans-serif';
const italic = '"Ethos Nova Italic", "Helvetica", "Arial", sans-serif';
const regular = '"Ethos Nova Regular", "Helvetica", "Arial", sans-serif';
const semibold = '"Visby CF SemiBold", "Helvetica", "Arial", sans-serif';
const heavy = '"Ethos Nova Heavy", "Helvetica", "Arial", sans-serif';

const lightCf = '"Visby CF Light", "Helvetica", "Arial", sans-serif';
const boldCf = '"Visby CF Bold", "Helvetica", "Arial", sans-serif';
const mediumCf = '"Visby CF Medium", "Helvetica", "Arial", sans-serif';
const italicCf = '"Visby CF Italic", "Helvetica", "Arial", sans-serif';
const regularCf = '"Visby CF Regular", "Helvetica", "Arial", sans-serif';
const heavyCf = '"Visby CF Heavy", "Helvetica", "Arial", sans-serif';

const sizes = {
  extraSmall: "0.625rem", // TODO/HINT 10px
  smaller: "0.75rem", // TODO/HINT 12px
  small: "0.875rem", // TODO/HINT 14px
  regular: "1rem", // TODO/HINT 16px
  medium: "1.125rem", // TODO/HINT 18px
  large: "1.375rem", // TODO/HINT 22px
  larger: "1.5rem", // TODO/HINT 32px
  extraLarge: "2.625rem", // TODO/HINT 42px
};

const fonts = {
  bold,
  light,
  sizes,
  medium,
  italic,
  heavy,
  regular,
  semibold,
  lightCf,
  boldCf,
  mediumCf,
  italicCf,
  regularCf,
  heavyCf,
};

export default fonts;
