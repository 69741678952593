import Styles from "../../styles";

import texts from "../../../../config/texts";
import { useContext, useRef, useState } from "react";
import { BiSolidCamera } from "react-icons/bi";
import {
  customModal,
  customModalBlur,
} from "../../../../components/modals/utils";
import { api } from "../../../../services";
import { hooks, LocalStorage } from "../../../../utils";
import { ButtonContained } from "../../../../components";
import { fonts, Spacing, SVG } from "../../../../config";
import { StorageContext } from "../../../../contexts/StorageContext";
import alerts from "../../../../utils/Alerts";
interface Props {
  $style?: any;
}

function DocumentModal({ $style }: Props): JSX.Element {
  const currentText =
    texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].config;
  const actionText =
    texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].actionButton;
  const { setUserData, userData } = useContext<any>(StorageContext);
  const { call } = hooks.useRequest();
  const [confirmed, setConfirmed] = useState<any | null>(null);
  const [otherLoading, setOtherLoading] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<any | null>(null);
  const [selectedImageVerse, setSelectedImageVerse] = useState<any | null>(
    null
  );
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const fileInputRefVerse = useRef<HTMLInputElement | null>(null);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > 8 * 1024 * 1024) {
        alerts.alertError("Os tamanho do arquivo deve ser inferior a 8mb");
        const inputV = document.getElementById("document-img") as any;
        if (inputV) {
          inputV.value = null;
        }

        return;
      } else {
        setSelectedImage(file);
      }
    }
  };

  const handleImageVerseChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > 8 * 1024 * 1024) {
        alerts.alertError("Os tamanho do arquivo deve ser inferior a 8mb");
        const inputV = document.getElementById("document-img-verse") as any;
        if (inputV) {
          inputV.value = null;
        }

        return;
      } else {
        setSelectedImageVerse(file);
      }
    }
  };

  const convertToBase64 = (file: File) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target) {
          resolve(event.target.result as string);
        } else {
          reject("Failed to convert to base64");
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const convertImage = async () => {
    setOtherLoading(true);
    if (selectedImage && selectedImageVerse) {
      try {
        const file = await convertToBase64(selectedImage);
        const file2 = await convertToBase64(selectedImageVerse);
        const data = {
          registerFrontImage: file
            .replace("data:image/png;base64,", "")
            .replace("data:image/jpeg;base64,", ""),
          registerBackImage: file2
            .replace("data:image/png;base64,", "")
            .replace("data:image/jpeg;base64,", ""),
        };

        call(null, api.editUserDocs(data), (response) => {
          if (response.ok) {
            setUserData({ ...userData, ...response?.data });
            setOtherLoading(false);
            setConfirmed(true);
          } else {
            setOtherLoading(false);
          }
        });
      } catch (error) {
        setOtherLoading(false);
      }
    } else {
      setOtherLoading(false);
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Simula um clique no input de arquivo
    }
  };

  const handleButtonVerseClick = () => {
    if (fileInputRefVerse.current) {
      fileInputRefVerse.current.click(); // Simula um clique no input de arquivo
    }
  };

  const cropToSquare = (base64Image: string) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const image = new Image();
    image.src = base64Image;

    image.onload = () => {
      const minSize = Math.min(image.width, image.height);
      const centerX = (image.width - minSize) / 2;
      const centerY = (image.height - minSize) / 2;

      canvas.width = minSize;
      canvas.height = minSize;
      ctx?.drawImage(
        image,
        centerX,
        centerY,
        minSize,
        minSize,
        0,
        0,
        minSize,
        minSize
      );

      const croppedBase64 = canvas.toDataURL("image/jpeg"); // Pode escolher outro formato se preferir

      setSelectedImage(croppedBase64);
      customModal.setInfos(
        "Salvar",
        [],
        {
          onClick: () => {
            call(
              null,
              api.editUserImage({
                image: croppedBase64,
              }),
              (response) => {
                if (response.ok) {
                } else {
                  setSelectedImage(null);
                  fileInputRef?.current?.value === null;
                  const fileInput = fileInputRef.current;

                  if (fileInput && fileInput.value) {
                    fileInput.value = "";
                  }
                }
              }
            );
            customModal.close();
          },
          label: "Enviar",
        },
        {
          onClick: () => {
            customModal.close();
          },
          label: "Cancelar",
        },
        <Styles.ModalContainer>
          <Styles.MotalSubtText>{currentText.image.title}</Styles.MotalSubtText>
          <Styles.UserImg $img={croppedBase64}></Styles.UserImg>
        </Styles.ModalContainer>
      );
    };
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: Spacing(2.625),
      }}
    >
      <SVG.DocumentIcon
        style={{
          fill: "#E7FF00",
          stroke: "#E7FF00",
          color: "#E7FF00",
          maxWidth: "109.287px",
          maxHeight: "109.287px",
          width: "100%",
          height: "auto",
        }}
      />
      <p
        style={{
          fontSize: Spacing(3.125),
          fontFamily: fonts.heavy,
          textAlign: "center",
          color: "white",
          margin: 0,
        }}
      >
        {confirmed || userData?.docs?.registerStatus === "PENDING"
          ? currentText.docs.under
          : currentText.docs.send}
      </p>

      {!confirmed && userData?.docs?.registerStatus !== "PENDING" && (
        <>
          <p
            style={{
              fontSize: Spacing(1.25),
              fontFamily: fonts.mediumCf,
              textAlign: "center",
              lineHeight: "198%",
              color: "white",
              margin: 0,
            }}
          >
            {currentText.docs.sub}
          </p>
          <input
            id="document-img"
            type="file"
            accept="image/jpeg, image/png"
            onChange={handleImageChange}
            ref={fileInputRef}
            style={{ display: "none" }}
          />
          <input
            id="document-img-verse"
            type="file"
            accept="image/jpeg, image/png"
            onChange={handleImageVerseChange}
            ref={fileInputRefVerse}
            style={{ display: "none" }}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: " space-evenly",
              gap: Spacing(1),
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            <Styles.ModalImport
              loading={otherLoading}
              disabled={otherLoading}
              onClick={handleButtonClick}
            >
              {selectedImage ? (
                <Styles.ModalImportText>
                  {selectedImage?.name}
                </Styles.ModalImportText>
              ) : (
                currentText.docs.inputOne
              )}
            </Styles.ModalImport>
            <Styles.ModalImport
              loading={otherLoading}
              disabled={otherLoading}
              onClick={handleButtonVerseClick}
            >
              {selectedImageVerse ? (
                <Styles.ModalImportText>
                  {selectedImageVerse?.name}
                </Styles.ModalImportText>
              ) : (
                currentText.docs.inputTwo
              )}
            </Styles.ModalImport>
          </div>
          {selectedImage && selectedImageVerse && (
            <Styles.ModalImportSend
              loading={otherLoading}
              disabled={otherLoading}
              $full
              onClick={convertImage}
              fullWidth={false}
            >
              {actionText.send}
            </Styles.ModalImportSend>
          )}
        </>
      )}
      {confirmed ||
        (userData?.docs?.registerStatus === "PENDING" && (
          <Styles.ModalImportSend
            onClick={() => customModalBlur.close()}
            $full
            fullWidth={false}
          >
            {actionText.close}
          </Styles.ModalImportSend>
        ))}
    </div>
  );
}

export default DocumentModal;
