import { Grid } from "@material-ui/core";
import Styles from "../styles/StylesProDiferencial";

function Roadmap({ data }) {
  return (
    <div style={{ width: "70%", paddingBlock: 60 }}>
      <Grid
        container
        style={{
          position: "relative",
          height: "fit-content",
        }}
      >
        {data.map((item, index) => {
          return (
            <Grid
              xs={4}
              item
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Styles.RoadmapText title>
                <strong>{item.title}</strong>
              </Styles.RoadmapText>
            </Grid>
          );
        })}
        {data.map((item, index) => {
          return (
            <Grid item xs={4}>
              <Styles.RoadmapLineContainer>
                <Styles.GreenDot
                  end={index === data.length - 1}
                  middle={index !== 0 && index !== data.length - 1}
                />
                <Styles.Line
                  middle={index !== 0 && index !== data.length - 1}
                  end={index === data.length - 1}
                />
              </Styles.RoadmapLineContainer>
            </Grid>
          );
        })}
        {data.map((item, index) => {
          return (
            <Grid
              item
              xs={4}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Styles.RoadmapText>{item.text}</Styles.RoadmapText>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

export default Roadmap;
