import Styles from "../styles";
const ConfigGrain = process.env.REACT_APP_ASSETS_URL + "/imgs/configGrain.webp";
interface Props {
  user: any;
}

function BoxHeader({ user }: Props): JSX.Element {
  return (
    <Styles.HeaderBox $img={ConfigGrain}>
      <Styles.SmallUserImg
        $img={"data:image/png;base64," + user?.image}
      ></Styles.SmallUserImg>
      <Styles.NameUser small>{user?.name}</Styles.NameUser>
    </Styles.HeaderBox>
  );
}

export default BoxHeader;
