import Styles from "../styles/NFTStyles";
const Img1K = process.env.REACT_APP_ASSETS_URL + "/imgs/1k.webp";
import { useNavigate } from "react-router-dom";
import { paths } from "../../../navigation/navigate";
import texts from "../../../config/texts";
import { useContext } from "react";
import { StorageContext } from "../../../contexts/StorageContext";
import { LocalStorage } from "../../../utils";

function NFT() {
  const currentText =
    texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].about;
  const actionText =
    texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].actionButton;
  const { isLogged } = useContext<any>(StorageContext);
  const navigate = useNavigate();

  return (
    <Styles.Container>
      <Styles.BgVideoContainer>
        <Styles.BgVideo
          loop
          autoPlay
          playsInline
          controls={false}
          muted
          preload="auto"
        >
          <source
            src={process.env.REACT_APP_ASSETS_URL + "/videos/parkBarigui.webm"}
            type="video/webm"
          />
        </Styles.BgVideo>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Styles.TextContainer>
            <Styles.BuyBiocoin>
              {currentText.nfts.title[0]} <br />{" "}
              <Styles.BuyBiocoinSpan>Biocoin</Styles.BuyBiocoinSpan>{" "}
              {currentText.nfts.title[4]}{" "}
              <Styles.BuyBiocoinSpan last>
                {" "}
                <br /> {currentText.nfts.title[1]}
              </Styles.BuyBiocoinSpan>
              <Styles.BuyBiocoinSpan last green>
                {" "}
                {currentText.nfts.title[2]}
              </Styles.BuyBiocoinSpan>{" "}
              <Styles.BuyBiocoinSpan last>
                {currentText.nfts.title[3]}
              </Styles.BuyBiocoinSpan>
            </Styles.BuyBiocoin>
            <Styles.StyledButton
              onClick={() =>
                isLogged ? navigate(paths.payment) : navigate(paths.login)
              }
            >
              {actionText.buy}
            </Styles.StyledButton>
            <Styles.OneKIcon src={Img1K} />
          </Styles.TextContainer>

          <Styles.TextContainer right>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                zIndex: 99,
              }}
            >
              <Styles.NFTColletcion>
                {currentText.nfts.content}
              </Styles.NFTColletcion>
              <Styles.StyledButtonOutlined
                onClick={() =>
                  isLogged ? navigate(paths.nfts) : navigate(paths.login)
                }
              >
                {actionText.nfts}
              </Styles.StyledButtonOutlined>
            </div>
          </Styles.TextContainer>
        </div>
      </Styles.BgVideoContainer>
    </Styles.Container>
  );
}

export default NFT;
