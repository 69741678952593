import styled from "styled-components";
const leafBackgorund = process.env.REACT_APP_ASSETS_URL + "/imgs/leafBackgorund.webp";
import { fonts, SVG } from "../../../config";

const Container = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    background: `linear-gradient(0deg, rgba(0, 0, 31, 0.30) 0%, rgba(0, 0, 31, 0.30) 100%), url(${leafBackgorund}), lightgray 50% / cover no-repeat`,
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",

    height: "100vh",
    maxHeight: theme.spacing(47.5),

    marginTop: `-${$menuHeight}px`,

    alignItems: "flex-end",
    justifyContent: "flex-end",

    paddingInline: spacing(15.625),
    paddingBlock: spacing(5),
    gap: spacing(2),

    [`@media (max-width:${1024 - 1}px)`]: {
      flexDirection: "column-reverse",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      marginTop: 0,
      paddingInline: spacing(6.5),
      paddingBlock: spacing(2.5),
      height: "fit-content",
      maxHeight: "none",
      paddingTop: spacing(25),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      paddingInline: spacing(2.75),
      paddingBlock: spacing(1.75),
      paddingTop: spacing(12.5),
    },
  };
});

const Logo = styled(SVG.BioLogo)(({ theme }) => {
  const { spacing } = theme;
  return {
    maxWidth: "420px",
    width: "100%",
    fill: "white",
    height: "auto",

    [`@media (max-width:${1024 - 1}px)`]: {
      maxWidth: "274.5px",
    },
    [`@media (max-width:${581 - 1}px)`]: { maxWidth: "145px" },
  };
});

const TermsContainer = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    paddingInline: spacing(18.5),
    paddingBlock: spacing(6),
    display: "flex",
    flexDirection: "row",
    width: "100%",
    gap: spacing(3.75),

    [`@media (max-width:${1024 - 1}px)`]: {
      padding: spacing(7.5),

      flexDirection: "column",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      padding: spacing(3.5),
    },
  };
});

const Item = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    width: "100%",
    display: "flex",
    gap: spacing(2.75),
    flexDirection: "column",
    marginBottom: spacing(4),
    [`@media (max-width:${1024 - 1}px)`]: {
      maxWidth: "100%",
      gap: spacing(2.5),
    },
    [`@media (max-width:${581 - 1}px)`]: { gap: spacing(1.5) },
  };
});

const Title = styled.p(({ theme }) => {
  const { spacing } = theme;
  return {
    fontFamily: fonts.boldCf,
    fontSize: spacing(3.75),
    padding: 0,
    margin: 0,
    textTransform: "uppercase",
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(3.5),
    },
    [`@media (max-width:${581 - 1}px)`]: { fontSize: spacing(2) },
  };
});

const Description = styled.p(({ theme }) => {
  const { spacing } = theme;
  return {
    fontFamily: fonts.regular,
    fontSize: spacing(2),
    padding: 0,
    margin: 0,
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(1.75),
    },
    [`@media (max-width:${581 - 1}px)`]: { fontSize: spacing(1) },
  };
});

const Styles = {
  Container,
  Logo,
  TermsContainer,
  Item,
  Title,
  Description,
};

export default Styles;
