import Styles from "../PaymentStyles";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { customModal } from "../../../components/modals/utils";
const Coin = process.env.REACT_APP_ASSETS_URL + "/imgs/goldenCoin.webp";
import { paths } from "../../../navigation/navigate";
import { useNavigate } from "react-router-dom";
import texts from "../../../config/texts";
import { LocalStorage } from "../../../utils";

function TableGrid({ data, setDeletePurchase }) {
  const navigate = useNavigate();
  const currentText =
    texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].payment.table;

  function seePurchase() {
    customModal.setInfos("", [""], null, null, contentModal(), true, null);
  }

  function deletePurchase() {
    customModal.setInfos(
      currentText.modalTitle,
      [currentText.modalText],
      {
        label: currentText.delete,
        onClick: () => {
          customModal.close();
          navigate(paths.home);
        },
      },
      {
        label: currentText.no,
        onClick: () => {
          setDeletePurchase(true);
          customModal.close();
        },
      },
      null,
      null,
      null
    );
  }

  const contentModal = () => {
    return (
      <>
        <Styles.ModalContainer>
          <Styles.ModalContent>
            <Styles.ModalImg src={Coin} />
            <Styles.ModalText $title>Biocoin</Styles.ModalText>
            <Styles.ModalLine $black />
            {data.map((item, index) => {
              return (
                <>
                  <Styles.ModalText $quantity>
                    {index === 2 && `${item.data} Biocoins`}
                  </Styles.ModalText>

                  <Styles.ModalText>
                    {index === 3 && item.data}
                  </Styles.ModalText>
                </>
              );
            })}
          </Styles.ModalContent>
          <Styles.ModalLine />
          <Styles.ModalButtonContainer>
            <Styles.ModalCloseButton
              onClick={() => {
                customModal.close();
              }}
            >
              {currentText.close}
            </Styles.ModalCloseButton>
            <Styles.ModalDeleteButton
              onClick={() => {
                deletePurchase();
              }}
            >
              {currentText.delete}
            </Styles.ModalDeleteButton>
          </Styles.ModalButtonContainer>
        </Styles.ModalContainer>
      </>
    );
  };

  return (
    <>
      <Styles.TableContainer>
        <Styles.Table>
          <Styles.TableRow>
            {data.map((item, index) => {
              return (
                <>
                  {index === data.length - 1 ? (
                    <Styles.TableItem $last>
                      <Styles.TableHeaderText>
                        {item.header}
                      </Styles.TableHeaderText>
                    </Styles.TableItem>
                  ) : index === 3 ? (
                    <Styles.TableItem $withButton>
                      <Styles.TableHeaderText>
                        {item.header}
                      </Styles.TableHeaderText>
                    </Styles.TableItem>
                  ) : (
                    <Styles.TableItem>
                      <Styles.TableHeaderText>
                        {item.header}
                      </Styles.TableHeaderText>
                    </Styles.TableItem>
                  )}
                </>
              );
            })}
          </Styles.TableRow>
          <Styles.TableRow>
            {data.map((item, index) => {
              return (
                <>
                  {index === data.length - 1 ? (
                    <Styles.TableItem $last $bottom>
                      <Styles.TableBodyText $last>
                        {item.data}
                      </Styles.TableBodyText>
                    </Styles.TableItem>
                  ) : index === 3 ? (
                    <Styles.TableItem $bottom $withButton>
                      <Styles.TableBodyText>{item.data}</Styles.TableBodyText>
                      <Styles.SeePurchaseButton
                        onClick={() => seePurchase()}
                        startIcon={
                          <VisibilityOutlinedIcon
                            style={{
                              width: "15px",
                              height: "auto",
                            }}
                          />
                        }
                      >
                        {currentText.seePurchase}
                      </Styles.SeePurchaseButton>
                      <Styles.DeletePurchaseButton
                        onClick={() => deletePurchase()}
                        startIcon={
                          <DeleteOutlinedIcon
                            style={{
                              width: "15px",
                              height: "auto",
                            }}
                          />
                        }
                      >
                        {currentText.delete}
                      </Styles.DeletePurchaseButton>
                    </Styles.TableItem>
                  ) : (
                    <Styles.TableItem $bottom>
                      <Styles.TableBodyText>{item.data}</Styles.TableBodyText>
                    </Styles.TableItem>
                  )}
                </>
              );
            })}
          </Styles.TableRow>
        </Styles.Table>
      </Styles.TableContainer>

      <Styles.TableContainerMobile>
        {data.map((item, index) => {
          return (
            <>
              {index === data.length - 1 ? (
                <Styles.TableItem $bottom>
                  <Styles.TableHeaderText>{item.header}</Styles.TableHeaderText>
                  <Styles.TableBodyText>{item.data}</Styles.TableBodyText>
                </Styles.TableItem>
              ) : index === 3 ? (
                <Styles.TableItem>
                  <Styles.TableHeaderText>{item.header}</Styles.TableHeaderText>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <Styles.TableBodyText>{item.data}</Styles.TableBodyText>
                    <div style={{ display: "flex", marginTop: 25 }}>
                      <Styles.SeePurchaseButton
                        onClick={() => seePurchase()}
                        startIcon={
                          <VisibilityOutlinedIcon
                            style={{
                              width: "12px",
                              height: "auto",
                            }}
                          />
                        }
                      >
                        {currentText.seePurchase}
                      </Styles.SeePurchaseButton>
                      <Styles.DeletePurchaseButton
                        onClick={() => deletePurchase()}
                        startIcon={
                          <DeleteOutlinedIcon
                            style={{
                              width: "12px",
                              height: "auto",
                            }}
                          />
                        }
                      >
                        {currentText.delete}
                      </Styles.DeletePurchaseButton>
                    </div>
                  </div>
                </Styles.TableItem>
              ) : (
                <Styles.TableItem>
                  <Styles.TableHeaderText>{item.header}</Styles.TableHeaderText>
                  <Styles.TableBodyText>{item.data}</Styles.TableBodyText>
                </Styles.TableItem>
              )}
            </>
          );
        })}
      </Styles.TableContainerMobile>
    </>
  );
}

export default TableGrid;
