import { paths } from "../navigation/navigate";

const pagesConfig = {
  notLogged: [
    {
      navigationId: 0,
      path: paths.login,
      name: "Login",
      title: "Login",
      working: true,
      noHeader: true,
      noMenu: true,
    },

    {
      navigationId: 0,
      path: paths.forgetPassword,
      name: "EsqueceuSenha",
      title: "Esqueceu Senha",
      working: true,
      noHeader: true,
      noMenu: true,
    },

    {
      navigationId: 0,
      path: paths.home,
      name: "Home",
      title: "Home",
      working: true,
    },
    {
      navigationId: 1,
      name: "About",
      title: "Sobre",
      titleen: "About",
      path: paths.about,
      working: true,
    },
    {
      navigationId: "2",
      name: "Dashboard",
      title: "Dashboard",
      path: "/dashboard",
      icon: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTQgNFYyMkgyMFYyNEg0QzIuOSAyNCAyIDIzLjEgMiAyMlY0SDRNMTUgN0gyMC41TDE1IDEuNVY3TTggMEgxNkwyMiA2VjE4QzIyIDE5LjExIDIxLjExIDIwIDIwIDIwSDhDNi44OSAyMCA2IDE5LjEgNiAxOFYyQzYgLjg5IDYuODkgMCA4IDBNMTcgMTZWMTRIOFYxNkgxN00yMCAxMlYxMEg4VjEySDIwWiIgLz48L3N2Zz4=",
    },
    {
      navigationId: "3",
      name: "Technology",
      title: "Tecnologia",
      titleen: "Technology",
      path: "/tecnologia",
      icon: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTQgNFYyMkgyMFYyNEg0QzIuOSAyNCAyIDIzLjEgMiAyMlY0SDRNMTUgN0gyMC41TDE1IDEuNVY3TTggMEgxNkwyMiA2VjE4QzIyIDE5LjExIDIxLjExIDIwIDIwIDIwSDhDNi44OSAyMCA2IDE5LjEgNiAxOFYyQzYgLjg5IDYuODkgMCA4IDBNMTcgMTZWMTRIOFYxNkgxN00yMCAxMlYxMEg4VjEySDIwWiIgLz48L3N2Zz4=",
    },
    {
      navigationId: "4",
      name: "Project",
      title: "O Projeto",
      titleen: "Project",
      path: "/o_projeto",
      icon: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTQgNFYyMkgyMFYyNEg0QzIuOSAyNCAyIDIzLjEgMiAyMlY0SDRNMTUgN0gyMC41TDE1IDEuNVY3TTggMEgxNkwyMiA2VjE4QzIyIDE5LjExIDIxLjExIDIwIDIwIDIwSDhDNi44OSAyMCA2IDE5LjEgNiAxOFYyQzYgLjg5IDYuODkgMCA4IDBNMTcgMTZWMTRIOFYxNkgxN00yMCAxMlYxMEg4VjEySDIwWiIgLz48L3N2Zz4=",
    },
    {
      navigationId: "5",
      name: "Faq",
      title: "FAQ",
      path: "/faq",
      noHeader: true,
      icon: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTQgNFYyMkgyMFYyNEg0QzIuOSAyNCAyIDIzLjEgMiAyMlY0SDRNMTUgN0gyMC41TDE1IDEuNVY3TTggMEgxNkwyMiA2VjE4QzIyIDE5LjExIDIxLjExIDIwIDIwIDIwSDhDNi44OSAyMCA2IDE5LjEgNiAxOFYyQzYgLjg5IDYuODkgMCA4IDBNMTcgMTZWMTRIOFYxNkgxN00yMCAxMlYxMEg4VjEySDIwWiIgLz48L3N2Zz4=",
    },
    {
      navigationId: "6",
      name: "Blog",
      title: "Blog",
      path: "/blog",
      noHeader: true,
      icon: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTQgNFYyMkgyMFYyNEg0QzIuOSAyNCAyIDIzLjEgMiAyMlY0SDRNMTUgN0gyMC41TDE1IDEuNVY3TTggMEgxNkwyMiA2VjE4QzIyIDE5LjExIDIxLjExIDIwIDIwIDIwSDhDNi44OSAyMCA2IDE5LjEgNiAxOFYyQzYgLjg5IDYuODkgMCA4IDBNMTcgMTZWMTRIOFYxNkgxN00yMCAxMlYxMEg4VjEySDIwWiIgLz48L3N2Zz4=",
    },
    {
      navigationId: 7,
      name: "Register",
      title: "Register",
      path: paths.register,
      working: true,
      noHeader: true,
      noMenu: true,
    },
    {
      navigationId: 8,
      name: "TermsOfUse",
      title: "TermsOfUse",
      path: paths.termsOfUse,
      working: true,
      noHeader: true,
      noMenu: true,
    },
    {
      navigationId: 9,
      name: "PrivacyPolicy",
      title: "PrivacyPolicy",
      path: paths.privacyPolicy,
      working: true,
      noHeader: true,
      noMenu: true,
    },
    {
      navigationId: 10,
      name: "ForestArea",
      title: "ForestArea",
      path: paths.forestArea,
      working: true,
      noHeader: true,
      noMenu: true,
    },

    {
      navigationId: "7",
      name: "BlogArticle",
      title: "Blog Artigo",
      path: paths.blogReader,
      noHeader: true,
      noMenu: true,
      icon: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTQgNFYyMkgyMFYyNEg0QzIuOSAyNCAyIDIzLjEgMiAyMlY0SDRNMTUgN0gyMC41TDE1IDEuNVY3TTggMEgxNkwyMiA2VjE4QzIyIDE5LjExIDIxLjExIDIwIDIwIDIwSDhDNi44OSAyMCA2IDE5LjEgNiAxOFYyQzYgLjg5IDYuODkgMCA4IDBNMTcgMTZWMTRIOFYxNkgxN00yMCAxMlYxMEg4VjEySDIwWiIgLz48L3N2Zz4=",
    },
    {
      navigationId: 14,
      name: "AllForest",
      title: "AllForest",
      path: paths.allForest,
      noHeader: true,
      noMenu: true,
    },
  ],
  logged: [
    {
      navigationId: 0,
      noMenu: false,
      path: paths.home,
      name: "Home",
      title: "Home",
      icon: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTQgNFYyMkgyMFYyNEg0QzIuOSAyNCAyIDIzLjEgMiAyMlY0SDRNMTUgN0gyMC41TDE1IDEuNVY3TTggMEgxNkwyMiA2VjE4QzIyIDE5LjExIDIxLjExIDIwIDIwIDIwSDhDNi44OSAyMCA2IDE5LjEgNiAxOFYyQzYgLjg5IDYuODkgMCA4IDBNMTcgMTZWMTRIOFYxNkgxN00yMCAxMlYxMEg4VjEySDIwWiIgLz48L3N2Zz4=",
    },
    {
      navigationId: 1,
      name: "About",
      title: "Sobre",
      titleen: "About",
      path: paths.about,
      icon: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTQgNFYyMkgyMFYyNEg0QzIuOSAyNCAyIDIzLjEgMiAyMlY0SDRNMTUgN0gyMC41TDE1IDEuNVY3TTggMEgxNkwyMiA2VjE4QzIyIDE5LjExIDIxLjExIDIwIDIwIDIwSDhDNi44OSAyMCA2IDE5LjEgNiAxOFYyQzYgLjg5IDYuODkgMCA4IDBNMTcgMTZWMTRIOFYxNkgxN00yMCAxMlYxMEg4VjEySDIwWiIgLz48L3N2Zz4=",
      working: true,
    },
    {
      navigationId: "2",
      name: "Dashboard",
      title: "Dashboard",
      path: "/dashboard",
      icon: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTQgNFYyMkgyMFYyNEg0QzIuOSAyNCAyIDIzLjEgMiAyMlY0SDRNMTUgN0gyMC41TDE1IDEuNVY3TTggMEgxNkwyMiA2VjE4QzIyIDE5LjExIDIxLjExIDIwIDIwIDIwSDhDNi44OSAyMCA2IDE5LjEgNiAxOFYyQzYgLjg5IDYuODkgMCA4IDBNMTcgMTZWMTRIOFYxNkgxN00yMCAxMlYxMEg4VjEySDIwWiIgLz48L3N2Zz4=",
    },
    {
      navigationId: "3",
      name: "Technology",
      title: "Tecnologia",
      titleen: "Technology",
      path: "/tecnologia",
      icon: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTQgNFYyMkgyMFYyNEg0QzIuOSAyNCAyIDIzLjEgMiAyMlY0SDRNMTUgN0gyMC41TDE1IDEuNVY3TTggMEgxNkwyMiA2VjE4QzIyIDE5LjExIDIxLjExIDIwIDIwIDIwSDhDNi44OSAyMCA2IDE5LjEgNiAxOFYyQzYgLjg5IDYuODkgMCA4IDBNMTcgMTZWMTRIOFYxNkgxN00yMCAxMlYxMEg4VjEySDIwWiIgLz48L3N2Zz4=",
    },
    {
      navigationId: "4",
      name: "Project",
      title: "O Projeto",
      titleen: "Project",
      path: "/o_projeto",
      icon: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTQgNFYyMkgyMFYyNEg0QzIuOSAyNCAyIDIzLjEgMiAyMlY0SDRNMTUgN0gyMC41TDE1IDEuNVY3TTggMEgxNkwyMiA2VjE4QzIyIDE5LjExIDIxLjExIDIwIDIwIDIwSDhDNi44OSAyMCA2IDE5LjEgNiAxOFYyQzYgLjg5IDYuODkgMCA4IDBNMTcgMTZWMTRIOFYxNkgxN00yMCAxMlYxMEg4VjEySDIwWiIgLz48L3N2Zz4=",
    },
    {
      navigationId: "5",
      name: "Faq",
      title: "FAQ",
      noHeader: true,
      path: "/faq",
      icon: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTQgNFYyMkgyMFYyNEg0QzIuOSAyNCAyIDIzLjEgMiAyMlY0SDRNMTUgN0gyMC41TDE1IDEuNVY3TTggMEgxNkwyMiA2VjE4QzIyIDE5LjExIDIxLjExIDIwIDIwIDIwSDhDNi44OSAyMCA2IDE5LjEgNiAxOFYyQzYgLjg5IDYuODkgMCA4IDBNMTcgMTZWMTRIOFYxNkgxN00yMCAxMlYxMEg4VjEySDIwWiIgLz48L3N2Zz4=",
    },
    {
      navigationId: "6",
      name: "Blog",
      noHeader: true,
      title: "Blog",
      path: "/blog",
      icon: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTQgNFYyMkgyMFYyNEg0QzIuOSAyNCAyIDIzLjEgMiAyMlY0SDRNMTUgN0gyMC41TDE1IDEuNVY3TTggMEgxNkwyMiA2VjE4QzIyIDE5LjExIDIxLjExIDIwIDIwIDIwSDhDNi44OSAyMCA2IDE5LjEgNiAxOFYyQzYgLjg5IDYuODkgMCA4IDBNMTcgMTZWMTRIOFYxNkgxN00yMCAxMlYxMEg4VjEySDIwWiIgLz48L3N2Zz4=",
    },
    {
      navigationId: "7",
      name: "BlogArticle",
      title: "Blog Artigo",
      path: paths.blogReader,
      noHeader: true,
      noMenu: true,
      icon: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTQgNFYyMkgyMFYyNEg0QzIuOSAyNCAyIDIzLjEgMiAyMlY0SDRNMTUgN0gyMC41TDE1IDEuNVY3TTggMEgxNkwyMiA2VjE4QzIyIDE5LjExIDIxLjExIDIwIDIwIDIwSDhDNi44OSAyMCA2IDE5LjEgNiAxOFYyQzYgLjg5IDYuODkgMCA4IDBNMTcgMTZWMTRIOFYxNkgxN00yMCAxMlYxMEg4VjEySDIwWiIgLz48L3N2Zz4=",
    },
    {
      navigationId: 10,
      name: "ForestArea",
      title: "ForestArea",
      path: paths.forestArea,
      working: true,
      noHeader: true,
      noMenu: true,
    },
    {
      navigationId: 11,
      name: "Configs",
      title: "Configs",
      path: paths.config,
      working: true,
      noHeader: true,
      noMenu: true,
    },
    {
      navigationId: 8,
      name: "TermsOfUse",
      title: "TermsOfUse",
      path: paths.termsOfUse,
      working: true,
      noHeader: true,
      noMenu: true,
    },
    {
      navigationId: 9,
      name: "PrivacyPolicy",
      title: "PrivacyPolicy",
      path: paths.privacyPolicy,
      working: true,
      noHeader: true,
      noMenu: true,
    },
    {
      navigationId: 12,
      name: "Payment",
      title: "Pagamento",
      path: paths.payment,
      noHeader: true,
      noMenu: true,
      icon: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTQgNFYyMkgyMFYyNEg0QzIuOSAyNCAyIDIzLjEgMiAyMlY0SDRNMTUgN0gyMC41TDE1IDEuNVY3TTggMEgxNkwyMiA2VjE4QzIyIDE5LjExIDIxLjExIDIwIDIwIDIwSDhDNi44OSAyMCA2IDE5LjEgNiAxOFYyQzYgLjg5IDYuODkgMCA4IDBNMTcgMTZWMTRIOFYxNkgxN00yMCAxMlYxMEg4VjEySDIwWiIgLz48L3N2Zz4=",
    },
    {
      navigationId: 13,
      name: "Extract",
      title: "Extract",
      path: paths.extract,
      noHeader: true,
      noMenu: true,
    },
    {
      navigationId: 14,
      name: "Nfts",
      title: "Nfts",
      path: paths.nfts,
      noHeader: true,
      noMenu: true,
    },

    {
      navigationId: 15,
      name: "NFTTransfer",
      title: "NFTTransfer",
      path: paths.nftTransfer,
      noHeader: true,
      noMenu: true,
    },
    {
      navigationId: 14,
      name: "AllForest",
      title: "AllForest",
      path: paths.allForest,
      noHeader: true,
      noMenu: true,
    },
  ],
};
export default pagesConfig;
