import React from "react";

const GrainImage = process.env.REACT_APP_ASSETS_URL + "/imgs/grainBackground.webp";

import Styles from "./styles";

import { useNavigate } from "react-router-dom";

import { hooks, LocalStorage } from "../../utils";

import { api } from "../../services";
const NftDash = process.env.REACT_APP_ASSETS_URL + "/imgs/nftDash.webp";
import { Grid } from "@material-ui/core";
import { paths } from "../../navigation/navigate";
import Texts from "../../config/texts";

function Nfts() {
  const texts =
    Texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].nfts;
  const textAction =
    Texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].actionButton;

  const [isMounted, setMount] = React.useState<boolean>(false);
  const [menuHeight, setHeight] = React.useState<any>(null);
  const [nftList, setNftList] = React.useState<any | null>(null);
  const [nftUserList, setNftUserList] = React.useState<any | null>(null);
  const { loading, call } = hooks.useRequest();
  const [navState, setNavState] = React.useState<any>(0);
  const navigate = useNavigate();

  const Mount = React.useCallback(() => {
    call(
      "nftList",
      api.getNFTs({
        page: 0,
        size: 999999,
      }),
      async (response) => {
        if (response.ok) {
          let newList: any[] = [];

          for (const item of response?.data?.content) {
            let newImg: any = "";
            let newQrCode: any = "";
            await call(
              null,
              api.getNFTImg(item.id, item.imageId),
              (response) => {
                newImg = `data:image/jpeg;base64,${response.data}`;
              }
            );
            await call(
              null,
              api.getNFTImg(item.id, item.qrcodeId),
              (response) => {
                newQrCode = `data:image/jpeg;base64,${response.data}`;
              }
            );

            newList.push({
              ...item,
              img: newImg,
              qrCode: newQrCode,
            });
          }

          setNftList({ ...response.data, content: newList });
        }
      }
    );
    call(
      "nftList",
      api.getNFTsUser({
        page: 0,
        size: 999999,
      }),
      async (response) => {
        if (response.ok) {
          let newList: any[] = [];

          for (const item of response?.data?.content) {
            if (item.status === "INACTIVE" || item.status === "REJECTED") {
              let newImg: any = "";
              let newQrCode: any = "";

              await call(
                null,
                api.getNFTImg(item.nft.id, item.nft.imageId),
                (response) => {
                  newImg = `data:image/jpeg;base64,${response.data}`;
                }
              );
              await call(
                null,
                api.getNFTImg(item.nft.id, item.nft.qrcodeId),
                (response) => {
                  newQrCode = `data:image/jpeg;base64,${response.data}`;
                }
              );

              newList.push({
                ...item,
                img: newImg,
                qrCode: newQrCode,
              });
            }
          }

          setNftUserList({ content: newList });
        }
      }
    );
  }, []);

  React.useEffect(() => {
    if (!isMounted) {
      Mount();
      setMount(true);
    }
  }, [Mount, isMounted]);

  React.useLayoutEffect(() => {
    if (!menuHeight) {
      const mh = document.getElementById("header-menu")?.offsetHeight;

      setHeight(mh);
    }
  }, [menuHeight]);

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        overflow: "hidden",
        flexDirection: "column",
      }}
    >
      <Styles.DashContainer $image={GrainImage}>
        <Styles.YellowBall></Styles.YellowBall>
        <Styles.YellowBallTwo></Styles.YellowBallTwo>
        <Styles.WhiteBox>
          <Styles.TitleBox>
            <Styles.TitleDash>{texts.title}</Styles.TitleDash>
          </Styles.TitleBox>
          <Styles.NftContainer $image={NftDash}>
            <Styles.NftTextBox>
              <Styles.NftTitle>{texts.nft}</Styles.NftTitle>

              <Styles.OpenSeaButton
                fullWidth={false}
                onClick={() => {
                  window.open("https://opensea.io/Biocoin", "_blank");
                }}
              >
                {texts.sea}
              </Styles.OpenSeaButton>
            </Styles.NftTextBox>
          </Styles.NftContainer>

          <Styles.NavNfts>
            <Styles.NavNftsButton
              $selected={navState === 0}
              onClick={() => setNavState(0)}
            >
              {texts.toSell}
            </Styles.NavNftsButton>
            <Styles.NavNftsButton
              $selected={navState === 1}
              onClick={() => setNavState(1)}
            >
              {texts.redeemed}
            </Styles.NavNftsButton>
          </Styles.NavNfts>

          <Grid container spacing={2}>
            {navState === 0 ? (
              nftList?.content?.length > 0 ? (
                nftList?.content?.map((item) => {
                  return (
                    <Grid
                      item
                      justifyContent="center"
                      alignContent="center"
                      alignItems="center"
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      sm={12}
                      md={6}
                      lg={3}
                      xl={2}
                    >
                      <Styles.NftCard>
                        <div>
                          <Styles.NftCardImg $src={item.img} />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "8px",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <Styles.NftCardSubtitle>
                                {item.collection}
                              </Styles.NftCardSubtitle>
                              <Styles.NftCardTitle>
                                {item.name}
                              </Styles.NftCardTitle>
                              <Styles.NftCardSubtitle>
                                {item.content}
                              </Styles.NftCardSubtitle>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-end",

                                alignItems: "center",
                              }}
                            >
                              <Styles.NftNumber>
                                #{item.number}
                              </Styles.NftNumber>
                              <Styles.NftQrCode src={item.qrCode} />
                            </div>
                          </div>
                        </div>

                        <Styles.NftButton
                          onClick={() => {
                            window.open(item.purchaseUrl, "_blank");
                          }}
                        >
                          {textAction.buy}
                        </Styles.NftButton>
                      </Styles.NftCard>
                    </Grid>
                  );
                })
              ) : (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Styles.NftTitle>{textAction.empty}</Styles.NftTitle>
                </div>
              )
            ) : nftUserList?.content?.length > 0 ? (
              nftUserList?.content?.map((item) => {
                return (
                  <Grid
                    item
                    justifyContent="center"
                    alignContent="center"
                    alignItems="center"
                    style={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    sm={12}
                    md={6}
                    lg={3}
                    xl={2}
                  >
                    <Styles.NftCard>
                      <div>
                        <Styles.NftCardImg $src={item.img} />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "8px",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <Styles.NftCardSubtitle>
                              {item.nft.collection}
                            </Styles.NftCardSubtitle>
                            <Styles.NftCardTitle>
                              {item.nft.name}
                            </Styles.NftCardTitle>
                            <Styles.NftCardSubtitle>
                              {item.nft.content}
                            </Styles.NftCardSubtitle>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "flex-end",

                              alignItems: "center",
                            }}
                          >
                            <Styles.NftNumber>
                              #{item.nft.number}
                            </Styles.NftNumber>
                            <Styles.NftQrCode src={item.qrCode} />
                          </div>
                        </div>
                      </div>

                      <Styles.NftButton
                        onClick={() =>
                          navigate(paths.nftTransfer, {
                            state: { nft: item },
                          })
                        }
                      >
                        {textAction.transfer}
                      </Styles.NftButton>
                    </Styles.NftCard>
                  </Grid>
                );
              })
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Styles.NftTitle>{textAction.empty}</Styles.NftTitle>
              </div>
            )}
          </Grid>
        </Styles.WhiteBox>{" "}
        <Styles.Back
          disabled={loading}
          loading={loading}
          fullWidth={false}
          onClick={() => navigate(paths.dashboard)}
        >
          {textAction.back}
        </Styles.Back>
      </Styles.DashContainer>
    </div>
  );
}

export default Nfts;
