import React, { useContext, useEffect } from "react";

import { StrongText } from "../../components";
import { SVG, fonts, Spacing } from "../../config";

const DashCoin = process.env.REACT_APP_ASSETS_URL + "/imgs/CoinDash.webp";
const NftDash = process.env.REACT_APP_ASSETS_URL + "/imgs/nftDash.webp";

const GrainImage =
  process.env.REACT_APP_ASSETS_URL + "/imgs/grainBackground.webp";
import { FaTiktok } from "react-icons/fa";
import { Instagram } from "@material-ui/icons";

import { AiOutlinePlusCircle } from "react-icons/ai";

import Purpose from "../home/components/Purpose";

import Styles from "./styles";

import { CircularProgress, Grid } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { paths } from "../../navigation/navigate";
import ChartBox from "../../components/charts/chartBox";
import { hooks, LocalStorage, models } from "../../utils";
import { StorageContext } from "../../contexts/StorageContext";
import alerts from "../../utils/Alerts";
import { customModalBlur } from "../../components/modals/utils";
import WarningDash from "./components/WarningDash";
import ptBr from "../../config/texts/pt-br";
import { api } from "../../services";
import { FaXTwitter } from "react-icons/fa6";
import Texts from "../../config/texts";

function Dashboard() {
  const lgn = LocalStorage.getItem("language");
  const texts = Texts[lgn ? lgn : "pt"].dash;
  const textsHeader = Texts[lgn ? lgn : "pt"].project;
  const actionText = Texts[lgn ? lgn : "pt"].forests;

  const { loading, call } = hooks.useRequest();
  const [menuHeight, setHeight] = React.useState<any>(null);
  const [wallet, setWallet] = React.useState<any>([
    {
      icon: SVG.DashThreeIcon,

      onClick: () => {
        !isLogged && goLogin();
      },

      ...texts.loginCard[0],
    },
    {
      icon: SVG.BranchIcon,
      ...texts.loginCard[1],
      onClick: () => {
        navigate("/forest/acquired");
      },
    },
    {
      icon: SVG.BranchIcon,
      ...texts.loginCard[2],
      onClick: () => {
        navigate("/forest/buy");
      },
    },
  ]);
  const classes = Styles.useStyles();
  const navigate = useNavigate();
  const { isLogged, userData } = useContext<any>(StorageContext);

  useEffect(() => {
    if (isLogged) {
      call(null, api.getWallet(), (data) => {
        if (data.ok) {
          setWallet((oldWallet) => {
            let newWallet = [...oldWallet];
            newWallet[0].subText = (
              <Styles.LineCoinText className="textBox">
                {data?.data?.biocoin}
              </Styles.LineCoinText>
            );
            // let reais = data?.data?.money?.toFixed(2);
            // const [parteInteira, parteDecimal] = reais?.split(".");
            // newWallet[1].subText = (
            //   <Styles.LineSecondaryText small className="textBox">
            //     R${" "}
            //     <Styles.LineSecondaryTextSpan className="textBox">
            //       {parteInteira}
            //     </Styles.LineSecondaryTextSpan>
            //     ,{parteDecimal}
            //   </Styles.LineSecondaryText>
            // );

            return newWallet;
          });
        }
      });
    }
  }, [isLogged]);

  const handleDownload = () => {
    const pdfPath =
      process.env.REACT_APP_ASSETS_URL + "/documents/WhitePaper.pdf";
    window.open(pdfPath, "_blank");
  };

  const goPlaceholder = () => {
    alerts.alertWarning("Pagina em desenvolvimento!");
  };

  const goLogin = () => {
    navigate(paths.login);
  };

  const gerarData = () => {
    if (window.innerWidth < 1024) {
      return {
        values: [6.2, 5.7, 7.1, 5.7, 6.4, 6.8, 7.5],
        labels: ["04", "07", "10", "13", "16", "19", "22"],
      };
    } else {
      return {
        values: [
          6.2, 5.7, 7.1, 5.7, 6.4, 6.8, 7.5, 6, 6.5, 6.2, 5.7, 7.1, 6.5, 6.2,
          7.3, 7.6, 8, 7.6, 6.5,
        ],
        labels: [
          "04",
          "07",
          "10",
          "13",
          "16",
          "19",
          "22",
          "25",
          "28",
          "31",
          "34",
          "37",
          "40",
          "43",
          "46",
          "49",
          "52",
          "55",
          "58",
        ],
      };
    }
  };

  const iconButtons = [
    {
      icon: Instagram,
      link: "https://www.instagram.com/biocoinofficial/",
      name: "Instagram",
    },
    // {
    //   icon: FaFacebookF,
    //   link: "https://www.facebook.com/",
    //   name: "Facebook",
    // },
    {
      icon: FaTiktok,
      link: "https://www.tiktok.com/@biocoin.net.br?_t=8gIA4hwahkM&_r=1",
      name: "Tiktok",
    },
    {
      icon: FaXTwitter,
      link: "https://x.com/BioCoin_?s=20",
      name: "Twitter",
    },
    // {
    //   icon: LuSendHorizonal,
    //   link: "https://www.google.com/",
    //   name: "Send",
    // },
    // {
    //   icon: FaPinterestP,
    //   link: "https://www.pinterest.com/",
    //   name: "Pinterest",
    // },
    // {
    //   icon: BiLogoMediumOld,
    //   link: "https://www.medium.com/",
    //   name: "Medium",
    // },
  ];

  const configCards = [
    {
      icon: SVG.InvoiceIcon,
      ...texts.configCards[0],
      onClick: () => {
        navigate(paths.extract);
      },
    },
    {
      icon: SVG.GoldIcon,
      ...texts.configCards[1],
      onClick: () => {
        customModalBlur.setInfos(
          <WarningDash />,
          { height: "fit-content", width: "fit-content" },
          true
        );
      },
    },
    {
      icon: SVG.ConfigIcon,
      ...texts.configCards[2],
      onClick: () => {
        navigate(paths.config);
      },
    },
    {
      icon: SVG.NftsIcons,
      ...texts.configCards[3],
      onClick: () => {
        navigate(paths.nfts);
      },
    },
  ];

  React.useLayoutEffect(() => {
    if (!menuHeight) {
      const mh = document.getElementById("header-menu")?.offsetHeight;

      setHeight(mh);
    }
  }, [menuHeight]);

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        overflow: "hidden",
        flexDirection: "column",
      }}
    >
      <Styles.Container $menuHeight={menuHeight}>
        <Styles.BgVideo
          $menuHeight={menuHeight}
          loop
          autoPlay
          playsInline
          controls={false}
          muted
          preload="auto"
        >
          <source
            src={process.env.REACT_APP_ASSETS_URL + "/videos/dashVideo.webm"}
            type="video/webm"
          />
        </Styles.BgVideo>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Styles.LandingFirst>
            <Styles.LandingText>{textsHeader.landing.title}</Styles.LandingText>
            <Styles.LandingDesctiption>
              {textsHeader.landing.content}
            </Styles.LandingDesctiption>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "space-between",
              }}
            >
              <Styles.LeftButtons>
                <Styles.RegistryButton
                  onClick={() => {
                    isLogged ? navigate("/payment") : goLogin();
                  }}
                  fullWidth={false}
                >
                  {actionText.buy}
                </Styles.RegistryButton>{" "}
                <Styles.WhitelistButton
                  onClick={handleDownload}
                  fullWidth={false}
                >
                  {actionText.whitepaper}
                </Styles.WhitelistButton>
              </Styles.LeftButtons>
            </div>
          </Styles.LandingFirst>
          <Styles.CoinImg src={DashCoin} />
        </div>
      </Styles.Container>

      <Styles.DashContainer $image={GrainImage}>
        <Styles.YellowBall></Styles.YellowBall>
        <Styles.YellowBallTwo></Styles.YellowBallTwo>
        <Styles.WhiteBox>
          <Styles.TitleBox>
            <Styles.TitleDash>
              {isLogged ? texts.header + userData?.name : texts.headerTwo}
            </Styles.TitleDash>
            <Styles.IconsBox>
              {iconButtons.map((item) => (
                <Styles.NavButton
                  color="secondary"
                  aria-label={item?.name}
                  onClick={() => {
                    window.open(item.link, "_blank");
                  }}
                >
                  <div className={classes.svgContainer}>
                    <item.icon style={{ width: "100%", height: "100%" }} />
                  </div>
                </Styles.NavButton>
              ))}
            </Styles.IconsBox>
          </Styles.TitleBox>

          <Styles.NftContainer $image={NftDash}>
            <Styles.NftTextBox>
              <Styles.NftTitle>{texts.nft}</Styles.NftTitle>

              <Styles.OpenSeaButton
                fullWidth={false}
                onClick={() => {
                  window.open("https://opensea.io/Biocoin", "_blank");
                }}
              >
                {texts.sea}
              </Styles.OpenSeaButton>
            </Styles.NftTextBox>
          </Styles.NftContainer>

          <Styles.GridPadding container>
            {loading ? (
              <div
                style={{
                  backgroundColor: "#050500",
                  padding: "26px",
                  position: "relative",
                  borderRadius: "13.942px",
                  height: "fit-content",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <Grid container item xs={12} md={12} lg={8}>
                {wallet.map((item, index) => {
                  return (
                    <Grid item xs={12} md={12} lg={4}>
                      <Styles.PaddingBox first={index === 0}>
                        <Styles.ValueButtons onClick={item.onClick}>
                          <Styles.ValueButtonLabel>
                            <Styles.ValueButtonIconBox className="iconBox">
                              {typeof item.icon === "string" ? (
                                <p
                                  className="iconInBox"
                                  style={{
                                    fontSize: "clamp(8px, 4vw, 15px)",
                                    fontFamily: fonts.heavy,
                                    padding: 0,
                                    margin: "100%",
                                  }}
                                >
                                  {item.icon}
                                </p>
                              ) : (
                                <item.icon
                                  className="iconInBox"
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                  }}
                                />
                              )}
                            </Styles.ValueButtonIconBox>
                            <Styles.ValueButtonText className="textBox">
                              {item.text}
                            </Styles.ValueButtonText>
                          </Styles.ValueButtonLabel>
                          {item.subText && (
                            <Styles.LineText className="textBox">
                              {item.subText}
                            </Styles.LineText>
                          )}
                        </Styles.ValueButtons>
                      </Styles.PaddingBox>
                    </Grid>
                  );
                })}
              </Grid>
            )}

            <Grid item xs={12} md={12} lg={4}>
              <Styles.PaddingBox last>
                <Styles.YellowValueButtons
                  onClick={() => {
                    isLogged ? navigate("/payment") : goLogin();
                  }}
                >
                  <Styles.ValueButtonLabel>
                    <Styles.YellowValueButtonIconBox>
                      <AiOutlinePlusCircle
                        style={{
                          fill: "#E7FF00",
                          height: "100%",
                          width: "100%",
                        }}
                      />
                    </Styles.YellowValueButtonIconBox>
                    <Styles.YellowValueButtonText>
                      {texts.buy}
                    </Styles.YellowValueButtonText>
                  </Styles.ValueButtonLabel>
                </Styles.YellowValueButtons>
              </Styles.PaddingBox>
            </Grid>
          </Styles.GridPadding>

          <div
            style={{
              backgroundColor: "#050500",
              padding: "26px",
              position: "relative",
              borderRadius: "13.942px",
              height: "fit-content",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Styles.GraphText>{texts.growth}</Styles.GraphText>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: Spacing(1),
                  flexWrap: "wrap",
                }}
              >
                {texts.times.map((item, index) => (
                  <Styles.DaysButtons
                    key={index + "-time"}
                    filled
                    fullWidth={false}
                  >
                    {item}
                  </Styles.DaysButtons>
                ))}
              </div>
            </div>

            <Styles.GraphContainer>
              <ChartBox
                height={"100%"}
                name="teste"
                chartData={models.getLineChart(gerarData())}
              />
            </Styles.GraphContainer>

            <div
              style={{
                position: "absolute",
                zIndex: 3,
                borderRadius: "13.942px",
                background: "rgba(5, 5, 0, 0.65)",
                backgroundBlendMode: "multiply",
                width: "100%",
                height: "100%",
                top: 0,
                left: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Styles.GraphTextHold>{texts.await}</Styles.GraphTextHold>
            </div>
          </div>

          {isLogged && (
            <Styles.GridPadding container>
              {configCards.map((item, index) => {
                return (
                  <Grid item xs={12} md={6} lg={3}>
                    <Styles.PaddingBox>
                      <Styles.ValueButtons onClick={item.onClick}>
                        <Styles.ValueButtonIconBox className="iconBox">
                          {typeof item.icon === "string" ? (
                            <p
                              style={{
                                fontSize: "clamp(8px, 4vw, 16px)",
                                fontFamily: fonts.heavy,
                                padding: 0,
                                margin: "100%",
                              }}
                            >
                              {item.icon}
                            </p>
                          ) : (
                            <item.icon
                              className="iconInBox"
                              style={{
                                height: "100%",
                                width: "100%",
                              }}
                            />
                          )}
                        </Styles.ValueButtonIconBox>
                        <Styles.ValueButtonText className="textBox" $bigger>
                          {item.text}
                        </Styles.ValueButtonText>
                      </Styles.ValueButtons>
                    </Styles.PaddingBox>
                  </Grid>
                );
              })}
            </Styles.GridPadding>
          )}
        </Styles.WhiteBox>
      </Styles.DashContainer>
    </div>
  );
}

export default Dashboard;
