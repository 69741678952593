import {
  DatePickerView,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import dateFns from "@date-io/date-fns";
import styled from "styled-components";
import FontStyles from "../styles/fontStyles";
import {
  FormControl,
  FormHelperText,
  Theme,
  ThemeProvider,
} from "@material-ui/core";
import brLocate from "date-fns/locale/pt-BR";
import { Spacing, fonts } from "../../config";
import { useFormFull } from "form-full";
import { validations } from "../../utils";
import { WrapperVariant } from "@material-ui/pickers/wrappers/Wrapper";
import { createMuiTheme } from "@material-ui/core/styles";

export type TextInputDateBaseProps = {
  label?: string;
  alternativeColors?: boolean;
  inputStyle?: styled;
  required?: string;
  readOnly?: boolean;
  icon?: any;
  theme?: any;
  disableError?: boolean;
  borderRadius?: any;
  variant?: WrapperVariant;
  views?: DatePickerView[];
  autoOk?: boolean;
  format?: string;
  minDate?: any;
  maxDate?: any;
  autoComplete?: string;
  end?: string;
  restProps?: any;
  name: string;
};

const customTheme: Theme = createMuiTheme({
  overrides: {
    // Componente geral
    MuiPaper: {
      root: { color: "white" },
      rounded: { borderRadius: 0 },
    },
    // Toolbar
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "rgba(27, 30, 34, 0.90)",
        borderBottom: "1px solid #E7FF00",
        [`@media (max-width:${581 - 1}px)`]: {
          height: Spacing(6.25),
        },
      },
    },
    // Toolbar texto dia e mês
    MuiPickersToolbarText: {
      toolbarTxt: {
        fontFamily: fonts.regular,
        [`@media (max-width:${581 - 1}px)`]: {
          fontSize: "100%",
        },
      },
      toolbarBtnSelected: {
        color: "white",
        [`@media (max-width:${581 - 1}px)`]: {
          fontSize: "100%",
        },
      },
    },
    // Header
    MuiPickersCalendarHeader: {
      // Arrow
      iconButton: {
        backgroundColor: "transparent",
        color: "white",
        [`@media (max-width:${581 - 1}px)`]: {
          marginInline: Spacing(1.5),
        },
      },
      // Texto dia da semana
      dayLabel: {
        fontFamily: fonts.regular,
        color: "white",
        [`@media (max-width:${581 - 1}px)`]: {
          fontSize: Spacing(1),
        },
      },
    },
    // Header e corpo texto
    MuiTypography: {
      // Header texto mês e ano
      alignCenter: {
        fontFamily: fonts.regular,
        color: "white",
        [`@media (max-width:${581 - 1}px)`]: {
          fontSize: Spacing(1.5),
        },
      },
      // Corpo dias
      body2: {
        fontFamily: fonts.regular,
        [`@media (max-width:${581 - 1}px)`]: {
          fontSize: Spacing(1.5),
        },
      },
    },
    // Corpo
    MuiPickersBasePicker: {
      pickerView: {
        backgroundColor: "rgba(27, 30, 34, 0.90)",
        [`@media (max-width:${581 - 1}px)`]: {
          minHeight: "300px",
        },
      },
    },
    // Dias
    MuiPickersDay: {
      // Dias
      day: { color: "white" },
      // Dia selecionado
      daySelected: {
        backgroundColor: "#E7FF00",
        color: "rgba(27, 30, 34, 0.90)",
        "&:hover": {
          backgroundColor: "transparent",
          color: "white",
          border: "1px solid #E7FF00",
        },
      },
      // Dia atual
      current: { color: "#E7FF00" },
    },
    // Footer
    MuiDialogActions: {
      root: {
        backgroundColor: "rgba(27, 30, 34, 0.90)",
        [`@media (max-width:${581 - 1}px)`]: {
          padding: Spacing(0, 1, 1, 0),
        },
      },
    },
    // Footer botões
    MuiButton: {
      root: { minWidth: "32px" },
      label: {
        color: "#E7FF00",
        fontFamily: fonts.bold,
        [`@media (max-width:${581 - 1}px)`]: {
          fontSize: Spacing(1.4),
          width: "auto",
        },
      },
      text: {
        [`@media (max-width:${581 - 1}px)`]: {
          padding: Spacing(0),
        },
      },
    },
    // Texto ano
    MuiPickersYear: {
      root: { fontFamily: fonts.regular },
      yearSelected: { color: "#E7FF00" },
    },
    // Texto mês
    MuiPickersMonth: {
      monthSelected: { color: "#E7FF00" },
    },
    // Input
    MuiFilledInput: {
      root: {
        border: "1.588px solid #FFF",
        background: "rgba(27, 30, 34, 0.70)",
        borderRadius: "53.201px",
        borderTopLeftRadius: undefined,
        borderTopRightRadius: undefined,
        color: "white",
        fontFamily: fonts.regularCf,
        fontSize: Spacing(2.75),
        paddingInline: Spacing(3.75),
        paddingBlock: Spacing(1),
        marginTop: Spacing(-0.5),
        [`@media (max-width:${1024 - 1}px)`]: {
          paddingInline: Spacing(3.5),
          fontSize: Spacing(2.5),
        },
        [`@media (max-width:${581 - 1}px)`]: {
          paddingInline: Spacing(1.25),
          paddingBlock: Spacing(0.5),
          fontSize: Spacing(1.5),
        },
      },
      input: {
        padding: "6px 8px 7px 8px",
        [`@media (max-width:${1024 - 1}px)`]: {
          padding: "6px 8px 7px 8px",
        },
        [`@media (max-width:${581 - 1}px)`]: {
          padding: "3px 4px 3px 4px",
        },
      },
    },
    // Icone input
    MuiIconButton: {
      root: {
        color: "white",
        [`@media (max-width:${581 - 1}px)`]: {
          padding: 0,
          margin: 0,
        },
      },
    },
  },
} as any);

const LabelView = styled.div(() => {
  return {
    display: "flex",
    alignContent: "center",
    justifyContent: "flex-start",
  };
});

const Label = styled.p(({ theme, $witherror }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.semibold14,
    textTransform: "uppercase",
    color: $witherror ? colors.error.main : colors.text.secondary,
    transition: ".2s",
    pointerEvents: "none",
  };
});

const ErrorMessage = styled(FormHelperText)(() => {
  return {
    color: "red",
    minHeight: Spacing(2.5),
    "&&.MuiFormHelperText-root": {
      "@media (max-width: 784px)": {
        fontSize: Spacing(1.2),
      },
    },
  };
});

function getHint(error) {
  if (error) return error;

  return null;
}

function InputDateComponent(props: TextInputDateBaseProps) {
  const { value, error, onBlur, onChange, ref, ffHandler } = useFormFull.field({
    ...props,
    validation: (data) =>
      validations.inputDate(data, props.maxDate, props.minDate),
  });

  const {
    label,
    icon,
    theme,
    required,
    disableError,
    borderRadius,
    variant,
    inputStyle,
    views = ["year", "month", "date"],
    autoOk = true,
    format,
    readOnly,
    minDate,
    maxDate,
    alternativeColors,
    autoComplete,
    end,
    ...restProps
  } = props;
  const getBirthDate = (data) => {
    let day: string;
    let month: string;
    let year: string;

    let dateOfBirth = new Date(data);
    day = dateOfBirth.getDate().toString();
    month = (dateOfBirth.getMonth() + 1).toString();
    year = dateOfBirth.getFullYear().toString();

    return [day, month, year];
  };


  return (
    <FormControl fullWidth error={Boolean(error)}>
      <MuiPickersUtilsProvider locale={brLocate} utils={dateFns}>
        {label && (
          <LabelView>
            <Label
              className={"form-input-label-" + label?.replace(/\s/g, "")}
              $withValue={Boolean(value) || value === 0}
              $withError={Boolean(error)}
            >
              {label}
              {required && "*"}
            </Label>
          </LabelView>
        )}
        <ThemeProvider theme={customTheme}>
          <KeyboardDatePicker
            {...restProps}
            {...(readOnly ? { open: !readOnly } : {})}
            inputVariant="filled"
            format={format ? format : "dd/MM/yyyy"}
            minDate={
              typeof minDate !== "string" && minDate !== null
                ? minDate
                : undefined
            }
            maxDate={
              typeof maxDate !== "string" && maxDate !== null
                ? maxDate
                : undefined
            }
            InputProps={{
              disableUnderline: true,
              readOnly: readOnly,
            }}
            value={value ? value : null}
            required={Boolean(required)}
            variant={variant}
            fullWidth
            views={views}
            inputRef={ref}
            autoOk={autoOk}
            invalidDateMessage={null}
            minDateMessage={null}
            maxDateMessage={null}
            onChange={(date) => {
              onChange(null, date);
              setTimeout(() => {
                onBlur(ffHandler?.testFieldError(props.name));
              }, 10);
            }}
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
      {disableError ? (
        Boolean(error) ? (
          <ErrorMessage error={Boolean(error)}>{getHint(error)}</ErrorMessage>
        ) : null
      ) : (
        <ErrorMessage error={Boolean(error)}>{getHint(error)}</ErrorMessage>
      )}
    </FormControl>
  );
}

export default InputDateComponent;
