import { InputPassword } from "../../../components";

import Styles from "../styles";
import React from "react";
import alerts from "../../../utils/Alerts";

import { FormFull } from "form-full";
import { fonts, Spacing } from "../../../config";
import { hooks, LocalStorage, validations } from "../../../utils";
import { api } from "../../../services";
import ResponseError from "../../../services/helpers/ResponseError";
import BoxHeader from "./BoxHeader";
import Texts from "../../../config/texts";

function Password({ goBack, user }: any): JSX.Element {
  const texts =
    Texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].config;
  const textAction =
    Texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].actionButton;
  const { loading, call } = hooks.useRequest();

  const [ref, setRef] = React.useState<any>(null);

  const changePassword = (data) => {
    call(
      null,
      api.patchPasswordUser({
        password: data?.password,
        newPassword: data?.newPassword,
      }),
      (response) => {
        if (response.ok) {
          alerts.alertSuccess(texts.password.modal);
        } else {
          const errorResponse = new ResponseError(response);
          errorResponse.alertMessage();
        }
      }
    );
  };
  return (
    <>
      <Styles.WhiteBox>
        <Styles.TitleBox>
          <Styles.TitleConfig>{texts.password.title}</Styles.TitleConfig>
        </Styles.TitleBox>
        <Styles.BoxUserContent>
          <BoxHeader user={user} />

          <FormFull
            clearOnSubmit={true}
            formRef={setRef}
            onSubmit={changePassword}
          >
            <div style={{ width: "100%" }}>
              <Styles.InputTitle>{texts.password.current}</Styles.InputTitle>
              <InputPassword
                required={texts.required}
                autoComplete="P"
                key={21}
                inputStyle={{
                  background: "rgba(27, 30, 34, 0.70)",
                  borderRadius: "53.201px",
                  color: "white",
                  fontFamily: fonts.regularCf,
                  fontSize: Spacing(2.75),
                  paddingInline: Spacing(3.75),
                  paddingBlock: Spacing(2),
                  marginTop: Spacing(0.75),
                  border: "1.988px solid #D3D527",
                  "& .MuiInputBase-input": {
                    padding: 0,

                    boxShadow: "none !important",
                  },

                  [`@media (max-width:${1024 - 1}px)`]: {
                    paddingInline: Spacing(3.5),
                    fontSize: Spacing(2.5),
                  },
                  [`@media (max-width:${581 - 1}px)`]: {
                    paddingInline: Spacing(1.25),
                    paddingBlock: Spacing(1),
                    fontSize: Spacing(1.5),
                  },
                }}
                name="password"
              />
            </div>
            <div style={{ width: "100%" }}>
              <Styles.InputTitle>{texts.password.new}</Styles.InputTitle>
              <InputPassword
                required={texts.required}
                autoComplete="newP"
                key={22}
                inputStyle={{
                  background: "rgba(27, 30, 34, 0.70)",
                  borderRadius: "53.201px",
                  color: "white",
                  fontFamily: fonts.regularCf,
                  fontSize: Spacing(2.75),
                  paddingInline: Spacing(3.75),
                  paddingBlock: Spacing(2),
                  marginTop: Spacing(0.75),
                  border: "1.988px solid #D3D527",
                  "& .MuiInputBase-input": {
                    padding: 0,

                    boxShadow: "none !important",
                  },

                  [`@media (max-width:${1024 - 1}px)`]: {
                    paddingInline: Spacing(3.5),
                    fontSize: Spacing(2.5),
                  },
                  [`@media (max-width:${581 - 1}px)`]: {
                    paddingInline: Spacing(1.25),
                    paddingBlock: Spacing(1),
                    fontSize: Spacing(1.5),
                  },
                }}
                name="newPassword"
              />{" "}
            </div>

            <div style={{ width: "100%" }}>
              <Styles.InputTitle>{texts.password.newConfirm}</Styles.InputTitle>
              <InputPassword
                required={texts.required}
                autoComplete="newRepeat"
                key={23}
                inputStyle={{
                  background: "rgba(27, 30, 34, 0.70)",
                  borderRadius: "53.201px",
                  color: "white",
                  fontFamily: fonts.regularCf,
                  fontSize: Spacing(2.75),
                  paddingInline: Spacing(3.75),
                  paddingBlock: Spacing(2),
                  marginTop: Spacing(0.75),
                  border: "1.988px solid #D3D527",
                  "& .MuiInputBase-input": {
                    padding: 0,

                    boxShadow: "none !important",
                  },

                  [`@media (max-width:${1024 - 1}px)`]: {
                    paddingInline: Spacing(3.5),
                    fontSize: Spacing(2.5),
                  },
                  [`@media (max-width:${581 - 1}px)`]: {
                    paddingInline: Spacing(1.25),
                    paddingBlock: Spacing(1),
                    fontSize: Spacing(1.5),
                  },
                }}
                name="newPasswordConfirm"
              />
            </div>
            <div>
              <Styles.SendButton
                disabled={loading}
                loading={loading}
                onClick={() => {
                  let newP = ref.getValue("newPassword");
                  let newPC = ref.getValue("newPasswordConfirm");
                  if (newP === newPC) {
                    ref.setFieldValidation("newPasswordConfirm", (value) => {
                      const isPassword = validations.isPassword(value);
                      if (isPassword !== null) {
                        return isPassword;
                      } else {
                        return null;
                      }
                    });
                    ref.submit();
                  } else {
                    ref.setFieldValidation("newPasswordConfirm", (value) => {
                      const isPassword = validations.isPassword(value);
                      if (isPassword !== null) {
                        return isPassword;
                      } else {
                        return texts.samePassword;
                      }
                    });
                    ref.testFieldError("newPasswordConfirm");
                  }
                }}
                name="submit"
              >
                {texts.password.submit}
              </Styles.SendButton>
            </div>
          </FormFull>
        </Styles.BoxUserContent>
      </Styles.WhiteBox>
      <Styles.Back
        disabled={loading}
        loading={loading}
        fullWidth={false}
        onClick={goBack}
      >
        {textAction.back}
      </Styles.Back>
    </>
  );
}

export default Password;
