import getBarChart from "./getBarChart";
import getDoughnutChart from "./getDoughnutChart";
import getLineChart from "./getLineChart";
import getMultiLineChart from "./getMultiLineChart";
import getImagesLIst from "./getImagesLIst";

const models = {
  getBarChart,
  getDoughnutChart,
  getLineChart,
  getMultiLineChart,
  getImagesLIst,
};
export default models;
