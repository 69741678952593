import Styles from "../PaymentStyles";
const GoldenCoin = process.env.REACT_APP_ASSETS_URL + "/imgs/goldenCoin.webp";
const BackgroundDifferentials =
  process.env.REACT_APP_ASSETS_URL + "/imgs/differentialsBackground.webp";
import { paths } from "../../../navigation/navigate";
import { useNavigate } from "react-router-dom";
import texts from "../../../config/texts";
import { LocalStorage } from "../../../utils";

interface PageProps {
  goBack: () => void;
  loading: boolean;
  canNextPage: boolean;
}

function StepFinal({ goBack, loading, canNextPage }: PageProps): JSX.Element {
  const currentText =
    texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].payment.final;
  const navigate = useNavigate();

  return (
    <>
      <Styles.FinalContainer>
        <Styles.BlackContainer backgroundImg={BackgroundDifferentials}>
          <Styles.Subtitle2>
            {canNextPage ? currentText.thanks : currentText.wrong}
          </Styles.Subtitle2>
          <Styles.CoinImg src={GoldenCoin} />
          <Styles.Subtitle2 $smaller>
            {canNextPage ? currentText.proccess : currentText.tryAgain}
          </Styles.Subtitle2>
          <Styles.StyledButton
            style={{ textTransform: "capitalize" }}
            onClick={() => navigate(paths.home)}
          >
            {currentText.closeButton}
          </Styles.StyledButton>
        </Styles.BlackContainer>
        <Styles.VideoContainer>
          <Styles.LoopVideo
            autoPlay
            playsInline
            controls={false}
            loop
            muted
            preload="auto"
          >
            <source
              src={process.env.REACT_APP_ASSETS_URL + "/videos/homeLoop.webm"}
              type="video/webm"
            />
          </Styles.LoopVideo>
        </Styles.VideoContainer>
      </Styles.FinalContainer>

      <Styles.FinalContainerMobile>
        <Styles.Subtitle2>
          {canNextPage ? currentText.thanks : currentText.wrong}
        </Styles.Subtitle2>
        <Styles.CoinImg src={GoldenCoin} />
        <Styles.Subtitle2 $smaller>
          {canNextPage ? currentText.proccess : currentText.tryAgain}
        </Styles.Subtitle2>
        <Styles.StyledButton onClick={() => navigate(paths.home)}>
          {currentText.closeButton}
        </Styles.StyledButton>
      </Styles.FinalContainerMobile>
    </>
  );
}

export default StepFinal;
