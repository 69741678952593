import { Button, Grid, IconButton, makeStyles } from "@material-ui/core";
import { url } from "inspector";
import styled from "styled-components";
import { IoWarningOutline } from "react-icons/io5";
import {
  ButtonContained,
  ButtonOutlined,
  FontStyles,
} from "../../../components";
import { fonts, SVG } from "../../../config";
import colors from "../../../config/colors";
const Container = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    position: "relative",
    display: "flex",

    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: `calc(448px + ${$menuHeight}px)`,
    height: "100%",
    marginTop: `-${$menuHeight}px`,

    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "flex-end",
    width: "100%",
    backgroundColor: "black",
    [`@media (max-width:${1024 - 1}px)`]: {
      marginTop: 0,
      width: "100%",
      height: "100%",

      minHeight: "650px",
    },

    [`@media (max-width:${470 - 1}px)`]: {
      minHeight: "343px",
    },
  };
});

const BgVideoContainer = styled.div(({ theme, $menuHeight }) => {
  return {
    position: "relative",
    width: "100%",
    zIndex: 0,
    display: "flex",
    justifyContent: "center",
    height: theme.spacing(82.875),

    [`@media (max-width:${745 - 1}px)`]: {
      height: theme.spacing(72.5),
    },
    [`@media (max-width:${470 - 1}px)`]: {
      width: "100%",
      height: theme.spacing(34.375),
    },
    [`@media (min-width:${1440}px)`]: {
      height: theme.spacing(82.875),
    },
  };
});

const HomeButtons = styled(Button)(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      borderRadius: "13.493px",
      border: "0.587px solid #FFF",
      background: "rgba(217, 217, 217, 0.05)",
      backdropFilter: "blur(1.5927602052688599px)",
      ...FontStyles.bold12,
      fontFamily: fonts.heavy,
      color: "white",
      minWidth: spacing(13.125),
      minHeight: spacing(13.125),
      flex: 1,
      width: "100%",
      height: "100%",
      ".MuiButton-label": {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "space-between",
        gap: spacing(5.25),
        textTransform: "capitalize",
        lineHeight: "normal",
        fontFamily: fonts.heavy,
      },
    },
  };
});

const LeftButtons = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    gap: spacing(2),
    [`@media (max-width:${1024 - 1}px)`]: {
      gap: spacing(1.1),
      flexDirection: "column",
      width: "200px",
    },
    [`@media (max-width:${470 - 1}px)`]: {
      flexDirection: "column",
      width: "104px",
      gap: spacing(0.75),
    },
  };
});

const RegistryButton = styled(ButtonContained)(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      ...FontStyles.bold16,
      fontSize: spacing(3),
      fontFamily: fonts.heavy,
      paddingInline: spacing(4.25),
      paddingBlock: spacing(1.375),
      [`@media (max-width:${1024 - 1}px)`]: {
        fontSize: spacing(2.25),
        width: "100%",
      },
      [`@media (max-width:${470 - 1}px)`]: {
        fontSize: spacing(1.1),
        paddingInline: spacing(2.5),
        paddingBlock: spacing(0.75),
        margin: 0,
      },
    },
  };
});

const RegistrySecondButton = styled(ButtonContained)(
  ({ theme, $menuHeight }) => {
    const { spacing } = theme;
    return {
      "&&.MuiButton-root": {
        display: "none",
        ...FontStyles.bold16,
        fontFamily: fonts.heavy,

        alignItens: "center",
        justifyContent: "center",
        [`@media (max-width:${1024 - 1}px)`]: {
          display: "flex",
          fontSize: spacing(1.75),
        },
        [`@media (max-width:${470 - 1}px)`]: {
          fontSize: spacing(0.9),
          padding: spacing(0.45),
        },
      },
    };
  }
);

const AreaIcon = styled(SVG.AreaIcon)(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    width: "auto",
    height: "auto",
    [`@media (max-width:${1024 - 1}px)`]: {},
    [`@media (max-width:${470 - 1}px)`]: {
      maxWidth: spacing(1),
      maxHeight: spacing(1),
    },
  };
});

const WhitelistButton = styled(ButtonOutlined)(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      ...FontStyles.bold16,
      fontFamily: fonts.heavy,
      color: colors.yellow,
      borderColor: colors.yellow,
      fontSize: spacing(3),
      paddingInline: spacing(4.25),
      paddingBlock: spacing(1.375),
      [`@media (max-width:${1024 - 1}px)`]: {
        fontSize: spacing(2.25),
        width: "100%",
      },
      [`@media (max-width:${470 - 1}px)`]: {
        fontSize: spacing(1.1),
        paddingInline: spacing(2.5),
        paddingBlock: spacing(0.75),
        margin: 0,
      },
    },
  };
});

const WhitelistSecondButton = styled(ButtonOutlined)(
  ({ theme, $menuHeight }) => {
    const { spacing } = theme;
    return {
      "&&.MuiButton-root": {
        ...FontStyles.bold16,
        fontSize: spacing(1.75),
        color: "black",
        borderColor: "black",
        display: "none",
        fontFamily: fonts.heavy,
        [`@media (max-width:${1024 - 1}px)`]: {
          display: "flex",
        },
        [`@media (max-width:${470 - 1}px)`]: {
          fontSize: spacing(0.9),
          padding: spacing(0.45),
        },
      },
    };
  }
);

const LandingText = styled.p(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    lineHeight: "127.5%",
    ...FontStyles.regular14,
    fontSize: spacing(4.375),
    maxWidth: "620px",
    padding: 0,
    margin: 0,
    marginBottom: spacing(2),
    color: "white",
    [`@media (max-width:${1024 - 1}px)`]: {
      marginBottom: spacing(1.5),
      fontSize: spacing(4.375),
      maxWidth: "317px",
    },

    [`@media (max-width:${470 - 1}px)`]: {
      fontSize: spacing(2.25),
      maxWidth: "197px",
    },
  };
});

const LandingDesctiption = styled.p(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    fontFamily: fonts.regularCf,
    fontSize: spacing(2),
    maxWidth: "524px",
    padding: 0,
    margin: 0,
    paddingBottom: spacing(4.5),
    width: "100%",
    lineHeight: "168.5%",
    color: "white",
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(2.25),
      maxWidth: "293px",
      paddingBottom: spacing(3.75),
    },

    [`@media (max-width:${470 - 1}px)`]: {
      fontSize: spacing(1.25),
      paddingBottom: spacing(1.75),
      maxWidth: "155px",
    },
  };
});

const LandingFirst = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    zIndex: 3,
    paddingLeft: spacing(13.125),
    paddingBottom: spacing(9),
    width: "100%",
    maxWidth: "618px",
    [`@media (max-width:${1024 - 1}px)`]: {
      paddingLeft: spacing(6),
      paddingBottom: spacing(6),
      maxWidth: "42vw",
      minWidth: "318px",
      width: "100%",
    },

    [`@media (max-width:${470 - 1}px)`]: {
      paddingLeft: spacing(3.25),
      paddingBottom: spacing(4.25),
    },
  };
});

const LandingSecond = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",

    paddingBottom: spacing(5.75),
    paddingRight: spacing(8),
    gap: spacing(1),
    [`@media (max-width:${1024 - 1}px)`]: {
      display: "none",
    },

    [`@media (max-width:${470 - 1}px)`]: {
      display: "none",
    },
  };
});

const LandingButtonContainer = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    gap: spacing(2.25),
    paddingBottom: spacing(5.75),
    paddingLeft: spacing(13.125),
    marginTop: spacing(8),
    [`@media (max-width:${1024 - 1}px)`]: {
      display: "none",
      marginTop: 0,
    },

    [`@media (max-width:${470 - 1}px)`]: {
      display: "none",
    },
  };
});

const BgVideo = styled.video(({ theme, $menuHeight }) => {
  return {
    position: "absolute",
    objectFit: "cover",
    width: "100%",
    height: "100%",
    top: 0,
    zIndex: 2, pointerEvents: "none",
    [`@media (max-width:${745 - 1}px)`]: {},
    [`@media (max-width:${470 - 1}px)`]: {},
    [`@media (min-width:${1440}px)`]: {},
  };
});

const ProblemContainer = styled.div(({ theme, $bgImage }) => {
  const { spacing } = theme;
  return {
    padding: spacing(5.75),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    backgroundImage: `url(${$bgImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    [`@media (max-width:${1024 - 1}px)`]: {
      paddingBlock: spacing(6),
      paddingInline: spacing(4),
    },

    [`@media (max-width:${470 - 1}px)`]: { padding: spacing(3.25) },
  };
});

const ProblemTitle = styled.p(({ theme, $bgImage }) => {
  const { spacing } = theme;
  return {
    padding: 0,
    margin: 0,
    color: "white",
    fontFamily: fonts.heavy,
    fontSize: spacing(6.5),
    textAlign: "center",
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(7.5),
    },

    [`@media (max-width:${470 - 1}px)`]: { fontSize: spacing(4) },
  };
});

const ProblemSubTitle = styled.p(({ theme, $bgImage }) => {
  const { spacing } = theme;
  return {
    padding: 0,
    margin: 0,
    color: "white",
    fontFamily: fonts.regularCf,
    fontSize: spacing(2.75),
    paddingBottom: spacing(2),
    textAlign: "center",
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(3.125),
    },

    [`@media (max-width:${470 - 1}px)`]: { fontSize: spacing(2) },
  };
});

const ProblemBulletsContainer = styled.div(({ theme, $bgImage }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    gap: `clamp(${spacing(1.75)}px, 3vw, ${spacing(5.75)}px)`,
    alignItems: "center",
    justifyContent: "center",
    [`@media (max-width:${1024 - 1}px)`]: {
      maxWidth: "546.183px",
      flexWrap: "wrap",
      gap: `clamp(${spacing(1.75)}px, 6vw, ${spacing(5.75)}px)`,
    },

    [`@media (max-width:${470 - 1}px)`]: {
      gap: spacing(3.5),
      width: "100%",
    },
  };
});

const ProblemBullet = styled.div(({ theme, $bgImage }) => {
  const { spacing } = theme;
  return {
    borderRadius: "26.833px",
    border: "1.167px solid #FFF",
    background: "rgba(217, 217, 217, 0.05)",
    backdropFilter: "blur(10px)",
    minWidth: "210px",
    minHeight: "210px",
    paddingInline: spacing(1.5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [`@media (max-width:${1024 - 1}px)`]: {
      minWidth: "210px",
      minHeight: "210px",
    },

    [`@media (max-width:${470 - 1}px)`]: {
      minWidth: "auto",
      minHeight: "auto",
      width: "clamp(100px, 32vw, 129px)",
      height: "clamp(100px, 32vw, 129px)",
    },
  };
});

const ProblemBulletTitle = styled.p(({ theme, $bgImage }) => {
  const { spacing } = theme;
  return {
    padding: 0,
    margin: 0,
    color: "white",
    fontFamily: fonts.heavy,
    fontSize: spacing(3.75),
    textAlign: "center",
    [`@media (max-width:${1024 - 1}px)`]: { fontSize: spacing(4.5) },

    [`@media (max-width:${470 - 1}px)`]: {
      fontSize: `clamp(${spacing(1.75)}px, 5vw, ${spacing(2.375)}px)`,
    },
  };
});

const ProblemBulletText = styled.p(({ theme, $bgImage }) => {
  const { spacing } = theme;
  return {
    padding: 0,
    margin: 0,
    color: "white",
    fontFamily: fonts.regularCf,
    fontSize: spacing(1.25),
    textAlign: "center",
    maxWidth: "170px",
    paddingTop: spacing(1.75),
    [`@media (max-width:${1024 - 1}px)`]: { fontSize: spacing(1.3) },

    [`@media (max-width:${470 - 1}px)`]: {
      fontSize: spacing(0.75),
      lineHeight: "149%",
      maxWidth: "105px",
      paddingTop: spacing(1.25),
    },
  };
});

const BuyContainer = styled.div(({ theme, $menuHeight }) => {
  return {
    maxHeight: "333px",
    height: "fit-content",
    width: "100%",
    display: "flex",
    backgroundColor: "#1A2024",

    [`@media (max-width:${1024 - 1}px)`]: {
      flexDirection: "column",
      maxHeight: "100%",
    },
    [`@media (max-width:${470 - 1}px)`]: {},
  };
});

const BuyVideo = styled.video(({ theme, $menuHeight }) => {
  return {
    maxWidth: " 769px",
    width: "53%",
    height: "100%",
    objectFit: "cover",
    pointerEvents: "none",
    [`@media (max-width:${1024 - 1}px)`]: {
      width: "100%",
      maxWidth: "100%",
      maxHeight: "351.945px",
      height: "100%",
    },
    [`@media (max-width:${470 - 1}px)`]: { maxHeight: "185.907px" },
  };
});

const BuyContent = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    paddingBlock: spacing(7.75),
    paddingInline: spacing(5),
    flexDirection: "column",
    gap: spacing(2),
    [`@media (max-width:${1024 - 1}px)`]: {
      paddingBlock: spacing(6.5),
      paddingInline: spacing(6),
      gap: spacing(2),
    },
    [`@media (max-width:${470 - 1}px)`]: {
      padding: spacing(3.25),
      gap: spacing(1),
    },
  };
});

const BuyTitle = styled.p(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    color: "white",
    margin: 0,
    padding: 0,
    fontFamily: fonts.boldCf,
    fontSize: spacing(5),
    lineHeight: "100%",
    [`@media (max-width:${1024 - 1}px)`]: {
      maxWidth: "483.155px",
    },
    [`@media (max-width:${470 - 1}px)`]: {
      maxWidth: "255.215px",
      fontSize: spacing(2.5),
    },
  };
});

const BuyContext = styled.p(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    color: "white",
    margin: 0,
    padding: 0,
    fontFamily: fonts.regularCf,
    fontSize: spacing(2.5),
    [`@media (max-width:${1024 - 1}px)`]: { fontSize: spacing(2.375) },
    [`@media (max-width:${470 - 1}px)`]: { fontSize: spacing(1.25) },
  };
});

const BuyButtonsContainer = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    display: "flex",

    flexDirection: "row",
    gap: spacing(1),
    [`@media (max-width:${1024 - 1}px)`]: {},
    [`@media (max-width:${470 - 1}px)`]: {},
  };
});

const BuyButtonConteined = styled(ButtonContained)(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      maxWidth: "139.7px",
      fontSize: spacing(2),
      [`@media (max-width:${1024 - 1}px)`]: {},
      [`@media (max-width:${470 - 1}px)`]: {
        maxWidth: "73.794px",
        fontSize: spacing(1),
        padding: spacing(0.6),
        paddingInline: 0,
      },
    },
  };
});

const BuyButtonsOutlined = styled(ButtonOutlined)(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      maxWidth: "139.7px",
      fontSize: spacing(2),
      [`@media (max-width:${1024 - 1}px)`]: {},
      [`@media (max-width:${470 - 1}px)`]: {
        maxWidth: "73.794px",
        fontSize: spacing(1),
        padding: spacing(0.6),
        paddingInline: 0,
      },
    },
  };
});

const CoinImg = styled.img(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    position: "relative",
    minWidth: "50%",
    width: "100%",
    height: "auto",
    bottom: 0,
    maxWidth: "724px",
    zIndex: 3,
    [`@media (max-width:${1024 - 1}px)`]: {
      maxWidth: "564px",
      width: "76vw",
      minWidth: "unset",
      bottom: 0,
      right: "-48vw",
      transform: "translate(-50%, 0)",
    },

    [`@media (max-width:${470 - 1}px)`]: {
      right: "-7%",
      maxWidth: "297px",

      width: "76vw",
    },
  };
});

const YellowBall = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    position: "absolute",
    zIndex: -1,

    top: 0,
    right: 0,
    transform: "translate(90%, -50%)",
    borderRadius: "996px",
    width: "798.961px",
    height: "996px",
    filter: "blur(225px)",
    background: "#E7FF00E6",

    [`@media (max-width:${1024 - 1}px)`]: {
      borderRadius: "514.6px",
      width: "412.797px",
      height: "514.6px",
      filter: "blur(116.25px)",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      transform: "translate(50%, -50%)",
      borderRadius: "310px",
      width: "310px",
      height: "310px",
      filter: "blur(100px)",
    },
  };
});

const YellowBallTwo = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    position: "absolute",
    transform: "translate(-40%, 50%)",
    bottom: 0,
    left: 0,
    borderRadius: "631px",
    width: "631px",
    height: "601px",
    background: "#E7FF00E6",
    filter: "blur(300px)",
    zIndex: -1,

    [`@media (max-width:${1024 - 1}px)`]: {
      borderRadius: "310.517px",
      width: "326.017px",
      height: "310.517px",
      filter: "blur(155px)",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      transform: "translate(-105%, 40%)",
      borderRadius: "540.567px",
      width: "640.189px",
      height: "540.567px",
      filter: "blur(300px)",
    },
  };
});
const NavButton = styled(IconButton)(({ theme }) => {
  const { spacing, palette } = theme;
  return {
    "&&.MuiIconButton-root": {
      padding: 0,
      borderRadius: 0,
      maxWidth: spacing(3.25),
      maxHeight: spacing(3.25),
      color: "#1A2024",
      [`@media (max-width:${785 - 1}px)`]: {
        maxWidth: spacing(2.5),
        maxHeight: spacing(2.5),
      },
      [`@media (max-width:${581 - 1}px)`]: {
        maxWidth: spacing(1.25),
        maxHeight: spacing(1.25),
      },
      "&&.MuiIconButton-label": {
        maxWidth: spacing(1.75),
        maxHeight: spacing(1.75),
        [`@media (max-width:${785 - 1}px)`]: {
          maxWidth: spacing(1.5),
          maxHeight: spacing(1.5),
        },
      },
    },
  };
});
const useStyles = makeStyles((theme) => ({
  svgContainer: {
    display: "flex",

    maxWidth: theme.spacing(3.25),
    maxHeight: theme.spacing(3.25),
    "@media (max-width: 581px)": {
      maxWidth: theme.spacing(1.5),
      maxHeight: theme.spacing(1.5),
    },
  },
}));

const DashContainer = styled.div(({ theme, $image }) => {
  const { spacing } = theme;
  return {
    position: "relative",
    width: "100%",

    backgroundColor: "black",
    zIndex: 1,
    backgroundImage: `url(${$image})`,
    paddingBlock: spacing(9.25),
    paddingInline: spacing(12.5),

    [`@media (max-width:${1024 - 1}px)`]: {
      padding: spacing(6),
    },
    [`@media (max-width:${581 - 1}px)`]: { padding: spacing(2.5) },
  };
});

const WhiteBox = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    borderRadius: "43.919px",
    background: "#F2F2F2",
    backdropFilter: "blur(18.822629928588867px)",

    width: "100%",
    zIndex: 3,
    padding: spacing(10),

    [`@media (max-width:${1024 - 1}px)`]: {
      paddingInline: `clamp(48px, 8vw, 90px)`,
    },
    [`@media (max-width:${581 - 1}px)`]: {
      paddingInline: `clamp(24px, 11vw, 468px)`,
      paddingBlock: `clamp(18px, 8vw, 32px)`,
    },
  };
});
const TitleBox = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    paddingBottom: spacing(5),
    [`@media (max-width:${1024 - 1}px)`]: {
      flexDirection: "column",
      alignItems: "flex-start",
      paddingBottom: spacing(2),
    },
    [`@media (max-width:${581 - 1}px)`]: {},
  };
});
const IconsBox = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "row",
    gap: spacing(2),

    [`@media (max-width:${1024 - 1}px)`]: { paddingTop: spacing(1) },
    [`@media (max-width:${581 - 1}px)`]: { flexWrap: "wrap", gap: spacing(1) },
  };
});

const TitleDash = styled.p(({ theme }) => {
  const { spacing } = theme;
  return {
    margin: 0,
    fontFamily: fonts.heavy,
    fontSize: spacing(4.375),
    lineHeight: "140.791%",
    [`@media (max-width:${1024 - 1}px)`]: {},
    [`@media (max-width:${581 - 1}px)`]: { fontSize: spacing(2) },
  };
});

const NftContainer = styled.div(({ theme, $image }) => {
  const { spacing } = theme;
  return {
    backgroundImage: `url(${$image})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: "27.607px",
    display: "flex",
    justifyContent: "flex-end",

    [`@media (max-width:${1024 - 1}px)`]: {
      alignItems: "flex-end",

      minHeight: "231px",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      borderRadius: "14px",
      minHeight: "122px",
    },
  };
});

const NftTextBox = styled.div(({ theme, $image }) => {
  const { spacing } = theme;
  return {
    width: "40%",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    flexDirection: "column",
    background: " rgba(255, 255, 255, 0.68)",
    backdropFilter: "blur(5.646789073944092px)",
    padding: spacing(6),
    borderTopRightRadius: "27.607px",
    borderBottomRightRadius: "27.607px",
    [`@media (max-width:${1024 - 1}px)`]: {
      width: "100%",
      height: "40%",
      flexDirection: "row",
      paddingInline: spacing(4.75),
      paddingBlock: spacing(2.25),
      alignItems: "center",
      borderBottomLeftRadius: "27.607px",
      borderTopRightRadius: "0px",
      borderBottomRightRadius: "27.607px",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      paddingInline: spacing(2.625),
      paddingBlock: spacing(1.625),
      borderTopRightRadius: "0px",
      borderBottomRightRadius: "14px",
      borderBottomLeftRadius: "14px",
    },
    [`@media (max-width:${280 - 1}px)`]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  };
});
const NftTitle = styled.p(({ theme }) => {
  const { spacing } = theme;
  return {
    ...FontStyles.regular18,
    fontSize: spacing(4),
    margin: 0,
    lineHeight: "normal",
    [`@media (max-width:${1024 - 1}px)`]: {
      maxWidth: "256px",
      fontSize: spacing(2.5),
    },
    [`@media (max-width:${581 - 1}px)`]: { fontSize: spacing(1.375) },
  };
});

const OpenSeaButton = styled(ButtonContained)(({ theme }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      paddingBlock: spacing(1.1),
      paddingInline: spacing(20 / 8),
      fontSize: spacing(2),
      lineHeight: "normal",
      [`@media (max-width:${1024 - 1}px)`]: { fontSize: spacing(1.75) },
      [`@media (max-width:${581 - 1}px)`]: { fontSize: spacing(0.9) },
    },
  };
});

const ValueButtons = styled(Button)(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      "& .iconInBox": {
        color: "black",
        fill: "black",
      },
      textAlign: "unset",
      textTransform: "none",
      flexDirection: "column",
      padding: 0,
      margin: 0,
      width: "100%",
      height: "100%",
      flex: 1,
      minHeight: "146px",
      display: "flex",

      justifyContent: "center",
      paddingInline: spacing(3.125),
      borderRadius: "27.55px",
      border: "1.252px solid rgba(231, 255, 0, 0.51)",
      background:
        "linear-gradient(232deg, rgba(231, 255, 0, 0.14) 3.16%, rgba(231, 255, 0, 0.00) 40.87%), #050500",
      boxShadow: "10.018px 15.027px 53.84675px 0px rgba(0, 0, 0, 0.15)",
      ".MuiButton-label": {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        gap: spacing(1),
        lineHeight: "normal",
      },
      [`@media (max-width:${1281 - 1}px)`]: { minHeight: "205px" },
      [`@media (max-width:${581 - 1}px)`]: {
        minHeight: "108px",
        paddingInline: spacing(3),
      },
    },
    "&&.MuiButton-root:hover": {
      backgroundColor: "#E7FF00",
      color: "black !important",
      "& .iconBox": {
        // Estilos para .iconBox quando o botão está em hover
        backgroundColor: "black", // Substitua pela cor desejada
      },

      "& .textBox": {
        color: "black",
      },
      "& .iconInBox": {
        color: "#E7FF00",
        fill: "#E7FF00",
      },
    },
  };
});

const YellowValueButtons = styled(Button)(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      textAlign: "unset",
      textTransform: "none",
      padding: 0,
      margin: 0,
      cursor: "pointer",
      width: "100%",
      height: "100%",
      flex: 1,
      minHeight: "146px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      paddingInline: spacing(3),
      borderRadius: "27.55px",
      border: "1.252px solid rgba(231, 255, 0, 0.51)",
      background:
        "linear-gradient(232deg, #BEF264 3.16%, rgba(231, 255, 0, 0.00) 40.87%), #E7FF00",
      boxShadow: "10.018px 15.027px 53.84675px 0px rgba(0, 0, 0, 0.15)",
      ".MuiButton-label": {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        gap: spacing(1),
        lineHeight: "normal",
      },
      [`@media (max-width:${1281 - 1}px)`]: { minHeight: "205px" },
      [`@media (max-width:${581 - 1}px)`]: {
        minHeight: "108px",
        paddingInline: spacing(3),
      },
    },
  };
});
const YellowValueButtonIconBox = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    maxWidth: "77px",
    maxHeight: "77px",
    height: "100%",
    width: "100%",
    backgroundColor: "#1A2024",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: spacing(1.25),
    borderRadius: "22px",
    [`@media (max-width:${1281 - 1}px)`]: {
      maxWidth: "108px",
      maxHeight: "108px",
      padding: spacing(1.5),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      maxWidth: "57px",
      maxHeight: "57px",
      padding: spacing(1.25),
      borderRadius: "16px",
    },
  };
});
const ValueButtonIconBox = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    maxWidth: "30px",
    maxHeight: "30px",

    height: "100%",
    width: "100%",
    backgroundColor: "#E7FF00",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    borderRadius: "5px",
    padding: spacing(0.75),

    [`@media (max-width:${1281 - 1}px)`]: {
      maxWidth: "58px",
      maxHeight: "58px",
      padding: spacing(0.75),
      borderRadius: "10px",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      maxWidth: "31px",
      maxHeight: "31px",
      padding: spacing(0.75),
      borderRadius: "6px",
    },
  };
});
const YellowValueButtonText = styled.p(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    width: "fit-content",
    margin: 0,
    fontSize: spacing(4),
    lineHeight: "107.52%",
    color: "#1A2024",
    fontFamily: fonts.boldCf,
    fontWeight: "900",
    [`@media (max-width:${1281 - 1}px)`]: {
      fontSize: spacing(5.625),
      lineHeight: "100%",
      maxWidth: "230.179px",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: "clamp(18px, 7vw, 24px)",
      maxWidth: "116px",
    },
  };
});
const ValueButtonText = styled.p(({ theme, $bigger }) => {
  const { spacing } = theme;
  return {
    width: "fit-content",
    margin: 0,
    fontSize: $bigger ? spacing(2.2) : spacing(1.75),
    lineHeight: "107.52%",
    color: "white",
    fontFamily: fonts.heavy,
    [`@media (max-width:${1281 - 1}px)`]: {
      fontSize: $bigger ? spacing(3.5) : spacing(3),
      lineHeight: "normal",
      maxWidth: "230.179px",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: $bigger ? spacing(1.75) : spacing(1.5),
      maxWidth: "121px",
    },
  };
});

const LineText = styled.p(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    width: "100%",

    fontSize: spacing(1.625),
    lineHeight: "100%",
    color: "white",
    fontFamily: fonts.heavy,
    margin: 0,
    padding: 0,
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: spacing(1.1),
    },
  };
});

const LineSecondaryText = styled.p(({ theme, small }) => {
  const { spacing } = theme;
  return {
    width: "100%",

    fontSize: small ? spacing(2.5) : spacing(3.75),
    lineHeight: "100%",
    color: "white",
    fontFamily: fonts.heavy,
    margin: 0,
    padding: 0,
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: small ? spacing(1.1) : spacing(1.75),
    },
  };
});

const LineCoinText = styled.p(({ theme, small }) => {
  const { spacing } = theme;
  return {
    width: "100%",
    fontSize: spacing(4.5),
    lineHeight: "100%",
    color: "white",
    fontFamily: fonts.heavy,
    margin: 0,
    padding: 0,
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: spacing(2.25),
    },
  };
});
const LineSecondaryTextSpan = styled.span(({ theme, small }) => {
  const { spacing } = theme;
  return {
    fontSize: spacing(3.75),

    margin: 0,
    padding: 0,
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: spacing(2.25),
    },
  };
});
const ValueButtonLabel = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    width: "100%",
    height: "fit-content",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: spacing(1),

    [`@media (max-width:${1281 - 1}px)`]: { gap: spacing(2) },
    [`@media (max-width:${581 - 1}px)`]: { gap: spacing(1) },
  };
});

const DaysButtons = styled(ButtonOutlined)(({ theme, filled }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      margin: 0,
      fontSize: spacing(2.25),
      fontFamily: filled ? fonts.heavy : fonts.regular,
      fontWeight: filled ? "900" : "400",
      backgroundColor: filled && "#E7FF00",
      color: filled && "black",
      [`@media (max-width:${1281 - 1}px)`]: {
        display: filled ? "flex" : "none",
      },
      [`@media (max-width:${581 - 1}px)`]: {
        fontSize: spacing(1),
        padding: spacing(0.75),
        paddingInline: spacing(1.1),
        minWidth: 0,
      },
    },
  };
});

const GraphText = styled.p(({ theme, filled }) => {
  const { spacing } = theme;
  return {
    margin: 0,
    color: "white",
    ...FontStyles.bold18,
    fontSize: spacing(2.5),
    [`@media (max-width:${1281 - 1}px)`]: {},
    [`@media (max-width:${581 - 1}px)`]: { fontSize: spacing(1.1) },
  };
});

const GraphContainer = styled.div(({ theme, filled }) => {
  const { spacing } = theme;
  return {
    width: "100%",
    minHeight: "326px",
    height: "100%",

    [`@media (max-width:${1281 - 1}px)`]: { minHeight: "435.167px" },
    [`@media (max-width:${581 - 1}px)`]: { minHeight: "230px" },
  };
});
const GraphTextHold = styled.p(({ theme, filled }) => {
  const { spacing } = theme;
  return {
    margin: 0,
    color: "white",
    fontFamily: fonts.heavy,
    fontSize: spacing(3.125),
    [`@media (max-width:${1281 - 1}px)`]: { fontSize: spacing(2.5) },
    [`@media (max-width:${581 - 1}px)`]: { fontSize: spacing(1.5) },
  };
});

const PaddingBox = styled.div(({ theme, first, last }) => {
  const { spacing } = theme;
  return {
    paddingRight: !last && spacing(2),

    [`@media (max-width:${1281 - 1}px)`]: {
      paddingInline: 0,
      paddingBottom: spacing(4),
      paddingLeft: first && 0,
    },
    [`@media (max-width:${581 - 1}px)`]: { paddingBottom: spacing(2) },
  };
});

const WarningTitle = styled.p(({ theme, filled }) => {
  const { spacing } = theme;
  return {
    fontSize: spacing(3.125),
    fontFamily: fonts.heavy,
    textAlign: "center",
    color: "white",
    margin: 0,
    [`@media (max-width:${1024 - 1}px)`]: { fontSize: spacing(3.5) },
    [`@media (max-width:${581 - 1}px)`]: { fontSize: spacing(2.25) },
  };
});

const WarningSubTitle = styled.p(({ theme, filled }) => {
  const { spacing } = theme;
  return {
    fontSize: spacing(1.25),
    fontFamily: fonts.regularCf,
    color: "#FFF",
    textAlign: "center",

    lineHeight: "198%",
    [`@media (max-width:${1024 - 1}px)`]: { fontSize: spacing(1.75) },
    [`@media (max-width:${581 - 1}px)`]: { fontSize: spacing(1.5) },
  };
});

const WarningIcon = styled(IoWarningOutline)(({ theme, filled }) => {
  const { spacing } = theme;
  return {
    fill: "#E7FF00",
    stroke: "#E7FF00",
    color: "#E7FF00",
    maxWidth: "109.287px",
    maxHeight: "109.287px",
    width: "100%",
    height: "auto",
    [`@media (max-width:${1024 - 1}px)`]: {
      maxWidth: "129.287px",
      maxHeight: "129.287px",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      maxWidth: "77px",
      maxHeight: "77px",
    },
  };
});

const WarningModal = styled.div(({ theme, filled }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: spacing(2.625),
    [`@media (max-width:${1024 - 1}px)`]: { gap: spacing(2.625) },
    [`@media (max-width:${581 - 1}px)`]: {
      gap: spacing(1.875),
      maxWidth: "50vw",
      alignSelf: "center",
    },
  };
});

const GridPadding = styled(Grid)(({ theme, filled }) => {
  const { spacing } = theme;
  return {
    marginBlock: spacing(4.25),
    [`@media (max-width:${1024 - 1}px)`]: {
      marginTop: spacing(3.5),
      marginBottom: "0px",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      marginTop: spacing(2),
    },
  };
});

const Styles = {
  Container,
  BgVideoContainer,
  BgVideo,
  LandingFirst,
  LandingText,
  LandingDesctiption,
  LeftButtons,
  RegistryButton,
  WhitelistButton,

  CoinImg,
  YellowBallTwo,
  YellowBall,
  NavButton,
  useStyles,

  DashContainer,
  WhiteBox,
  NftContainer,
  TitleBox,
  TitleDash,
  IconsBox,
  NftTextBox,
  NftTitle,

  OpenSeaButton,
  ValueButtons,
  ValueButtonLabel,
  YellowValueButtons,
  ValueButtonIconBox,
  ValueButtonText,
  YellowValueButtonText,
  YellowValueButtonIconBox,
  LineText,
  DaysButtons,
  GraphText,
  GraphContainer,
  GraphTextHold,
  PaddingBox,

  WarningTitle,
  WarningSubTitle,
  WarningIcon,
  WarningModal,
  LineSecondaryText,
  LineSecondaryTextSpan,
  LineCoinText,
  GridPadding,
};

export default Styles;
