import { url } from "inspector";
import styled from "styled-components";
const BgLogo = process.env.REACT_APP_ASSETS_URL + "/imgs/homeForest.webp";
const GrainImage =
  process.env.REACT_APP_ASSETS_URL + "/imgs/grainBackground.webp";
const LeafBackground =
  process.env.REACT_APP_ASSETS_URL + "/imgs/leafBackgorund.webp";

import {
  ButtonContained,
  ButtonOutlined,
  FontStyles,
} from "../../../components";
import { fonts, SVG } from "../../../config";
import { Button } from "@material-ui/core";
import { SlLocationPin } from "react-icons/sl";
const Container = styled.div(({ theme, $menuHeight }) => {
  return {
    overflow: "hidden",
    zIndex: 1,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    backgroundColor: "#ededed",

    borderRadius: "5px",
    flex: 1,
    width: "100%",

    height: theme.spacing(69.75),

    [`@media (max-width:${745 - 1}px)`]: {
      height: theme.spacing(42.75),
    },
    [`@media (max-width:${470 - 1}px)`]: {
      width: "100%",
      height: theme.spacing(28.875),
    },
    [`@media (min-width:${1440}px)`]: {
      height: theme.spacing(69.75),
    },
  };
});

const BgVideoContainer = styled.div(({ theme, $menuHeight }) => {
  return {
    overflow: "hidden",
    position: "relative",
    width: "100%",
    zIndex: 0,
    display: "flex",
    justifyContent: "center",
    height: theme.spacing(69.75),

    [`@media (max-width:${745 - 1}px)`]: {
      height: theme.spacing(60.9),
    },
    [`@media (max-width:${470 - 1}px)`]: {
      width: "100%",
      height: theme.spacing(28.875),
    },
    [`@media (min-width:${1440}px)`]: {
      height: theme.spacing(69.75),
    },
  };
});

const HomeButtons = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    borderRadius: "13.493px",
    border: "0.587px solid #FFF",
    background: "rgba(217, 217, 217, 0.05)",
    backdropFilter: "blur(6.5px)",
    ...FontStyles.bold12,
    fontFamily: fonts.heavy,
    color: "white",
    minWidth: spacing(13.125),
    minHeight: spacing(13.125),
    flex: 1,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    gap: spacing(5.25),
    textTransform: "capitalize",
    lineHeight: "normal",
    padding: "9px",
  };
});

const RegistryButton = styled(ButtonContained)(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      ...FontStyles.bold16,
      fontFamily: fonts.heavy,
      [`@media (max-width:${1024 - 1}px)`]: {
        fontSize: spacing(1.75),
      },
      [`@media (max-width:${470 - 1}px)`]: {
        fontSize: spacing(0.9),
        padding: spacing(0.45),
      },
    },
  };
});
const WhitelistButton = styled(ButtonOutlined)(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      ...FontStyles.bold16,
      fontFamily: fonts.heavy,
      color: "black",
      borderColor: "black",
      [`@media (max-width:${1024 - 1}px)`]: {
        display: "none",
      },
      [`@media (max-width:${470 - 1}px)`]: {
        fontSize: spacing(1),
      },
    },
  };
});

const WhitelistSecondButton = styled(ButtonOutlined)(
  ({ theme, $menuHeight }) => {
    const { spacing } = theme;
    return {
      "&&.MuiButton-root": {
        ...FontStyles.bold16,
        fontSize: spacing(1.75),
        color: "black",
        borderColor: "black",
        display: "none",
        fontFamily: fonts.heavy,
        [`@media (max-width:${1024 - 1}px)`]: {
          display: "flex",
        },
        [`@media (max-width:${470 - 1}px)`]: {
          fontSize: spacing(0.9),
          padding: spacing(0.45),
        },
      },
    };
  }
);

const LandingText = styled.p(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    lineHeight: "127.5%",
    ...FontStyles.medium14,
    fontSize: spacing(4.125),
    maxWidth: "402px",
    padding: 0,
    margin: 0,
    marginBottom: spacing(2),
    [`@media (max-width:${1024 - 1}px)`]: {
      marginBottom: spacing(1.5),
      fontSize: spacing(2.25),
      maxWidth: "224px",
    },

    [`@media (max-width:${470 - 1}px)`]: {
      fontSize: spacing(1.25),
      maxWidth: "97px",
    },
  };
});

const LandingFirst = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",

    paddingTop: spacing(20.75),
    paddingLeft: spacing(13.125),

    [`@media (max-width:${1024 - 1}px)`]: {
      paddingTop: spacing(28),
      paddingLeft: spacing(6.125),
    },

    [`@media (max-width:${470 - 1}px)`]: {
      paddingTop: spacing(6),
      paddingLeft: spacing(2.75),
    },
  };
});

const LandingSecond = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",

    paddingTop: spacing(24.75),
    paddingRight: spacing(13.125),
    gap: spacing(1),
    [`@media (max-width:${1024 - 1}px)`]: {
      paddingTop: spacing(31.5),
      paddingRight: spacing(6.125),
    },

    [`@media (max-width:${470 - 1}px)`]: {
      paddingTop: spacing(12.75),
      gap: 0,
      paddingRight: spacing(2.5),
    },
  };
});

const LandingButtonContainer = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    gap: spacing(2.25),
    paddingBottom: spacing(5.75),
    paddingLeft: spacing(13.125),

    [`@media (max-width:${1024 - 1}px)`]: {
      display: "none",
    },

    [`@media (max-width:${470 - 1}px)`]: {
      display: "none",
    },
  };
});

const BgVideo = styled.video(({ theme, $menuHeight }) => {
  return {
    border: "1px #ededed solid",
    objectFit: "cover",
    width: "auto",
    height: theme.spacing(69.75),
    position: "relative",
    top: `calc(-${$menuHeight}px + 40px)`, pointerEvents: "none",

    [`@media (max-width:${745 - 1}px)`]: {
      top: -$menuHeight + 14 + "px",
      height: theme.spacing(85),
    },
    [`@media (max-width:${470 - 1}px)`]: {
      top: -$menuHeight + 74 + "px",
      width: "100%",
      height: theme.spacing(28.875),
    },
    [`@media (min-width:${1440}px)`]: {
      height: theme.spacing(69.75),
    },
  };
});

const BgForest = styled.div(({ theme, bgImage }) => {
  return {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",

    overflow: "hidden",
    zIndex: 1,
    backgroundImage: `url(${BgLogo})`,
    backgroundSize: "1440px auto",
    backgroundPosition: "top",
    height: theme.spacing(48.90375),
    backgroundRepeat: "no-repeat",

    [`@media (max-width:${745 - 1}px)`]: {
      backgroundSize: "744px auto",
      height: theme.spacing(25.515),
    },
    [`@media (max-width:${470 - 1}px)`]: {
      backgroundSize: "625px auto",
      height: theme.spacing(22.25),
    },
    [`@media (min-width:${1440}px)`]: {
      backgroundSize: "2160px auto",
      height: theme.spacing(61.375),
    },
    [`@media (min-width:${2160}px)`]: {
      backgroundSize: "3240px auto",
      height: theme.spacing(79.785),
    },
  };
});

const BgForestImg = styled.img(({ theme, $menuHeight }) => {
  return {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "top",
    [`@media (max-width:${745 - 1}px)`]: {
      height: theme.spacing(26.15625),
    },
  };
});

const ForestContainer = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    paddingInline: spacing(13.5),
    paddingBlock: spacing(3.5),
    width: "100%",
    flexDirection: "column",
    gap: "26px",
    [`@media (max-width:${1024 - 1}px)`]: { padding: spacing(5.5) },

    [`@media (max-width:${470 - 1}px)`]: {
      paddingInline: "clamp(12px, 6vw, 26px)",
      paddingBlock: spacing(3.25),
      gap: "18px",
    },
  };
});
const ForestList = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",

    width: "100%",
    flexDirection: "column",
    gap: "26px",

    [`@media (max-width:${470 - 1}px)`]: { gap: "18px" },
  };
});
const ForestCard = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    borderRadius: "24px",
    background: "rgba(217, 217, 217, 0.37)",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "30px",
    padding: "32px",
    [`@media (max-width:${1024 - 1}px)`]: {
      flexDirection: "column",
      gap: "42px",
      borderRadius: "42px",
      padding: "48px",
    },

    [`@media (max-width:${470 - 1}px)`]: {
      gap: "20px",
      borderRadius: "24px",
      padding: "26px",
    },
  };
});
const ForestCardImg = styled.div(({ theme, $src }) => {
  const { spacing } = theme;
  return {
    background: "rgba(217, 217, 217, 0.37)",
    backgroundImage: `url(${$src})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    maxWidth: "181px",
    maxHeight: "181px",
    width: "100%",
    height: "100%",
    backgroundColor: "gray",
    borderRadius: "20px",
    cursor: "pointer",
    [`@media (max-width:${1024 - 1}px)`]: {
      minWidth: "100%",
      minHeight: "342px",
      borderRadius: "37px",
    },

    [`@media (max-width:${470 - 1}px)`]: {
      minWidth: "100%",
      minHeight: "181px",
      borderRadius: "20px",
    },
  };
});
const ForestCardMap = styled.div(({ theme, $src }) => {
  const { spacing } = theme;
  return {
    width: "100%",
    height: "100%",
    minHeight: "181px",
    maxHeight: "181px",
    backgroundColor: "gray",
    borderRadius: "20px",
    maxWidth: "268px",
    [`@media (max-width:${1024 - 1}px)`]: {
      minWidth: "100%",
      minHeight: "160px",
      maxHeight: "160px",
      borderRadius: "37px",
    },

    [`@media (max-width:${470 - 1}px)`]: {
      minWidth: "100%",
      minHeight: "85px",
      maxHeight: "85px",
      borderRadius: "20px",
    },
  };
});
const ForestIframe = styled.iframe(({ theme, $src }) => {
  const { spacing } = theme;
  return {
    width: "100%",
    height: "100%",

    borderRadius: "20px",
    [`@media (max-width:${1024 - 1}px)`]: {
      borderRadius: "37px",
    },

    [`@media (max-width:${470 - 1}px)`]: {
      borderRadius: "20px",
    },
  };
});
const ForestCardInfo = styled.div(({ theme, $src }) => {
  const { spacing } = theme;
  return {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    [`@media (max-width:${1024 - 1}px)`]: {},

    [`@media (max-width:${470 - 1}px)`]: {},
  };
});
const ForestName = styled.p(({ theme, $src }) => {
  const { spacing } = theme;
  return {
    fontFamily: fonts.heavy,
    fontSize: spacing(3.5),
    margin: 0,
    textTransform: "capitalize",
    lineHeight: "140.791%",
    cursor: "pointer",
    width: "fit-content",
    "&:hover": {
      textDecoration: "underline",
      opacity: 0.9,
    },
    [`@media (max-width:${1024 - 1}px)`]: {},

    [`@media (max-width:${470 - 1}px)`]: { fontSize: spacing(2) },
  };
});
const ForestLoc = styled.p(({ theme, $src }) => {
  const { spacing } = theme;
  return {
    fontFamily: fonts.heavy,
    fontSize: spacing(2.75),
    margin: 0,
    paddingTop: spacing(0.7),
    color: "rgba(0, 0, 0, 0.50)",
    lineHeight: "140.791%",
    [`@media (max-width:${1024 - 1}px)`]: {},

    [`@media (max-width:${470 - 1}px)`]: {
      fontSize: spacing(1.625),
      paddingTop: spacing(1),
    },
  };
});
const ForestCardArea = styled.div(({ theme, $src }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    [`@media (max-width:${1024 - 1}px)`]: { display: "none" },

    [`@media (max-width:${470 - 1}px)`]: {},
  };
});

const ForestAreaTitle = styled.p(({ theme, $src }) => {
  const { spacing } = theme;
  return {
    fontFamily: fonts.regular,
    fontSize: spacing(2.375),
    margin: 0,

    color: "#000",
    lineHeight: "140%",
  };
});
const ForestAreaValue = styled.p(({ theme, $src }) => {
  const { spacing } = theme;
  return {
    fontFamily: fonts.heavy,
    fontSize: spacing(2.375),
    margin: 0,
    paddingTop: spacing(2),
    color: "#000",
    lineHeight: "100%",
  };
});

const LocPin = styled(SlLocationPin)(({ theme, $src }) => {
  const { spacing } = theme;
  return {
    maxHeight: "15px",
    [`@media (max-width:${470 - 1}px)`]: {
      maxHeight: "9px",
    },
  };
});
const Back = styled(ButtonContained)(({ theme, $center, $image }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      marginTop: 0,
      paddingInline: spacing(4),
      paddingBlock: spacing(2.25),
      fontSize: spacing(2),
      borderRadius: spacing(2),

      [`@media (max-width:${1024 - 1}px)`]: {
        borderRadius: spacing(3),
        paddingInline: spacing(5.25),
        paddingBlock: spacing(2.5),
        fontSize: spacing(2.5),
        marginLeft: 0,
      },
      [`@media (max-width:${581 - 1}px)`]: {
        borderRadius: spacing(1.5),
        paddingInline: spacing(2.25),
        paddingBlock: spacing(1.5),
        fontSize: spacing(1.5),
      },
    },
  };
});
const More = styled(ButtonOutlined)(({ theme, $center, $image }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      marginTop: 0,

      fontSize: spacing(1.8),

      color: "black",
      borderColor: "black",
      [`@media (max-width:${1024 - 1}px)`]: {
        borderRadius: spacing(3),
        fontSize: spacing(2),
        marginLeft: 0,
      },
      [`@media (max-width:${581 - 1}px)`]: {
        borderRadius: spacing(1.5),
        fontSize: spacing(1.5),
      },
    },
  };
});
const Styles = {
  Container,
  BgVideoContainer,
  BgVideo,
  BgForest,
  BgForestImg,
  WhitelistButton,
  RegistryButton,
  LandingText,
  LandingFirst,
  LandingButtonContainer,
  LandingSecond,
  WhitelistSecondButton,

  ForestContainer,
  ForestList,
  ForestCard,
  ForestCardImg,
  ForestCardInfo,
  ForestName,
  ForestLoc,
  ForestCardArea,
  ForestAreaTitle,
  ForestAreaValue,
  ForestCardMap,
  LocPin,
  Back,
  ForestIframe,
  More,
};

export default Styles;
