import styled from "styled-components";
import { ButtonContained, FontStyles } from "../../../components";

import { fonts, Spacing } from "../../../config";
const coin = process.env.REACT_APP_ASSETS_URL + "/imgs/GOLDEN_COIN.webp";
const grainImage = process.env.REACT_APP_ASSETS_URL + "/imgs/grainBackground.webp";
const Container = styled.div(({ theme, $menuHeight, imgSrc }) => {
  const { palette: colors } = theme;

  return {
    backgroundColor: "rgba(5, 5, 0, 1)",

    width: "100%",
    position: "relative",
    height: `auto`,
    minHeight: `calc(100vh - ${$menuHeight + 16}px)`,

    overflow: "hidden",
    backgroundImage: `url(${grainImage})`,
    backgroundSize: "cover",
  };
});
const ImageBG = styled.div(({ theme, $menuHeight, imgSrc }) => {
  const { palette: colors } = theme;

  return {
    display: "flex",
    width: "100%",
    position: "relative",
    height: `auto`,
    minHeight: `calc(100vh - ${$menuHeight + 16}px)`,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    backgroundImage: `url(${coin})`,
    backgroundPositionY: "center",
    backgroundRepeat: "no-repeat",
    overflow: "hidden",
    backgroundSize: "auto 90vh",
    backgroundPositionX: "-8vw",
    [`@media (max-width:${1024 - 1}px)`]: {
      backgroundImage: "none",
    },
    [`@media (max-width:${581 - 1}px)`]: {},
  };
});

const Content = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    display: "flex",
    position: "relative",

    justifyContent: "center",
    flexDirection: "column",
    padding: spacing(6.25),
    paddingInline: "clamp(18px, 11vw, 173px)",
    overflowX: "auto",

    background: "rgba(5, 5, 0, 0.82)",
    backdropFilter: "blur(9px)",

    width: "100%",
    maxWidth: "63vw",
    height: "100%",
    flex: 1,
    [`@media (max-width:${1024 - 1}px)`]: {
      maxWidth: "100vw",
      backdropFilter: "none",
      background: "none",
    },
    [`@media (max-width:${581 - 1}px)`]: {},
  };
});

const Header = styled.div(() => {
  return {
    width: "fit-content",
    marginBottom: Spacing(5),
    display: "flex",
    alignSelf: "center",
    justifyContent: "flex-start",
  };
});
const InputTitle = styled.p(({ theme }) => {
  const { spacing } = theme;
  return {
    ...FontStyles.regular18,
    fontSize: spacing(2.75),
    margin: 0,
    padding: 0,
    color: "white",
    lineHeight: "140.791%",

    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: Spacing(1.5),
    },
  };
});
const Title = styled.p(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    color: "white",
    margin: 0,

    ...FontStyles.bold42,
    lineHeight: "140.791%",
    textAlign: "center",
    paddingBottom: spacing(5),
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: Spacing(4.75),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: Spacing(2.5),
      paddingBottom: spacing(2.25),
    },
  };
});

const Subtitle = styled.p(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    color: colors.grayText,
    ...FontStyles.medium16,
    margin: 0,
    paddingBottom: Spacing(3),
  };
});

const CTGLogo = styled.img(() => {
  return {
    width: Spacing(17),
    height: "auto",
  };
});

const ForgotPasswordButton = styled.p(({ theme, noPointer }) => {
  const { spacing, palette: colors } = theme;
  return {
    textAlign: noPointer ? "center" : "left",
    ...FontStyles.regular18,
    fontSize: spacing(2.875),
    color: "white",
    margin: 0,
    marginBottom: !noPointer && Spacing(4),
    cursor: !noPointer && "pointer",

    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: Spacing(2.5),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: Spacing(1.375),
      marginBottom: Spacing(2.25),
    },
  };
});

const Version = styled.div(() => {
  return {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
});

const ButtonContainer = styled.div(() => {
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  };
});

const P = styled.p(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    ...FontStyles.light16,
    color: colors.text.secondary,
    margin: 0,
    marginLeft: spacing(2),
    padding: 0,
  };
});
const YellowBall = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    position: "absolute",

    top: 0,
    right: 0,
    transform: "translate(90%, -50%)",
    borderRadius: "996px",
    width: "798.961px",
    height: "996px",
    filter: "blur(225px)",
    background: "#E7FF00E6",

    zIndex: 0,

    [`@media (max-width:${1024 - 1}px)`]: {
      borderRadius: "514.6px",
      width: "268.364px",
      height: "268.364px",
      filter: "blur(116.25px)",
      top: -250,
      right: 200,
    },
    [`@media (max-width:${581 - 1}px)`]: {
      borderRadius: "514.6px",
      width: "292.1024px",
      height: "247.19px",
      filter: "blur(116.25px)",
      top: -250,
      right: 200,
    },
  };
});

const YellowBallTwo = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    position: "absolute",
    transform: "translate(-40%, 50%)",
    bottom: 0,
    left: 0,
    borderRadius: "631px",
    width: "631px",
    height: "601px",
    background: "#E7FF00E6",
    filter: "blur(300px)",

    [`@media (max-width:${1024 - 1}px)`]: {
      width: "554.205px",
      height: "467.963px",
      filter: "blur(155px)",
      transform: "translate(-75%, 75%)",
      borderRadius: "554.205px",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      width: "292.1024px",
      height: "247.19px",
      filter: "blur(155px)",
      transform: "translate(-75%, 75%)",
      borderRadius: "554.205px",
    },
  };
});

const SubmitButton = styled(ButtonContained)(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    "&&.MuiButton-root": {
      fontFamily: fonts.heavy,
      fontSize: spacing(3.75),
      lineHeight: "140.791%",
      [`@media (max-width:${1024 - 1}px)`]: {
        fontSize: Spacing(2.5),
      },
      [`@media (max-width:${581 - 1}px)`]: {
        fontSize: Spacing(1.5),
      },
    },
  };
});

const Line = styled.div(() => {
  return {
    backgroundColor: "white",
    width: "100vw",
    height: "1px",
    maxWidth: "304px",
    marginBlock: Spacing(4),
    alignSelf: "center",
    [`@media (max-width:${1024 - 1}px)`]: {
      maxWidth: "286.8px",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      maxWidth: "151px",
      marginBlock: Spacing(2.25),
    },
  };
});
const Styles = {
  Content,
  Title,
  Subtitle,
  Header,
  CTGLogo,
  ImageBG,
  ForgotPasswordButton,
  Version,
  ButtonContainer,
  P,
  InputTitle,
  YellowBallTwo,
  YellowBall,
  Container,
  SubmitButton,
  Line,
};

export default Styles;
