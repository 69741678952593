import React from "react";

import Styles from "../styles/Styles";
import { customModal } from "../../../components/modals/utils";
import { pagesConfig } from "../../../config";
import { useNavigate } from "react-router-dom";
import { LocalStorage, SessionStorage } from "../../../utils";

type CustomMenuGroupProps = {
  item: any;
  index: number;
  firstItem: boolean;
  pathname: string;
  close: () => void;
};

function MenuGroupWithPagesComponent({
  item,
  index,
  firstItem,
  pathname,
  close,
}: CustomMenuGroupProps) {
  const classes = Styles.useStyles();
  const navigate = useNavigate();
  function handleLogout() {
    customModal.setInfos(
      "leave",
      ["confirm"],
      {
        label: "logout",
        onClick: () => {
          customModal.close();
          SessionStorage.removeItem("token");
          SessionStorage.removeItem("userData");
          // globalLogout();
        },
      },
      {
        label: "back",
        onClick: () => {
          customModal.close();
        },
      },
      null,
      null,
      null
    );
  }

  const goto = (path: string, title: string) => {
    let link;
    if (!isDisabled(path) === false) {
      if (title === "Sair") {
        handleLogout();
      } else {
        navigate(path);
      }
      close();
    } else {
      link = null;
    }
    return link;
  };

  function isDisabled(path: string) {
    let a = false;
    pagesConfig.logged.map((item) => {
      if (path === item.path) {
        a = true;
      }
      return item;
    });
    return a;
  }

  return (
    <Styles.Group $firstOne={firstItem}>
      <Styles.PageLine key={index + item?.name}>
        <Styles.Page
          $buttonContained={false}
          $currentPage={item.path === pathname}
          $disabled={!isDisabled(item.path)}
          key={item.name + index}
          onClick={() => goto(item.path, item.title)}
        >
          <Styles.PageContent $currentPage={item.path === pathname}>
            {/* {item.icon && item.path === pathname ? (
              <img alt="" src={item.icon} className={classes.icon} />
            ) : null} */}
            {LocalStorage.getItem("language") !== "pt"
              ? item["title" + LocalStorage.getItem("language")]
                ? item["title" + LocalStorage.getItem("language")]
                : item.title
              : item.title}
          </Styles.PageContent>
        </Styles.Page>
      </Styles.PageLine>
    </Styles.Group>
  );
}

export default MenuGroupWithPagesComponent;
