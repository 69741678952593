import { Spacing, fonts } from "../../config";
import {
  FormControl,
  Slider,
  ThemeProvider,
  createTheme,
} from "@material-ui/core";
import { useFormFull } from "form-full";

function getErrorClassname(base, error, valid) {
  const className = base;
  if (error) return `${className} invalid`;
  if (valid) return `${className} valid`;
  return className;
}

function SliderComponent(props) {
  const { value, error, onChange, ref, valid } = useFormFull.field(props);

  const {
    hideVisualError,
    name,
    label,
    required,
    disableError,
    defaultValue,
    min,
    max,
    step,
    disabled,
    valueLabelFormat,
  } = props;

  const theme = createTheme({
    overrides: {
      MuiSlider: {
        root: {
          color: "#E7FF00",
          margin: Spacing(10, 0, 2, 0),

          [`@media (max-width:${1024 - 1}px)`]: {
            margin: Spacing(5, 0, 1, 0),
          },
          [`@media (max-width:${581 - 1}px)`]: {
            margin: Spacing(2.5, 0, 1, 0),
          },
        },
        rail: {
          backgroundColor: "#FFF",
          height: "10px",
          borderRadius: "4px",
          opacity: disabled ? 0.5 : 1,
          [`@media (max-width:${1024 - 1}px)`]: {
            height: "6px",
          },
          [`@media (max-width:${581 - 1}px)`]: {
            height: "4px",
          },
        },
        track: {
          height: "10px",
          borderRadius: "4px",
          [`@media (max-width:${1024 - 1}px)`]: {
            height: "6px",
          },
          [`@media (max-width:${581 - 1}px)`]: {
            height: "4px",
          },
        },
        thumb: {
          width: Spacing(3.45),
          height: Spacing(3.45),
          marginTop: Spacing(-1),
          position: "sticky",
          [`@media (max-width:${1024 - 1}px)`]: {
            width: Spacing(1.81),
            height: Spacing(1.81),
            marginTop: Spacing(-0.5),
          },
          [`@media (max-width:${581 - 1}px)`]: {
            width: Spacing(0.95625),
            height: Spacing(0.95625),
            marginTop: Spacing(-0.25),
          },
        },
        valueLabel: {
          left: -2,
          width: "60px",
          color: "transparent",
          [`@media (max-width:${1024 - 1}px)`]: {
            left: -8,
          },
          [`@media (max-width:${581 - 1}px)`]: {
            left: -12,
          },
        },
      },
    },
  });

  return (
    <>
      <FormControl
        className={getErrorClassname("form-control", error, valid)}
        fullWidth
        error={hideVisualError ? undefined : Boolean(error)}
      >
        <ThemeProvider theme={theme}>
          <Slider
            valueLabelFormat={valueLabelFormat}
            defaultValue={defaultValue}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            step={step}
            min={min}
            max={max}
            value={value}
            name={name}
            onChange={(event, newValue) => {
              onChange(event, newValue);
            }}
            disabled={disabled}
          />
        </ThemeProvider>
      </FormControl>
    </>
  );
}

export default SliderComponent;
