import React from "react";
import { useNavigate } from "react-router-dom";
import { Input, InputPassword, StrongText } from "../../../components/index";
import Styles from "../styles";
import { FormFull } from "form-full";
import { LocalStorage, validations } from "../../../utils";

import { paths } from "../../../navigation/navigate";
import { fonts, Spacing } from "../../../config";
import Texts from "../../../config/texts";

function LoginStep({ onSubmit, loading }) {
  const texts =
    Texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].login;
  const [buttonDisabled, setButton] = React.useState(true);
  const navigate = useNavigate();
  return (
    <FormFull onSubmit={onSubmit}>
      <Styles.Content>
        <Styles.Title>{texts.title}</Styles.Title>

        <div
          style={{
            display: "flex",
            gap: Spacing(1.25),
            flexDirection: "column",
          }}
        >
          <div>
            <Styles.InputTitle>{texts.email}</Styles.InputTitle>
            <Input
              inputStyle={{
                border: "1.588px solid #FFF",
                background: "rgba(27, 30, 34, 0.70)",
                borderRadius: "53.201px",
                color: "white",
                fontFamily: fonts.regularCf,
                fontSize: Spacing(2.75),
                paddingInline: Spacing(3.75),
                paddingBlock: Spacing(2),
                marginTop: Spacing(0.75),
                "& .MuiInputBase-input": {
                  padding: 0,

                  boxShadow: "none !important",
                },

                [`@media (max-width:${1024 - 1}px)`]: {
                  paddingInline: Spacing(3.5),
                  fontSize: Spacing(2.5),
                },
                [`@media (max-width:${581 - 1}px)`]: {
                  paddingInline: Spacing(1.25),
                  paddingBlock: Spacing(1),
                  fontSize: Spacing(1.5),
                },
              }}
              name="username"
              required={texts.userMessage}
              validation={validations.isEmailValid}
            />
          </div>
          <div>
            <Styles.InputTitle>{texts.password}</Styles.InputTitle>
            <InputPassword
              name="password"
              inputStyle={{
                border: "1.588px solid #FFF",
                background: "rgba(27, 30, 34, 0.70)",
                borderRadius: "53.201px",
                color: "white",
                fontFamily: fonts.regularCf,
                fontSize: Spacing(2.75),
                paddingInline: Spacing(3.75),
                paddingBlock: Spacing(2),
                marginTop: Spacing(0.75),
                "& .MuiInputBase-input": {
                  padding: 0,

                  boxShadow: "none !important",
                },

                [`@media (max-width:${1024 - 1}px)`]: {
                  paddingInline: Spacing(3.5),
                  fontSize: Spacing(2.5),
                },
                [`@media (max-width:${581 - 1}px)`]: {
                  paddingInline: Spacing(1.25),
                  paddingBlock: Spacing(1),
                  fontSize: Spacing(1.5),
                },
              }}
              required={texts.passwordMessage}
              validation={validations.isPassword}
              onChange={() => {
                setButton(false);
              }}
            />
          </div>
        </div>

        <Styles.ButtonContainer>
          <Styles.ForgotPasswordButton
            onClick={() => navigate(paths.forgetPassword)}
          >
            {texts.forgotPass}
          </Styles.ForgotPasswordButton>
        </Styles.ButtonContainer>
        <Styles.SubmitButton
          name="submit"
          loading={loading}
          disabled={buttonDisabled}
          feedback={true}
          action="submit"
          type="submit"
          label={texts.enter}
        />
        <Styles.Line></Styles.Line>
        <Styles.ForgotPasswordButton noPointer>
          {texts.noAcount}{" "}
          <StrongText onClick={() => navigate(paths.register)}>
            {texts.registry}
          </StrongText>
        </Styles.ForgotPasswordButton>
      </Styles.Content>
    </FormFull>
  );
}

export default LoginStep;
