import React, { ReactElement, ReactNode } from "react";
import styled from "styled-components";
import { fonts } from "../../config";
import FontStyles from "../styles/fontStyles";

type CustomTextProps = {
  children: ReactNode;
  echo?: boolean;
  color?: string;
  onClick?: () => void;
  custom?: any;
};

function StrongText({
  children,
  echo,
  onClick,
  color,
  custom,
}: CustomTextProps): JSX.Element {
  return (
    <strong
      onClick={onClick}
      style={{
        fontFamily: !echo ? fonts.heavy : custom ? custom : fonts.boldCf,
        color: color && color,
        cursor: onClick && "pointer",
      }}
    >
      {children}
    </strong>
  );
}

export default StrongText;
