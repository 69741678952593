import React from "react";
import { FormFull } from "form-full";
import { hooks, LocalStorage, validations } from "../../../utils";
import Styles from "../PaymentStyles";
import { Input, InputEmail } from "../../../components";
import { fonts, Spacing } from "../../../config";
import TableGrid from "../components/TableGrid";
import Bar from "../components/Bar";
import texts from "../../../config/texts";

interface PageProps {
  goNext: () => void;
  step: number;
  setStep: any;
  tableData: any;
  userData: any;
  setDeletePurchase: any;
}

function StepInformation({
  goNext,
  step,
  setStep,
  tableData,
  userData,
  setDeletePurchase,
}: PageProps): JSX.Element {
  const currentText =
    texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].payment.information;

  const firstname = () => {
    const name = userData?.name?.split(" ");
    if (name?.length > 0) {
      return name[0];
    } else {
      return "";
    }
  };

  const lastname = () => {
    const name = userData?.name?.split(" ");

    if (name?.length > 0) {
      return name[name.length - 1];
    } else {
      return "";
    }
  };

  return (
    <>
      <FormFull onSubmit={() => {}}>
        <Styles.Content>
          <Styles.HeaderPayment>
            <Styles.Title>{currentText.title}</Styles.Title>
            <Bar step={step} />
            <Styles.Title>{currentText.subtitle}</Styles.Title>
          </Styles.HeaderPayment>

          <Styles.HeaderPaymentMobile>
            <Styles.Title>{currentText.titleMobile}</Styles.Title>
            <Styles.Title $bigger style={{ marginBlock: 10 }}>
              {currentText.subtitle}
            </Styles.Title>
          </Styles.HeaderPaymentMobile>

          <Styles.InputTitle>{currentText.firstname}</Styles.InputTitle>
          <Input
            inputStyle={{
              border: "1.5px solid #01003B",
              borderRadius: "95px",
              color: "#616161",
              fontFamily: fonts.regularCf,
              fontSize: Spacing(2.75),
              paddingInline: Spacing(3.75),
              paddingBlock: Spacing(2),
              marginTop: Spacing(0.75),
              "& .MuiInputBase-input": {
                padding: 0,
                boxShadow: "none !important",
              },
              [`@media (max-width:${1024 - 1}px)`]: {
                paddingBlock: Spacing(1.5),
                paddingInline: Spacing(1.75),
                fontSize: Spacing(1.6),
              },
              [`@media (max-width:${581 - 1}px)`]: {
                paddingInline: Spacing(1),
                paddingBlock: Spacing(0.5),
                fontSize: Spacing(1),
                marginTop: Spacing(0.15),
              },
            }}
            name="name"
            required={currentText.required}
            validation={validations.isValidName}
            defaultValue={firstname}
            readOnly={userData?.name}
          />
          <Styles.InputTitle>{currentText.lastname}</Styles.InputTitle>
          <Input
            inputStyle={{
              border: "1.5px solid #01003B",
              borderRadius: "95px",
              color: "#616161",
              fontFamily: fonts.regularCf,
              fontSize: Spacing(2.75),
              paddingInline: Spacing(3.75),
              paddingBlock: Spacing(2),
              marginTop: Spacing(0.75),
              "& .MuiInputBase-input": {
                padding: 0,
                boxShadow: "none !important",
              },
              [`@media (max-width:${1024 - 1}px)`]: {
                paddingBlock: Spacing(1.5),
                paddingInline: Spacing(1.75),
                fontSize: Spacing(1.6),
              },
              [`@media (max-width:${581 - 1}px)`]: {
                paddingInline: Spacing(1),
                paddingBlock: Spacing(0.5),
                fontSize: Spacing(1),
                marginTop: Spacing(0.15),
              },
            }}
            name="lastname"
            required={currentText.required}
            validation={validations.isValidName}
            defaultValue={lastname}
            readOnly={userData?.name}
          />

          <Styles.Title $bigger style={{ marginBottom: 10 }}>
            {currentText.contact}
          </Styles.Title>
          <Styles.InputTitle>Email</Styles.InputTitle>
          <InputEmail
            inputStyle={{
              border: "1.5px solid #01003B",
              borderRadius: "95px",
              color: "#616161",
              fontFamily: fonts.regularCf,
              fontSize: Spacing(2.75),
              paddingInline: Spacing(3.75),
              paddingBlock: Spacing(2),
              marginTop: Spacing(0.75),
              "& .MuiInputBase-input": {
                padding: 0,
                boxShadow: "none !important",
              },
              [`@media (max-width:${1024 - 1}px)`]: {
                paddingBlock: Spacing(1.5),
                paddingInline: Spacing(1.75),
                fontSize: Spacing(1.6),
              },
              [`@media (max-width:${581 - 1}px)`]: {
                paddingInline: Spacing(1),
                paddingBlock: Spacing(0.5),
                fontSize: Spacing(1),
                marginTop: Spacing(0.15),
              },
            }}
            name="email"
            required={currentText.required}
            validation={validations.isEmailValid}
            defaultValue={userData?.email ? userData?.email : null}
            readOnly={userData?.email}
          />
          <TableGrid data={tableData} setDeletePurchase={setDeletePurchase} />
        </Styles.Content>
        <Styles.StyledButton
          name="continue"
          fullWidth={false}
          onClick={() => {
            setStep(2), goNext();
          }}
        >
          {currentText.continueButton}
        </Styles.StyledButton>
      </FormFull>
    </>
  );
}

export default StepInformation;
