import { useContext } from "react";
import { Styles } from "../styles";
import { FontStyles, Input, StrongText } from "../../../components";

import { StorageContext } from "../../../contexts/StorageContext";
import { Spacing } from "../../../config";
import { FaTiktok } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { Instagram } from "@material-ui/icons";
import { FormFull } from "form-full";
import { hooks, LocalStorage, validations } from "../../../utils";
import { makeStyles } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { paths } from "../../../navigation/navigate";
import texts from "../../../config/texts";
import { api } from "../../../services";
import { customModal } from "../../../components/modals/utils";

type CustomFooterProps = {};
const InputStyle = {
  margin: 0,
  borderRadius: 0,
  maxWidth: Spacing(41.5),
  width: Spacing(41.5),

  backgroundColor: "#050500",
  color: "#D9D9D9",
  boxShadow: "none",
  ...FontStyles.light14,
  "& .MuiInputBase-input": {
    backgroundColor: "#050500",
    boxShadow: "none !important",
    padding: 0,
  },

  "@media (max-width: 744px)": {
    width: "100%",
    fontSize: Spacing(1.2),
    maxWidth: "100vw",
  },
};
const useStyles = makeStyles((theme) => ({
  svgContainer: {
    display: "flex",
    maxWidth: Spacing(2.375),
    maxHeight: Spacing(2.375),
    "@media (max-width: 784px)": {
      maxWidth: Spacing(1.75),
      maxHeight: Spacing(1.75),
    },
  },
}));
function Footer({}: CustomFooterProps) {
  const currentText =
    texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].footer;
  const actionButtonText =
    texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].actionButton;
  const { isLogged, setIsLogged } = useContext<any>(StorageContext);
  const { loading, call } = hooks.useRequest();
  const classes = useStyles();
  const navigate = useNavigate();
  const iconButtons = [
    {
      icon: Instagram,
      link: "https://www.instagram.com/biocoinofficial/",
      name: "Instagram",
    },

    {
      icon: FaTiktok,
      link: "https://www.tiktok.com/@biocoin.net.br?_t=8gIA4hwahkM&_r=1",
      name: "Tiktok",
    },

    {
      icon: FaXTwitter,
      link: "https://x.com/BioCoin_?s=20",
      name: "Twitter",
    },
  ];

  const newsletterRegister = (data) => {
    call(null, api.sendNewsletter(data.email), (response) => {
      if (response.ok) {
        customModal.setInfos(
          currentText.modalTitle,
          [currentText.modalText],
          {
            onClick: () => {
              customModal.close();
            },
            label: "Ok",
          },
          null
        );
      }
    });
  };

  const handleDownloadTerms = () => {
    const pdfPath = process.env.REACT_APP_ASSETS_URL + "/documents/termos_de_uso.pdf";
    window.open(pdfPath, "_blank");
  };

  return (
    <Styles.Container>
      <Styles.Content>
        <Styles.FirstContent>
          <Styles.Logo />
          <Styles.Email target="_blank" href="mailto:contact@biocoin.com">
            contact<StrongText echo>@biocoin.com</StrongText>
          </Styles.Email>
          <Styles.IconsList>
            {iconButtons.map((item) => (
              <Styles.NavButton
                color="secondary"
                aria-label={item.name}
                onClick={() => {
                  window.open(item.link, "_blank");
                }}
              >
                <div className={classes.svgContainer}>
                  <item.icon style={{ width: "100%", height: "100%" }} />
                </div>
              </Styles.NavButton>
            ))}
          </Styles.IconsList>
          <Styles.Version>
            {" "}
            {process.env.REACT_APP_ENV}
            {" - "}
            {process.env.REACT_APP_VERSION}
          </Styles.Version>
        </Styles.FirstContent>
        <Styles.LinksContainer>
          <Styles.SecondContent>
            <Styles.NavLink
              onClick={() => {
                navigate(paths.about);
                document.getElementById("header-menu")?.scrollIntoView();
              }}
            >
              {currentText.menu[0]}
            </Styles.NavLink>
            <Styles.NavLink
              onClick={() => {
                navigate(paths.faq);
                document.getElementById("header-menu")?.scrollIntoView();
              }}
            >
              {currentText.menu[1]}
            </Styles.NavLink>
            <Styles.NavLink
              onClick={() => {
                navigate(paths.blog);
                document.getElementById("header-menu")?.scrollIntoView();
              }}
            >
              {currentText.menu[2]}
            </Styles.NavLink>
          </Styles.SecondContent>
          <Styles.SecondContent alternative>
            <Styles.NavLink
              onClick={() => {
                handleDownloadTerms();
                document.getElementById("header-menu")?.scrollIntoView();
              }}
            >
              {currentText.menu[3]}
            </Styles.NavLink>
            <Styles.NavLink
              onClick={() => {
                navigate(paths.privacyPolicy);
                document.getElementById("header-menu")?.scrollIntoView();
              }}
            >
              {currentText.menu[4]}
            </Styles.NavLink>
          </Styles.SecondContent>
        </Styles.LinksContainer>
      </Styles.Content>
      <Styles.FormContent>
        <FormFull
          onSubmit={(item) => {
            newsletterRegister(item);
          }}
        >
          <Styles.InputTexts title> {currentText.conected}</Styles.InputTexts>
          <Styles.FormEmail>
            <Input
              disableError
              required="Insira um e-mail"
              validation={validations.isEmailValid}
              placeholder={currentText.emailPlaceholder}
              name="email"
              inputStyle={InputStyle}
            />

            <Styles.ButtonLogin
              disabled={loading}
              loading={loading}
              action="submit"
              name="submit"
              fullWidth={false}
            >
              {actionButtonText.send}
            </Styles.ButtonLogin>
          </Styles.FormEmail>
          <Styles.InputTexts>
            {actionButtonText.newsletterText}
          </Styles.InputTexts>
        </FormFull>
      </Styles.FormContent>
    </Styles.Container>
  );
}

export default Footer;
