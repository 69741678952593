import { CancelToken, create, RequestTransform } from "apisauce";
import caller from "./helpers/caller";

import { LocalStorage, SessionStorage } from "../utils";
import objectToQueryString from "./helpers/createQuery";

const apiUrl = process.env.REACT_APP_API;
let cancelToken = CancelToken.source();
const api = create({
  baseURL: apiUrl,
  timeout: 500000,
});

api.addRequestTransform((request: any) => {
  const token = LocalStorage.getItem("token");
  const idioma = LocalStorage.getItem("language");
  request.headers["Accept-Language"] = idioma ? idioma : "pt";
  if (token) {
    request.headers.authorization = token;
  }
});

api.addMonitor((response: any) => {
  const token = response.headers.authorization;

  if (token) {
    LocalStorage.setItem("token", token);
  }
});

async function getToken(loginData, axios) {
  return caller(api.post, "/login", null, loginData, axios);
}

async function getUserData() {
  return caller(api.get, "/user", null);
}

async function sendRecoveryPasswordRequest(data) {
  return caller(api.post, `/password/reset/request?email=${data.email}`, null);
}

async function recoveryPasswordValidate(data) {
  return caller(api.post, "/password/reset/code", null, data);
}

async function sendRecoveryPasswordNewPassword(data) {
  return caller(api.put, `/password/reset/confirm`, null, data);
}
// {  headers: { "Access-Control-Allow-Origin": "*" }}

async function putChangePassword(data) {
  return caller(api.post, "/access/redefinition", null, data);
}
async function getBlogList(data: Record<string, any>) {
  return caller(api.get, `/blog${objectToQueryString(data)}`, null);
}
async function getImage(id: string, blogId: string) {
  return caller(api.get, `/blog/${blogId}/image/${id}`, null);
}

async function putRegister(data) {
  return caller(api.post, "/user", null, data);
}

async function patchPasswordUser(data) {
  return caller(api.patch, "/user/password", null, data);
}

async function editUser(data) {
  return caller(api.put, "/user", null, data);
}

async function getCurrency() {
  return caller(api.get, "/currency", null);
}

async function putPayment(data) {
  return caller(api.post, "/payment", null, data);
}

async function getPayments() {
  return caller(api.get, "/transaction", null);
}

async function getWallet() {
  return caller(api.get, "/wallet", null);
}

async function editUserImage(data) {
  return caller(api.patch, "/user/image", null, data);
}

async function getUserImage() {
  return caller(api.get, "/user/image", null);
}

async function editUserDocs(data) {
  return caller(api.patch, "/user/docs", null, data);
}

async function resetUser(data) {
  return caller(api.patch, "/user/reset", null, data);
}

async function validationEmail() {
  return caller(api.post, "/email/validate/request", null);
}

async function getQRCodeT2F() {
  return caller(api.get, "/login/subscribe_qr_code_t2f", null);
}

async function send2FA(code, axios) {
  return caller(
    api.post,
    `/login/two_factor_authentication?code=${code}`,
    null,
    axios
  );
}

async function sendNewsletter(data) {
  return caller(api.post, `/newsletter/subscribe?email=${data}`, null);
}
async function getForests(data) {
  return caller(api.get, `/preserved-area`, null, data);
}

async function getForestImg(preservedAreaId, imageId) {
  return caller(
    api.get,
    `/preserved-area/${preservedAreaId}/image/${imageId}`,
    null
  );
}

async function getForestVideo(preservedAreaId, videoId) {
  return caller(
    api.get,
    `/preserved-area/${preservedAreaId}/video/${videoId}/download`,
    null,
    null,
    { responseType: "arraybuffer" }
  );
}

async function getNFTs(data) {
  return caller(api.get, `/nft/available`, null, data);
}

async function getNFTsUser(data) {
  return caller(api.get, `/nft-redeem`, null, data);
}

async function getNFTImg(id, imageId) {
  return caller(api.get, `/nft/${id}/image/${imageId}`, null);
}

async function transferNFT(id, data) {
  return caller(api.patch, `/nft-redeem/${id}`, null, data);
}

async function refundRequest(data) {
  return caller(api.post, `/refund/request`, null, data);
}

const apiServices = {
  sendRecoveryPasswordRequest,
  sendRecoveryPasswordNewPassword,
  recoveryPasswordValidate,

  getToken,

  getUserData,

  putChangePassword,
  putRegister,
  getBlogList,
  getImage,
  patchPasswordUser,
  editUser,
  editUserImage,
  getUserImage,
  editUserDocs,
  defaulApi: api,
  cancelToken,
  resetUser,

  getCurrency,
  putPayment,
  getWallet,
  getPayments,
  validationEmail,

  getQRCodeT2F,
  send2FA,
  sendNewsletter,
  getForests,
  getForestImg,
  getForestVideo,

  getNFTs,
  getNFTImg,
  getNFTsUser,
  transferNFT,
  refundRequest,
};

export default apiServices;
