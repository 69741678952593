import { makeStyles } from "@material-ui/core";

import styled from "styled-components";

import { ButtonContained, FontStyles } from "../../../components";
import { fonts } from "../../../config";

const Container = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    position: "relative",
    display: "flex",

    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: `calc(448px + ${$menuHeight}px)`,
    height: "100%",
    marginTop: `-${$menuHeight}px`,

    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "flex-end",
    width: "100%",
    backgroundColor: "black",
    [`@media (max-width:${1024 - 1}px)`]: {
      marginTop: 0,
      width: "100%",
      height: "100%",

      minHeight: "650px",
    },

    [`@media (max-width:${470 - 1}px)`]: {
      minHeight: "343px",
    },
  };
});

const YellowBall = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    position: "absolute",
    zIndex: -1,

    top: 0,
    right: 0,
    transform: "translate(90%, -50%)",
    borderRadius: "996px",
    width: "798.961px",
    height: "996px",
    filter: "blur(225px)",
    background: "#E7FF00E6",

    [`@media (max-width:${1024 - 1}px)`]: {
      borderRadius: "514.6px",
      width: "412.797px",
      height: "514.6px",
      filter: "blur(116.25px)",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      transform: "translate(50%, -50%)",
      borderRadius: "310px",
      width: "310px",
      height: "310px",
      filter: "blur(100px)",
    },
  };
});

const YellowBallTwo = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    position: "absolute",
    transform: "translate(-40%, 50%)",
    bottom: 0,
    left: 0,
    borderRadius: "631px",
    width: "631px",
    height: "601px",
    background: "#E7FF00E6",
    filter: "blur(300px)",
    zIndex: -1,

    [`@media (max-width:${1024 - 1}px)`]: {
      borderRadius: "310.517px",
      width: "326.017px",
      height: "310.517px",
      filter: "blur(155px)",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      transform: "translate(-105%, 40%)",
      borderRadius: "540.567px",
      width: "640.189px",
      height: "540.567px",
      filter: "blur(300px)",
    },
  };
});

const useStyles = makeStyles((theme) => ({
  svgContainer: {
    display: "flex",

    maxWidth: theme.spacing(3.25),
    maxHeight: theme.spacing(3.25),
    "@media (max-width: 581px)": {
      maxWidth: theme.spacing(1.5),
      maxHeight: theme.spacing(1.5),
    },
  },
}));

const DashContainer = styled.div(({ theme, $image }) => {
  const { spacing } = theme;
  return {
    position: "relative",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "black",
    zIndex: 1,
    backgroundImage: `url(${$image})`,
    paddingBlock: spacing(9.25),
    paddingInline: spacing(12.5),

    [`@media (max-width:${1024 - 1}px)`]: {
      padding: spacing(6),
    },
    [`@media (max-width:${581 - 1}px)`]: { padding: spacing(2.5) },
  };
});

const WhiteBox = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    borderRadius: "43.919px",
    background: "#F2F2F2",
    backdropFilter: "blur(18.822629928588867px)",

    width: "100%",
    zIndex: 3,
    padding: spacing(10),

    [`@media (max-width:${1024 - 1}px)`]: {
      paddingInline: `clamp(48px, 8vw, 90px)`,
    },
    [`@media (max-width:${581 - 1}px)`]: {
      paddingInline: `clamp(24px, 11vw, 468px)`,
      paddingBlock: `clamp(18px, 8vw, 32px)`,
    },
  };
});
const TitleBox = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    paddingBottom: spacing(5),
    [`@media (max-width:${1024 - 1}px)`]: {
      flexDirection: "column",
      alignItems: "flex-start",
      paddingBottom: spacing(2),
    },
    [`@media (max-width:${581 - 1}px)`]: {},
  };
});
const IconsBox = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "row",
    gap: spacing(2),

    [`@media (max-width:${1024 - 1}px)`]: { paddingTop: spacing(1) },
    [`@media (max-width:${581 - 1}px)`]: { flexWrap: "wrap", gap: spacing(1) },
  };
});

const TitleDash = styled.p(({ theme }) => {
  const { spacing } = theme;
  return {
    margin: 0,
    fontFamily: fonts.heavy,
    fontSize: spacing(4.375),
    lineHeight: "140.791%",
    [`@media (max-width:${1024 - 1}px)`]: {},
    [`@media (max-width:${581 - 1}px)`]: { fontSize: spacing(2) },
  };
});

const NftContainer = styled.div(({ theme, $image }) => {
  const { spacing } = theme;
  return {
    backgroundImage: `url(${$image})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: "27.607px",
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "18px",

    [`@media (max-width:${1024 - 1}px)`]: {
      alignItems: "flex-end",

      minHeight: "231px",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      borderRadius: "14px",
      minHeight: "122px",
      marginBottom: "28px",
    },
  };
});

const NftTextBox = styled.div(({ theme, $image }) => {
  const { spacing } = theme;
  return {
    width: "40%",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    flexDirection: "column",
    background: " rgba(255, 255, 255, 0.68)",
    backdropFilter: "blur(5.646789073944092px)",
    padding: spacing(6),
    borderTopRightRadius: "27.607px",
    borderBottomRightRadius: "27.607px",
    [`@media (max-width:${1024 - 1}px)`]: {
      width: "100%",
      height: "40%",
      flexDirection: "row",
      paddingInline: spacing(4.75),
      paddingBlock: spacing(2.25),
      alignItems: "center",
      borderBottomLeftRadius: "27.607px",
      borderTopRightRadius: "0px",
      borderBottomRightRadius: "27.607px",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      paddingInline: spacing(2.625),
      paddingBlock: spacing(1.625),
      borderTopRightRadius: "0px",
      borderBottomRightRadius: "14px",
      borderBottomLeftRadius: "14px",
    },
    [`@media (max-width:${280 - 1}px)`]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  };
});
const NftTitle = styled.p(({ theme }) => {
  const { spacing } = theme;
  return {
    ...FontStyles.regular18,
    fontSize: spacing(4),
    margin: 0,
    lineHeight: "normal",
    [`@media (max-width:${1024 - 1}px)`]: {
      maxWidth: "256px",
      fontSize: spacing(2.5),
    },
    [`@media (max-width:${581 - 1}px)`]: { fontSize: spacing(1.375) },
  };
});

const OpenSeaButton = styled(ButtonContained)(({ theme }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      paddingBlock: spacing(1.1),
      paddingInline: spacing(20 / 8),
      fontSize: spacing(2),
      lineHeight: "normal",
      [`@media (max-width:${1024 - 1}px)`]: { fontSize: spacing(1.75) },
      [`@media (max-width:${581 - 1}px)`]: { fontSize: spacing(0.9) },
    },
  };
});

const NavNfts = styled.div(({ theme, $image }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "row",

    borderRadius: "42px",
    background: "#1A2024",
    [`@media (max-width:${1024 - 1}px)`]: {},
    marginBottom: "18px",
    [`@media (max-width:${581 - 1}px)`]: { marginBottom: "24px" },
  };
});

const NavNftsButton = styled.div(({ theme, $selected }) => {
  const { spacing } = theme;
  return {
    flex: 1,
    maxWidth: "50%",
    width: "100%",
    cursor: "pointer",
    margin: 0,
    backgroundColor: $selected ? "#D3D527" : "#1A2024",
    color: $selected ? "#1A2024" : "#FFFFFF",
    fontFamily: $selected ? fonts.heavy : fonts.light,
    fontSize: spacing(2),
    paddingBlock: spacing(2.25),
    borderRadius: "42px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [`@media (max-width:${1024 - 1}px)`]: {
      fontFamily: fonts.heavy,
      fontSize: spacing(2.625),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: spacing(1.5),
      paddingBlock: spacing(1.5),
    },
  };
});

const NftCard = styled.div(({ theme, $selected }) => {
  const { spacing } = theme;
  return {
    height: "100%",
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    padding: "21px",
    borderRadius: "25.943px",
    border: "1.179px solid rgba(231, 255, 0, 0.51)",
    background:
      "linear-gradient(232deg, rgba(231, 255, 0, 0.14) 3.16%, rgba(231, 255, 0, 0.00) 40.87%), #050500",
    boxShadow: "9.43371px 14.15057px 50.7062px 0px rgba(0, 0, 0, 0.15)",
    [`@media (max-width:${1024 - 1}px)`]: {
      padding: "40px",
      gap: "22px",
      maxWidth: "478px",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      padding: "21px",
      gap: "12px",
      maxWidth: "283px",
    },
  };
});

const NftCardImg = styled.div(({ theme, $src }) => {
  const { spacing } = theme;
  return {
    width: "100%",
    backgroundPosition: "center",
    marginBottom: "8px",
    borderRadius: "21.226px",
    border: "1.179px solid #D3D527",
    backgroundImage: `url(${$src})`,
    backgroundSize: "cover",
    height: "clamp(100px, 13vw, 200px)",
    [`@media (max-width:${960 - 1}px)`]: {
      borderRadius: "40px",
      border: "2px solid #D3D527",
      marginBottom: "18px",
      height: "clamp(250px, 54vw, 398px)",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      borderRadius: "21px",
      border: "1.17px solid #D3D527",
      marginBottom: "12px",
      height: "clamp(150px, 54vw, 200px)",
    },
  };
});

const NftCardTitle = styled.p(({ theme, $selected }) => {
  const { spacing } = theme;
  return {
    color: "#D3D527",
    margin: 0,
    ...FontStyles.bold18,
    paddingBlock: "6px",
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(4.5),
      paddingBlock: "12px",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: spacing(2.25),
      paddingBlock: "7px",
    },
  };
});

const NftCardSubtitle = styled.p(({ theme, $selected }) => {
  const { spacing } = theme;
  return {
    color: "white",
    margin: 0,
    ...FontStyles.light14,
    fontSize: "10px",
    [`@media (max-width:${1024 - 1}px)`]: { fontSize: spacing(2.5) },
    [`@media (max-width:${581 - 1}px)`]: { fontSize: spacing(1.35) },
  };
});
const NftNumber = styled.p(({ theme, $selected }) => {
  const { spacing } = theme;
  return {
    color: "white",
    margin: 0,
    ...FontStyles.regular12,
    fontSize: "10px",
    [`@media (max-width:${1024 - 1}px)`]: { fontSize: spacing(2.5) },
    [`@media (max-width:${581 - 1}px)`]: { fontSize: spacing(1.35) },
  };
});
const NftQrCode = styled.img(({ theme, $selected }) => {
  const { spacing } = theme;
  return {
    width: "100%",
    height: "100%",
    maxHeight: "44px",
    maxWidth: "44px",
    [`@media (max-width:${1024 - 1}px)`]: {
      maxHeight: "84px",
      maxWidth: "84px",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      maxHeight: "44px",
      maxWidth: "44px",
    },
  };
});

const NftButton = styled(ButtonContained)(({ theme, $selected }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      [`@media (max-width:${1024 - 1}px)`]: {
        fontSize: "32px",
        paddingBlock: "24px",
      },
      [`@media (max-width:${581 - 1}px)`]: {
        fontSize: "17px",
        paddingBlock: "13px",
      },
    },
  };
});
const Back = styled(ButtonContained)(({ theme, $center, $image }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      paddingInline: spacing(4),
      paddingBlock: spacing(2.25),
      fontSize: spacing(2),
      borderRadius: spacing(2),
      marginTop: spacing(4),
      [`@media (max-width:${1024 - 1}px)`]: {
        borderRadius: spacing(3),
        paddingInline: spacing(5.25),
        paddingBlock: spacing(2.5),
        fontSize: spacing(2.5),
        marginLeft: 0,
      },
      [`@media (max-width:${581 - 1}px)`]: {
        borderRadius: spacing(1.5),
        paddingInline: spacing(2.25),
        paddingBlock: spacing(1.5),
        fontSize: spacing(1.5),
        marginTop: spacing(1.5),
      },
    },
  };
});
const Styles = {
  Container,

  YellowBallTwo,
  YellowBall,

  useStyles,

  DashContainer,
  WhiteBox,
  NftContainer,
  TitleBox,
  TitleDash,
  IconsBox,
  NftTextBox,
  NftTitle,

  OpenSeaButton,
  NavNfts,
  NavNftsButton,
  NftCard,
  NftCardImg,
  NftCardSubtitle,
  NftCardTitle,
  NftNumber,
  NftQrCode,
  NftButton,
  Back,
};

export default Styles;
