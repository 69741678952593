import React, { useContext } from "react";

import Styles from "./styles";
import { fonts, Spacing } from "../../config";

import { useLocation, useNavigate } from "react-router-dom";
import { paths } from "../../navigation/navigate";
import texts from "../../config/texts";
import { StorageContext } from "../../contexts/StorageContext";
import { FormFull } from "form-full";
import { Input } from "../../components";
import { CircularProgress, Grid, Tooltip } from "@material-ui/core";

import { FaLocationPin } from "react-icons/fa6";
import { hooks, LocalStorage, masks } from "../../utils";
import { api } from "../../services";
import base64 from "../../utils/storage/Base64";
interface Address {
  number?: string;
  street?: string;
  complement?: string;
  district?: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  lat: number;
  lon: number;
}
const PAGE_SIZE = 10;
function AllForest() {
  const location = useLocation();
  const currentText =
    texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].forests;
  const actionText =
    texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].actionButton;
  const [isMounted, setMount] = React.useState<boolean>(false);
  const [menuHeight, setHeight] = React.useState<any>(null);
  const [forestList, setForestList] = React.useState<any | null>(null);
  const [pageNumber, setPageNumber] = React.useState<any>(0);
  const { loading, call } = hooks.useRequest();
  const { isLogged } = useContext<any>(StorageContext);
  const navigate = useNavigate();

  const handleDownload = () => {
    const pdfPath =
      process.env.REACT_APP_ASSETS_URL + "/documents/WhitePaper.pdf";
    window.open(pdfPath, "_blank");
  };

  React.useLayoutEffect(() => {
    if (!menuHeight) {
      const mh = document.getElementById("header-menu")?.offsetHeight;

      setHeight(mh);
    }
  }, [menuHeight]);

  const Mount = React.useCallback(() => {
    call(
      "forestList",
      api.getForests({
        page: 0,
        size: PAGE_SIZE,
        acquired: location.pathname.includes("buy") ? false : true,
      }),
      async (response) => {
        if (response.ok) {
          let newList: any[] = [];

          for (const item of response?.data?.content) {
            let newImg: any = "";

            await call(
              null,
              api.getForestImg(item.id, item["imageId1"]),
              (response) => {
                newImg = `data:image/jpeg;base64,${response.data}`;
              }
            );

            newList.push({
              ...item,
              img: newImg,
            });
          }

          setForestList({ ...response.data, content: newList });
        }
      }
    );
  }, []);

  React.useEffect(() => {
    if (!isMounted) {
      Mount();
      setMount(true);
    }
  }, [Mount, isMounted]);

  const loadMorePages = () => {
    call(
      "blogList",
      api.getForests({
        page: pageNumber + 1,
        size: PAGE_SIZE,
        acquired: location.pathname.includes("buy") ? false : true,
      }),
      async (response) => {
        if (response.ok) {
          let newList: any[] = [...forestList?.content];

          for (const item of response?.data?.content) {
            let newImg: any = "";

            await call(
              null,
              api.getForestImg(item.id, item["imageId1"]),
              (response) => {
                newImg = `data:image/jpeg;base64,${response.data}`;
              }
            );

            newList.push({
              ...item,
              img: newImg,
            });
          }

          setForestList({ ...response.data, content: newList });
          setPageNumber(pageNumber + 1);
        }
      }
    );
  };

  const searchByName = (name) => {
    const filter = {
      page: 0,
      size: PAGE_SIZE,
      acquired: location.pathname.includes("buy") ? false : true,
    };
    if (name !== null && name !== "") {
      filter["filter"] = name;
    }
    call("blogList", api.getForests(filter), async (response) => {
      if (response.ok) {
        let newList: any[] = [];

        for (const item of response?.data?.content) {
          let newImg: any = "";

          await call(
            null,
            api.getForestImg(item.id, item["imageId1"]),
            (response) => {
              newImg = `data:image/jpeg;base64,${response.data}`;
            }
          );

          newList.push({
            ...item,
            img: newImg,
          });
        }

        setForestList({ ...response.data, content: newList });
        setPageNumber(0);
      }
    });
  };

  const formatAddress = (address: Address) => {
    const parts: any = [];

    if (address.number && address.street) {
      parts.push(`${address.number} ${address.street}`);
    } else if (address.street) {
      parts.push(address.street);
    }

    if (address.city && address.state) {
      parts.push(`${address.city} - ${address.state}`);
    } else if (address.city) {
      parts.push(address.city);
    } else if (address.state) {
      parts.push(address.state);
    }

    if (address.postalCode) {
      parts.push(address.postalCode);
    }

    return parts.join(", ");
  };

  const convertToJsonAndRedirect = (item: any) => {
    let path = location.pathname.includes("buy")
      ? "/forest/buy"
      : "/forest/acquired";
    navigate(path + "?" + item.name, { state: { item } });
  };

  return (
    <>
      <Styles.Container $menuHeight={menuHeight}>
        <Styles.BgVideoContainer $menuHeight={menuHeight}>
          <Styles.BgVideo
            $menuHeight={menuHeight}
            loop
            autoPlay
            playsInline
            controls={false}
            muted
            preload="auto"
          >
            <source
              src={
                process.env.REACT_APP_ASSETS_URL + "/videos/homeLogoVideo.webm"
              }
              type="video/webm"
            />
          </Styles.BgVideo>
          <Styles.BgForest></Styles.BgForest>
          <div
            style={{
              position: "absolute",
              top: 0,
              zIndex: 3,
              left: 0,
            }}
          >
            <Styles.LandingFirst>
              <Styles.LandingText>{currentText.landingText}</Styles.LandingText>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  gap: Spacing(1),
                }}
              >
                <Styles.RegistryButton
                  fullWidth={false}
                  onClick={() =>
                    isLogged ? navigate("/payment") : navigate(paths.register)
                  }
                >
                  {isLogged ? currentText.buy : currentText.register}
                </Styles.RegistryButton>
                <Styles.WhitelistButton
                  fullWidth={false}
                  onClick={handleDownload}
                >
                  {currentText.whitepaper}
                </Styles.WhitelistButton>
              </div>
            </Styles.LandingFirst>
          </div>
          <div
            style={{
              position: "absolute",
              top: 0,
              zIndex: 3,
              right: 0,
            }}
          >
            <Styles.LandingSecond>
              <Styles.WhitelistSecondButton
                fullWidth={true}
                onClick={handleDownload}
              >
                {currentText.whitepaper}
              </Styles.WhitelistSecondButton>
            </Styles.LandingSecond>
          </div>
          <div style={{ position: "absolute", bottom: 0, zIndex: 3, left: 0 }}>
            <Styles.LandingButtonContainer></Styles.LandingButtonContainer>
          </div>
        </Styles.BgVideoContainer>
      </Styles.Container>
      <Styles.ForestContainer>
        <FormFull onSubmit={() => {}}>
          <Input
            customBlur={(event, value) => {
              if (event.key === "Enter") {
                searchByName(value);
              }
            }}
            disableError
            placeholder={currentText.placeholder}
            fullWidth={true}
            inputStyle={{
              border: "1.5px solid #B3B3B3",
              background: "#fff",
              borderRadius: "53.201px",
              color: "#616161",
              fontFamily: fonts.regularCf,
              fontSize: Spacing(2.625),
              paddingInline: Spacing(3.75),
              paddingBlock: Spacing(2),
              marginTop: Spacing(0.75),
              "& .MuiInputBase-input": {
                padding: 0,

                boxShadow: "none !important",
              },

              [`@media (max-width:${1024 - 1}px)`]: {
                paddingInline: Spacing(3.5),
                fontSize: Spacing(2.5),
              },
              [`@media (max-width:${581 - 1}px)`]: {
                paddingInline: Spacing(1.25),
                paddingBlock: Spacing(1),
                fontSize: Spacing(1.5),
              },
            }}
            name="search"
          />
        </FormFull>
        <Styles.ForestList>
          {forestList?.content?.length > 0 ? (
            <>
              {forestList?.content?.map((item, index) => {
                return (
                  <Styles.ForestCard index={index} key={index}>
                    <Tooltip title="Abrir na galeria" aria-label="open-gallery">
                      <Styles.ForestCardImg
                        onClick={() => {
                          convertToJsonAndRedirect(item);
                        }}
                        $src={item.img}
                      ></Styles.ForestCardImg>
                    </Tooltip>

                    <Styles.ForestCardInfo>
                      <div>
                        <Tooltip
                          title="Abrir na galeria"
                          aria-label="open-gallery"
                        >
                          <Styles.ForestName
                            onClick={() => {
                              convertToJsonAndRedirect(item);
                            }}
                          >
                            {item.name}
                          </Styles.ForestName>
                        </Tooltip>
                        <Styles.ForestLoc>
                          <Styles.LocPin />
                          {formatAddress(item.address)}
                        </Styles.ForestLoc>
                      </div>

                      <Styles.ForestCardArea>
                        <div>
                          <Styles.ForestAreaTitle>
                            {currentText.totalArea}
                          </Styles.ForestAreaTitle>
                          <Styles.ForestAreaValue>
                            {item.areaAcres}
                          </Styles.ForestAreaValue>
                        </div>

                        <div>
                          <Styles.ForestAreaTitle>
                            {currentText.unity}
                          </Styles.ForestAreaTitle>{" "}
                          <Styles.ForestAreaValue>
                            {" "}
                            {currentText.hec}
                          </Styles.ForestAreaValue>
                        </div>

                        <div>
                          <Styles.ForestAreaTitle>
                            {currentText.metro}
                          </Styles.ForestAreaTitle>{" "}
                          <Styles.ForestAreaValue>
                            {item.areaMeters}
                          </Styles.ForestAreaValue>
                        </div>
                        <div>
                          <Styles.ForestAreaTitle>
                            {currentText.value}
                          </Styles.ForestAreaTitle>{" "}
                          <Styles.ForestAreaValue>
                            {masks.formatarValorMonetario(item.value)}
                          </Styles.ForestAreaValue>
                        </div>
                      </Styles.ForestCardArea>
                    </Styles.ForestCardInfo>

                    <Styles.ForestCardMap>
                      <Styles.ForestIframe
                        id="gmap_canvas"
                        src={`https://maps.google.com/maps?q=${item.address.lat},${item.address.lon}&z=13&ie=UTF8&iwloc=&output=embed`}
                        frameBorder="0"
                        scrolling="no"
                        marginHeight={0}
                        marginWidth={0}
                      ></Styles.ForestIframe>
                    </Styles.ForestCardMap>
                  </Styles.ForestCard>
                );
              })}
              {loading && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress style={{ color: "black" }} />
                </div>
              )}
            </>
          ) : loading ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress style={{ color: "black" }} />
            </div>
          ) : (
            <Styles.ForestName>Sem conteúdo</Styles.ForestName>
          )}
          {forestList?.totalPages > 1 &&
            pageNumber + 1 < forestList?.totalPages && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Styles.More
                  loading={loading}
                  disabled={loading}
                  fullWidth={false}
                  onClick={() => loadMorePages()}
                >
                  {currentText.more}
                </Styles.More>
              </div>
            )}
        </Styles.ForestList>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Styles.Back
            onClick={() => {
              navigate(-1);
            }}
            fullWidth={false}
          >
            {actionText.back}
          </Styles.Back>
        </div>
      </Styles.ForestContainer>
    </>
  );
}

export default AllForest;
