import React from "react";

const GrainImage =
  process.env.REACT_APP_ASSETS_URL + "/imgs/grainBackground.webp";

import Styles from "./styles";

import { useNavigate } from "react-router-dom";

import { hooks, LocalStorage, masks } from "../../utils";
import { ButtonContained, CustomText, StrongText } from "../../components";
import { fonts, SVG } from "../../config";
import { api } from "../../services";
import { CircularProgress } from "@material-ui/core";
import Texts from "../../config/texts";
import { IoCashOutline } from "react-icons/io5";
import { customModalBlur } from "../../components/modals/utils";
import EmailModal from "./components/EmailModal";

type ObjectStatus =
  | "INACTIVE"
  | "PENDING"
  | "CANCELED"
  | "COMPLETED"
  | "REFUNDED"
  | "REFUND_REQUESTED";
type ObjectType = "DEPOSIT" | "PURCHASE" | "REFUND_DEPOSIT" | "REFUND_PURCHASE";
type ObjectMethod = "BILLET" | "CREDIT_CARD" | "PIX" | "WALLET";

interface OriginalObject {
  id: string;
  value: number;
  valueUnit?: number;
  qty?: number;
  status: ObjectStatus;
  type: ObjectType;
  method: ObjectMethod;
  user: unknown;
  createdAt: string;
  asaasReference?: string;
  transactionParent?: OriginalObject;
  asaasId: string | null;
}

interface ResultObject {
  month: string;
  year: string;
  list: {
    valueUnit?: number;
    value: number;
    date: string;
    status: ObjectStatus;
    type: ObjectType;
    method: ObjectMethod;
    asaasId: string | null;
    id: string;
    qty?: number;
  }[];
}
type HorizontalScrollableDivProps = {
  className?: string;
};

function Extract() {
  const texts =
    Texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].extract;

  const months =
    Texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].months;

  const navigate = useNavigate();
  const { loading, call } = hooks.useRequest();
  const [isMounted, setMount] = React.useState<boolean>(false);

  const [menuHeight, setHeight] = React.useState<any>(null);
  const [payments, setPayments] = React.useState<ResultObject[]>([]);

  const Mount = React.useCallback(() => {
    function getMonthName(month: number): string {
      return months[month].fullName;
    }

    function formatarData(dataString: string): string {
      const data = new Date(dataString);

      const dia = String(data.getDate()).padStart(2, "0");
      const mes = String(data.getMonth() + 1).padStart(2, "0"); // Mês é baseado em zero, então adicionamos 1
      const ano = String(data.getFullYear());

      const hora = String(data.getHours()).padStart(2, "0");
      const minutos = String(data.getMinutes()).padStart(2, "0");

      return `${dia}/${mes} ${hora}:${minutos}`;
    }

    function separateByMonthAndYear(objects: OriginalObject[]): ResultObject[] {
      const result: ResultObject[] = [];

      objects.forEach((object) => {
        const createdAt = new Date(object.createdAt);
        const month = getMonthName(createdAt.getMonth());
        const year = createdAt.getFullYear().toString();

        let existingResult = result.find(
          (item) => item.month === month && item.year === year
        );

        if (!existingResult) {
          existingResult = {
            month,
            year,
            list: [],
          };
          result.push(existingResult);
        }

        existingResult.list.push({
          valueUnit: object.valueUnit,
          value: object.value,
          date: formatarData(object.createdAt),
          status: object.status,
          type: object.type,
          method: object.method,
          asaasId: object.asaasId,
          id: object.id,
          qty: object?.qty,
        });
      });

      return result;
    }

    call(null, api.getPayments(), (data) => {
      if (data.ok) {
        setPayments(separateByMonthAndYear(data?.data?.content));
      }
    });
  }, []);

  React.useEffect(() => {
    if (!isMounted) {
      Mount();
      setMount(true);
    }
  }, [Mount, isMounted]);

  React.useLayoutEffect(() => {
    if (!menuHeight) {
      const mh = document.getElementById("header-menu")?.offsetHeight;

      setHeight(mh);
    }
  }, [menuHeight]);

  function getTypeDesc(type: ObjectType): string {
    switch (type) {
      case "DEPOSIT":
        return texts.deposit;
      case "PURCHASE":
        return texts.purchase;
      case "REFUND_DEPOSIT":
        return texts.refundDeposit;
      case "REFUND_PURCHASE":
        return texts.refundPurchase;
      default:
        return texts.unknow;
    }
  }

  function getMethodDesc(method: ObjectMethod) {
    switch (method) {
      case "BILLET":
        return " - " + texts.billet;
      case "CREDIT_CARD":
        return " - " + texts.creditCard;
      case "PIX":
        return " - " + texts.pix;
      case "WALLET":
        return "";
      default:
        return "";
    }
  }

  function getStatusDesc(status: ObjectStatus): string {
    switch (status) {
      case "INACTIVE":
        return texts.inactive;
      case "PENDING":
        return texts.pending;
      case "CANCELED":
        return texts.canceled;
      case "COMPLETED":
        return texts.completed;
      case "REFUNDED":
        return texts.refunded;
      case "REFUND_REQUESTED":
        return texts.refundRequested;
      default:
        return texts.unknow;
    }
  }

  function sendRefund(id: string | null, type?: string) {
    customModalBlur.setInfos(
      <EmailModal id={id} type={type} />,
      {
        maxWidth: "429px",
        height: "fit-content",
      },
      true
    );
  }

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        overflow: "hidden",
        flexDirection: "column",
      }}
    >
      <Styles.DashContainer $image={GrainImage}>
        <Styles.YellowBall></Styles.YellowBall>
        <Styles.YellowBallTwo></Styles.YellowBallTwo>
        <Styles.WhiteBox>
          <Styles.TitleBox>
            <Styles.TitleConfig>{texts.extract}</Styles.TitleConfig>
            {/*   <FormFull onSubmit={() => {}}>
              <Input
                disableError
                inputStyle={{
                  justifySelf: "flex-end",
                  borderRadius: "27.552px",
                  border: "1.252px solid #121515",
                  maxWidth: "657px",
                  width: "45vw",
                  [`@media (max-width:${1024 - 1}px)`]: {
                    width: "100%",
                    maxWidth: "100%",
                    border: "1.5px solid #121515",
                    paddingInline: Spacing(3),
                    paddingBlock: Spacing(2.25),
                  },
                  [`@media (max-width:${581 - 1}px)`]: {
                    border: "1px solid #121515",
                    paddingInline: Spacing(2),
                    paddingBlock: Spacing(1.25),
                  },
                }}
                startAdornment={
                  <SVG.SearchIcon style={{ marginRight: "8px" }} />
                }
                name="searchBar"
              ></Input>
            </FormFull> */}
          </Styles.TitleBox>
          {loading ? (
            <div
              style={{
                width: "100%",
                height: "fit-content",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <CircularProgress style={{ color: "black" }} />{" "}
            </div>
          ) : (
            <>
              {payments?.map((month) => (
                <div style={{ flexDirection: "column", display: "flex" }}>
                  <Styles.MonthContainer>
                    <Styles.MonthTitle>{month?.month}</Styles.MonthTitle>
                    <Styles.LineTitle></Styles.LineTitle>
                    <Styles.MonthTitle year>{month?.year}</Styles.MonthTitle>
                  </Styles.MonthContainer>
                  <div>
                    {month?.list.map((item) => (
                      <Styles.OptionSecBox>
                        <Styles.OptionSecBoxContainerMobile>
                          <Styles.IconSecContainer>
                            <SVG.PixIconSec
                              style={{ width: "100%", height: "100%" }}
                            />
                          </Styles.IconSecContainer>
                        </Styles.OptionSecBoxContainerMobile>

                        <Styles.OptionSecBoxContainerMobile secondary>
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              flexDirection: "row",
                            }}
                          >
                            <Styles.TextSecContainer>
                              <Styles.TitleSecurity>
                                {getTypeDesc(item?.type)}

                                {getMethodDesc(item?.method)}

                                <Styles.TitleSecurityM>
                                  {item?.status === "COMPLETED"
                                    ? ""
                                    : "Status: " + getStatusDesc(item?.status)}
                                </Styles.TitleSecurityM>
                              </Styles.TitleSecurity>
                              {item?.valueUnit ? (
                                <Styles.SubTitleSecurity>
                                  {texts.quotation}
                                  <StrongText custom={fonts.bold}>
                                    {masks.money(item?.valueUnit)}
                                  </StrongText>
                                </Styles.SubTitleSecurity>
                              ) : null}
                            </Styles.TextSecContainer>

                            {item?.asaasId &&
                              item?.type === "DEPOSIT" &&
                              item?.status === "COMPLETED" && (
                                <Styles.ButtonCashbackMob
                                  onClick={() =>
                                    sendRefund(item?.id, item?.method)
                                  }
                                  fullWidth={false}
                                >
                                  <Styles.ContentButtonCashback>
                                    <Styles.IconCash />
                                    <Styles.CashText>
                                      {texts.refundButton}
                                    </Styles.CashText>
                                  </Styles.ContentButtonCashback>
                                </Styles.ButtonCashbackMob>
                              )}
                          </div>

                          <Styles.TextSecContainer row>
                            <Styles.TitleSecurity>
                              {item?.qty
                                ? item?.qty + " - Biocoins"
                                : masks.money(item?.value)}
                            </Styles.TitleSecurity>
                            <Styles.SubTitleSecurity secondary>
                              {item?.date}
                            </Styles.SubTitleSecurity>
                          </Styles.TextSecContainer>
                        </Styles.OptionSecBoxContainerMobile>

                        <Styles.OptionSecBoxContainer>
                          <Styles.IconSecContainer>
                            <SVG.PixIconSec
                              style={{ width: "100%", height: "100%" }}
                            />
                          </Styles.IconSecContainer>
                          <Styles.TextSecContainer>
                            <Styles.TitleSecurity>
                              {getTypeDesc(item?.type)}
                              {getMethodDesc(item?.method)}
                            </Styles.TitleSecurity>
                            {item?.valueUnit ? (
                              <Styles.SubTitleSecurity>
                                {texts.quotation}
                                <StrongText custom={fonts.bold}>
                                  {masks.money(item?.valueUnit)}
                                </StrongText>
                              </Styles.SubTitleSecurity>
                            ) : null}
                          </Styles.TextSecContainer>
                        </Styles.OptionSecBoxContainer>

                        <Styles.TextSecContainer end>
                          <div>
                            <Styles.TitleSecurity>
                              {item?.status === "COMPLETED"
                                ? ""
                                : getStatusDesc(item?.status) + "| "}
                              {item?.qty
                                ? item?.qty + " Biocoins"
                                : masks.money(item?.value)}{" "}
                            </Styles.TitleSecurity>
                            <Styles.SubTitleSecurity>
                              {item?.date}
                            </Styles.SubTitleSecurity>
                          </div>

                          {item?.asaasId &&
                            item?.type === "DEPOSIT" &&
                            item?.status === "COMPLETED" && (
                              <Styles.ButtonCashback
                                onClick={() =>
                                  sendRefund(item?.id, item?.method)
                                }
                                fullWidth={false}
                              >
                                <Styles.ContentButtonCashback>
                                  <IoCashOutline size={24} />
                                  <CustomText
                                    fontFamily={fonts.heavy}
                                    fontSize={1.9}
                                  >
                                    {texts.refundButton}
                                  </CustomText>
                                </Styles.ContentButtonCashback>
                              </Styles.ButtonCashback>
                            )}
                        </Styles.TextSecContainer>
                      </Styles.OptionSecBox>
                    ))}
                  </div>
                </div>
              ))}
            </>
          )}
        </Styles.WhiteBox>
        <Styles.Back fullWidth={false} onClick={() => navigate(-1)}>
          {texts.back}
        </Styles.Back>
      </Styles.DashContainer>
    </div>
  );
}

export default Extract;
