import React from "react";

import Styles from "./styles";

import { useLocation, useNavigate } from "react-router-dom";

import BlogListFooter from "../blog/components/BlogListFooter";
import texts from "../../config/texts";
import { LocalStorage } from "../../utils";
function BlogArticle() {
  const actionText = texts[LocalStorage.getItem("language") ?LocalStorage.getItem("language"): 'pt'].actionButton;
  const [menuHeight, setHeight] = React.useState<any>(null);
  const navigate = useNavigate();

  const { state } = useLocation();

  React.useLayoutEffect(() => {
    if (!menuHeight) {
      const mh = document.getElementById("header-menu")?.offsetHeight;

      setHeight(mh);
    }
  }, [menuHeight]);

  const convertTime = (item: Date): string => {
    const dia = item.getDate();
    const mes = item.getMonth() + 1;

    const diaFormatado = dia.toString().padStart(2, "0");
    const mesFormatado = mes.toString().padStart(2, "0");

    return `${diaFormatado}/${mesFormatado}`;
  };

  return (
    <>
      <Styles.Container
        $image={`data:image/jpeg;base64,${state.img}`}
        $menuHeight={menuHeight}
      ></Styles.Container>
      <Styles.FaqContainer>
        <Styles.TitleBorder>
          <Styles.PostTitle>{state?.name}</Styles.PostTitle>
        </Styles.TitleBorder>

        <Styles.PostDescription
          dangerouslySetInnerHTML={{ __html: state?.content }}
        ></Styles.PostDescription>

        <Styles.Back
          onClick={() => {
            navigate(-1);
          }}
          fullWidth={false}
        >
          {actionText.back}
        </Styles.Back>
      </Styles.FaqContainer>
      <BlogListFooter noTablet />
    </>
  );
}

export default BlogArticle;
