import Styles from "../styles";
import texts from "../../../config/texts";
import React, { useRef } from "react";
import { Spacing } from "../../../config";
import styled from "styled-components";
import { LocalStorage } from "../../../utils";

function SelfieImage({
  selectedImage,
  setSelectedImage,
  confirmed,
  otherLoading,
  required,
  hasData,
}): JSX.Element {
  const text = texts[LocalStorage.getItem("language") ?LocalStorage.getItem("language"): 'pt'].nftTransfer;
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedImage(file);
      hasData(false);
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      if (!selectedImage) {
        hasData(true);
      }

      fileInputRef.current.click(); // Simula um clique no input de arquivo
    }
  };

  const ErrorMessage = styled.p(() => {
    return {
      margin: "3px 0px 0px",
      fontSize: "12px",
      color: "#F0166D",
      minHeight: Spacing(2.5),
      "&&.MuiFormHelperText-root": {
        "@media (max-width: 784px)": {
          fontSize: Spacing(1.2),
        },
      },
    };
  });

  return (
    <>
      <Styles.SelfieInput>
        {!confirmed && (
          <>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              ref={fileInputRef}
              style={{ display: "none" }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: Spacing(1),
                flexWrap: "wrap",
                width: "100%",
              }}
            >
              <Styles.ModalImport
                loading={otherLoading}
                $full
                fullWidth={false}
                onClick={handleButtonClick}
              >
                {text.attach}
              </Styles.ModalImport>
              {selectedImage && (
                <Styles.SelfieName>{selectedImage.name}</Styles.SelfieName>
              )}
            </div>
          </>
        )}
      </Styles.SelfieInput>
      {required && <ErrorMessage>{text.required}</ErrorMessage>}
    </>
  );
}

export default SelfieImage;
