import Styles from "./styles";
import React, { useContext } from "react";
import { StorageContext } from "../../contexts/StorageContext";
const GrainImage = process.env.REACT_APP_ASSETS_URL + "/imgs/grainBackground.webp";
import { FormFull } from "form-full";
import { Spacing, fonts } from "../../config";
import { hooks, LocalStorage, validations } from "../../utils";
import { Input } from "../../components";
import { api } from "../../services";
import ResponseError from "../../services/helpers/ResponseError";
import alerts from "../../utils/Alerts";
import { CircularProgress } from "@material-ui/core";
import texts from "../../config/texts";
import SelfieImage from "./components/SelfieImage";
import { useLocation, useNavigate } from "react-router-dom";
import { paths } from "../../navigation/navigate";
import { customModal } from "../../components/modals/utils";

function NFTTransfer(): JSX.Element {
  const text =
    texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].nftTransfer;
  const { loading, call } = hooks.useRequest();
  const [isMounted, setMount] = React.useState<boolean>(false);
  const [ref, setRef] = React.useState<any>(null);
  const [selectedImage, setSelectedImage] = React.useState<any | null>(null);
  const [confirmed, setConfirmed] = React.useState<any | null>(null);
  const [otherLoading, setOtherLoading] = React.useState<boolean>(false);
  const [required, setRequired] = React.useState<any>();
  const { isLogged, getData, addData, userData, setUserData } =
    useContext<any>(StorageContext);
  const navigate = useNavigate();
  const { state } = useLocation();

  const Mount = React.useCallback(() => {
    const user = getData("userData");

    if (!user && isLogged) {
      call(null, api.getUserData(), (data) => {
        if (data.ok) {
          if (data.data.imageId) {
            call(
              null,
              api.getUserImage(),
              (response) => {
                addData("userData", { ...data?.data, image: response?.data });
                setUserData({ ...data?.data, image: response?.data });
              },
              (response) => {
                if (response.status !== 404) {
                  const errorResponse = new ResponseError(response);

                  if (errorResponse.message) {
                    alerts.alertError(errorResponse.message);
                  }
                }
              }
            );
          } else {
            addData("userData", { ...data?.data, image: null });
            setUserData({ ...data?.data, image: null });
          }
        }
      });
    } else {
      setUserData(user);
    }
  }, [getData]);

  React.useEffect(() => {
    if (!isMounted) {
      Mount();
      setMount(true);
    }
  }, [Mount, isMounted]);

  const convertToBase64 = (file: File) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target) {
          resolve(event.target.result as string);
        } else {
          reject("Failed to convert to base64");
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const requestTransfer = async (digitalWallet) => {
    setOtherLoading(true);
    if (selectedImage) {
      try {
        const file = await convertToBase64(selectedImage);

        const image = {
          selfieImage: file
            .replace("data:image/png;base64,", "")
            .replace("data:image/jpeg;base64,", ""),
        };

        let id = state.nft.id;
        let data = {
          digitalWallet: digitalWallet,
          selfieImage: image.selfieImage,
        };
        call(null, api.transferNFT(id, data), (response) => {
          if (response.ok) {
            setConfirmed(true);
            customModal.setInfos(
              text.modalTitle,
              [text.modalText],
              {
                onClick: () => {
                  customModal.close();
                  navigate(paths.nfts);
                },
                label: "Ok",
              },
              null
            );
          }
        });
      } catch (error) {
        setOtherLoading(false);
      }
    } else {
      setOtherLoading(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        overflow: "hidden",
        flexDirection: "column",
      }}
    >
      <FormFull formRef={setRef} onSubmit={() => {}}>
        <Styles.DashContainer $image={GrainImage}>
          <Styles.YellowBall></Styles.YellowBall>
          <Styles.YellowBallTwo></Styles.YellowBallTwo>
          <Styles.WhiteBox>
            {loading ? (
              <CircularProgress
                size={64}
                style={{
                  color: "black",
                  alignSelf: "center",
                  justifyContent: "center",
                  marginBlock: Spacing(3),
                }}
              />
            ) : (
              <>
                <Styles.TitleBox>
                  <Styles.TitleConfig>{text.title}</Styles.TitleConfig>
                </Styles.TitleBox>
                <Styles.Title>{text.nameAndInfo}</Styles.Title>
                <Styles.InputTitle>{text.firstname}</Styles.InputTitle>
                <Input
                  inputStyle={{
                    border: "1.5px solid #01003B",
                    borderRadius: "95px",
                    color: "#616161",
                    fontFamily: fonts.regularCf,
                    fontSize: Spacing(2.75),
                    paddingInline: Spacing(3.75),
                    paddingBlock: Spacing(2),
                    marginTop: Spacing(0.75),
                    "& .MuiInputBase-input": {
                      padding: 0,
                      boxShadow: "none !important",
                    },
                    [`@media (max-width:${1024 - 1}px)`]: {
                      paddingBlock: Spacing(1.5),
                      paddingInline: Spacing(1.75),
                      fontSize: Spacing(1.6),
                    },
                    [`@media (max-width:${581 - 1}px)`]: {
                      paddingInline: Spacing(1),
                      paddingBlock: Spacing(0.5),
                      fontSize: Spacing(1),
                      marginTop: Spacing(0.15),
                    },
                  }}
                  name="name"
                  validation={validations.isValidName}
                  defaultValue={userData?.name.split(" ")[0]}
                  readOnly={userData?.name}
                />
                <Styles.InputTitle>{text.lastname}</Styles.InputTitle>
                <Input
                  inputStyle={{
                    border: "1.5px solid #01003B",
                    borderRadius: "95px",
                    color: "#616161",
                    fontFamily: fonts.regularCf,
                    fontSize: Spacing(2.75),
                    paddingInline: Spacing(3.75),
                    paddingBlock: Spacing(2),
                    marginTop: Spacing(0.75),
                    "& .MuiInputBase-input": {
                      padding: 0,
                      boxShadow: "none !important",
                    },
                    [`@media (max-width:${1024 - 1}px)`]: {
                      paddingBlock: Spacing(1.5),
                      paddingInline: Spacing(1.75),
                      fontSize: Spacing(1.6),
                    },
                    [`@media (max-width:${581 - 1}px)`]: {
                      paddingInline: Spacing(1),
                      paddingBlock: Spacing(0.5),
                      fontSize: Spacing(1),
                      marginTop: Spacing(0.15),
                    },
                  }}
                  name="lastname"
                  validation={validations.isValidName}
                  defaultValue={
                    userData?.name.split(" ")[
                      userData?.name.split(" ").length - 1
                    ]
                  }
                  readOnly={userData?.name}
                />

                <Styles.Title>{text.wallet}</Styles.Title>
                <Styles.InputTitle>{text.wallet}</Styles.InputTitle>
                <Input
                  inputStyle={{
                    border: "1.5px solid #01003B",
                    borderRadius: "95px",
                    color: "#616161",
                    fontFamily: fonts.regularCf,
                    fontSize: Spacing(2.75),
                    paddingInline: Spacing(3.75),
                    paddingBlock: Spacing(2),
                    marginTop: Spacing(0.75),
                    "& .MuiInputBase-input": {
                      padding: 0,
                      boxShadow: "none !important",
                    },
                    [`@media (max-width:${1024 - 1}px)`]: {
                      paddingBlock: Spacing(1.5),
                      paddingInline: Spacing(1.75),
                      fontSize: Spacing(1.6),
                    },
                    [`@media (max-width:${581 - 1}px)`]: {
                      paddingInline: Spacing(1),
                      paddingBlock: Spacing(0.5),
                      fontSize: Spacing(1),
                      marginTop: Spacing(0.15),
                    },
                  }}
                  name="digitalWallet"
                  required={text.required}
                  validation={validations.validNumber}
                  placeholder="xxxxxxxxxx"
                />

                <Styles.Title>{text.selfie}</Styles.Title>
                <Styles.InputTitle>{text.selfieInput}</Styles.InputTitle>
                <SelfieImage
                  hasData={setRequired}
                  confirmed={confirmed}
                  otherLoading={otherLoading}
                  selectedImage={selectedImage}
                  setSelectedImage={setSelectedImage}
                  required={required}
                />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Styles.RequestTransfer
                    onClick={async () => {
                      const error = await ref.testErrorsAndReturnData();

                      if (!error?.hasError && selectedImage) {
                        const digitalWallet = ref.getValue("digitalWallet");
                        requestTransfer(digitalWallet);
                      } else if (!selectedImage) {
                        setRequired(true);
                      }
                    }}
                  >
                    {text.request}
                  </Styles.RequestTransfer>
                </div>
              </>
            )}
          </Styles.WhiteBox>
          <Styles.Back fullWidth={false} onClick={() => navigate(paths.nfts)}>
            {text.back}
          </Styles.Back>
        </Styles.DashContainer>
      </FormFull>
    </div>
  );
}

export default NFTTransfer;
