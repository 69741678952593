import { Menu, MenuItem } from "@material-ui/core";
import styled from "styled-components";
import { ButtonText, FontStyles } from "../../../components";
const leafBackgorund =
  process.env.REACT_APP_ASSETS_URL + "/imgs/leafBackgorund.webp";
import { fonts, SVG } from "../../../config";

const Container = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    background: `linear-gradient(0deg, rgba(0, 0, 31, 0.30) 0%, rgba(0, 0, 31, 0.30) 100%), url(${leafBackgorund}), lightgray 50% / cover no-repeat`,
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",

    height: "100vh",
    maxHeight: theme.spacing(47.5),

    marginTop: `-${$menuHeight}px`,

    alignItems: "flex-end",
    justifyContent: "flex-end",

    paddingInline: spacing(15.625),
    paddingBlock: spacing(5),
    gap: spacing(2),

    [`@media (max-width:${1024 - 1}px)`]: {
      flexDirection: "column-reverse",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      marginTop: 0,
      paddingInline: spacing(6.5),
      paddingBlock: spacing(2.5),
      height: "fit-content",
      maxHeight: "none",
      paddingTop: spacing(25),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      paddingInline: spacing(2.75),
      paddingBlock: spacing(1.75),
      paddingTop: spacing(12.5),
    },
  };
});

const Logo = styled(SVG.BioLogo)(({ theme }) => {
  const { spacing } = theme;
  return {
    maxWidth: "420px",
    width: "100%",
    fill: "white",
    height: "auto",

    [`@media (max-width:${1024 - 1}px)`]: {
      maxWidth: "274.5px",
    },
    [`@media (max-width:${581 - 1}px)`]: { maxWidth: "145px" },
  };
});

const PrivacyContainer = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    paddingInline: spacing(18.5),
    paddingBlock: spacing(6),
    display: "flex",
    flexDirection: "row",
    width: "100%",
    gap: spacing(3.75),

    [`@media (max-width:${1024 - 1}px)`]: {
      padding: spacing(7.5),

      flexDirection: "column",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      padding: spacing(3.5),
    },
  };
});

const Item = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    width: "100%",
    display: "flex",
    gap: spacing(2.75),
    flexDirection: "column",
    marginBottom: spacing(4),
    [`@media (max-width:${1024 - 1}px)`]: {
      maxWidth: "100%",
      gap: spacing(2.5),
    },
    [`@media (max-width:${581 - 1}px)`]: { gap: spacing(1.5) },
  };
});

const Title = styled.p(({ theme }) => {
  const { spacing } = theme;
  return {
    fontFamily: fonts.boldCf,
    fontSize: spacing(3.75),
    padding: 0,
    margin: 0,
    textTransform: "uppercase",
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(3.5),
    },
    [`@media (max-width:${581 - 1}px)`]: { fontSize: spacing(2) },
  };
});

const Description = styled.p(({ theme }) => {
  const { spacing } = theme;
  return {
    fontFamily: fonts.regular,
    fontSize: spacing(2),
    padding: 0,
    margin: 0,
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: spacing(1.75),
    },
    [`@media (max-width:${581 - 1}px)`]: { fontSize: spacing(1) },
  };
});

const PageNav = styled.div(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    position: "absolute",
    bottom: " 20%",
    left: "50%",

    transition: "opacity ease-in-out 0.2s",
    boxShadow: "0 30px 40px 0 rgb(16 36 94 / 40%)",
    borderRadius: "4px",
    backgroundColor: "white",
    display: colors.background.paper,
    padding: spacing(1),
    opacity: 0.6,
    ":hover": {
      opacity: 1,
    },
  };
});

const ButtonNav = styled(ButtonText)(({ theme, $color }) => {
  const { palette: colors, spacing } = theme;
  return {
    ...FontStyles.medium12,
    padding: spacing(0.85),
    minWidth: 0,
    color: $color && colors.background.alternative,
    "& .MuiButton-startIcon": {
      margin: 0,
    },
  };
});
const Pdf = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    borderRadius: spacing(0.2),
    boxShadow: "0px 24px 80px #2C4D7426",
    width: "auto'",
  };
});

const Options = styled(Menu)(() => {
  return {
    boxShadow: "5px 5px 15px #00000029",
    "& .MuiMenu-paper": {
      boxShadow: "5px 5px 15px #00000029",
    },
  };
});

const OptionItem = styled(MenuItem)(({ theme, last }) => {
  const { palette: colors, spacing } = theme;
  return {
    color: "#C2C2C2",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: spacing(1),
    borderBottom: !last && "2px solid #C2C2C2",

    ...FontStyles.bold16,
    ":hover": {
      backgroundColor: colors.background.alternative + "60",
      color: colors.background.alternative,
    },
  };
});

const OptionButton = styled(ButtonText)(({ theme, $color }) => {
  return {
    color: $color && "#C2C2C2",
    height: " min-content",
  };
});

const Styles = {
  Container,
  Logo,
  PrivacyContainer,
  Item,
  Title,
  Description,

  PageNav,
  ButtonNav,
  Pdf,

  Options,
  OptionItem,

  OptionButton,
};

export default Styles;
