import React from "react";
import { customModal, customModalBlur } from "../utils";
import { Fade, IconButton } from "@material-ui/core";

import { Styles } from "../styles";
import { Theme } from "../../../config";
import ThemeProviderComponent from "../../others/ThemeProvider";

function ModalContainer() {
  const [infos, setInfos] = React.useState<any>({});
  const [open, setOpen] = React.useState(false);
  const { content, style, backdropClick } = infos;

  const handleModal = React.useCallback(
    (config: any) => {
      if (config.open) {
        setInfos(config.infos);
      }
      setOpen(config.open);
    },
    [setInfos, setOpen]
  );

  customModalBlur.setCallback(handleModal);
  return (
    <ThemeProviderComponent theme={Theme.Light}>
      <Styles.StyledModal
        open={open}
        onClose={(event, reason) => {
          if (backdropClick) {
            if (reason === "backdropClick" || reason === "escapeKeyDown") {
              setOpen(false);
            }
          } else if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setOpen(false);
          }
        }}
        BackdropComponent={Styles.StyledBackdrop}
        aria-labelledby="Título do modal"
        aria-describedby="Descrição do modal"
      >
        <Fade in={open}>
          <Styles.ContainerBlur $style={style}>
            {content ? content : null}
          </Styles.ContainerBlur>
        </Fade>
      </Styles.StyledModal>
    </ThemeProviderComponent>
  );
}

export default React.memo(ModalContainer);
