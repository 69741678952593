import { StrongText } from "../../../components";
const Animals = process.env.REACT_APP_ASSETS_URL + "/imgs/homeAnimals.webp";

import Styles from "../styles";
import texts from "../../../config/texts";
import { useNavigate } from "react-router-dom";
import { paths } from "../../../navigation/navigate";
import { LocalStorage } from "../../../utils";
interface Props {
  $style?: any;
}

function Purpose({ $style }: Props): JSX.Element {
  const currentText = texts[LocalStorage.getItem("language") ?LocalStorage.getItem("language"): 'pt'].home;
  const navigate = useNavigate();
  const goBlog = () => {
    navigate(paths.blog);
  };
  return (
    <Styles.AboutContainer style={{ ...$style }}>
      <Styles.AboutMobileContainer>
        <Styles.AnimalsImg src={Animals} />
        <Styles.AboutTitleMobile onClick={goBlog}>
          {currentText.purpose.title}
        </Styles.AboutTitleMobile>
      </Styles.AboutMobileContainer>

      <Styles.AboutTexts>
        <Styles.AboutTitle onClick={goBlog}>
          {currentText.purpose.title}
        </Styles.AboutTitle>

        <Styles.AboutDesciption>
          {currentText.purpose.about}
        </Styles.AboutDesciption>
      </Styles.AboutTexts>
    </Styles.AboutContainer>
  );
}

export default Purpose;
