import {
  FormControlLabel,
  FormControl,
  FormHelperText,
  Checkbox,
  withTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { useFormFull } from "form-full";

const useStyles = makeStyles((theme) => {
  const { spacing } = theme;
  return {
    icon: {
      width: spacing(3),
      height: spacing(3),
      borderRadius: spacing(0.5),
      [`@media (max-width:${1024 - 1}px)`]: {
        width: spacing(2),
        height: spacing(2),
        borderRadius: spacing(0.4),
      },
      [`@media (max-width:${581 - 1}px)`]: {
        width: spacing(1.48),
        height: spacing(1.48),
        borderRadius: spacing(0.3),
      },
    },
    border: {
      fill: "rgba(222, 226, 230, 0.60)",
      x: `calc(50% - ${spacing(3.18) / 2}px)`,
      y: `calc(50% - ${spacing(3.18) / 2}px)`,
      width: spacing(3.18),
      height: spacing(3.18),
      rx: spacing(0.2),
      [`@media (max-width:${1024 - 1}px)`]: {
        x: `calc(50% - ${spacing(2.08) / 2}px)`,
        y: `calc(50% - ${spacing(2.08) / 2}px)`,
        width: spacing(2.08),
        height: spacing(2.08),
      },
      [`@media (max-width:${581 - 1}px)`]: {
        x: `calc(50% - ${spacing(1.58) / 2}px)`,
        y: `calc(50% - ${spacing(1.58) / 2}px)`,
        width: spacing(1.58),
        height: spacing(1.58),
      },
    },
    iconCenter: {
      fill: "#E7FF00",
      width: spacing(3.1),
      height: spacing(3.1),
      rx: spacing(0.2),
      x: `calc(50% - ${spacing(3.1) / 2}px)`,
      y: `calc(50% - ${spacing(3.1) / 2}px)`,
      stroke: "white",
      strokeWidth: spacing(0.2),
      [`@media (max-width:${1024 - 1}px)`]: {
        x: `calc(50% - ${spacing(2.1) / 2}px)`,
        y: `calc(50% - ${spacing(2.1) / 2}px)`,
        width: spacing(2.1),
        height: spacing(2.1),
      },
      [`@media (max-width:${581 - 1}px)`]: {
        x: `calc(50% - ${spacing(1.6) / 2}px)`,
        y: `calc(50% - ${spacing(1.6) / 2}px)`,
        width: spacing(1.6),
        height: spacing(1.6),
      },
    },
  };
});

function CheckboxComponent(props) {
  const { value, error, onChange, ref } = useFormFull.field(props);

  const { label, required, disableError } = props;

  const classes = useStyles();
  return (
    <FormControl required={required} error={Boolean(error)}>
      <FormControlLabel
        inputRef={ref}
        value={value}
        checked={value === "true" ? true : false}
        onChange={(event: any) => {
          if (event.target?.value === "true") {
            onChange(event, "false");
          } else {
            onChange(event, "true");
          }
        }}
        control={
          <Checkbox
            color="primary"
            icon={
              <svg className={classes.icon}>
                <rect className={classes.border} />
              </svg>
            }
            checkedIcon={
              <svg className={classes.icon}>
                <rect className={classes.border} />
                <rect className={classes.iconCenter} />
                <CheckIcon style={{ fill: "black" }} />
              </svg>
            }
          />
        }
        label=""
        labelPlacement="end"
      />
      {disableError ? null : (
        <FormHelperText>{error ? error : " "}</FormHelperText>
      )}
    </FormControl>
  );
}

export default withTheme(CheckboxComponent);
