import Styles from "../styles";

import { useContext, useRef, useState } from "react";

const ConfigGrain = process.env.REACT_APP_ASSETS_URL + "/imgs/configGrain.webp";
import UploadImage from "./UploadImage";
import { useNavigate } from "react-router-dom";
import { StorageContext } from "../../../contexts/StorageContext";
import { hooks, LocalStorage } from "../../../utils";

import { paths } from "../../../navigation/navigate";
import { customModal } from "../../../components/modals/utils";
import { api } from "../../../services";
import { CircularProgress } from "@material-ui/core";
import Texts from "../../../config/texts";

function MainPage({
  className,

  list,
  goBack,
  goNext,
  user,
}: any): JSX.Element {
  const classes = Styles.useStyles();
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { setIsLogged, setUserData, userData } =
    useContext<any>(StorageContext);
  const text =
    Texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].config;
  const actionText =
    Texts[
      LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
    ].actionButton;
  const { call, loading } = hooks.useRequest();

  const cropToSquare = (base64Image: string) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const image = new Image();
    image.src = base64Image;

    image.onload = () => {
      const minSize = Math.min(image.width, image.height);
      const centerX = (image.width - minSize) / 2;
      const centerY = (image.height - minSize) / 2;

      canvas.width = minSize;
      canvas.height = minSize;
      ctx?.drawImage(
        image,
        centerX,
        centerY,
        minSize,
        minSize,
        0,
        0,
        minSize,
        minSize
      );

      const croppedBase64 = canvas.toDataURL("image/jpeg"); // Pode escolher outro formato se preferir

      customModal.setInfos(
        actionText.save,
        [],
        {
          onClick: () => {
            call(
              null,
              api.editUserImage({
                image: croppedBase64
                  .replace("data:image/png;base64,", "")
                  .replace("data:image/jpeg;base64,", ""),
              }),
              (response) => {
                if (response.ok) {
                  LocalStorage.setItem("userData", {
                    ...userData,
                    image: croppedBase64
                      .replace("data:image/png;base64,", "")
                      .replace("data:image/jpeg;base64,", ""),
                  });
                  setUserData({
                    ...userData,
                    image: croppedBase64
                      .replace("data:image/png;base64,", "")
                      .replace("data:image/jpeg;base64,", ""),
                  });
                } else {
                  fileInputRef?.current?.value === null;
                  const fileInput = fileInputRef.current;

                  if (fileInput && fileInput.value) {
                    fileInput.value = "";
                  }
                }
              }
            );
            customModal.close();
          },
          label: actionText.send,
        },
        {
          onClick: () => {
            customModal.close();
          },
          label: actionText.cancel,
        },
        <Styles.ModalContainer>
          <Styles.MotalSubtText>{text.image.title}</Styles.MotalSubtText>
          <Styles.UserImg $img={croppedBase64}></Styles.UserImg>
        </Styles.ModalContainer>
      );
    };
  };

  const onLogout = () => {
    setIsLogged(false);
    LocalStorage.removeItem("token");
    LocalStorage.removeItem("userData");
    navigate(paths.home);
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (containerRef.current) {
      setIsDragging(true);
      setStartX(e.clientX - containerRef.current.offsetLeft);
      setScrollLeft(containerRef.current.scrollLeft);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isDragging) return;
    if (containerRef.current) {
      const x = e.clientX - containerRef.current.offsetLeft;
      const walk = (x - startX) * 1;
      containerRef.current.scrollLeft = scrollLeft - walk;
    }
  };

  return (
    <>
      <Styles.WhiteBox>
        <Styles.TitleBox>
          <Styles.TitleConfig>{text.title}</Styles.TitleConfig>
          <Styles.ExitButton fullWidth={false} onClick={onLogout}>
            <Styles.ExitButtonIcon />
            {actionText.out}
          </Styles.ExitButton>
        </Styles.TitleBox>
        <Styles.BoxUserContent>
          <Styles.FloatingBox $img={ConfigGrain}></Styles.FloatingBox>

          <div style={{ position: "relative" }}>
            <Styles.UserImg $img={"data:image/png;base64," + user?.image}>
              {loading && <CircularProgress />}
            </Styles.UserImg>

            <UploadImage
              loading={loading}
              fileInputRef={fileInputRef}
              cropToSquare={cropToSquare}
            />
          </div>

          <Styles.NameUser>{user?.name}</Styles.NameUser>

          <Styles.OptionsList
            ref={containerRef}
            className={className}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
            onMouseMove={handleMouseMove}
          >
            {list.map((item) => {
              return (
                <Styles.OptionContainer>
                  <Styles.OptionIcon>
                    <item.icon className={classes.icon} />
                  </Styles.OptionIcon>
                  <Styles.OptionBox
                    onClick={() => {
                      if (item.page) {
                        navigate(item.page);
                      } else {
                        goNext(item.id);
                      }
                    }}
                  >
                    <Styles.OptionIconModal>
                      <item.icon className={classes.icon} />
                    </Styles.OptionIconModal>
                    <Styles.OptionTitle>{item.title}</Styles.OptionTitle>
                    <Styles.OptionSubTitle>{item.desc}</Styles.OptionSubTitle>
                  </Styles.OptionBox>
                </Styles.OptionContainer>
              );
            })}
          </Styles.OptionsList>
        </Styles.BoxUserContent>
      </Styles.WhiteBox>
      <Styles.Back fullWidth={false} onClick={goBack}>
        {actionText.back}
      </Styles.Back>
    </>
  );
}

export default MainPage;
