import { CancelToken } from "apisauce";
import React from "react";
import { StorageContext } from "../contexts/StorageContext";
import ResponseError from "../services/helpers/ResponseError";
import alerts from "./Alerts";

function useStateCallback(initialState?: any) {
  const [state, setState] = React.useState<any>(initialState);
  const cbRef = React.useRef<any>(null); // mutable ref to store current callback

  const setStateCallback = React.useCallback((state?: any, cb?: any) => {
    cbRef.current = cb; // store passed callback to ref
    setState(state);
  }, []);

  React.useEffect(() => {
    // cb.current is `null` on initial render, so we only execute cb on state *updates*
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = null; // reset callback after execution
    }
  }, [state]);

  return [state, setStateCallback];
}

function useConstructor(onBuilding?: any) {
  const created = React.useRef<any>(false);
  if (!created?.current) {
    created.current = true;
    onBuilding();
  }
}

function useMount(onMount?: any, onUnmount?: any) {
  const mounted = React.useRef(false);
  React.useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      onMount();
      return onUnmount;
    }
  }, [onMount, onUnmount, mounted]);
}

function useRequest() {
  const [loading, setLoading] = React.useState(false);

  const storage = React.useContext<any>(StorageContext);

  async function call(
    storageKey?: any,
    apiRequest?: any,
    callback?: any,
    customError?: (response: any) => void
  ) {
    setLoading(true);
    const response = await apiRequest;
    setLoading(false);

    if (response) {
      if (storageKey) {
        storage.addData(storageKey, response);
      }
      if (callback) {
        callback(response);
      }
      if (!response.ok) {
        if (customError) {
          customError(response);
        } else {
          const errorResponse = new ResponseError(response);
          
          if (errorResponse.message) {
            alerts.alertError(errorResponse.message);
          }
        }
      }
    }
    return response;
  }

  return { loading, call };
}

let abortController: AbortController | null = null;

export function cancelLoginRequest() {
  if (abortController) {
    abortController.abort();
  }
}

export function createAbortController() {
  abortController = new AbortController();
  return abortController.signal;
}

const hooks = {
  useStateCallback,
  useMount,
  useConstructor,
  useRequest,
  createAbortController,
  cancelLoginRequest,
};
export default hooks;
