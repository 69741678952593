import styled from "styled-components";
import { fonts } from "../../../config";
import { ButtonContained } from "../../../components";

const Container = styled.div(({ backgroundImg }) => {
  return {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: "100%",
    maxHeight: "319px",

    backgroundImage: `url(${backgroundImg})`,
    backgroundSize: "100% 100%",
    backgroundRepeat: "repeat",
    backgroundPosition: "center",

    [`@media (max-width:${1024 - 1}px)`]: { maxHeight: "502px" },
    [`@media (max-width:${581 - 1}px)`]: { maxHeight: "265px" },
  };
});

const TextContainer = styled.div(() => {
  return {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    zIndex: 3,
    marginLeft: "-60%",
    marginTop: "53px",
    width: "fit-content",
    alignSelf: "flex-start",

    [`@media (max-width:${1024 - 1}px)`]: {
      margin: 30,
      marginTop: "43px",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      margin: 20,
      marginTop: "23px",
    },
  };
});

const AboutTitle = styled.p(({ theme }) => {
  return {
    padding: 0,
    margin: 0,
    marginBottom: 16,
    fontSize: theme.spacing(5),
    color: "white",
    fontFamily: fonts.heavy,
    lineHeight: "normal",

    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: theme.spacing(6.28),
      marginBottom: 6,
      alignSelf: "flex-start",
    },

    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: theme.spacing(3.25),
    },
    [`@media (max-width:${321 - 1}px)`]: {
      alignSelf: "center",
      fontSize: theme.spacing(3),
      maxWidth: "calc(100vw - 16px)",
    },
  };
});

const AboutDescription = styled.p(({ theme }) => {
  return {
    padding: 0,
    margin: 0,
    fontSize: theme.spacing(1.75),
    fontFamily: fonts.light,
    lineHeight: "176%",
    color: "white",
    width: 415,
    marginBottom: theme.spacing(3),

    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: theme.spacing(2.18),
      width: 626,
      marginBottom: theme.spacing(6),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: theme.spacing(1.15),
      width: 330,
      marginBottom: theme.spacing(3),
    },
    [`@media (max-width:${321 - 1}px)`]: {
      width: 300,
      maxWidth: "calc(100vw - 16px)",
    },
  };
});

const StyledButton = styled(ButtonContained)(({ theme }) => {
  return {
    "&&.MuiButton-root": {
      width: 180,
      fontSize: theme.spacing(2.25),
      marginBottom: "53px",

      [`@media (max-width:${1024 - 1}px)`]: {
        width: 225,
        height: 47,
        fontSize: theme.spacing(2.8),
        marginBottom: "200px",
      },
      [`@media (max-width:${581 - 1}px)`]: {
        width: 120,
        height: 25,
        fontSize: theme.spacing(1.48),
        marginBottom: "100px",
      },
    },
  };
});

const IpadContainer = styled.div(() => {
  return {
    position: "absolute",
    top: -40,
    right: "18%",
    zIndex: 1,
    [`@media (max-width:${1024 - 1}px)`]: {
      top: 285,
      right: "10%",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      top: "auto",
      bottom: "-4vw",
      right: 20,
    },
  };
});

const IpadImg = styled.img(() => {
  return {
    width: 600,
    height: "auto",
    [`@media (max-width:${1024 - 1}px)`]: {
      width: 330,
      height: "auto",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      maxWidth: 200,
      width: "50vw",
      height: "auto",
    },
  };
});

const Styles = {
  Container,
  TextContainer,
  AboutTitle,
  AboutDescription,
  StyledButton,
  IpadContainer,
  IpadImg,
};

export default Styles;
