import { Backdrop, Modal, Paper } from "@material-ui/core";
import styled from "styled-components";
import { Spacing } from "../../../config";
import CloseIcon from "@material-ui/icons/Close";
import FontStyles from "../../styles/fontStyles";
import { ButtonContained, ButtonText } from "../..";

const StyledBackdrop = styled(Backdrop)({
  backdropFilter: "blur(5px)",
  WebkitBackdropFilter: "blur(5px)",
  backgroundColor: "#00000020",
});

const StyledModal = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  ":focus": {},
  [`@media (max-width:${581 - 1}px)`]: {
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
});

const Container = styled(Paper)(({ theme, noMaxWidth, $style }) => {
  const { spacing } = theme;
  return {
    "&&.MuiPaper-root": {
      position: "absolute",
      width: "auto",
      maxWidth: noMaxWidth ? "60vw" : 470,
      maxHeight: "90vh",
      paddingBlock: Spacing(3),
      top: "50%",
      left: "50%",
      transform: `translate(-50%, -50%)`,
      outline: "none",
      borderRadius: "36.357px",
      border: "1.653px solid rgba(231, 255, 0, 0.51)",
      background:
        "linear-gradient(232deg, rgba(231, 255, 0, 0.07) 3.16%, rgba(231, 255, 0, 0.00) 40.87%), rgba(5, 5, 0, 0.80)",
      boxShadow: "0px 6.61029px 6.61029px 0px rgba(0, 0, 0, 0.25)",
      backdropFilter: "blur(16.52573013305664px)",
      overflowY: "auto",
      ...$style,
    },
  };
});

const ContainerBlur = styled(Paper)(({ theme, $style }) => {
  const { spacing } = theme;
  return {
    "&&.MuiPaper-root": {
      display: "flex",
      flexDirection: "column",
      position: "relative", // Permitir que a imagem vaze para fora do modal
      width: "100%",
      maxWidth: "905px",
      maxHeight: "86vh",
      padding: theme.spacing(9.25),
      paddingBlock: theme.spacing(4.5),
      height: "100%",

      borderRadius: "36.357px",
      border: "1.653px solid rgba(231, 255, 0, 0.51)",
      background:
        "linear-gradient(232deg, rgba(231, 255, 0, 0.07) 3.16%, rgba(231, 255, 0, 0.00) 40.87%), rgba(5, 5, 0, 0.80)",
      boxShadow: "0px 6.61029px 6.61029px 0px rgba(0, 0, 0, 0.25)",
      backdropFilter: "blur(16.52573013305664px)",
      ...$style,
      [`@media (max-width:${1024 - 1}px)`]: {
        padding: theme.spacing(6.75),
        paddingBlock: theme.spacing(3),
        maxWidth: "80vw",
      },
      [`@media (max-width:${581 - 1}px)`]: {
        padding: theme.spacing(3.75),
        paddingBlock: theme.spacing(2),
        paddingTop: theme.spacing(8),
        maxWidth: "100vw",
        width: "100%",
        borderRadius: "80px 80px 0px 0px",
        background: "rgba(26, 32, 36, 0.90)",
        backdropFilter: "blur(5.264187812805176px)",
        border: "0px",
        minHeight: "65vh",
      },
    },
  };
});

const ContentContainer = styled.div(({ theme }) => {
  return {
    overflow: "hidden",
  };
});

const HeaderContainer = styled.div(({ theme, noExit }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingInline: spacing(3.12),
    // marginTop: noExit ? spacing(1.5) : spacing(0.5),
  };
});

const Close = styled(CloseIcon)(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    color: colors.carbon,
    width: spacing(3),
    height: spacing(3),
  };
});

const TextContainer = styled.div(({ theme }) => {
  return {
    color: "black",
    paddingBottom: Spacing(1),

    textAlign: "center",
    [`@media only screen and (max-height: 700px)`]: {
      padding: 0,
      margin: 0,
    },
  };
});
const PaddingModal = styled.div(({ theme }) => {
  return {
    paddingInline: Spacing(3.5),
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "flex-start",
    alignItems: "center",
  };
});
const ContentText = styled.p(({ theme, first }) => {
  const { palette: colors, spacing } = theme;
  return {
    ...FontStyles.medium16,
    color: "white",
    textAlign: "justify",
    paddingInline: spacing(3.12),
    marginBottom: !first ? spacing(1) : spacing(2),
    marginTop: spacing(1),
  };
});
const Title = styled.p(({ theme }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.bold18,
    color: "white",
    margin: 0,
    padding: 0,
  };
});
const FooterContainer = styled.div(({ theme }) => {
  return {
    marginTop: Spacing(1),
    paddingInline: Spacing(3.5),
    display: "flex",

    justifyContent: "flex-start",
    alignItems: "center",
  };
});

const ConfirmButton = styled(ButtonContained)(({ theme }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButton-root": {
      ...FontStyles.medium16,
      width: "auto",
      marginLeft: spacing(2),
    },
  };
});

const ExitButton = styled(ButtonText)(({ theme }) => {
  return {
    "&&.MuiButton-root": {
      ...FontStyles.medium16,

      textTransform: "capitalize",
    },
  };
});

const Styles = {
  StyledBackdrop,
  StyledModal,
  Container,
  ContentContainer,
  HeaderContainer,
  Close,
  TextContainer,
  PaddingModal,
  ContentText,
  Title,
  FooterContainer,
  ConfirmButton,
  ExitButton,
  ContainerBlur,
};

export default Styles;
