import { Button } from "@material-ui/core";
import { clamp } from "date-fns";
import React from "react";

import { ButtonContained, CustomText, FontStyles } from "../../components";
import { SVG, fonts, Spacing } from "../../config";
import texts from "../../config/texts";
import { LocalStorage } from "../../utils";

import Styles from "./styles";

function Faq() {
  const currentText = texts[LocalStorage.getItem("language") ?LocalStorage.getItem("language"): 'pt'].faq;
  const [menuHeight, setHeight] = React.useState<any>(null);
  const faqList = currentText.list;
  React.useLayoutEffect(() => {
    if (!menuHeight) {
      const mh = document.getElementById("header-menu")?.offsetHeight;

      setHeight(mh);
    }
  }, [menuHeight]);

  return (
    <>
      <Styles.Container $menuHeight={menuHeight}>
        <Styles.PagTitle>{currentText.title}</Styles.PagTitle>
        <Styles.Logo />
      </Styles.Container>
      <Styles.FaqContainer>
        <Styles.FaqList>
          {faqList.map((item, index) => (
            <Styles.FaqItem>
              <Styles.FaqTitle>
                {index + 1 + ". " + item.title}{" "}
              </Styles.FaqTitle>
              <Styles.FaqDescription
                style={{
                  fontFamily: fonts.regular,
                  fontSize: Spacing(2),
                  padding: 0,
                  margin: 0,
                }}
              >
                {item.description}
              </Styles.FaqDescription>
            </Styles.FaqItem>
          ))}
        </Styles.FaqList>

        <div
          style={{
            borderRadius: `clamp(${Spacing(1.5)}px, 100vw, ${Spacing(2)}px)`,
            width: "100%",
            maxWidth: "476px",
            backgroundColor: "#1A2024",
            paddingTop: Spacing(4),
            height: "fit-content",
            alignSelf: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: Spacing(1),
              paddingInline: `clamp(${Spacing(1.75)}px, 100%, ${Spacing(
                2.5
              )}px)`,
              paddingBottom: `clamp(${Spacing(0.5)}px, 100%, ${Spacing(
                1.5
              )}px)`,
            }}
          >
            <div
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "fit-content",
                height: "fit-content",
                maxHeight: `clamp(17.5px, 100%, 24px)`,
              }}
            >
              <SVG.CartaContorno />
              <SVG.CartaCor
                style={{
                  position: "absolute",
                  top: 0,
                  left: "50%",
                  transform: "translate(-50%, 0px)",
                }}
              />
            </div>
            <p
              style={{
                padding: 0,
                margin: 0,
                color: "white",
                lineHeight: "normal",
                ...FontStyles.regular16,
                fontSize: `clamp(${Spacing(1.5)}px, 100%, ${Spacing(2)}px)`,
              }}
            >
              {currentText.question}
            </p>
          </div>
          <p
            style={{
              padding: 0,
              margin: 0,
              color: "white",
              paddingInline: Spacing(2.5),
              lineHeight: "normal",
              paddingBottom: Spacing(3.75),
              ...FontStyles.semibold16,
              fontSize: `clamp(${Spacing(1.5)}px, 100%, ${Spacing(2)}px)`,
            }}
          >
            {currentText.chat}
          </p>
          <Button
            target="_blank"
            href="mailto: contact@biocoin.net.br"
            style={{
              width: "100%",
              color: "#1A2024",
              backgroundColor: "#E7FF00",
              borderRadius: 0,
              borderBottomLeftRadius: `clamp(${Spacing(
                1.5
              )}px, 100vw, ${Spacing(1.9)}px)`,
              borderBottomRightRadius: `clamp(${Spacing(
                1.5
              )}px, 100vw, ${Spacing(1.9)}px)`,
              textTransform: "initial",
              ...FontStyles.regular16,
              fontSize: `clamp(${Spacing(1.5)}px, 100%, ${Spacing(2)}px)`,
            }}
          >
            {currentText.startChat}
          </Button>
        </div>
      </Styles.FaqContainer>
    </>
  );
}

export default Faq;
