import React, { useContext, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { StorageContext } from "./contexts/StorageContext";
import pagesConfig from "./config/pagesConfig";
import pages from "./pages/index";
import { Spacing, Theme } from "./config";
import { makeStyles } from "@material-ui/core";
import { hooks, LocalStorage } from "./utils";
import { api } from "./services";
import ResponseError from "./services/helpers/ResponseError";
import alerts from "./utils/Alerts";

const useStyles = makeStyles((theme: any) => {
  return {
    pageContainer: {
      display: "flex",
      width: "100%",
      maxWidth: "100vw",
      flexDirection: "column",

      justifyContent: "flex-start",
      alignItems: "flex-start",
      overflowY: "hidden",
      overflowX: "hidden",

      backgroundColor: "white",
    },
    pageContainerLanding: {
      display: "flex",
      width: "100%",
      maxWidth: "100vw",
      flexDirection: "column",

      justifyContent: "flex-start",
      alignItems: "flex-start",
      overflowY: "hidden",
      overflowX: "hidden",

      backgroundColor: theme.palette.background.paper,
    },
  };
});

interface IUserProps {
  Home: () => Element;
  About: () => Element;
}

function App() {
  const classes = useStyles(Theme.Light);
  const location = useLocation();

  const { isLogged, cancelToken, logOut } = useContext<any>(StorageContext);
  const [menuHeight, setHeight] = React.useState<any>(null);
  const [menu, setMenu] = React.useState<any>(false);
  const [lg, setLg] = React.useState<any>(
    LocalStorage.getItem("language") ? LocalStorage.getItem("language") : "pt"
  );
  const { loading, call } = hooks.useRequest();
  const [coins, setCoins] = React.useState<any>(null);
 
  const { getData, addData, userData, setUserData, removeData } =
    useContext<any>(StorageContext);

  useEffect(() => {
    if (isLogged) {
      const token = LocalStorage.getItem("token");
      const pingWallet = LocalStorage.getItem("pingWallet");
      if (token && !Boolean(pingWallet)) {
        const eventSource = new EventSource(
          process.env.REACT_APP_API +
            `/wallet/sse?Authorization=${token?.replace("Bearer ", "")}`
        );
        addData("pingWallet", "true");
        eventSource.onmessage = (event) => {
          try {
            if (event.data !== "") {
              const newMessage = JSON.parse(
                event.data?.replace("changed: ", "")
              );
              const oldData = getData("userData");
            
              setCoins(newMessage);
              addData("userData", {
                ...oldData,
                image: null,
                ...newMessage,
              });
              setUserData((d) => {
                return {
                  ...d,
                  image: null,
                  ...newMessage,
                };
              });
            }
          } catch (error) {
            console.error("Failed to parse SSE data:", error);
            removeData("pingWallet");
          }
        };

        eventSource.onerror = (error) => {
          console.error("EventSource failed:", error);
          eventSource.close(); // Fecha a conexão SSE em caso de erro
          LocalStorage.removeItem("pingWallet"); // Remove pingWallet do armazenamento local
        };

        return () => {
          eventSource.close(); // Fecha a conexão SSE ao desmontar o componente
          LocalStorage.removeItem("pingWallet"); // Remove pingWallet do armazenamento local
        };
      }
    }
  }, [isLogged]);
  useEffect(() => {
    if (isLogged) {
      if (!userData && isLogged) {
        call(
          null,
          api.getUserData(),
          (data) => {
            if (data.ok) {
              LocalStorage.setItem("language", data.data?.language);
              setLg(data.data?.language);
              if (data.data.imageId) {
                call(
                  null,
                  api.getUserImage(),
                  (response) => {
                    call(null, api.getWallet(), (dataWallet) => {
                      if (dataWallet.ok) {
                        setCoins(dataWallet?.data);
                        addData("userData", {
                          ...data?.data,
                          image: response?.data,
                          ...dataWallet?.data,
                        });
                        setUserData({
                          ...data?.data,
                          image: response?.data,
                          ...dataWallet?.data,
                        });
                      } else {
                        addData("userData", {
                          ...data?.data,
                          image: response?.data,
                        });
                        setUserData({ ...data?.data, image: response?.data });
                      }
                    });
                  },
                  (response) => {
                    if (response.status !== 404) {
                      const errorResponse = new ResponseError(response);

                      if (errorResponse.message) {
                        alerts.alertError(errorResponse.message);
                      }
                    }
                  }
                );
              } else {
                call(null, api.getWallet(), (dataWallet) => {
                  if (dataWallet.ok) {
                    setCoins(dataWallet?.data);
                    addData("userData", {
                      ...data?.data,
                      image: null,
                      ...dataWallet?.data,
                    });
                    setUserData({
                      ...data?.data,
                      image: null,
                      ...dataWallet?.data,
                    });
                  } else {
                    addData("userData", { ...data?.data, image: null });
                    setUserData({ ...data?.data, image: null });
                  }
                });
              }
            }
          },
          (response) => {
            if (response.status === 401) {
              logOut();
            } else {
              const errorResponse = new ResponseError(response);

              if (errorResponse.message) {
                alerts.alertError(errorResponse.message);
              }
            }
          }
        );
      }
    }
  }, [isLogged, userData]);

  React.useLayoutEffect(() => {
    if (!menuHeight) {
      const mh = document.getElementById("header-menu")?.offsetHeight;

      setHeight(mh);
    }
  }, [menuHeight]);

  useEffect(() => {
    cancelToken.cancel();
  }, [location.pathname]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "100%",
        maxWidth: "100vw",
      }}
    >
      <pages.Menu
        coins={coins}
        userData={userData}
        openMenu={setMenu}
        isOpen={menu}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "nowrap",
          flex: 1,
          padding: 0,
          maxWidth: "100vw",
          overflowX: "hidden",
        }}
      >
        <pages.Header
          coins={coins}
          loading={loading}
          defaultLg={lg}
          userData={userData}
          openMenu={() => setMenu(true)}
        />
        {!isLogged ? (
          <div className={classes.pageContainerLanding}>
            <Routes>
              {pagesConfig.notLogged.map((item?: any, index?: any) => {
                const Component = pages[item.name as keyof IUserProps];
                return (
                  <Route
                    key={item.path + index}
                    path={item.path}
                    element={<Component />}
                  />
                );
              })}
            </Routes>
          </div>
        ) : (
          <div className={classes.pageContainerLanding}>
            <Routes>
              {pagesConfig.logged.map((item: any) => {
                if (item) {
                  const Component = pages[item.name as keyof IUserProps];
                  return (
                    <Route
                      key={item.navigationId}
                      path={item.path}
                      element={<Component />}
                    />
                  );
                } else {
                  return null;
                }
              })}
            </Routes>
          </div>
        )}
        <pages.Footer />
      </div>{" "}
    </div>
  );
}

export default App;
