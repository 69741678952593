import styled from "styled-components";

import fonts from "../../config/fonts";
import { Spacing } from "../../config";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Button, FormHelperText, Input, Link } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { ButtonContained, ButtonText, FontStyles } from "../../components";
const coin = process.env.REACT_APP_ASSETS_URL + "/imgs/GOLDEN_COIN.webp";
const grainImage = process.env.REACT_APP_ASSETS_URL + "/imgs/grainBackground.webp";

const Container = styled.div(({ theme, $menuHeight, imgSrc }) => {
  const { palette: colors } = theme;

  return {
    backgroundColor: "rgba(5, 5, 0, 1)",

    width: "100%",
    position: "relative",
    height: `auto`,
    minHeight: `calc(100vh - ${$menuHeight + 16}px)`,

    overflow: "hidden",
    backgroundImage: `url(${grainImage})`,
    backgroundSize: "cover",
  };
});
const ImageBG = styled.div(({ theme, $menuHeight, imgSrc }) => {
  const { palette: colors } = theme;

  return {
    display: "flex",
    width: "100%",
    position: "relative",
    height: `auto`,
    minHeight: `calc(100vh - ${$menuHeight + 16}px)`,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    backgroundImage: `url(${coin})`,
    backgroundPositionY: "center",
    backgroundRepeat: "no-repeat",
    overflow: "hidden",
    backgroundSize: "auto 90vh",
    backgroundPositionX: "-8vw",
    [`@media (max-width:${1024 - 1}px)`]: {
      backgroundImage: "none",
    },
    [`@media (max-width:${581 - 1}px)`]: {},
  };
});

const Content = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    display: "flex",
    position: "relative",

    justifyContent: "center",
    flexDirection: "column",
    padding: spacing(6.25),
    paddingInline: "clamp(18px, 11vw, 173px)",
    overflowX: "auto",

    background: "rgba(5, 5, 0, 0.82)",
    backdropFilter: "blur(9px)",

    width: "100%",
    maxWidth: "63vw",
    height: "100%",
    flex: 1,
    [`@media (max-width:${1024 - 1}px)`]: {
      maxWidth: "100vw",
      backdropFilter: "none",
      background: "none",
    },
    [`@media (max-width:${581 - 1}px)`]: {},
  };
});
const CardContent = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    display: "flex",
    position: "relative",

    justifyContent: "space-between",
    flexDirection: "column",
    padding: spacing(6.25),
    overflowX: "auto",
    textAlign: "left",
    backgroundColor: colors.background.default,
    borderRadius: spacing(1.1),

    width: spacing(72.5),
    maxHeight: spacing(85.5),
    height: "85%",
  };
});

const Card = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    backgroundColor: colors.background.default,
    borderRadius: spacing(1.1),

    padding: spacing(6.25),
    overflowX: "auto",

    width: spacing(72.5),
  };
});

const Header = styled.div(({ theme }) => {
  // const { spacing } = theme;
  return {
    width: "100%",
    height: "fit-content",

    display: "flex",
    alignSelf: "center",
    justifyContent: "center",
    marginBottom: Spacing(5),
  };
});
const Footer = styled.div(({ theme }) => {
  // const { spacing } = theme;
  return {
    width: "100%",
    height: "fit-content",

    display: "flex",
    alignSelf: "center",
    justifyContent: "flex-end",
    paddingInline: "19%",
    marginInline: "auto",
  };
});
const NavButtons = styled.div(({ theme }) => {
  // const { spacing } = theme;
  return {
    width: "fit-content",
    display: "flex",
    alignSelf: "center",
    justifyContent: "flex-start",
  };
});

const Title = styled.p(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    color: "white",
    margin: 0,

    ...FontStyles.bold42,
    lineHeight: "140.791%",
    textAlign: "center",
    paddingBottom: spacing(5),
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: Spacing(4.75),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: Spacing(2.5),
      paddingBottom: spacing(2.25),
    },
  };
});

const Subtitle = styled.p(({ theme }) => {
  const { spacing } = theme;
  return {
    color: "white",
    fontFamily: fonts.regularCf,
    fontSize: spacing(3),
    paddingBottom: spacing(4),
    margin: 0,
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: Spacing(2.5),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: Spacing(1.25),
      paddingBottom: spacing(1.5),
    },
  };
});

const EcomLogo = styled.img(({ theme }) => {
  // const { spacing } = theme;
  return {
    width: Spacing(15),
    height: "auto",
  };
});

const P = styled.p(({ theme, gray }) => {
  const { palette: colors } = theme;
  return {
    fontFamily: gray ? fonts.medium : fonts.bold,
    color: gray ? colors.grayText : colors.purple,
    fontSize: fonts.sizes.regular,
  };
});

const Span = styled.span(({ theme }) => {
  const { palette: colors } = theme;
  return {
    fontFamily: fonts.bold,
    color: colors.purple,
    fontSize: fonts.sizes.regular,
    marginLeft: 3,
  };
});

const NavigationTitle = styled.p(() => {
  return {
    ...FontStyles.medium12,
    color: "#C2C2C2",
    margin: 0,
  };
});
const NavigationPage = styled.p(() => {
  return {
    ...FontStyles.medium14,
    color: "#C2C2C2",
    margin: 0,
  };
});
const Return = styled(Button)(() => {
  return {
    ...FontStyles.bold14,
    color: "#2C4D74",
    margin: 0,
    fontWeight: "800",
  };
});
const ArrowCircle = styled.div(() => {
  return {
    border: "1px solid #EFEFEF",
    borderRadius: Spacing(20),
    marginRight: Spacing(2),
    height: Spacing(3),
    width: Spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
});
const ArrowLeft = styled(ArrowBackIcon)(() => {
  return {
    height: Spacing(1.8),
    width: Spacing(1.8),
  };
});
const ArrowRight = styled(ArrowForwardIcon)(() => {
  return {
    height: Spacing(1.8),
    width: Spacing(1.8),
  };
});
const InputCode = styled(Input)(() => {
  return {
    width: "100%",
    height: "80%",
    color: "#464646",
    "& .MuiInput-input": {
      fontSize: 30,
      marginLeft: 10,
      textTransform: "uppercase",
    },
  };
});
const ButtonReSend = styled(ButtonText)(() => {
  return {
    marginBottom: Spacing(4),
  };
});
const ErrorMessage = styled(FormHelperText)(() => {
  return {
    color: "red",
    minHeight: Spacing(2.5),
  };
});

const InputBox = styled.div(({ $Image }) => {
  return {
    marginBottom: Spacing(4.5),
  };
});

const MarginBottom = styled.div(({ theme, center }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    width: "100%",
    justifyContent: center ? "center" : "flex-start",
    alignItems: center ? "center" : "flex-start",
    marginBottom: spacing(5),
  };
});
const LabelContainer = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    marginTop: spacing(0),
    marginBottom: spacing(1),
    marginLeft: spacing(4),
    width: "100%",
    display: "flex",
  };
});
const InputCodeLabel = styled.p(({ theme }) => {
  return {
    ...FontStyles.bold16,
    color: "black",
    fontSize: 12,
  };
});

const Countdown = styled.p(({ theme }) => {
  return {
    width: "100%",
    margin: 0,
    padding: 0,

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#626366",
    ...FontStyles.medium16,
    fontFamily: fonts.light,
    marginBottom: Spacing(1.5),
  };
});

const ReSend = styled(Link)(({ theme, disabled }) => {
  const { palette: colors, spacing } = theme;
  return {
    width: "100%",
    textAlign: "center",
    cursor: !disabled ? "pointer" : "default",
    color: colors.text.secondary,
    opacity: disabled ? 0.3 : 1,
    margin: 0,
    padding: 0,
    textDecoration: "underline",

    paddingBottom: spacing(0.9),
    ...FontStyles.medium18,
    fontSize: spacing(3),
    lineHeight: "140.791%",
    [`@media (max-width:${1024 - 1}px)`]: {
      fontSize: Spacing(2),
    },
    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: Spacing(1),
    },
  };
});
const InfoIcon = styled(Info)(({ theme }) => {
  const { spacing } = theme;
  return {
    width: spacing(1.75),
    height: spacing(1.75),
    padding: 0,
    marginRight: spacing(0.5),
  };
});
const YellowBall = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    position: "absolute",

    top: 0,
    right: 0,
    transform: "translate(90%, -50%)",
    borderRadius: "996px",
    width: "798.961px",
    height: "996px",
    filter: "blur(225px)",
    background: "#E7FF00E6",

    zIndex: 0,

    [`@media (max-width:${1024 - 1}px)`]: {
      borderRadius: "514.6px",
      width: "268.364px",
      height: "268.364px",
      filter: "blur(116.25px)",
      top: -250,
      right: 200,
    },
    [`@media (max-width:${581 - 1}px)`]: {
      borderRadius: "514.6px",
      width: "292.1024px",
      height: "247.19px",
      filter: "blur(116.25px)",
      top: -250,
      right: 200,
    },
  };
});

const YellowBallTwo = styled.div(({ theme, $menuHeight }) => {
  const { spacing } = theme;
  return {
    position: "absolute",
    transform: "translate(-40%, 50%)",
    bottom: 0,
    left: 0,
    borderRadius: "631px",
    width: "631px",
    height: "601px",
    background: "#E7FF00E6",
    filter: "blur(300px)",

    [`@media (max-width:${1024 - 1}px)`]: {
      width: "554.205px",
      height: "467.963px",
      filter: "blur(155px)",
      transform: "translate(-75%, 75%)",
      borderRadius: "554.205px",
    },
    [`@media (max-width:${581 - 1}px)`]: {
      width: "292.1024px",
      height: "247.19px",
      filter: "blur(155px)",
      transform: "translate(-75%, 75%)",
      borderRadius: "554.205px",
    },
  };
});
const InputTitle = styled.p(({ theme }) => {
  const { spacing } = theme;
  return {
    ...FontStyles.regular18,
    fontSize: spacing(2.75),
    margin: 0,
    padding: 0,
    color: "white",
    lineHeight: "140.791%",

    [`@media (max-width:${581 - 1}px)`]: {
      fontSize: Spacing(1.5),
    },
  };
});

const SubmitButton = styled(ButtonContained)(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    "&&.MuiButton-root": {
      fontFamily: fonts.heavy,
      fontSize: spacing(3.75),
      lineHeight: "140.791%",
      [`@media (max-width:${1024 - 1}px)`]: {
        fontSize: Spacing(2.5),
      },
      [`@media (max-width:${581 - 1}px)`]: {
        fontSize: Spacing(1.5),
      },
    },
  };
});

const BackButton = styled(ButtonText)(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    "&&.MuiButton-root": {
      fontFamily: fonts.heavy,
      fontSize: spacing(3.25),
      lineHeight: "140.791%",
      [`@media (max-width:${1024 - 1}px)`]: {
        fontSize: Spacing(2),
      },
      [`@media (max-width:${581 - 1}px)`]: {
        fontSize: Spacing(1.25),
      },
    },
  };
});

const Styles = {
  Container,
  Content,
  Title,
  Subtitle,
  Header,
  EcomLogo,
  P,
  Span,
  ImageBG,
  Footer,
  NavigationTitle,
  NavigationPage,
  Return,
  ArrowLeft,
  ArrowRight,
  ArrowCircle,
  InputCode,
  ButtonReSend,
  ErrorMessage,
  InputBox,
  NavButtons,
  MarginBottom,
  LabelContainer,
  InputCodeLabel,
  Countdown,
  ReSend,
  InfoIcon,
  CardContent,
  Card,
  YellowBallTwo,
  YellowBall,
  InputTitle,
  SubmitButton,
  BackButton,
};

export default Styles;
