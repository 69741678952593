import { ButtonContained, StrongText } from "../../../components";
const Animals = process.env.REACT_APP_ASSETS_URL + "/imgs/homeAnimals.webp";

import Styles from "../styles";
import { Style } from "@material-ui/icons";
import { Spacing } from "../../../config";
import { useNavigate } from "react-router-dom";
import React from "react";
import { hooks } from "../../../utils";
import { api } from "../../../services";
import { paths } from "../../../navigation/navigate";
import { CircularProgress } from "@material-ui/core";
interface Props {
  noTablet?: boolean;
}
const convertTime = (item: Date): string => {
  const dia = item.getDate();
  const mes = item.getMonth() + 1;

  const diaFormatado = dia.toString().padStart(2, "0");
  const mesFormatado = mes.toString().padStart(2, "0");

  return `${diaFormatado}/${mesFormatado}`;
};
const PAGE_SIZE = 9;
function BlogListFooter({ noTablet }: Props): JSX.Element {
  const [blogList, setBlogList] = React.useState<any>({});
  const [isMounted, setMount] = React.useState<boolean>(false);

  const navigate = useNavigate();
  const { loading, call } = hooks.useRequest();

  const Mount = React.useCallback(() => {
    call(
      "blogList",
      api.getBlogList({
        page: 0,
        size: PAGE_SIZE,
      }),
      async (response) => {
        if (response.ok) {
          let newList: any[] = [];

          for (const item of response?.data?.content) {
            await call(null, api.getImage(item.imageId, item.id), (response) => {
              newList.push({ ...item, img: response?.data });
            });
          }

          setBlogList({
            ...response.data,
            content: newList,
          });
        }
      }
    );
  }, []);

  React.useEffect(() => {
    if (!isMounted) {
      Mount();
      setMount(true);
    }
  }, [Mount, isMounted]);

  return (
    <Styles.FooterContainer $noTablet={noTablet}>
      <Styles.FooterTitle>BLOG</Styles.FooterTitle>
      <Styles.ScrollList>
        {loading ? (
          <CircularProgress
            size={64}
            style={{
              color: "black",
              alignSelf: "center",
              justifyContent: "center",
              marginBlock: Spacing(3),
            }}
          />
        ) : blogList?.content?.length > 0 ? (
          blogList?.content?.map((item) => (
            <Styles.ScrollCard
              fullWidth={false}
              onClick={() => {
                navigate(`/blog/article/${item?.id}`, {
                  state: { ...item },
                });
                document.getElementById("header-menu")?.scrollIntoView();
              }}
            >
              <Styles.ScrollCardContent>
                <Styles.BlogImage $src={`data:image/jpeg;base64,${item.img}`} />
                <div
                  style={{
                    display: "flex",
                    gap: Spacing(1),
                    alignItems: "center",
                  }}
                >
                  <Styles.BlogText>BLOG</Styles.BlogText>
                  <Styles.DateText>
                    {convertTime(new Date(item.createdAt))}
                  </Styles.DateText>
                </div>
                <Styles.DateText>{item.name}</Styles.DateText>
              </Styles.ScrollCardContent>
            </Styles.ScrollCard>
          ))
        ) : (
          <p>Sem publicações no blog</p>
        )}
        {blogList?.totalPages > 1 && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <ButtonContained
              style={{
                backgroundColor: "#1A2024",
                color: "#E7FF00",
                minWidth: "max-content",
                marginRight: "20px",
              }}
              fullWidth={false}
              onClick={() => navigate(paths.blog)}
            >
              Ver mais
            </ButtonContained>
          </div>
        )}
      </Styles.ScrollList>
    </Styles.FooterContainer>
  );
}

export default BlogListFooter;
